import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyContractService } from '@services/mycontract.service';
import { ContractDetailsModalComponent } from '../contract-details-modal/contract-details-modal.component';

@Component({
  selector: 'app-contract-summary',
  templateUrl: './contract-summary.component.html',
  styleUrls: ['./contract-summary.component.css']
})
export class ContractSummaryComponent implements OnInit {

  @Input() commercialCondition: any;
  @Input() principalPaymentMethodType: number;

  contractBillingCuttingCycle: string;

  constructor(
    private modalService: NgbModal,
    private myAccServ: MyContractService
  ) { }

  ngOnInit(): void {
    this.contractBillingCuttingCycle = this.myAccServ.contractBillingCuttingCycle;
  }

  goToDetails() {
    const modalRef = this.modalService.open(ContractDetailsModalComponent);
    modalRef.componentInstance.commercialCondition = this.commercialCondition;
    modalRef.componentInstance.principalPaymentMethodType = this.principalPaymentMethodType;
  }
}
