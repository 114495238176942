<div class="batch-management container-fluid">

  <div class="row">
    <header class="col-12 Dashboard_Overview">
      <h1 data-e2e="page-title" class="batch-management__title">/ Gestão em lote</h1>
    </header>

    <div class="batch-management__link-page">
      <a
        class="link-page-not-bg"
        id="link-page-myvehicles"
        data-e2e="link-page-myvehicles"
        routerLink="/meus-veiculos"
      >
        <img class="link-page-not-bg__icon icon-traffic" alt="Ícone seta esquerda">
        Meus veículos
      </a>
    </div>

    <app-batch-management-card></app-batch-management-card>

  </div>
</div>
