<div class="d-flex justify-content-center">
    <div class="col-10 Dashboard_col_1">
        <div class="container-repom">
            <div class="box-title">
                <span data-e2e="available-contracts-title">{{ title }}</span>
            </div>

            <div class="card-body contract">


                <!-- Search Contracts -->
                <div *ngIf="!isLoadingContracts" class="container-search">
                    <p class="search-title">Buscar por</p>
                    <form class="form-search" [formGroup]="searchTypeForm">
                        <div class="select-box">
                            <select class="select-box__select" id="form-search-select" data-e2e="form-search-select"
                                formControlName="searchType" (change)="onChangeSearchType()">
                                <ng-container *ngFor="let type of searchTypes">
                                    <option *ngIf="!isAtendimentoRoute || type.description !== 'Todos'"
                                        id="form-search-type-{{type.id}}" [attr.data-e2e]="'form-search-type-'+type.id"
                                        [value]="type.id">
                                        {{type.description}}
                                    </option>
                                </ng-container>
                            </select>
                        </div>


                        <div class="input-box">
                            <img class="input-box__img-before" src="/assets/img/icon-search-g.svg" alt="Ícone lupa">
                            <input class="input-box__input" [class.is-invalid]="
              searchTypeForm.controls.searchField.invalid &&
              searchTypeForm.controls.searchField.touched
            " id="form-search-input" data-e2e="form-search-input" formControlName="searchField" type="text"
                                placeholder="{{ getPlaceholder() }}" maxlength="100" [mask]="getMask()"
                                (keyup)="isTypingInput = true" (input)="onInputChange()">
                            <img *ngIf="handleClear()" class="input-box__img-after"
                                src="/assets/img/ico-invalid-password-requirement.svg" alt="Ícone cículo com x"
                                id="form-search-clear" data-e2e="form-search-clear" (click)="clearSearch()">
                        </div>
                        @if(isAtendimentoRoute){
                        <button class="btn btn-secondary" id="form-search-btn" data-e2e="form-search-btn"
                            [disabled]="isLoading || !isButtonEnabled()" (click)="onSearchBackOffice()">
                            Buscar
                        </button>
                        }@else{
                        <button class="btn btn-secondary" id="form-search-btn" data-e2e="form-search-btn"
                            [disabled]="isLoading || !isButtonEnabled()" (click)="onSearch()">
                            Buscar
                        </button>
                        }


                    </form>

                    <app-vmessage id="form-search-input-msg-error" data-e2e="form-search-input-msg-error" *ngIf="
                      searchTypeForm.controls.searchField.invalid &&
                      searchTypeForm.controls.searchField.touched
                    " errorMessage="{{ 'Campo obrigatório' }} ">
                    </app-vmessage>


                    <section class="container-results" *ngIf="totalContractsList > 0 && !isLoading">
                        <p id="msg-results" data-e2e="msg-results" *ngIf="verifyRelationConsultantUse">{{
                            messageLastAccess }}</p>
                        <p id="msg-results" data-e2e="msg-results">{{ messageResults }}</p>
                    </section>
                </div>

                <!-- Loader Search Contracts -->
                <div class="d-flex justify-content-center col-12" *ngIf="isLoadingContracts">
                    <div class="container-loading">
                        <app-loader id="loader-search-contracts" data-e2e="loader-search-contracts"
                            [isLoading]="isLoading" message="{{ messageLoader }}"></app-loader>
                    </div>
                </div>

                <!-- Contracts List -->
                <div class="container-contract-cards" *ngIf="contractList.length > 0">
                    <div class="col-lg-6 mt-4" *ngFor="let contract of contractList; let i = index"
                        [hidden]="isLoading">
                        <div class="contract-card card shadow" [ngClass]="getContractClass(contract)"
                            (click)="selectContract(contract)">
                            <div class="status"
                                *ngIf="contract.lastBillStatusType == 6 && contract.contractBlockLevel !== 3">
                                <img src="../../../assets/img/money_off.svg" alt="Ícone sifrão cortado" />
                                <span [attr.data-e2e]="'overdue-invoice-msg-' + i" id="block-title{{i}}">fatura
                                    vencida</span>
                            </div>

                            <div class="status" *ngIf="contract.contractBlockLevel == 3">
                                <img src="../../../assets/svg/block.svg" alt="Ícone bloqueado" />
                                <span [attr.data-e2e]="'financial-block-msg-' + i" id="block-title{{i}}">bloqueio
                                    financeiro
                                </span>
                            </div>

                            <div class="contractInfos" id="contract-card-{{i}}" [attr.data-e2e]="'contract-card-'+i">
                                <div class="head">
                                    <img src="assets/svg/user.svg" alt="Ícone usuário" />
                                    <span [attr.data-e2e]="'contract-description-' + i" id="description{{i}}">{{
                                        contract.description }}
                                    </span>
                                </div>

                                <div class="info">
                                    <p [attr.data-e2e]="'info-contract-document-number-' + i"
                                        id="document-number-{{i}}">
                                        {{ contract.documentNumberCompanyContract | mask: '00.000.000/0000-00' }}
                                        <br />
                                        Contrato: <span [attr.data-e2e]="'contract-id-' + i" id="contract-id-{{i}}">{{
                                            contract.id }}</span>
                                    </p>

                                    <p [attr.data-e2e]="'info-contract-nickname-' + i" id="contract-nickname-{{i}}"
                                        [ngClass]="contract.nameContract ? 'hasContractName' : ''">
                                        {{ contract.nameContract }}
                                    </p>
                                </div>
                            </div>

                            <div class="popover-body">
                                <button class="btn-popover" id="edit-contract-btn-{{i}}"
                                    [attr.data-e2e]="'open-edit-contract-modal-btn-'+i" type="button" placement="left"
                                    ngbTooltip="Informar o nome do contrato" (click)="openEditContractModal(contract)">
                                    <img src="../../../assets/svg/edit.svg" alt="Ícone lápis" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Loader Contracts -->
                <div class="m-auto" *ngIf="isLoading">
                    <div class="container-loading">
                        <app-loader id="loader-contracts" data-e2e="loader-contracts" [isLoading]="isLoading"
                            message="{{ messageLoader }}"></app-loader>
                    </div>
                </div>

                @if (totalContractsList == 0 ) { <div class="batch-tag-block-unblock__no-results div-ajustes-layout">
                    <div class="sub-div-ajustes-layout">
                        <app-no-results-msg [inTitle]="noResultMsgTitle"
                            [inSubtitle]="noResultMsgSubtitle"></app-no-results-msg>
                    </div>
                </div>
                }
            </div>

        </div>

        <div class="container-pagination pagination-custom" *ngIf="!isLoadingContracts && totalContractsList > 10">
            <app-pagination id="pagination-component" data-e2e="pagination-component" [totalItems]="totalContractsList"
                [itemsPerPage]="10" [maxPages]="3" [currentPage]="searchFieldParams?.page"
                (pageChangeEvent)="onPageChange($event)"></app-pagination>
        </div>
    </div>
</div>