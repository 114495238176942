<main class="TransactionPartnerConciliation">
  <div class="container header-page">
    <h1 data-e2e="page-title">Histórico de conciliações</h1>
    <button
          class="btn btn-primary"
          translate
          data-e2e="export-btn"
          (click)="openImportDocumentsModal()"
        >
        <img class="button-icon" src="assets/svg/icon-import.svg" />
          Importar novo documento
        </button>
  </div>

  <section class="table-card">
    <div class="table-header">
      <div class="table-header-left">
        <div class="label-select">
          <label class="out-label" for="select-input">Quantidade de linhas</label>
          <select
            id="select-input"
            name="perPages"
            data-e2e="perPages"
            class="per-pages"
            (change)="onPageSizeChange($event)"
          >
            <option *ngFor="let item of perPages" [value]="item">
              {{ 'exibir ' + item + ' linhas' }}
            </option>
          </select>
          <img src="assets/svg/form-select-icon.svg" alt="arrow-select" />
        </div>

        <div class="label-select partners" ngbDropdown #myDrop="ngbDropdown" autoClose="outside">
          <label class="out-label" for="select-input-partners">Filtre por parceiro</label>
          <button
          type="button"
          class="dropdown-partners"
          id="dropdownManual"
          ngbDropdownAnchor
          (click)="myDrop.open(); isOpen = true"
          >Selecione<img src="assets/svg/form-select-icon.svg" alt="arrow-select" /></button>

          <div ngbDropdownMenu class="dropdown-partners body" aria-labelledby="dropdownManual">
            <div class="dropdown-item" *ngFor="let partner of SourcePartners; let i = index">
              <input type="checkbox" class="partner-checkbox" [id]="'partner-checkbox'+ i" [name]="'partner-checkbox'+ i" [value]="partner.id" (change)="onSelectPartners(partner.id, $event.target.checked)"/>
              <label class="partner-name" [for]="'partner-checkbox'+ i">
                {{partner.ipefName}}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="download-button">
        <button
          [disabled]="noFiles"
          class="btn btn-primary"
          translate
          data-e2e="export-btn"
          (click)="Download()"
        >
          Download CSV

          <img class="button-icon" src="assets/img/ico-download.svg" />
        </button>
      </div>
    </div>
    <div class="table-main">
      <table class="table">
        <thead class="thead">
          <tr>
            <th
              *ngFor="let column of tableHeader"
              [ngClass]="bindOrderByClass(column[0])"
            >
              <div
                class="btn-order-table"
                [attr.data-e2e]="'sort-' + column[0] + '-date-btn'"
                translate
              >
                {{ column[1] }}
              </div>

              <div class="round-order" (click)="onOrderByChange(column[0])">
                <img src="assets/svg/order-table.svg" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="!isLoading && noFiles">
          <p class="empty-search-msg" translate>No Transactions Found</p>
        </tbody>

        <tbody *ngIf="!isLoading">
          <tr *ngFor="let transaction of TollVoucherConciliationFiles?.tollVoucherConciliationFiles ; let i = index">
            <td [attr.data-e2e]="'table-referenceDate-' + i" class="reference-date">
              {{ transaction?.referenceDate}}
            </td>

            <td [attr.data-e2e]="'table-partnerName-' + i">
              {{ transaction?.partnerName }}
            </td>
            <td [attr.data-e2e]="'table-registrationDate' + i">
              {{ transaction?.registrationDate}}
            </td>
            <td [attr.data-e2e]="'table-reconcilValue-'+ i">
              {{ transaction?.reconciledValue | currency:'BRL':'symbol':'1.2-2' }}
            </td>
            <td [attr.data-e2e]="'table-status-' + i" class="table-status-error" >
                <ng-container *ngIf="transaction?.vprConciliationFileStatusId === Enum.Conciliated">
                  <i><img src="assets/svg/icon-check-green-circle.svg" alt=""></i> Conciliado
                </ng-container>
                <ng-container *ngIf="transaction?.vprConciliationFileStatusId === Enum.Error">
                    <span><img src="assets/svg/icon-error-circle-red.svg" alt=""> Erro</span>  <button class="custom-button btn btn-primary" (click)="openErrorsModal(transaction.conciliationErrors, transaction.totalErrors,  transaction)">Ver detalhes</button>
                </ng-container>
                <ng-container *ngIf="transaction?.vprConciliationFileStatusId === Enum.Processing">
                  <i><img src="assets/svg/icon-warning-yellow.svg" alt=""></i> Em Processamento
                </ng-container>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ message | translate }}"
        ></app-loader>
      </div>
    </div>

    <div class="pagination-custom"  *ngIf="
    TollVoucherConciliationFiles?.total > queryArguments.pageSize && !isLoading
  ">
      <ngb-pagination
        (pageChange)="onPaginationChange($event)"
        [pageSize]="queryArguments.pageSize"
        [maxSize]="5"
        [collectionSize]="TollVoucherConciliationFiles?.total"
        [(page)]="queryArguments.page"
        [rotate]="true"
        [boundaryLinks]="true"
        [ellipses]="false"
        display="dynamic"
      >
      </ngb-pagination>
    </div>
  </section>

</main>
