import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { FormsModule } from '@angular/forms';
import { SaldovprVeiculosRoutingModule } from './routes/saldovpr-veiculos-routing.module';
import { SaldovprVeiculosComponent } from './saldovpr-veiculos.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    FormsModule,
    RouterModule,
    SaldovprVeiculosRoutingModule,
    PopoverModule.forRoot(),
  ],
  declarations: [
    SaldovprVeiculosComponent
  ],
  providers: [provideNgxMask()],
})

export class SaldovprVeiculosModule {}
