import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';
import { CoreModule } from 'src/app/core/core.module';
import { BatchManagementComponent } from './batch-management.component';
import { BatchManagementRoutingModule } from './routes/batch-management-routing.module';
import { BatchManagementCardComponent } from './components/batch-management-card/batch-management-card.component';
import { BatchTagActivateComponent } from './components/batch-management-card/components/batch-tag-activate/batch-tag-activate.component';
import { TagActivateUploadModalComponent } from './components/batch-management-card/components/tag-activate-upload-modal/tag-activate-upload-modal.component';
import { BatchTagFileInconsistenciesModalComponent } from './components/batch-management-card/components/batch-tag-activate/components/batch-tag-file-inconsistencies-modal/batch-tag-file-inconsistencies-modal.component';
import { BatchTagBlockUnblockComponent } from './components/batch-management-card/components/batch-tag-block-unblock/batch-tag-block-unblock.component';
import { BatchTagBlockUnblockHistoryComponent } from './components/batch-management-card/components/batch-tag-block-unblock-history/batch-tag-block-unblock-history.component';
import { TagBlockUnblockModalComponent } from './components/batch-management-card/components/tag-block-unblock-modal/tag-block-unblock-modal.component';
import { TagBlockUnblockInconsistenciesModalComponent } from './components/batch-management-card/components/batch-tag-block-unblock-history/components/tag-block-unblock-inconsistencies-modal/tag-block-unblock-inconsistencies-modal.component';

@NgModule({
  declarations: [
    BatchManagementComponent,
    BatchManagementCardComponent,
    BatchTagActivateComponent,
    TagActivateUploadModalComponent,
    BatchTagFileInconsistenciesModalComponent,
    BatchTagBlockUnblockComponent,
    BatchTagBlockUnblockHistoryComponent,
    TagBlockUnblockModalComponent,
    TagBlockUnblockInconsistenciesModalComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    BatchManagementRoutingModule,
  ],
  providers: [DatePipe, provideNgxMask()],
})
export class BatchManagementModule { }
