<div class="mt-6 px-0 Billhierarchy">
  <div class="row">
    <div class="col-sm-auto pr-0 BillhierarchyColumn1">
      <div class="row">
        <div class="col-12 mt-3 groupsTitle">
          <div class="groups">
            <div class="d-flex justify-content-center">
              <p data-e2e="group-title">GRUPOS</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3 mt-3" style="margin-top: 2rem !important">
        <div class="col-12 px-0">
          <button
            data-e2e="filter-toggle-btn"
            type="button"
            id="button-animated"
            aria-controls="dropdown-animated"
            class="btn btn-filter dropdown-toggle"
            [class.without-border-bottom-radius]="isOpenDropdown"
            [class.active]="isOpenDropdown"
            [class.large-box]="isOpenDropdown"
            (click)="toogle()"
          >
            <img src="assets/img/filter.svg" class="button-icon" />
            Filtros
          </button>

          <div class="filter" *ngIf="open">
            <div class="form-header-search">
              <label data-e2e="search-label" for="formNumberOrderInput">Pesquisar</label>

              <input
                data-e2e="hierarchy-search-input"
                [value]="BillHierarchyQueryArguments.SearchHierarchy"
                id="formNumberOrderInput"
                placeholder="busca de hierarquia"
                type="text"
                (keyup)="onFullNameKeyup($event)"
                class="form-control ico-insert-text"
              />

              <button
                data-e2e="hierarchy-search-btn"
                id="searchButton"
                type="button"
                (click)="onSearchClick($event)"
                class="btn btn-primary btn btn-search"
              >
                <i class="ico ico-search-white"></i><span>buscar</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ 'Carregando...' }}"
      ></app-loader>
      </div>

      <ul
        class="box-vehicles"
        *ngFor="let hierarchy of BillHierarchyResultDto?.data; let i = index"
        [ngClass]="ChangeClassSelect(i)"
        (click)="
          gridHierarchy.OpenHierarchyInfos(hierarchy.hierarchyId);
          SelectVehicle(i)
        "
      >
        <li>
          <div class="row align-items-center pl-3">
            <div class="col-8 d-flex flex-column">
              <span
                [attr.data-e2e]="'hierarchy-description-' + i"
                id="detailHierarchyDescription{{ i }}"
                class="hierarchyDesc"
                >{{ hierarchy.hierarchyDescription }}</span
              >
              <span [attr.data-e2e]="'hierarchy-breadcrumb-' + i" id="detailBreadCrumb{{ i }}" class="fs-11">{{
                getBreadCrumb(hierarchy.hierarchyId)
              }}</span>
              <span [attr.data-e2e]="'hierarchy-total-' + i" id="detailAmountTotal{{ i }}" class="fs-1">{{
                hierarchy.amountTotal | currency: "BRL":"symbol":"0.2-2":"pt-BR"
              }}</span>
              <!--   <span class="fs-11">Sudeste / São Paulo / Sede 3 / Tags ativas</span>
                            <span>Frota 2</span>
                            <span class="fs-1">R$ 380.000,00</span> -->
            </div>
            <div class="col-4">
              <i class="ico-bill ico-white ico-search"></i>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col-md-auto px-0 BillhierarchyColumn2">
      <div class="container-grid-vehicles">
        <app-grid-hierarchy #gridHierarchy></app-grid-hierarchy>
      </div>
    </div>
  </div>
</div>
