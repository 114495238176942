import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { LoadingComponent } from './components/loading/loading.component';
import { NotAuthorizedComponent } from './components/auxiliary-page/not-authorized/not-authorized.component';
import { NotfoundComponent } from './components/auxiliary-page/notfound/notfound.component';
import { UnderConstructionComponent } from './components/auxiliary-page/under-construction/under-construction.component';
import { RadioButtonModule } from './components/radiobutton/radiobutton.module';
import { TagsDisponiveisComponent } from './components/tag/tags-disponiveis/tags-disponiveis.component';
import { VeiculosDisponiveisComponent } from './components/vehicle/veiculos-disponiveis/veiculos-disponiveis.component';
import { VmessageComponent } from './components/vmessage/vmessage.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MatTabsModule } from "@angular/material/tabs";
import { MatRadioModule } from "@angular/material/radio";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagReceiptModalComponent } from './components/tag/tag-receipt-modal/tag-receipt-modal.component';
import { CardModelComponent } from './components/card-model/card-model.component';
import { CreditCardComponent } from './components/payment/credit-card/credit-card.component';
import { FirstAccessBallonComponent } from './components/first-access-ballon/first-access-ballon.component';
import { OrderTimelineComponent } from './components/order-timeline/order-timeline.component';
import { PaymentMethodComponent } from './components/payment/payment-method/payment-method.component';
import { VerificationCodeComponent } from './components/verification-code/verification-code.component';
import { CodeInputModule } from 'angular-code-input';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { RegisterCreditCardModalComponent } from './components/payment/register-credit-card-modal/register-credit-card-modal.component';
import { UpdateCreditCardModalComponent } from './components/payment/update-credit-card-modal/update-credit-card-modal.component';
import { RegisterCreditCardConfirmationComponent } from './components/payment/register-credit-card-modal/register-credit-card-confirmation/register-credit-card-confirmation.component';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AddDeliveryAddressComponent } from './components/address/add-delivery-address/add-delivery-address.component';
import { EditDeliveryAddressComponent } from './components/address/edit-delivery-address/edit-delivery-address.component';
import { DeleteDeliveryAddressComponent } from './components/address/delete-delivery-address/delete-delivery-address.component';
import { DeliveryAddressesModalComponent } from './components/address/delivery-addresses-modal/delivery-addresses-modal.component';
import { PaymentTransactionConfirmationComponent } from './components/payment/payment-transaction-confirmation/payment-transaction-confirmation.component';
import { PixPaymentModalComponent } from './components/payment/pix-payment-modal/pix-payment-modal.component';
import { PurchaseConfirmationModalComponent } from './components/payment/purchase-confirmation-modal/purchase-confirmation-modal.component';
import { QRCodeModule } from 'angularx-qrcode';
import { LooseRechargeConfirmationComponent } from './components/payment/payment-transaction-confirmation/components/loose-recharge-confirmation/loose-recharge-confirmation.component';
import { TagOrderConfirmationComponent } from './components/payment/payment-transaction-confirmation/components/tag-order-confirmation/tag-order-confirmation.component';
import { AddressFormComponent } from './components/address/address-form/address-form.component';
import { AddressConfirmDataComponent } from './components/address/address-confirm-data/address-confirm-data.component';
import { FileInconsistenciesModalComponent } from './components/file-inconsistencies-modal/file-inconsistencies-modal.component';
import { ChartsComponent } from './components/charts/charts.component';
import { VehicleDetailsPopoverComponent } from './components/vehicle/vehicle-details-popover/vehicle-details-popover.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BillFilterPipe } from '@pipes/bill.pipe';
import { PhonePipe } from '@pipes/phone.pipe';
import { TagFilterPipe } from '@pipes/tagFilter.pipe';
import { VehicleFilterPipe } from '@pipes/vehicleFilter.pipe';
import { AccessControlDirective } from '@directives/access-control/access-control.directive';
import { DisabledControlDirective } from '@directives/access-control/disabled-control.directive';
import { ButtonBlockDirective } from '@directives/button-block/button-block.directive';
import { BtnExportDirective } from '@directives/btn-export.directive';
import { ChartsDirective } from '@directives/charts.directive';
import { CopyClipboardDirective } from '@directives/copy-clipboard.directive';
import { DndDirective } from '@directives/dnd.directive';
import { ModalityTypeDirective } from '@directives/modality-type.directive';
import { PhoneMaskDirective } from '@directives/phone-mask.directive';
import { CustomDefaultAlertComponent } from './components/alerts/custom-default-alert/custom-default-alert.component';
import { AbsPipe } from '@pipes/abs.pipe';
import { ExcludeCreditCardModalComponent } from './components/payment/exclude-credit-card-modal/exclude-credit-card-modal.component';
import { FeedbackModalComponent } from './components/modals/feedback-modal/feedback-modal.component';
import { RechargeFeeTableComponent } from './components/payment/recharge-fee-table/recharge-fee-table.component';
import { ValidationPasswordComponent } from "./components/validation-password/validation-password.component";
import { MatListModule } from "@angular/material/list";
import { ClickOutsideDirective } from "@directives/click/click-outside.directive";
import { IntegrationFeeTableComponent } from './components/payment/integration-fee-table/integration-fee-table.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { FiltersComponent } from "./components/filters/filters.component";
import { CheckboxFilterComponent } from "./components/filters/components/checkbox-filter/checkbox-filter.component";
import { DateFilterComponent } from "./components/filters/components/date-filter/date-filter.component";
import { RadioFilterComponent } from "./components/filters/components/radio-filter/radio-filter.component";
import { RangeDatePickerComponent } from "./components/filters/components/range-date-picker/range-date-picker.component";
import {
  RangeAmountFilterComponent
} from "./components/filters/components/range-amount-filter/range-amount-filter.component";
import { ControlBackOfficeProfileAccessDirective } from "@directives/control-backoffice-profile-access.directive";
import { ControlCustomerProfileAccessDirective } from "@directives/control-customer-profile-access.directive";
import { ControlAccessProfileDirective } from "@directives/control-profile-access.directive";
import { CepFormatPipe } from "@pipes/cep.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { TabsComponent } from './components/tabs/tabs.component';
import { DragdropFileComponent } from './components/dragdrop-file/dragdrop-file.component';
import { NoResultsMsgComponent } from './components/no-results-msg/no-results-msg.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { InputDatePickerComponent } from './components/input-date-picker/input-date-picker.component';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    RadioButtonModule,
    MatTabsModule,
    TabsModule,
    MatRadioModule,
    BsDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    CodeInputModule,
    MatFormFieldModule,
    QRCodeModule,
    BsDatepickerModule,
    MatListModule,
    MatCheckboxModule,
    PopoverModule.forRoot(),
    TranslateModule,
    //Standalone
    ControlAccessProfileDirective,
    ControlBackOfficeProfileAccessDirective,
    ControlCustomerProfileAccessDirective
  ],
  declarations: [
    //components
    LoadingComponent,
    NotAuthorizedComponent,
    NotfoundComponent,
    UnderConstructionComponent,
    TagsDisponiveisComponent,
    VeiculosDisponiveisComponent,
    VmessageComponent,
    LoaderComponent,
    TagReceiptModalComponent,
    CardModelComponent,
    CreditCardComponent,
    FirstAccessBallonComponent,
    OrderTimelineComponent,
    PaymentMethodComponent,
    VerificationCodeComponent,
    DatePickerComponent,
    RegisterCreditCardModalComponent,
    RegisterCreditCardConfirmationComponent,
    UpdateCreditCardModalComponent,
    AddDeliveryAddressComponent,
    EditDeliveryAddressComponent,
    DeleteDeliveryAddressComponent,
    DeliveryAddressesModalComponent,
    PaymentTransactionConfirmationComponent,
    PixPaymentModalComponent,
    PurchaseConfirmationModalComponent,
    LooseRechargeConfirmationComponent,
    TagOrderConfirmationComponent,
    AddressFormComponent,
    AddressConfirmDataComponent,
    FileInconsistenciesModalComponent,
    ChartsComponent,
    VehicleDetailsPopoverComponent,
    CustomDefaultAlertComponent,
    ValidationPasswordComponent,
    PaginationComponent,
    TabsComponent,
    DragdropFileComponent,
    NoResultsMsgComponent,
    InputDatePickerComponent,
    //pipes
    BillFilterPipe,
    PhonePipe,
    TagFilterPipe,
    VehicleFilterPipe,
    CepFormatPipe,
    //directives
    AccessControlDirective,
    DisabledControlDirective,
    ButtonBlockDirective,
    BtnExportDirective,
    ChartsDirective,
    CopyClipboardDirective,
    DndDirective,
    ModalityTypeDirective,
    PhoneMaskDirective,
    AbsPipe,
    ExcludeCreditCardModalComponent,
    FeedbackModalComponent,
    RechargeFeeTableComponent,
    ClickOutsideDirective,
    IntegrationFeeTableComponent,
    FiltersComponent,
    CheckboxFilterComponent,
    DateFilterComponent,
    RadioFilterComponent,
    RangeAmountFilterComponent,
    RangeDatePickerComponent
  ],
  exports: [
    //components
    LoadingComponent,
    NotAuthorizedComponent,
    NotfoundComponent,
    UnderConstructionComponent,
    TagsDisponiveisComponent,
    VeiculosDisponiveisComponent,
    VmessageComponent,
    LoaderComponent,
    TagReceiptModalComponent,
    CardModelComponent,
    CreditCardComponent,
    FirstAccessBallonComponent,
    OrderTimelineComponent,
    PaymentMethodComponent,
    VerificationCodeComponent,
    DatePickerComponent,
    RegisterCreditCardModalComponent,
    RegisterCreditCardConfirmationComponent,
    UpdateCreditCardModalComponent,
    AddDeliveryAddressComponent,
    EditDeliveryAddressComponent,
    DeleteDeliveryAddressComponent,
    DeliveryAddressesModalComponent,
    PaymentTransactionConfirmationComponent,
    PixPaymentModalComponent,
    PurchaseConfirmationModalComponent,
    LooseRechargeConfirmationComponent,
    TagOrderConfirmationComponent,
    AddressFormComponent,
    AddressConfirmDataComponent,
    FileInconsistenciesModalComponent,
    ChartsComponent,
    VehicleDetailsPopoverComponent,
    CustomDefaultAlertComponent,
    ValidationPasswordComponent,
    PaginationComponent,
    TabsComponent,
    DragdropFileComponent,
    NoResultsMsgComponent,
    InputDatePickerComponent,
    //pipes
    BillFilterPipe,
    PhonePipe,
    TagFilterPipe,
    VehicleFilterPipe,
    CepFormatPipe,
    //directives
    AccessControlDirective,
    DisabledControlDirective,
    ButtonBlockDirective,
    BtnExportDirective,
    ChartsDirective,
    CopyClipboardDirective,
    DndDirective,
    ModalityTypeDirective,
    PhoneMaskDirective,
    AbsPipe,
    RechargeFeeTableComponent,
    ClickOutsideDirective,
    IntegrationFeeTableComponent,
    FiltersComponent,
    CheckboxFilterComponent,
    DateFilterComponent,
    RadioFilterComponent,
    RangeAmountFilterComponent,
    RangeDatePickerComponent,

    //Standalone
    ControlAccessProfileDirective,
    ControlBackOfficeProfileAccessDirective,
    ControlCustomerProfileAccessDirective
  ],
  providers: [DatePipe, provideNgxMask()],
})

export class SharedModule { }
