<div class="content-page">
  <div class="container-fluid page-myaccount">
    <div class="row">
      <div class="col-12 Dashboard_Overview">
        <h1 data-e2e="page-title">/ Detalhes da Fatura</h1>
      </div>
      <div class="col-12">
        <h1 data-e2e="page-title-responsive" class="page-title-responsive">Detalhes da Fatura</h1>
      </div>

      <div class="d-flex row header-content">
        <div class="col-md-auto" id="div-btn-invoice-link">
          <button
            data-e2e="btn-invoice-link"
            [routerLink]="'/faturas'"
            type="button"
            class="btn btn-repom">
            voltar para as faturas
            <i class="ico ico-arrow-left"></i>
            <span class="btn-animation"></span>
          </button>
        </div>
        <div class="container-loading" *ngIf="isLoading">
          <app-loader
            [isLoading]="isLoading"
            message="{{ 'Carregando...' }}"
          ></app-loader>
        </div>
        <div class="col" id="id-bill-card" *ngIf="!isLoading">
          <app-bill-card [billHeaderDto]="billHeader" [count]="0" [isLoading]="isLoading" [inContractModalityTypeId]="contractModalityTypeId"></app-bill-card>
        </div>
      </div>

      <div class="col-12 mt-5 Dashboard_col_1">
        <div class="container-bill" *ngIf="!isLoading">

          <div class="row tabs-responsive">
            <div class="col-12 d-flex justify-content-center" style="margin-top: -70px; position: absolute">
              <ul class="nav nav-tabs d-flex justify-content-between nav-justified" id="" role="tablist">
                <li class="nav-item">
                  <a
                    data-e2e="general-link"
                    (click)="selectedTab = tabs[0]"
                    class="nav-link active"
                    id="nav1-tab"
                    data-bs-toggle="tab"
                    href="#nav1"
                    role="tab"
                    aria-controls="nav1"
                    aria-selected="true">
                    Geral
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    data-e2e="vehicle-link"
                    (click)="selectedTab = tabs[1]"
                    class="nav-link"
                    id="nav2-tab"
                    data-bs-toggle="tab"
                    href="#nav2"
                    role="tab"
                    aria-controls="nav2"
                    aria-selected="false">
                    Veículos
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    data-e2e="hierarchy-link"
                    (click)="selectedTab = tabs[2]"
                    class="nav-link"
                    id="nav3-tab"
                    data-bs-toggle="tab"
                    href="#nav3"
                    role="tab"
                    aria-controls="nav3"
                    aria-selected="false">
                    Hierarquia
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="tab-content">
            <div *ngIf="selectedTab === tabs[0]" role="tabpanel" class="tab-pane active" id="one">
              <app-bill-detail-general [billSummary]="billSummary"></app-bill-detail-general>
            </div>

            <div *ngIf="selectedTab === tabs[1]" role="tabpanel" class="tab-pane active" id="two">
              <app-bill-detail-vehicles></app-bill-detail-vehicles>
            </div>

            <div *ngIf="selectedTab === tabs[2]" role="tabpanel" class="tab-pane active" id="three">
              <app-bill-detail-hierarchy></app-bill-detail-hierarchy>
            </div>
          </div>
        </div>

        <div class="container-loading" *ngIf="isLoading">
          <app-loader [isLoading]="isLoading" message="{{ 'Download em andamento...'}}"></app-loader>
        </div>
      </div>
    </div>
  </div>
</div>
