<div class="Dashboard container-fluid">
  <div class="row">
    <div class="col-12 Dashboard_Overview">
      <h1 data-e2e="page-title">/ ATIVAR TAGS</h1>

      <div data-e2e="tag-activation-time-msg" class="tag-white">
        <b>F</b>ique atento, em alguns estabelecimentos, as novas Tags <br> podem levar até 6 horas para serem ativadas.
      </div>

    </div>

    <div class="col-12">
      <app-first-access-ballon [page]="2"></app-first-access-ballon>
    </div>

    <div *accessControl="103003" class="container-link-batch-management">
      <a
        class="link-page-not-bg"
        id="link-page-batch-management"
        data-e2e="link-page-batch-management"
        routerLink="/gestao-lote"
      >
        <img class="link-page-not-bg__icon icon-two-chains" alt="Ícone de duas correntes">
        Ativar TAGs em lote
      </a>
    </div>

    <div class="col-6 Dashboard_col_1">
      <app-tags-disponiveis
        [elementEmpty]="isEmpty"
        (changeTag)="onchangeTag($event)"
        [tagSelected]="tagselected">
      </app-tags-disponiveis>
    </div>
    <!-- /box left-->

    <div class="col-6 Dashboard_col_2">
      <app-veiculos-disponiveis
        (changeVehicle)="onchangeVehicle($event)"
        [contractvehicleselected]="contractvehicleselected">
      </app-veiculos-disponiveis>
      <!--Dashboard_ExtratoVPR-->
    </div>

  </div>

  <!--row-->
  <div class="row">
    <div class="col-lg-4 "></div>

    <div class="col-lg-4 text-center">
      <span class="arrow-left bg-btn-arrow-left"></span>
      <button
        id="confirm"
        type="button"
        data-e2e="save-contract-tag-btn"
        class="btn btn-secondary"
        buttonBlock
        [ContractBlock]="[2,3,6]"
        [ConditionToBlock]="tagselected==null || contractvehicleselected==null"
        (click)="saveContractVehicleTag()">
        Confirmar
     </button>

      <span class="arrow-right bg-btn-arrow-right"></span>
    </div>
    <div class="col-lg-4 ">
    </div>
  </div>
</div>

