import { Component, OnInit } from '@angular/core';
import { finalize, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketsService } from './../../services/tickets.service';
import { GetVehiclesItemsDto } from '../../dtos/getVehiclesItems.dto';
import { GetContractHierarchiesQueryResultDto } from '../../dtos/getContractHierarchies.dto';
import { GetContractHierarchiesQueryArguments } from '../../dtos/getHierarchiesQueryArguments.dto';
import { GetFromHierarchiesQueryResult } from '../../dtos/getFromHierarchiesQueryResult.dto';
import { GetTransactionsHierarchiesQueryArguments } from '../../dtos/getTransactionsHierarchiesQueryArguments.dto';
import { NotifyService } from '@services/notify.service';
import { TicketVehicleModalComponent } from '../ticket-vehicle-modal/ticket-vehicle-modal.component';

@Component({
  selector: 'app-tab-hierarchy',
  templateUrl: './tab-hierarchy.component.html',
  styleUrls: ['./tab-hierarchy.component.scss']
})
export class TabHierarchyComponent implements OnInit {
  inlineDateTime: { chosenLabel: string; startDate: moment.Moment; endDate: moment.Moment };
  items : Array<GetVehiclesItemsDto>
  getContractHierarchiesQueryResult: GetContractHierarchiesQueryResultDto;
  getHierarchiesQueryResult: GetFromHierarchiesQueryResult;
  getContractHierarchiesQueryArguments: GetContractHierarchiesQueryArguments;
  getTransactionsHierarchiesQueryArguments: GetTransactionsHierarchiesQueryArguments;
  filterDebounceContract: Subject<GetContractHierarchiesQueryArguments> = new Subject<GetContractHierarchiesQueryArguments>();
  filterDebounceTransactions: Subject<GetTransactionsHierarchiesQueryArguments> = new Subject<GetTransactionsHierarchiesQueryArguments>();
  selectedDate = new Date();
  today = new Date();
  dayCustomHierarchies : Date[];
  minDate: Date;
  maxDate: Date;
  dateHierarchies?: any;
  perPages = [];
  isLoading: boolean;
  isShownPeriod: boolean = false;
  isShownValue: boolean = false;
  isShownHierarchy: boolean = false;
  isDatePickerOpen: boolean;
  isOpen: boolean;
  isOpenDropdown: boolean;
  open: boolean;
  isLoadingVehicles: boolean;
  click: any;
  hierarchySelected: number;
  hierarchyDescription: string;
  element: HTMLElement;
  setDateMin:Date;
  setDateMax: Date;
  oldDate: Date;
  searchHierarchies: string;
  selected: any;
  public cleanList: boolean;
  pageSizeTransactions: number;
  isSideLoading: boolean;



  public formPreDateHierarchy = this.formBuilder.group({
    dateHierarchies: "30"
  });

  public formValues = this.formBuilder.group({
    minValue: null,
    maxValue: null,
  });

  constructor(
    private ticketsService: TicketsService,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService,
    private modalService: NgbModal
  ) {
    this.cleanList = true;
    this.minDate = new Date(Date.now());
    this.maxDate = new Date(Date.now());
    this.minDate.setMonth(this.minDate.getMonth()-13);
    this.oldDate = new Date();
    this.oldDate.setDate( this.oldDate.getDate() - 30);
    this.getContractHierarchiesQueryArguments = new GetContractHierarchiesQueryArguments();
    this.getContractHierarchiesQueryArguments.search = "";
    this.getContractHierarchiesQueryArguments.startDate = this.oldDate;
    this.getContractHierarchiesQueryArguments.endDate = new Date();
    this.filterDebounceContract.pipe(debounceTime(300)).subscribe(filter => this.getContractHierarchies(filter));

    this.pageSizeTransactions = 5;
    this.perPages = [5, 10, 20, 50];
    this.getTransactionsHierarchiesQueryArguments = new GetTransactionsHierarchiesQueryArguments();
    this.getTransactionsHierarchiesQueryArguments.page = 1;
    this.getTransactionsHierarchiesQueryArguments.pageSize = 5;
    this.getTransactionsHierarchiesQueryArguments.desc = false;
    this.getTransactionsHierarchiesQueryArguments.filter = 1;
    this.getTransactionsHierarchiesQueryArguments.search = "";
    this.filterDebounceTransactions.pipe(debounceTime(300)).subscribe(filter => this.getHierarchyQuery(this.hierarchySelected, filter));
  }

  ngOnInit() {
    this.getContractHierarchies(this.getContractHierarchiesQueryArguments);
  }


  getContractHierarchies(args: GetContractHierarchiesQueryArguments) {
    this.getHierarchiesQueryResult = null;
    var daysBetween = (new Date(args.endDate).getTime() - new Date(args.startDate).getTime())/1000/60/60/24;
    if(daysBetween>90){
      this.notifyService.clear();
      this.notifyService.showError("Período de consulta","O período de consulta não pode ser maior que 90 dias");
      return;
    }
    this.isSideLoading = true;
    this.isLoading = true;
    this.ticketsService
      .getContractHierarchies(args)
      .pipe(
        finalize(() => {
          this.isSideLoading = false;
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.getContractHierarchiesQueryResult = success;
        },
        (error) => {
          return;
        }
      );
  }

  setHierarchyId(hierarchyId: number, hierarchyDescription: string) {
    this.cleanList = false;
    this.hierarchySelected = hierarchyId;
    this.getTransactionsHierarchiesQueryArguments.hierarchyDescription = hierarchyDescription;
    this.getTransactionsHierarchiesQueryArguments.startDate = this.setDateMin;
    this.getTransactionsHierarchiesQueryArguments.endDate = this.setDateMax;
    this.getHierarchyQuery(this.hierarchySelected, this.getTransactionsHierarchiesQueryArguments,true);
  }

  getHierarchyQuery(hierarchyId: number, args: GetContractHierarchiesQueryArguments,fromHierarchys:boolean = false){
    //this.getHierarchiesQueryResult = null;
    var daysBetween = (new Date(args.endDate).getTime() - new Date(args.startDate).getTime())/1000/60/60/24;
    if(daysBetween>90){
      this.notifyService.clear();
      this.notifyService.showError("Período de consulta","O período de consulta não pode ser maior que 90 dias");
      return;
    }

    if(fromHierarchys){
      this.isLoading = true;
    }
    else{
      this.isLoadingVehicles = true;
    }

    this.ticketsService
    .getHierarchiesQuery(hierarchyId, args)
    .pipe(
      finalize(() => {
        this.isLoading = false;
        this.isLoadingVehicles = false;
      })
    )
    .subscribe(
      (success) => {
        this.getHierarchiesQueryResult = success;
      },
      (error) => {
        this.notifyService.clear();
        console.error(error);
      }
    );
  }

  onPaginationChange(event: any) {
    if(this.getTransactionsHierarchiesQueryArguments.page !== event.page){
      this.getTransactionsHierarchiesQueryArguments.page = event.page;
      this.getHierarchyQuery(this.hierarchySelected, this.getTransactionsHierarchiesQueryArguments);
    }
  }

  onSetVehicleFromPageSize(event: any){
    this.onPageSizeChange(this.hierarchySelected, event);
  }

  onPageSizeChange(vehicleId: number, event: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.getTransactionsHierarchiesQueryArguments.page = 1;
    this.pageSizeTransactions = event.target.value;
    this.getTransactionsHierarchiesQueryArguments.pageSize = event.target.value;
    this.getHierarchyQuery(vehicleId, this.getTransactionsHierarchiesQueryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.getTransactionsHierarchiesQueryArguments.orderBy == field && !this.getTransactionsHierarchiesQueryArguments.desc,
      'order-by-desc': this.getTransactionsHierarchiesQueryArguments.orderBy == field && this.getTransactionsHierarchiesQueryArguments.desc
    }
  }

  onOrderByChange(field: string) {
    if (this.getTransactionsHierarchiesQueryArguments.orderBy === field) {
      this.getTransactionsHierarchiesQueryArguments.desc = !this.getTransactionsHierarchiesQueryArguments.desc;
    } else {
      this.getTransactionsHierarchiesQueryArguments.orderBy = field;
      this.getTransactionsHierarchiesQueryArguments.desc = true;
    }
    this.getHierarchyQuery(this.hierarchySelected, this.getTransactionsHierarchiesQueryArguments);
  }

  onFullNameKeyup($event: any) {
    this.cleanList = true;
    this.getContractHierarchiesQueryArguments.search = $event.target.value;
    this.filterDebounceContract.next(this.getContractHierarchiesQueryArguments);
  }

  onSearchClick (event: any){
    this.getContractHierarchies(this.getContractHierarchiesQueryArguments);
  }

  onFilterDateHierarchies(values: Date): void {
    if(values !== null){
      var daysBetween = (new Date(values[1]).getTime() - new Date(values[0]).getTime()) / 1000 / 60 / 60 / 24;
      if (daysBetween > 90) {
        this.notifyService.clear();
        this.notifyService.showError("Período de consulta", "O período de consulta não pode ser maior que 90 dias");
        //this.isShownPeriod = false;
       this.dayCustomHierarchies = [
          this.getTransactionsHierarchiesQueryArguments.startDate,
          this.getTransactionsHierarchiesQueryArguments.endDate
        ];
        return;
      }
      this.formPreDateHierarchy.get('dateHierarchies').reset();
      values[0].setHours(0,0,0,0);
      values[1].setHours(23,59,59,0);
      this.toggleDateRangePicker();
      this.getContractHierarchiesQueryArguments.startDate = this.getTransactionsHierarchiesQueryArguments.startDate =values[0];
      this.getContractHierarchiesQueryArguments.endDate = this.getTransactionsHierarchiesQueryArguments.endDate = values[1];
      this.filterDebounceContract.next(this.getContractHierarchiesQueryArguments);
      this.setDateMin = this.getContractHierarchiesQueryArguments.startDate;
      this.setDateMax = this.getContractHierarchiesQueryArguments.endDate;
    }
  }

  setSevenDaysLast() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 7);
    this.dayCustomHierarchies = [this.selectedDate, this.today];
    return this.dayCustomHierarchies;
  }

  setThirtyDaysLast() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 30);
    this.dayCustomHierarchies = [this.selectedDate, this.today];
    return this.dayCustomHierarchies;
  }

  setFifteenDaysLast() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 15);
    this.dayCustomHierarchies = [this.selectedDate, this.today];
    return this.dayCustomHierarchies;
  }

  toggleDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
    this.isShownPeriod = false;
    this.open = false;
    this.isShownHierarchy = false;
    this.isShownValue = false;
    this.hideDate();
  }

  toggleShowPeriod(){
    this.isDatePickerOpen = !this.isDatePickerOpen;
  }

  toggleDateRangePicker() {
    this.cleanList = true;
    this.isShownPeriod = !this.isShownPeriod;
    this.isOpen = !this.isOpen;
    this.isShownHierarchy = false;
    this.isShownValue = false;
    this.toggleShowPeriod();
  }

  resetFilters() {
    this.cleanList = true;
   // this.formPreDateHierarchy.get('dateHierarchies').reset();
   this.formPreDateHierarchy = this.formBuilder.group({
    dateHierarchies: "30"
  });;
    this.dayCustomHierarchies = [];
    this.searchHierarchies = null;
    this.setDateMin = this.oldDate;
    this.setDateMax = new Date();
    this.getContractHierarchiesQueryArguments.search = "";
    this.getContractHierarchiesQueryArguments.startDate = this.oldDate;
    this.getContractHierarchiesQueryArguments.endDate = new Date();
    this.getTransactionsHierarchiesQueryArguments.startDate =  this.oldDate;
    this.getTransactionsHierarchiesQueryArguments.endDate = new Date();
    this.getContractHierarchies(this.getContractHierarchiesQueryArguments);
  }

  toggleDateAfterChangeTab(tab){
    if(tab == 3){
      if(this.isOpenDropdown){
          if(this.isDatePickerOpen){
            this.showDate()
          }
      }
      else{
        this.isDatePickerOpen = false;
        this.hideDate()
      }
    }
    else{
        if(this.isDatePickerOpen){
          this.hideDate()
        }
      }
  }

  hideDate() {
    this.element = document.getElementById('hideHierarchyDate') as HTMLElement;
    this.element?.click();
  }

  showDate() {
    this.element = document.getElementById('showHierarchyDate') as HTMLElement;
    this.element?.click();
  }

  showVehicleModal(licensePlate,hierarchyDescription) {
    const modalRef = this.modalService.open(TicketVehicleModalComponent);
    modalRef.componentInstance.licensePlate =  licensePlate;
    modalRef.componentInstance.hierarchyDescription = hierarchyDescription;
    modalRef.componentInstance.startDate = this.getContractHierarchiesQueryArguments.startDate;
    modalRef.componentInstance.endDate = this.getContractHierarchiesQueryArguments.endDate;
  }

}

