<div class="confirm-data">
  <p>{{this.subTitleModal}}</p>

  <table>
    <tr>
      <td>Nome:</td>
      <td>{{userEdit.fullName}}</td>
    </tr>
    <tr>
      <td>Perfil:</td>
      <td class="profile">
        {{userEdit.description}}
        <i class="ico ico-alert" placement="right" ngbTooltip="{{userEdit.subTitle}}"></i>
      </td>
    </tr>
    <tr>
      <td>CPF:</td>
      <td>{{ userEdit.documentNumber | mask: '000.000.000-00' }}</td>
    </tr>
    <tr>
      <td>Data de nascimento:</td>
      <td>{{userEdit.dateofBirth | date:'dd/MM/yyyy' }}</td>
    </tr>
    <tr>
      <td>Telefone:</td>
      <td>{{userEdit.countryCode + userEdit.prefix + userEdit.telephoneNumber | mask: '+00 (00) 00000-0000' }}</td>
    </tr>
    <tr>
      <td>E-mail:</td>
      <td>{{userEdit.email}}</td>
    </tr>
    <tr>
      <td>Status:</td>
      <td>{{ userEdit.statusUsuarioDescription }}</td>
    </tr>
    @if(isBackoffice){
      <tr>
        <td>Protocolo:</td>
        <td>{{ userEdit.protocol }}</td>
      </tr>
      <tr>
        <td>Motivo:</td>
        <td>{{ userEdit.reason }}</td>
      </tr>
    }
  </table>

  @if(this.resultEdit){
    <span>{{ this.resultEdit?.registrationDate | date:'dd/MM/yyyy HH:mm' }} por {{
    this.resultEdit?.userName }}</span>
  }

</div>
