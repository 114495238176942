import { inject } from '@angular/core';
import { Router } from "@angular/router";
import { GTMMonitorService } from "@services/GTM/g-t-m-monitor.service";
import { AbstractConfigurationBase } from "./abstract-configuration-base";
import { PopUpService } from '../components/pop-up/pop-up.service';
import { UserPilotService } from '@services/user-pilot/user-pilot.service';

export class AbstractProcessLauncher extends AbstractConfigurationBase {

  private gtmService: GTMMonitorService;
  private servicePopUp: PopUpService;
  private userPilotService: UserPilotService;

  constructor(protected router: Router) {
    super();
    this.gtmService = inject(GTMMonitorService);
    this.servicePopUp = inject(PopUpService);
    this.userPilotService = inject(UserPilotService);

    this.sendDataForGoogle();
    this.servicePopUpManager();
    this.sendDataToUserPilot();
  }

  /**
   * Send data to Google Tag Manager only if the user is a customer or relationship profile
   * @private
   */
  private sendDataForGoogle(): void {
    if (this.userProfileService.isCustomerOrRelationshipProfile()) {
      const url = this.router.routerState.snapshot.url;
      const userId = this.userProfileService.getUserId();

      this.gtmService.postGoogleTagManager(url, String(userId));
    }
  }

  private sendDataToUserPilot(): void {
    if (this.userProfileService.isCustomerOrRelationshipProfile()) {
      const userInfo = this.userProfileService.getUserInfo();
      this.userPilotService.postData(userInfo, this.userProfileService.getUserId())
    }
  }

  /**
   * Initialize the pop-up service
   * @private
   */
  private servicePopUpManager(): void {
    this.servicePopUp.initialize();
  }

}
