<div class="container header-page tags-osa">
  <h1 data-e2e="order-title" translate>Orders</h1>

  <div class="actions-header-page tags-osa">
    <div class="input-group btn-search">
      <input
        type="text"
        data-e2e="order-search-input"
        class="form-control"
        placeholder="pedido, CNPJ, contrato..."
        [(ngModel)]="orderQueryArguments.search"
      />

      <div class="input-group-append search-content">
        <button
          data-e2e="order-search-btn"
          class="btn btn-secondary"
          (click)="getOrdersQuery(orderQueryArguments)"
        >
          <img src="../assets/img/ico-search-white.svg" /> Buscar
        </button>
      </div>
    </div>
  </div>
</div>

<section class="container request-section col-md-11 mt-5">
  <div class="card mb-5 card-loading">
    <div class="card-body pt-0">
      <div
        class="title d-flex align-items-center justify-content-between mt-4 mb-5"
      >
        <div class="d-flex justify-content-around align-items-center">

          <app-filters
            [hasStatusTypeFilter]="true"
            [statusTypes]="tagRequest.types"
            (filtersParams)="handleFiltersParams($event)"
            (resetParams)="resetFilters()"
          >
          </app-filters>

          <form novalidate>
            <div class="form-group select-table">
              <label data-e2e="page-size-label" translate>Lines amount</label>
              <select
                data-e2e="page-size-select"
                class="form-control form-select"
                (change)="onPageSizeChange($event)"
              >
                <option
                  *ngFor="let i of perPages; let count = index"
                  value="{{ i }}"
                >
                  {{ i }} linhas
                </option>
              </select>
            </div>
          </form>
        </div>
        <div class="box-export">
          <button
          data-e2e="export-btn"
          type="button"
          id="downloadButton"
          class="btn btn-repom btn-export m-0"
          (click)="Download()">
          <i class="ico ico-export"></i>
          exportar
          <span class="btn-animation"></span>
        </button>

        <div class="container-loading" *ngIf="isDownloading">
          <app-loader [isLoading]="isDownloading" message="{{ 'Download em andamento...'}}"></app-loader>
        </div>

        </div>
      </div>

      <div class="">
        <table class="table table-order table-striped">
          <thead class="thead">
            <tr>
              <th></th>
              <th>
                <button
                  data-e2e="sort-date-btn"
                  class="btn-order-table"
                  translate
                >
                  Date
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-order-id-btn"
                  class="btn-order-table"
                  translate
                >
                  Order number
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-company-btn"
                  class="btn-order-table"
                  translate
                >
                  Company name
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-amount-btn"
                  class="btn-order-table"
                  translate
                >
                  Amount tags
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-status-btn"
                  class="btn-order-table"
                  translate
                >
                  Status
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-amount-btn"
                  class="btn-order-table"
                  translate
                >
                  Valor
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-protocol-btn"
                  class="btn-order-table"
                  translate
                >
                  Protocol
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-actions-btn"
                  class="btn-order-table"
                  translate
                >
                  Actions
                </button>
              </th>
            </tr>
          </thead>

          <tbody *ngIf="!isLoading">
            <tr *ngIf="getOrders.total == 0">
              <td
                data-e2e="empty-search-msg"
                colspan="12"
                class="text-center w-100 py-4"
                translate
              >
                Não há Pedido encontrado para esta busca. Verifique e tente
                novamente.
              </td>
            </tr>

            <tr
              *ngFor="
                let order of getOrders.items;
                index as i;
                first as isFirst
              "
            >
              <td>
                <section
                  class="request-details-section"
                  [ngClass]="{ show: hideTooltip[i] }"
                >
                  <app-request-details-popover
                    [order]="order"
                    [id]="i"
                  ></app-request-details-popover>
                </section>

                <a
                  (click)="hideTooltip[i] = !hideTooltip[i];
                  updateCardBodyHeightBasedOnOrder(i, !hideTooltip[i])"
                  class="btn-popover"
                >
                  <img src="assets/svg/icon-popover.svg" />
                </a>
              </td>
              <td [attr.data-e2e]="'table-registration-' + i">
                {{ order?.registrationDate | date: 'dd/MM/yyyy - HH:mm:ss' }}
              </td>
              <td [attr.data-e2e]="'table-id-' + i">{{ order?.id }}</td>
              <td [attr.data-e2e]="'table-company-' + i">
                {{ order?.companyName }}
              </td>
              <td [attr.data-e2e]="'table-tag-' + i">{{ order?.tagCount }}</td>
              <td [attr.data-e2e]="'table-status-' + i" class="table-status">
                <img
                  *ngIf="order?.tagRequestStatusTypeId == 1"
                  src="../assets/svg/done.svg"
                  class="table-status-icon"
                />
                <img
                  *ngIf="order?.tagRequestStatusTypeId == 3"
                  src="../assets/svg/separate-order.svg"
                  class="table-status-icon"
                />
                <img
                  *ngIf="order?.tagRequestStatusTypeId == 4"
                  src="../assets/svg/shipping-company.svg"
                  class="table-status-icon"
                />
                <img
                  *ngIf="order?.tagRequestStatusTypeId == 5"
                  src="../assets/svg/track.svg"
                  class="table-status-icon"
                />
                {{ order?.tagRequestStatusType }}
              </td>
              <td [attr.data-e2e]="'table-total-' + i">
                {{
                  order?.totalAmountPayable
                    | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
                }}
              </td>
              <td [attr.data-e2e]="'table-protocol' + i">
                {{ order?.protocol }}
              </td>
              <td class="request-table-action">
                <button
                  *ngIf="
                    order?.tagRequestStatusTypeId == 1 ||
                    order?.tagRequestStatusTypeId == 3
                  "
                  class="btn btn-quartiary"
                  [attr.data-e2e]="'table-separate-order-btn-' + i"
                  (click)="redirectToSeparateOrder(order)"
                  translate
                >
                  Separate order
                  <img
                    *ngIf="order?.tagCount != 0"
                    class="button-icon ml-2"
                    src="assets/img/save.svg"
                  />
                </button>

                <div class="content" *ngIf="order?.tagRequestStatusTypeId == 4">
                  <button
                    class="btn-icon"
                    [attr.data-e2e]="'table-print-btn-' + i"
                    (click)="redirectToPrint(order)"
                  >
                    <img src="../assets/svg/print.svg" />
                  </button>
                  <button
                    class="btn btn-quartiary px-1"
                    translate
                    [attr.data-e2e]="'table-report-tracking-btn' + i"
                    (click)="reportTracking(order)"
                  >
                    Report tracking
                  </button>
                </div>

                <!-- trocar id -->
                <div class="content" *ngIf="order?.tagRequestStatusTypeId == 5">
                  <button
                    class="btn-icon"
                    [attr.data-e2e]="'table-print-btn-' + i"
                    (click)="redirectToPrint(order)"
                  >
                    <img src="../assets/svg/print.svg" />
                  </button>
                  <button
                    class="btn btn-quartiary"
                    translate
                    [attr.data-e2e]="'table-view-tracking-' + i"
                    (click)="viewTracking(order)"
                  >
                    See tracking
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="container-loading" *ngIf="isLoading">

          <app-loader
            [isLoading]="isLoading"
            message="{{ 'Loading...' | translate }}"
          ></app-loader>

        </div>
      </div>

      <div class="pagination-custom"    *ngIf="getOrders.total > orderQueryArguments.pageSize && !isLoading">
        <ngb-pagination
          (pageChange)="onPaginationChange($event)"
          [pageSize]="orderQueryArguments.pageSize"
          [maxSize]="5"
          [collectionSize]="getOrders.total"
          [(page)]="orderQueryArguments.page"
          [rotate]="true"
          [boundaryLinks]="true"
          [ellipses]="false"
          display="dynamic"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>
