<div class="login">
    <div class="logoVPImage">
      <img src="assets/img/gestao_de_pedagio.png" />
    </div>
    <div class="BGLogin">
      <img src="assets/svg/BG-Login.svg" />
    </div>
    <div class="logos row">
      <div class="edenred col-sm-auto"> <img src="assets/svg/logo-edenred.svg" /></div>
      <div class="ticketLog col-sm-auto"><img src="assets/svg/logo-ticket-log.svg" /></div>
      <div class="repom col-sm-auto"><img src="assets/svg/logo-repom.svg" /></div>
    </div>

    <div class="Login_BoxForm">
      <div class="BoxForm_Content">
        <div class="BoxForm_Title">
          <img src="assets/svg/icon_login.svg">
          <p>Olá, bem-vindo</p>
        </div>
        <div class="BoxForm_Form">
            <div class="choose">
              <div class="label">Escolha uma opção: </div>
              <button
                class="btn btn-repom btn-repom-user"
                data-e2e="btn-redirect-first-access"
                type="button"
                (click)="firstAccess()"
                >
                <i class="ico ico-first-access"></i>
                <div>Esse é meu primeiro acesso</div>
                <span class="btn-animation"></span>
              </button>
              <button
              class="btn btn-repom btn-repom-user"
              data-e2e="btn-redirect-login"
              type="button"
              (click)="login()"
              >
              <i class="ico ico-login"></i>
              <div>Já sou cadastrado</div>
              <span class="btn-animation"></span>
            </button>
            </div>
        </div>
        <!--BoxForm_Form-->
      </div>
      <div class="row">
        <div class="edenredMobile"> <img src="assets/svg/logo-edenred.svg" /><img src="assets/svg/logo-ticket-log.svg" /></div>
      </div>
      <!--BoxForm_Content-->
    </div>
    <!--Login_BoxForm-->
    <!-- **************** Login | End **************** -->
  </div>

