import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";
import {NgxMaskPipe} from "ngx-mask";
import { HolidaysCalendarComponent } from './holidays-calendar.component';
import { HolidaysCalendarRoutingModule } from './routes/holidays-calendar-routing.module';
import { CardComponent } from './components/card-holidays/card-holidays.component';
import { TableComponent } from './components/table-holidays/table-holidays.component';
import { ExcludeHolidayModalComponent } from './components/exclude-holiday-modal/exclude-holiday-modal.component';
import { ActionSuccessModalComponent } from './components/action-success-modal/action-success-modal.component';
import { CardInsertHolidaysComponent } from './components/card-insert-holidays/card-insert-holidays.component';
import { HeaderFormInsertHolidayComponent } from './components/header-form-insert-holiday/header-form-insert-holiday.component';
import { FormInsertHolidayComponent } from './components/form-insert-holiday/form-insert-holiday.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HolidayCalendarInsertComponent } from './components/holiday-calendar-insert-page/holiday-calendar-insert-page.component';
import { InsertWarningModalComponent } from './components/insert-warning-modal/insert-warning-modal.component';

@NgModule({
  declarations: [
    HolidaysCalendarComponent,
    HolidayCalendarInsertComponent,
    CardComponent,
    CardInsertHolidaysComponent,
    TableComponent,
    ExcludeHolidayModalComponent,
    ActionSuccessModalComponent,
    HeaderFormInsertHolidayComponent,
    FormInsertHolidayComponent,
    InsertWarningModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    HolidaysCalendarRoutingModule,
    NgxMaskPipe,
    BsDatepickerModule,
    MatCheckboxModule
  ]
})
export class HolidaysCalendarModule { }
