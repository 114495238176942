import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BillHierarchyQueryArguments } from '../../dto/bill-hierarchy-query.arguments';
import { BillHierarchyResultDto,Item } from '../../dto/bill-hierarchy-result.dto';
import { BillDetailHierarchy} from '../../services/bill-detail-hierarchy.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BillHierarchyBreadCrumbResultDto } from '../../dto/bill-hierarchy-bread-crumb-result.dto';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillVehiclesQueryArguments } from '../../dto/bill-vehicles-query.arguments';
import { BillVehiclesResultDto } from '../../dto/bill-vehicles-result.dto';
import { BillVehiclesTotalQueryArguments } from '../../dto/bill-vehicles-total-query.arguments';
import { BillVehiclesTotalResultDto } from '../../dto/bill-vehicles-total-result.dto';
import { BillVehicleModalComponent } from '../bill-vehicle-modal/bill-vehicle-modal.component';


@Component({
  selector: 'app-grid-hierarchy',
  templateUrl: './grid-hierarchy.component.html',
  styleUrls: ['./grid-hierarchy.component.css']
})
export class GridHierarchyComponent implements OnInit {

  billId:number;
  BillVehiclesQueryArguments: BillVehiclesQueryArguments;
  BillHierarchyQueryArguments: BillHierarchyQueryArguments;
  BillVehiclesResultDto: BillVehiclesResultDto;
  BillHierarchyResultDto: BillHierarchyResultDto;
  filterDebounce: Subject<BillHierarchyQueryArguments> = new Subject<BillHierarchyQueryArguments>();
  BillHierarchyBreadCrumbResultDto: BillHierarchyBreadCrumbResultDto;

  BillVehiclesTotalQueryArguments: BillVehiclesTotalQueryArguments;
  BillVehiclesTotalResultDto: BillVehiclesTotalResultDto;

  isLoading: boolean;
  isLoadingPage: boolean;
  perPages = []
  vehicleOpen: Array<boolean> = []
  Empty: boolean;
  open: boolean;
  isOpenDropdown: boolean;
  public Hierarchy;
  public licensePlate;
  public BillHierarchyList;
  public tagCount;
  public vehicleCount;
  public HierarchyFull;
  public amountTotal;
  public hierarchyId
  public total;
  public Vehicles;
  //req: boolean;


 constructor(private modalService: NgbModal,private billDetail: BillDetailHierarchy,  private actRoute: ActivatedRoute) {
    this.billId = this.actRoute.snapshot.params.id;
  }

  ngOnInit(){
    this.BillVehiclesTotal = []
    this.hierarchyId = '';
    this.open = false;
    this.BillHierarchyResultDto = {
      success: true,
      message: '',
      data: []
    };

    this.Empty = false;
    this.BillVehiclesTotalResultDto = {
      success: true,
      message: '',
      data: []
    };

    this.perPages = [10, 20, 50];

    this.BillHierarchyQueryArguments = new BillHierarchyQueryArguments();
    this.BillHierarchyQueryArguments.id = this.billId;
    this.BillHierarchyQueryArguments.page = 1;
    this.BillHierarchyQueryArguments.pageSize = 10;
    this.BillHierarchyQueryArguments.desc = false;


     this.getBillTotalHierarchys(this.BillHierarchyQueryArguments);

    this.BillVehiclesTotalQueryArguments = new BillVehiclesTotalQueryArguments();
    this.BillVehiclesTotalQueryArguments.id = this.billId;
    this.BillVehiclesTotalQueryArguments.page = 1;
    this.BillVehiclesTotalQueryArguments.pageSize = 10;
    this.BillVehiclesTotalQueryArguments.desc = false;

     this.getHierarchyList();
  }

  public OpenHierarchyInfos(hierarchyId){
    this.hierarchyId = hierarchyId
     this.BillHierarchyQueryArguments.page = 1;
     this.BillHierarchyQueryArguments.pageSize = 10;
     this.BillHierarchyQueryArguments.HierarchyId = hierarchyId;
    if(hierarchyId !== null){
      this.getBillDetailHierarchy(this.BillHierarchyQueryArguments);
     }

   }


   onPaginationChange(page: number) {
    this.BillVehiclesTotalQueryArguments.page = page;
    this.getBillDetailVehicles(this.BillVehiclesTotalQueryArguments)
  }

  showVehicleModal(licensePlate,nickname) {
    const modalRef = this.modalService.open(BillVehicleModalComponent);
    modalRef.componentInstance.Hierarchy = this.Hierarchy;
    modalRef.componentInstance.HierarchyFull = this.HierarchyFull;
    modalRef.componentInstance.billId = this.billId;
    modalRef.componentInstance.licensePlate = licensePlate;
    modalRef.componentInstance.nickname = nickname;

  }

  addClass(position){
    if(position % 2 === 0) {
      return "linetype1"
    }
    else{
      return "linetype2"
    }
 }

  onPageSizeChange(event: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.BillVehiclesTotalQueryArguments.page = 1;
    this.BillVehiclesTotalQueryArguments.pageSize = +event.target.value;
    this.getBillDetailVehicles(this.BillVehiclesTotalQueryArguments)
    //this.getFromServer(this.getVehiclesQueryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.BillVehiclesTotalQueryArguments.sortField == field && !this.BillVehiclesTotalQueryArguments.desc,
      'order-by-desc': this.BillVehiclesTotalQueryArguments.sortField == field && this.BillVehiclesTotalQueryArguments.desc
    }
  }

  onOrderByChange(field: string) {
    if (this.BillVehiclesTotalQueryArguments.sortField === field) {
      this.BillVehiclesTotalQueryArguments.desc = !this.BillVehiclesTotalQueryArguments.desc;
    } else {
      this.BillVehiclesTotalQueryArguments.sortField = field;
      this.BillVehiclesTotalQueryArguments.desc = true;
    }

    this.getBillDetailVehicles(this.BillVehiclesTotalQueryArguments)

  }


  getBillDetailHierarchy(args: BillHierarchyQueryArguments){

    this.isLoading = true;
    this.billDetail.getBillHierarchy(args)
    .pipe(
      finalize(() => {
        this.isLoading = false;
        this.isLoadingPage = false;
      })
    )
    .subscribe(
      (success) => {
        this.HierarchyList(success)
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }


  getBillTotalHierarchys(args: BillHierarchyQueryArguments){
    this.isLoading = true;
    this.billDetail.getBillHierarchy(args)
    .pipe(
      finalize(() => {
        this.isLoading = false;
        this.isLoadingPage = false;
      })
    )
    .subscribe(
      (success) => {
        if(success.data.length <= 0){
          this.Empty = true
        }
        else{
          this.Empty = false
        }
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }

  getBillDetailVehicles(args: BillVehiclesTotalQueryArguments){

    this.isLoadingPage = true;
    this.billDetail.getTotalVehicles(args)
    .pipe(
      finalize(() => {
        this.isLoading = false;
        this.isLoadingPage = false;
      })
    )
    .subscribe(
      (success) => {
        this.gridVehicles(success)
       // this.getBillVehiclesQueryResult = success;
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }

  getHierarchyList(){
    this.isLoading = true;
    this.billDetail.getHierarchyBreadCrumbList()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (success) => {
        this.HierarchyCrumbList(success)
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }

  HierarchyCrumbList(list){
    this.BillHierarchyBreadCrumbResultDto = list
  }

  HierarchyList(list) {
    this.BillHierarchyResultDto = list
   // this.BillHierarchyList = list.data[0].items
    this.amountTotal = list.data[0].amountTotal

    let id = list.data[0].hierarchyId

    this.BillVehiclesTotalQueryArguments.HierarchyId = id;
    this.getBillDetailVehicles(this.BillVehiclesTotalQueryArguments)

    let hierarchyfull  = this.BillHierarchyBreadCrumbResultDto.data.filter(item => item.id == id).map(item => item.breadCrumb).toString()
    let tagCount = this.BillHierarchyBreadCrumbResultDto.data.filter(item => item.id == id).map(item => item.tagCount).toString()
    let vehicleCount = this.BillHierarchyBreadCrumbResultDto.data.filter(item => item.id == id).map(item => item.vehicleCount).toString()
    let description_PT = this.BillHierarchyBreadCrumbResultDto.data.filter(item => item.id == id).map(item => item.description_PT).toString()

    if(hierarchyfull == ''){
       this.tagCount = '';
       this.vehicleCount = '';
       this.Hierarchy  = '';
       this.HierarchyFull = '';
    }
    else{
      this.tagCount = tagCount;
      this.vehicleCount = vehicleCount;
      this.Hierarchy = description_PT;
      this.HierarchyFull =  hierarchyfull;
    }

    if(list.data.toString() == ''){
     this.Empty = true;
    }
    else{
      this.Empty = false;
    }

    for(var count=0;count<list.data.length;count++){
      this.vehicleOpen[count] = false
    }

  }
  public BillVehiclesTotal;
  gridVehicles(list){
      this.BillVehiclesTotalResultDto = list
      this.BillVehiclesTotal = list.data
  }

}
