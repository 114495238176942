import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TicketsComponent } from './tickets.component';
import { TicketsRoutingModule } from './routes/tickets-routing.module';
import { TabGeneralComponent } from './components/tab-general/tab-general.component';
import { TabVehiclesComponent } from './components/tab-vehicles/tab-vehicles.component';
import { TabHierarchyComponent } from './components/tab-hierarchy/tab-hierarchy.component';
import { TicketVehicleModalComponent } from './components/ticket-vehicle-modal/ticket-vehicle-modal.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { TabsModule} from 'ngx-bootstrap/tabs';
import { SharedModule } from 'src/app/shared/shared.module';
import { RadioButtonModule } from 'src/app/shared/components/radiobutton/radiobutton.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    BsDropdownModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    MatTabsModule,
    TabsModule,
    NgxMaskPipe,
    NgxMaskDirective,
    TicketsRoutingModule,
  ],
  declarations: [
   TicketsComponent,
   TabGeneralComponent,
   TabVehiclesComponent,
   TabHierarchyComponent,
   TicketVehicleModalComponent
  ],
  providers: [DatePipe, provideNgxMask()],
})

export class TicketsModule {}
