<div class="content-page">
    <section class="container-logout">
        <a class="" (click)="logout()">
            <img src="assets/svg/Exit.svg" alt="">
            Sair
        </a>
    </section>

    <div class="container-fluid page-myaccount">
        <div class="col-lg-12 mt-4">
            <div data-e2e="select-contract-msg" class="subtitle">
                Selecione abaixo qual contrato deseja acessar:
            </div>
        </div>

        <app-card-select-contracts [contractList]="contractList" [isLoading]="isLoadingPage"
            [totalContractsList]="totalContractsList" [haveManyContracts]="haveManyContracts"
            [isMessageBySearchFilter]="isMessageBySearchFilter" (emitterValuesForm)="onSearch($event)"
            (emitterContract)="handleSelectedContract($event)" (emitterOpenModal)="showEditContractNameModal($event)"
            (pageChangeEvent)="onPaginationChange($event)" (emitterClear)="clearSearch($event)"
            [searchFieldParams]="searchFieldParams"
            [isRelationshipConsultantUser]="isRelationshipConsultantUser"></app-card-select-contracts>

    </div>
</div>

<app-footer *ngIf="!isLoadingPage" disableButton="true"></app-footer>