import { AdvanceDetailsService } from "./service/advance-details.service";
import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { debounceTime, finalize } from "rxjs/operators";
import { Subject } from "rxjs";
import {
  BillAdvanceDetailsQueryArguments,
  AdvanceVprTransaction,
} from "./dto/advance-details-dto";

@Component({
  selector: "app-advance-details-modal",
  templateUrl: "./advance-details-modal.component.html",
  styleUrls: ["./advance-details-modal.component.scss"],
})
export class AdvanceDetailsModalComponent implements OnInit {
  @Input() billId: number;
  contractId: number;

  isLoadingPage: boolean;
  isLoading: boolean;

  filterDebounce: Subject<BillAdvanceDetailsQueryArguments> =
    new Subject<BillAdvanceDetailsQueryArguments>();
  transactionQuery: BillAdvanceDetailsQueryArguments;
  transactions: AdvanceVprTransaction[];
  perPages: number[] = [10, 20, 50];
  total;

  constructor(
    private advanceDetailsService: AdvanceDetailsService,
    private modalService: NgbModal
  ) {
    this.filterDebounce
      .pipe(debounceTime(300))
      .subscribe((filter) => this.getAdvanceDetails(filter));
  }

  ngOnInit(): void {
    this.transactionQuery = new BillAdvanceDetailsQueryArguments(this.billId);
    this.getAdvanceDetails(this.transactionQuery);
  }

  onClose() {
    this.modalService.dismissAll();
  }

  private getAdvanceDetails(args: BillAdvanceDetailsQueryArguments) {
    this.isLoadingPage = true;

    this.advanceDetailsService
      .getAdvanceDetails(args)
      .pipe(
        finalize(() => {
          this.isLoadingPage = false;
        })
      )
      .subscribe(
        (success) => {
          this.transactions = success.getAnticipationReportQueryResult;
          this.contractId =
            success.getAnticipationReportQueryResult[0]?.contractId;
          this.total = success.numberOfItems;

          console.clear();
        },
        (error) => {
          console.error(error);
          return;
        }
      );
  }

  onOrderByChange(field: string) {
    if (this.transactionQuery.sortField === field) {
      this.transactionQuery.desc = !this.transactionQuery.desc;
    } else {
      this.transactionQuery.sortField = field;
      this.transactionQuery.desc = true;
    }

    this.getAdvanceDetails(this.transactionQuery);
  }

  bindOrderByClass(field: string) {
    return {
      "order-by-asc":
        this.transactionQuery.sortField == field && !this.transactionQuery.desc,
      "order-by-desc":
        this.transactionQuery.sortField == field && this.transactionQuery.desc,
    };
  }

  onPaginationChange(page: number) {
    if (this.transactionQuery.page !== page) {
      this.transactionQuery.page = page;
      this.getAdvanceDetails(this.transactionQuery);
    }
  }

  addClass(position) {
    if (position % 2 === 0) {
      return "linetype1";
    } else {
      return "linetype2";
    }
  }

  onSearch() {
    this.getAdvanceDetails(this.transactionQuery);
  }

  onDownload() {
    this.advanceDetailsService
      .getCvsAdvanceDetails(this.transactionQuery)
      .subscribe((x) => {
        const element = document.createElement("a");
        var newBlob = new Blob([x.file], { type: "text/csv" });

        element.href = URL.createObjectURL(newBlob);
        element.download =
          "Antecipação de Vale-Pedágio - Contrato " +
          this.contractId +
          " Fatura " +
          this.billId +
          ".csv";
        element.click();
      });
  }

  onFullNameKeyup() {
    this.filterDebounce.next(this.transactionQuery);
  }
}
