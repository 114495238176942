import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

// TODO - Apenas para teste. Falta definir a url para o portal unico
// const uriPortalUnico_Teste = 'https://dev-gestaodepedagio.edenred.com';
const uriPortalUnico = window.location.origin;

export const authConfig: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.identityProviderUrl,
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'app_tm_customer_portal',
    // clientId: 'app_tm_customer_server',
    // postLogoutRedirectUri: window.location.origin,
    postLogoutRedirectUri: uriPortalUnico,
    // URL of the SPA to redirect the user to after login
    // redirectUri: window.location.origin + '/login',
    redirectUri: uriPortalUnico + '/login',
    // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    silentRefreshRedirectUri: uriPortalUnico + '/silent-refresh.html',
    useSilentRefresh: false,
    scope: 'openid profile tm_customer_api tm_vehicle_rejected_api tm_unique_register_customer_api tm_system_account_rejected_api tm_financial_billing_api tm_tag_rejected_api tm_identity_server_api tm_tag_transaction_rejected_api tm_report_rejected_api tm_intvpr_r_api',
    oidc: true,
    responseType: 'code',
    // Only true in develop environment
    showDebugInformation: true,
    sessionChecksEnabled: false,
    customQueryParams: {},
    requireHttps: false, // Development only
};
