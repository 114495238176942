<div class="CreditReceivedComponent">
  <div class="content-page">
    <div class="container-fluid">
      <div class="row">
        <header class="col-12 Dashboard_Overview header-page">
          <div class="d-flex bd-highlight mb-3">
            <div class="mr-auto p-2 bd-highlight">
              <h1>/ Créditos Recebidos</h1>
              <div class="tag-white">
                <b>A</b>baixo são exibidos os créditos recebidos de<br />
                viagens de vale-pedágio
              </div>
            </div>
          </div>
        </header>

        <section class="container col-md-12 mt-5">
          <div class="card">
            <div class="card-body pt-0">

              <section class="action-center">
                <div class="action-center--left">
                  <div
                    class="btn-group"
                    dropdown
                    [isAnimated]="true"
                    [autoClose]="false"
                  >
                    <button
                      dropdownToggle
                      type="button"
                      id="button-animated"
                      aria-controls="dropdown-animated"
                      class="btn btn-filter dropdown-toggle"
                      [class.no-shadow]="isDatePickerOpen"
                      [class.without-border-bottom-radius]="isOpenDropdown"
                      [class.active]="isOpenDropdown"
                      (click)="toggleDropdown()"
                    >
                      <img src="assets/img/filter.svg" class="button-icon" />
                      Filtros
                    </button>

                    <div class="filters">
                      <ul
                        id="dropdown-animated"
                        *dropdownMenu
                        [ngClass]="
                          isShownPeriod
                            ? 'DatePickerOpen dropdown-menu dropdown-filter'
                            : 'dropdown-menu dropdown-filter'
                        "
                        [class.no-shadow]="isDatePickerOpen"
                        role="menu"
                        aria-labelledby="button-animated"
                      >
                        <li
                          role="menuitem"
                          (click)="toggleDateRangePickerGeneral()"
                        >
                          <div class="simulate-select">
                            <img src="assets/img/calendar_select.svg" />
                            <span class="simulate-label">Período</span>
                            <input
                              id="filterDate"
                              type="text"
                              placeholder="Selecione"
                              class="simulate-form-control"
                              #dpGeneral="bsDaterangepicker"
                              bsDaterangepicker
                              placement="right"
                              [bsValue]="dayCustomGeneral"
                              [maxDate]="maxDate"
                              (bsValueChange)="onFilterDateGeneral($event)"
                              [minDate]="minDate"
                              [outsideClick]="false"
                            />
                            <div
                              class="simulate-button"
                              [class.active]="isShownPeriod"
                            >
                              <img src="assets/img/arrow-select.svg" />
                            </div>
                          </div>
                        </li>

                        <li
                          role="menuitem"
                          (click)="toggleShowType(); dpGeneral.hide()"
                        >
                          <div class="simulate-select">
                            <img src="assets/img/receipt_long-24px.svg" />
                            <span class="simulate-label">Tipo de transação</span>
                            <p class="simulate-input" id="selectedType">
                              {{ tiposelecionado }}
                            </p>
                            <div
                              class="simulate-button"
                              [class.active]="isShowType"
                            >
                              <img src="assets/img/arrow-select.svg" />
                            </div>
                          </div>
                        </li>

                        <li role="menuitem">
                          <div class="content-button">
                            <button
                              id="resetFiltersButton"
                              class="btn btn-primary"
                              (click)="resetFilters()"
                            >
                              <img
                                class="button-icon"
                                src="assets/img/ico-delete.svg"
                              />
                              Limpar filtros
                            </button>
                            <button
                              id="hideGeneralDate"
                              #myDiv
                              hidden
                              class="btn btn-success"
                              (click)="dpGeneral.hide()"
                              type="button"
                            ></button>
                          </div>
                        </li>
                      </ul>

                      <div
                        class="box-area-datepicker"
                        [hidden]="date != null && !isOpen"
                        [ngClass]="{ show: isShownPeriod, hide: !isShownPeriod }"
                      >
                        <div class="content-extend">
                          <form [formGroup]="formDateGeneral">
                            <div class="datepicker">
                              <h4 class="text-center mb-2 mt-0">
                                Selecione o período
                              </h4>
                              <div
                                class="d-flex justify-content-around align-items-center"
                              >
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    class="custom-control-input"
                                    id="defaultGroupExample1"
                                    formControlName="dateGeneral"
                                    (click)="setSevenDaysLastGeneral()"
                                    name="dateGeneral"
                                    value="7"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="defaultGroupExample1"
                                    >últimos 7 dias</label
                                  >
                                </div>
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    class="custom-control-input"
                                    id="defaultGroupExample2"
                                    formControlName="dateGeneral"
                                    (click)="setFifteenDaysLastGeneral()"
                                    name="dateGeneral"
                                    value="15"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="defaultGroupExample2"
                                    >últimos 15 dias</label
                                  >
                                </div>
                                <div class="custom-control custom-radio">
                                  <input
                                    type="radio"
                                    class="custom-control-input"
                                    id="defaultGroupExample3"
                                    formControlName="dateGeneral"
                                    (click)="setThirtyDaysLastGeneral()"
                                    name="dateGeneral"
                                    value="30"
                                  />
                                  <label
                                    class="custom-control-label"
                                    for="defaultGroupExample3"
                                    >últimos 30 dias</label
                                  >
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <form [formGroup]="formExtract">
                        <div
                          class="box-area-hierarchy"
                          [ngClass]="{ show: isShowType, hide: !isShowType }"
                        >
                          <div class="content-extend">
                            <div class="hierarchy">
                              <h4 class="text-center mb-2">Selecione o tipo</h4>
                              <div class="col-lg-12 radioButtons">
                                <ngx-radiobutton-group
                                  [(ngModel)]="radioType"
                                  [disabled]="disabled"
                                  formControlName="radioType"
                                >
                                  <ngx-radiobutton
                                    id="radioType{{ i }}"
                                    (click)="onFilterTypeClick(value)"
                                    *ngFor="let value of types; let i = index"
                                    [value]="value.id"
                                  >
                                    <b>{{ value.name }}</b>
                                  </ngx-radiobutton>
                                </ngx-radiobutton-group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <form novalidate>
                    <div class="form-group select-table">
                      <label>Quantidade de linhas</label>
                      <select
                        class="form-control form-select"
                        id="pageSizeSelect"
                        (change)="onPageSizeChange($event)"
                      >
                        <option *ngFor="let i of perPages" value="{{ i }}">
                          exibir {{ i }} linhas
                        </option>
                      </select>
                    </div>
                  </form>

                  <form class="input-search">
                    <input
                      type="text"
                      id="search"
                      placeholder="Placa, CNPJ do Embarcador"
                      (keyup)="onFullNameKeyup($event)"
                      [value]="search"
                    />
                    <button
                      id="searchButton"
                      class="btn btn-search"
                      (click)="onSearchClick($event)"
                    >
                      <img
                        src="assets/img/icon-search.svg"
                        class="button-icon"
                      />
                      <span>Pesquisar</span>
                    </button>
                  </form>
                </div>

                <button
                  [disabled]="noTransactions"
                  class="btn btn-primary btn-download"
                  (click)="downloadCsv()"
                >Download CSV</button>
              </section>

              <div>
                <table class="table CreditReceived table-striped">
                  <thead class="thead">
                    <tr class="table-group">
                      <th class="header-table" colspan="2">
                        <div>Dados do embarcador</div>
                      </th>
                      <th class="header-table" colspan="6">
                        <div>Dados da emissão da viagem</div>
                      </th>
                      <th class="header-table" colspan="2">
                        <div>Valores (R$)</div>
                      </th>
                    </tr>

                    <tr class="table-heading">
                      <th
                        [ngClass]="bindOrderByClass('customerCorporateName')"
                        (click)="onOrderByChange('customerCorporateName')"
                      >
                        embarcador
                      </th>
                      <th
                        [ngClass]="bindOrderByClass('customerDocument')"
                        (click)="onOrderByChange('customerDocument')"
                      >
                        CNPJ
                      </th>
                      <th
                        class="table-trip"
                        [ngClass]="bindOrderByClass('tripCode')"
                        (click)="onOrderByChange('tripCode')"
                      >
                        Código da viagem
                      </th>
                      <th
                        [ngClass]="bindOrderByClass('tripDate')"
                        (click)="onOrderByChange('tripDate')"
                      >
                        data da emissão
                      </th>
                      <th
                        [ngClass]="bindOrderByClass('receiveDate')"
                        (click)="onOrderByChange('receiveDate')"
                      >
                        Data do crédito
                      </th>
                      <th
                      [ngClass]="bindOrderByClass('tripExpirationDate')"
                      (click)="onOrderByChange('tripExpirationDate')"
                      >
                        Data de expiração
                      </th>
                      <th
                        [ngClass]="bindOrderByClass('tripOrigin')"
                        (click)="onOrderByChange('tripOrigin')"
                      >
                        origem e destino
                      </th>
                      <th
                        [ngClass]="
                          bindOrderByClass('tollVoucherRechargeStatus')
                        "
                        (click)="onOrderByChange('tollVoucherRechargeStatus')"
                      >
                        tipo
                      </th>
                      <th

                    >
                      Documento
                    </th>
                      <th
                        class="table-plate"
                        [ngClass]="bindOrderByClass('licensePlate')"
                        (click)="onOrderByChange('licensePlate')"
                      >
                        Placa
                      </th>
                      <th
                        [ngClass]="bindOrderByClass('amount')"
                        (click)="onOrderByChange('amount')"
                      >
                        Valor Total
                      </th>
                    </tr>
                  </thead>

                  <div class="container-loading" *ngIf="isLoading">
                    <app-loader
                      [isLoading]="isLoading"
                      message="{{ message }}"
                    ></app-loader>
                  </div>

                  <tbody *ngIf="!isLoading">
                    <tr
                      *ngFor="
                        let item of getVprCreditReceivedQueryResult?.items;
                        let i = index
                      "
                    >
                      <td id="customerCorporateName{{ i }}">
                        {{ item?.customerCorporateName }}
                      </td>
                      <td id="customerDocument{{ i }}">
                        {{
                          item?.customerDocument | mask : "00.000.000/0000-00"
                        }}
                      </td>
                      <td id="tripCode{{ i }}">{{ item?.tripCode }}</td>
                      <td id="tripDate{{ i }}">
                        {{ item?.tripDate | date : "dd/MM/yyyy HH:mm:ss" }}
                      </td>
                      <td id="creditDate{{ i }}">
                        {{ item?.receiveDate | date : "dd/MM/yyy HH:mm:ss" }}
                      </td>
                      <td id="tripExpirationDate{{ i }}">
                        {{ item?.tripExpirationDate | date : "dd/MM/yyy HH:mm:ss" }}
                      </td>
                      <td>
                        <div class="origin row">
                          <p class="col-sm-auto">De:</p>
                          <p class="col" id="tripOrigin{{ i }}">
                            {{ item?.tripOrigin }}
                          </p>
                        </div>
                        <div class="destiny row">
                          <p class="col-sm-auto">Até:</p>
                          <p class="col" id="tripDestination{{ i }}">
                            {{ item?.tripDestination }}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div id="transactionTypeDescription{{ i }}">
                          {{ item?.transactionTypeDescription }}
                        </div>
                      </td>
                      <td>
                        <a *ngIf="item?.totalDocuments !== 0" (click)="onModelDocumentsOpen(item?.tripCode, item?.tripDocuments)" id="totalDocuments{{ i }}">
                          <span [ngClass]="">
                            {{ item?.totalDocuments + (item?.totalDocuments === 1 ? ' documento' : ' documentos') }}
                          </span>
                        </a>
                        <span *ngIf="item?.totalDocuments === 0" id="totalDocuments{{ i }}">
                          -
                        </span>
                      </td>
                      <td>
                          <p id="licensePlate{{ i }}">
                            {{ item?.licensePlate | mask : "AAA-AAAA" }}
                          </p>

                      </td>
                      <td>
                        <p id="amount{{ i }}">
                          <span [ngClass]="item?.amount > 0 ? 'positive' : 'negative'">
                            {{ item?.amount > 0 ? '+' : '-' }}
                          </span>

                          {{
                            item?.amount | abs
                              | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                          }}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <section
                  *ngIf="
                    getVprCreditReceivedQueryResult.total == 0 && !isLoading
                  "
                >
                  <div colspan="12" class="text-center w-100 py-4" translate>
                    Nenhum crédito de vale-pedágio encontrado do dia
                    <span id="FromDate">
                      {{
                        getVprCreditReceivedQueryArguments.FromDate
                          | date : "dd/MM/yyyy"
                      }}
                    </span>
                    até
                    <span id="ToDate">
                      {{
                        getVprCreditReceivedQueryArguments.ToDate
                          | date : "dd/MM/yyyy"
                      }}
                    </span>
                  </div>
                </section>

                <footer
                  class="pagination-custom"
                  *ngIf="
                    getVprCreditReceivedQueryResult.total >
                      getVprCreditReceivedQueryArguments.pageSize && !isLoading
                  "
                >
                  <app-pagination
                    [totalItems]="getVprCreditReceivedQueryResult.total"
                    [itemsPerPage]="getVprCreditReceivedQueryArguments.pageSize"
                    [maxPages]="5"
                    [currentPage]="getVprCreditReceivedQueryArguments.page"
                    (pageChangeEvent)="onPaginationChange($event)"
                  ></app-pagination>
                </footer>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
