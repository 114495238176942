import { Component, Input, OnInit } from '@angular/core';
import { ContractUsers } from '@models/old/contractusers.model';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserEditModalComponent } from '../user-edit-modal/user-edit-modal.component';
import { MyContractService } from '@services/mycontract.service';

@Component({
  selector: 'app-contract-users',
  templateUrl: './contract-users.component.html',
  styleUrls: ['./contract-users.component.css']
})

export class ContractUsersComponent implements OnInit {

  bsModalRef: BsModalRef;
  isLoading:Boolean = false;
  @Input() contractUsers: Array<ContractUsers> = [];
  public profileList = [];


  constructor(private modalService: BsModalService, private myContractService: MyContractService) {
  }

  ngOnInit() {
      this.isLoading = true;
      this.ShowGroups();
   }


  showUserEditModal(user, edit){
    const initialState = { parameter: 2020,user: user, profileList: this.profileList, isEdit: edit};
    this.bsModalRef = this.modalService.show(UserEditModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }


  ShowGroups() {
    this.myContractService.getGroups()
   .subscribe(
     (sucess:any) => {
      this.profileList = sucess;
      this.isLoading = false;
     },
     (error) => {
       console.log('Erro: ' + error);
     }
   );
  }


}




