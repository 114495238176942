<div class="tag-block-unblock-history">
  <p class="tag-block-unblock-history__info-text">
    Consulte abaixo o histórico dos seus últimos bloqueios e desbloqueios.
  </p>

  <form class="tag-block-unblock-history__form">

    <div class="form-group select-table">
      <label for="select-page-size">
        Quantidade de linhas
      </label>
      <select
        class="form-control form-select tag-block-unblock-history__form-select"
        id="select-page-size"
        data-e2e="select-page-size"
        (change)="onPageSizeChange($event)"
      >
        @for (size of pageSizes; track size) {
          <option
            id="page-size-{{size}}"
            [attr.data-e2e]="'page-size-' + size"
            [value]="size"
          >
            exibir {{size}} linhas
          </option>
        }
      </select>
    </div>

    <div class="form-group select-table">
      <label for="select-page-size">
        Filtre por tipo de ação
      </label>
      <select
        class="form-control form-select tag-block-unblock-history__form-select"
        id="select-page-size"
        data-e2e="select-page-size"
        (change)="onActionTypeChange($event)"
      >
        @for (action of filterActionTypes; track action.value) {
          <option
            id="action-type-{{action.value}}"
            [attr.data-e2e]="'action-type-' + action.value"
            [value]="action.value"
          >
            {{action.desc}}
          </option>
        }
      </select>
    </div>
  </form>

  <table class="tag-block-unblock-history__table">
    <thead class="tag-block-unblock-history__thead">
      <tr>
        <th [ngClass]="bindOrderByClass(tableHeadersHistory.ACTION_TYPE)">

          <div class="btn-order-table">
            Tipo de ação
          </div>

          <div class="round-order"
            id="btn-order-action-type"
            data-e2e="btn-order-action-type"
            (click)="onOrderByChange(tableHeadersHistory.ACTION_TYPE)"
          >
            <img src="assets/svg/order-table.svg" alt="Ordenação" />
          </div>
        </th>

        <th class="tag-block-unblock-history__th-registration-date" [ngClass]="bindOrderByClass(tableHeadersHistory.REGISTRATION_DATE)">

          <div class="btn-order-table">
            Data do envio
          </div>

          <div class="round-order"
            id="btn-order-registration-date"
            data-e2e="btn-order-registration-date"
            (click)="onOrderByChange(tableHeadersHistory.REGISTRATION_DATE)"
          >
            <img src="assets/svg/order-table.svg" alt="Ordenação" />
          </div>
        </th>

        <th [ngClass]="bindOrderByClass(tableHeadersHistory.USERNAME)">

          <div class="btn-order-table">
            Usuário
          </div>

          <div class="round-order"
            id="btn-order-username"
            data-e2e="btn-order-username"
            (click)="onOrderByChange(tableHeadersHistory.USERNAME)"
          >
            <img src="assets/svg/order-table.svg" alt="Ordenação" />
          </div>
        </th>

        <th class="tag-block-unblock-history__th-status" [ngClass]="bindOrderByClass(tableHeadersHistory.STATUS)">

          <div class="btn-order-table">
            Status
          </div>

          <div class="round-order"
            id="btn-order-status"
            data-e2e="btn-order-status"
            (click)="onOrderByChange(tableHeadersHistory.STATUS)"
          >
            <img src="assets/svg/order-table.svg" alt="Ordenação" />
          </div>
        </th>

        <th class="tag-block-unblock-history__th-action"></th>
      </tr>
    </thead>

    @if (totalItems > 0 && !isLoadingTableData) {
      <tbody>
        @for (item of historyResultList; track $index) {
          <tr>
            <td id="table-data-action-type-{{$index}}" [attr.data-e2e]="'table-data-action-type-' + $index"
              class="tag-block-unblock-history__td tag-block-unblock-history__td--emphasis"
            >
              {{ item.blockOrUnblockDescription }}
            </td>

            <td id="table-data-registration-date-{{$index}}" [attr.data-e2e]="'table-data-registration-date-' + $index"
              class="tag-block-unblock-history__td"
            >
              {{ item.registrationDate | date: 'dd/MM/yyyy - HH:mm:ss':'UTC' }}
            </td>

            <td id="table-data-username-{{$index}}" [attr.data-e2e]="'table-data-username-' + $index"
              class="tag-block-unblock-history__td"
            >
              {{ item.userName }}
            </td>

            <td
              class="tag-block-unblock-history__td"
              id="table-data-status-{{$index}}"
              [attr.data-e2e]="'table-data-status-' + $index"
            >
              @switch (item.tagFileControlStatusId) {
                @case (statusProcess.PROCESSING) {
                  <div class="column-status column-status--yellow">
                    <span class="column-status__text" id="table-data-status-processing">Em processamento</span>
                  </div>
                }
                @case (statusProcess.DONE) {
                  <div class="column-status column-status--green">
                    <span class="column-status__text" id="table-data-status-done">Concluído</span>
                  </div>
                }
                @case (statusProcess.ERROR) {
                  <div class="column-status column-status--red">
                    <span class="column-status__text" id="table-data-status-error">Erro</span>
                  </div>
                }
              }
            </td>

            <td>
              <button
                id="table-data-btn-action-{{$index}}"
                [attr.data-e2e]="'table-data-btn-action-' + $index"
                class="btn-icon-not-bg"
                type="button"
                [disabled]="item.tagFileControlStatusId != statusProcess.ERROR"
                (click)="openTagBlockUnblockInconsistenciesModal(item)"
                [ngbTooltip]="(item.tagFileControlStatusId == statusProcess.ERROR ? 'Ver detalhes' : '')"
              >
                <img class="btn-icon-not-bg__icon icon-document-text" alt="Ícone de documento">
              </button>
            </td>
          </tr>
        }
      </tbody>
    }

  </table>

  @if (totalItems > historyQuery.pageSize && !isLoadingTableData) {

    <div class="pagination-custom tag-block-unblock-history__pagination">

      <app-pagination
        [totalItems]="totalItems"
        [itemsPerPage]="historyQuery.pageSize"
        [maxPages]="5"
        [currentPage]="historyQuery.page"
        (pageChangeEvent)="onPaginationChange($event)"
      ></app-pagination>

    </div>
  }

  @if (isLoadingTableData) {

    <div class="container-loading">

      <app-loader [isLoading]="isLoadingTableData"></app-loader>

    </div>
  }
  @else if (totalItems == 0) {

    <div class="tag-block-unblock-history__no-results">

      <app-no-results-msg
        [inTitle]="noResultMsgTitle"
        [inSubtitle]="noResultMsgSubtitle"
      ></app-no-results-msg>

    </div>
  }
</div>
