import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  VehiclesQueryArguments
} from "@modules/customer/bill/components/bill-detail/components/bill-detail-hierarchy/dto/vehicles-query.arguments";
import {VehiclesDto} from "@models/backoffice/vehicles-backoffice.dto";
import {MyvehiclesService} from "@modules/customer/myvehicles/services/myvehicles.service";
import {GetVehiclesQueryResultDto} from "@modules/customer/myvehicles/dtos/get-vehicles-query-result.dto";
import {GetVehicleQueryResultDto} from "@modules/customer/myvehicles/dtos/get-vehicle-query-result.dto";

@Component({
  selector: 'app-vehicles-by-filter',
  templateUrl: './vehicles-by-filter.component.html',
  styleUrls: ['./vehicles-by-filter.component.scss'],
})
export class VehiclesByFilterComponent implements OnChanges {
  @Output() changeVehicle = new EventEmitter();
  @Input() filterType!: number;

  panelOpenState = false;

  query: VehiclesQueryArguments = new VehiclesQueryArguments(
    1,
    100000,
    'licensePlate',
    false,
    '',
    //filterType value is setted on the onChanges
  );
  isLoading: boolean;
  vehicles: GetVehicleQueryResultDto[];
  vehicleSearch: string;
  vehicleSelected: VehiclesDto;
  emptySearch: boolean;
  message: string;
  buttonSelected: number = 1;

  constructor(private vehiclesService: MyvehiclesService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.filterType) this.filterType = changes?.filterType.currentValue;

    this.query.filter = this.filterType;

    this.getVehicles(this.query);
  }

  async getVehicles(query) {
    this.isLoading = true;
    await this.vehiclesService
      .getVehicles(query)
      .subscribe((success: GetVehiclesQueryResultDto) => {
        this.vehicles = success.vehicles;
        this.isLoading = false;
      });
  }

  onchangeVehicle(item: VehiclesDto) {
    this.vehicleSelected = item;
    this.changeVehicle.emit(item);
  }

  buttonType(button: number) {
    this.buttonSelected = button;
  }

  searchVehicles(value: string) {
    let searchResult = this.vehicles.filter((value) => {
      return searchResult;
    });
  }

  emptySearchStyle() {
    return this.emptySearch ? `1fr` : `1fr 1fr 1fr`;
  }
}
