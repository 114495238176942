<!-- **************** MYACCOUNT | Start **************** -->
<div class="Dashboard container-fluid page-myaccount">
  <div class="row">
    <div class="col-12 Dashboard_Overview">
      <h1 data-e2e="page-title">/ MINHA CONTA</h1>
      <!-- <img class="logo-overview" src="assets/img/logo-bunge.png" />  colocar imagem dinamica da empresa-->
    </div>
    <div class="grid">
      <div class="Dashboard_col_1 account-container">
        <div class="container-repom">
          <div class="box-title">
            <span data-e2e="account-title">Minha conta</span>
          </div>
          <ng-template  class="container-loading" #userLoading>
            <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
          </ng-template>
          <div class="account" *ngIf="currentUser$ | async as user; else userLoading">
              <div class="photo-user">
                <span data-e2e="account-role-data" id="personResponsibleTypeDescription">{{ user?.personResponsibleTypeDescription }}</span> <img src="../../../assets/svg/account_user.svg" alt="Image de perfil" class="p">
              </div>
  
              <div class="desc">
                <p data-e2e="account-name-data" id="fullName" class="acc-title">{{ user?.fullName }}</p>
                <p data-e2e="account-doc-data" id="documentNumber" class="acc-subtitle">{{ user?.documentNumber | mask: '000.000.000-00' }}</p>
                <p data-e2e="account-birth-data" id="dateofBirth">{{ user?.dateofBirth | date: 'dd/MM/yyyy':'+0000' }}</p>
                <p data-e2e="account-phone-data" id="telephone">
                  <span id="collection">
                    <span data-e2e="account-country-data" id="countryCode">{{ user?.countryCode }}</span>
                    <span data-e2e="account-state-data" id="prefix">{{ user?.areaCode }}</span>
                    <span data-e2e="account-phone-data" id="telephoneNumber">{{ user?.telephoneNumber }}</span>
                  </span>
                    <button type="button" class="btn-popover edit-btn"
                      data-e2e="popover-edit-phone-action-label"
                      (click)="showEditPhoneModal(user)">
                      <img class="btn-popover-icon" src="../../../assets/svg/edit.svg" alt="" />
                    </button>
                  </p>
                
                <p data-e2e="account-address-data" id="emailAddress">
                  <span id="collection">{{ user?.emailAddress }}</span>
                  <button type="button" class="btn-popover edit-btn"
                    data-e2e="popover-edit-phone-action-label"
                    (click)="showEditEmailModal(user)">
                    <img class="btn-popover-icon" src="../../../assets/svg/edit.svg" alt="" />
                  </button>
                </p>
                <div class="btn-content">
                  <button
                  class="btn btn-primary btn-change-password position-relative"
                  data-e2e="btn-change-password"
                  (click)="changePassword()">
                    <img src="../../../assets/img/password-change.svg" />
                      Alterar senha
                  </button>
                </div>           
              </div>
          </div>
        </div>
      </div>
      <div class="Dashboard_col_1 account-container">
        <div class="container-repom">
          <div class="box-title">
            <span>Meus contratos</span>
          </div>
          <div class="container-loading" *ngIf="isLoading">
            <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
         </div>
          <div class="account UserContracts" *ngIf="!isLoading">
            <div class="row">
              <div class="contracts">
                 <div class="title" data-e2e="contracts-user-title">Contratos que possuo acesso:</div>
                 <div class="contract-container" *ngFor=" let contract of userContractList">
                  <div class="infos">
                    <div class="company"><span data-e2e="contract-company-description">{{contract?.description}}</span> - <span data-e2e="contract-company-document">{{ contract?.documentNumberCompanyContract | mask: '00.000.000/0000-00'}}</span></div>
                    <div class="contract">
                      <div>Contrato: <span data-e2e="contract-id">{{ contract?.id }}</span></div>           
                      <div *ngIf="contract?.nameContract">Apelido: <span data-e2e="contract-name">{{ contract?.nameContract }}</span></div>
                    </div>
                  </div>  
                  <div class="select-contract">
                    <button
                        class="btn btn-primary position-relative"
                        data-e2e="btn-select-contract"
                        (click)="selectContract(contract)"
                  >
                  Acessar contrato</button>
                  </div>          
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- **************** MYACCOUNT | finish **************** -->
