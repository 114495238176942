<div class="importVehiclesComponent">
<div class="modal-content">
    <div class="modal-header">
      <div data-e2e="modal-title" class="box-title"><span>Importar veículos</span></div>
      <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss"><span aria-hidden="true">×</span></button>
    </div>
    <div class="modal-body show modal-edit-vehicle" *ngIf="!concluded">
     <h3>Arraste e solte ou faça o upload</h3>
     <div class="row-box-yellow">
      <span class="box-yellow">
        <b>A</b>tenção, caso o arquivo com a planilha esteja aberto, <span class="red">salve e feche o arquivo</span> <br> para evitar erros e, somente depois, faça o upload.
      </span>
    </div>
     <div data-e2e="import-dragdrop"  class="dragdrop" appDnd (fileDropped)="onFileDropped($event)">
        <input type="file" id="inputFile" *ngIf="!imported" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
        <a><img src="assets/img/upload.png" /></a>
        <p *ngIf="!imported" class="title">Arraste e solte</p>
        <p *ngIf="!imported" class="text">a planilha de veículos nesta área para iniciar o cadastro em massa.</p>
        <div *ngIf="imported" class="file">{{files[0].name}} ({{ formatBytes(files[0].size) }})
          <a class="delete"    
          [popover]="actionPopover"
          #pop="bs-popover" 
          [outsideClick]="true" 
          position="right" data-e2e="remove-dragdrop" (click)="deleteFile(0)"><img src="assets/img/ico-delete.svg" /></a>
        </div>
        <ng-template #actionPopover placement="bottom" [animation]="true" [closeOnClickOutside]="true" [closeOnMouseOutside]="true">
          Remova o arquivo para substituir por outro.
        </ng-template>
    </div>
    <div *ngIf="messageError" class="error">Formato do arquivo incorreto: usar o mesmo formato do arquivo de download</div>
    <span *ngIf="!imported" class="hr">
        <p>Ou</p>
        <hr />
      </span>
    <button data-e2e="import-btn"  *ngIf="!imported" id="button-animated" aria-controls="dropdown-animated"  class="btn btn-repom btn-import btn-repom-user" type="button" >
        <input type="file"
        data-e2e="download-file-model" 
        id="file"
        (change)="fileBrowseHandler($event.target.files)">
        <i class="ico ico-upload"></i>
        <div>importar planilha</div>
        <span class="btn-animation"></span>
      </button>   
     <div class="templateDownload"> 
        <div class="message">Modelo de planilha de veículos?</div> 
        <div data-e2e="download-file-model" class="download" id="downloadFileModel" (click)="DownloadFileModel()">
          <span class="col-sm-auto">Faça o download aqui</span>
          <i
          data-e2e="download-file-model-tooltip-msg"
          class="ico ico-alert"
          placement="top"
          ngbTooltip="As colunas destacadas com * representam os dados de preenchimento obrigatório"
        ></i>
        </div> 
     </div>
    </div>
    <div class="modal-body show modal-edit-vehicle concluded" *ngIf="concluded">
        <h3>Upload de planilha concluído</h3>
        <div class="dragdrop confirmed">
            <a><img src="assets/img/upload.png" /></a>
            <p class="title">A INCLUSÃO DOS VEÍCULOS ESTÁ EM ANDAMENTO.</p>
            <p class="textConcluded">Assim que o procedimento for concluído, você será notificado pela área de notificações localizada no menu superior.</p>
            <a class="arrow"><img src="assets/img/upload-side-arrow.png" /></a>
        </div>  
        <div class="boxNotification">
            <div class="ico-notification">
                <div class="numberNotification">1</div>                     
            </div>
            <div class="ico-user"></div>            
        </div>  
    </div>
    <div class="modal-footer modal-footer-white" *ngIf="!concluded" >
        <button data-e2e="cancel-btn" type="button" class="btn btn-tertiary mr-4" id="btn-close" (click)="dismissModal()">Cancelar</button>
        <button data-e2e="save-import-btn" type="button" [disabled]="!imported || onSaving" (click)="uploadFile()" class="btn btn-secondary" id="btn-confirm">Importar veículos
          <img class="loading-btn" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
        </button>
     </div>
     <div class="modal-footer modal-footer-gray" *ngIf="concluded" id="close-btn">
        <button data-e2e="close-btn" type="button" *ngIf="concluded" (click)="dismissModal()" class="btn btn-secondary btn-concluded" >Fechar</button>
     </div>
</div>
</div>