<div data-e2e="select-group-msg" class="container notSelected" *ngIf="hierarchyId === '' && !isLoading && Empty == false">
  Selecione um grupo ao lado ou faça uma busca.
</div>

<div data-e2e="empty-hierarchy-msg" class="empty" *ngIf="Empty == true">
   Não há hierarquia cadastrada neste contrato!
</div>

<div class="container-loading" *ngIf="isLoading && Empty == false" >
  <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
</div>

<div class="container" *ngIf="hierarchyId !== '' && !isLoading">
  <div class="row">
      <div class="col-md-auto header d-flex align-items-center">
          <div class="img-hierarchy"></div>
          <div class="d-flex flex-column mr-5">
              <span class="blue-hierarchy" id="gridHierarchy">{{ Hierarchy }}</span>
              <span class="fs-12" id="gridHierarchyFull">{{ HierarchyFull }}</span>
           <!--    <span class="blue-hierarchy">Frota 2</span>
              <span class="fs-12">Sudeste / São Paulo / Sede 3 / Tags ativas</span> -->
          </div>
          <div class="d-flex flex-column">
              <span class="fs-12"><span id="gridVehicleCount">{{ vehicleCount }}</span> veículos associados</span>
              <span class="fs-12"><span id="gridTagCount">{{ tagCount }}</span> tags ativas</span>
              <!-- <span class="fs-12">360 veículos associados</span>
              <span class="fs-12">200 tags ativas</span> -->
          </div>
      </div>
      <div class="col-sm-auto header-2 d-flex align-items-center justify-content-end">
          <div class="d-flex flex-column">
              <span class="fs-12">Valor total gasto</span>
              <span><div class="money">R$</div><div id="gridAmountTotal" class="blue-hierarchy">{{ amountTotal | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</div></span>
          </div>
      </div>
  </div>

  <div class="row justify-content-center mt-4">
      <div class="col-md-auto pagesizeform">
        <form novalidate>
          <div class="form-group select-table">
            <select class="form-control SelectPageSize form-select" (change)="onPageSizeChange($event)">
              <option *ngFor="let i of perPages" value="{{i}}">exibir {{ i }} linhas</option>
            </select>
          </div>
        </form>
      </div>
  </div>

  <div class="row mt-2">
      <div class="col-12">
          <table class="table">
            <thead class="thead">
              <tr class="head1">
                  <td colspan="1" class="desc-vehicles"><div >DETALHES</div></td>
                  <td colspan="7" class="values-vehicles"><div>VALORES (R$)</div></td>
              </tr>
            <tr class="head2">
              <th [ngClass]="bindOrderByClass('licensePlate')" (click)="onOrderByChange('licensePlate')">
                <button class="btn-order-table" translate >PLACA / APELIDO</button>
                <div class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('amountPassenger')"  (click)="onOrderByChange('amountPassenger')">
                <button class="btn-order-table" translate>PEDÁGIO</button>
                <div class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('amountReversal')" (click)="onOrderByChange('amountReversal')">
                <button class="btn-order-table" translate>ESTORNO DE PEDÁGIO</button>
                <div class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('amountParking')"  (click)="onOrderByChange('amountParking')">
                <button class="btn-order-table" translate>ESTACIONAMENTO</button>
                <div class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('amountParkingReversal')" (click)="onOrderByChange('amountParkingReversal')">
                <button class="btn-order-table" translate>ESTORNO DE ESTACIONAMENTO</button>
                <div class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('amountVPR')" (click)="onOrderByChange('amountVPR')">
                <button class="btn-order-table" translate>GASTO EM VALE PEDÁGIO</button>
                <div class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
              <th [ngClass]="bindOrderByClass('amountServices')" (click)="onOrderByChange('amountServices')">
                <button class="btn-order-table" translate>MENSALIDADE</button>
                <div class="round-order">
                  <img src="../../../assets/svg/order-table.svg" alt="" />
                </div>
              </th>
            </tr>
          </thead>
              <tbody>
               <tr  *ngFor="let hierarchy of BillVehiclesTotal.items; let i = index" [ngClass]="addClass(i)" (click)="showVehicleModal(hierarchy.licensePlate,hierarchy.nickname);">
                  <td><div [attr.data-e2e]="'hierarchy-name-' + i" id="licensePlate{{i}}">{{ hierarchy.licensePlate }}</div><div id="nickname{{i}}">{{ hierarchy.nickname }}</div></td>
                  <td [attr.data-e2e]="'hierarchy-amount-passenger-' + i" id="amountPassenger{{i}}">{{ hierarchy.amountPassenger | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}</td>
                  <td [attr.data-e2e]="'hierarchy-reversal-amount-' + i" id="amountReversal{{i}}">{{ hierarchy.amountReversal | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}</td>
                  <td [attr.data-e2e]="'hierarchy-parking-amount-' + i" id="amountParking{{i}}">{{ hierarchy.amountParking | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}</td>
                  <td [attr.data-e2e]="'hierarchy-parking-reversal-' + i" id="amountParkingReversal{{i}}">{{ hierarchy.amountParkingReversal | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}</td>
                  <td [attr.data-e2e]="'hierarchy-vpr-amount-' + i" id="amountVPR{{i}}">{{ hierarchy.amountVPR | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}</td>
                  <td [attr.data-e2e]="'hierarchy-service-amount-' + i" id="amountServices{{i}}">{{ hierarchy.amountServices | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}</td>
                </tr>
              </tbody>
            </table>

            <div class="container-loading loadingPage" *ngIf="isLoadingPage" >
              <app-loader [isLoading]="isLoadingPage" message="{{ 'Carregando...'}}"></app-loader>
            </div>

            <div class="pagination-custom" *ngIf="BillVehiclesTotal.totalItems>BillVehiclesTotalQueryArguments.pageSize">
              <app-pagination
                [totalItems]="BillVehiclesTotal.totalItems"
                [itemsPerPage]="BillVehiclesTotalQueryArguments.pageSize"
                [maxPages]="5"
                [currentPage]="BillVehiclesTotalQueryArguments.page"
                (pageChangeEvent)="onPaginationChange($event)"
              ></app-pagination>
            </div>
      </div>
  </div>
</div>
