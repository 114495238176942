<!-- **************** MYCOMPANY | Start **************** -->
<div class="Dashboard container-fluid">
  <div class="row">
    <div class="col-12 Dashboard_Overview">
      <h1 data-e2e="page-title">/ CONTRATO</h1>
    </div>

    <div class="col-12" *ngIf="isLoading">
      <div class="container-loading">
        <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
      </div>
    </div>

    <div class="col-7 margintop22" *ngIf="!isLoading">
      <div class="row blockTitle">
        <div class="col-sm-auto"><img src="assets/img/company.svg"></div>
        <div class="contractPageTitle col">
          <p data-e2e="company-name" class="title" id="companyName">{{ legalPerson?.companyName }}</p>
          <p data-e2e="company-doc" class="subtitle" id="documentNumber" *ngIf="legalPerson?.documentNumber.length == 11">
          {{ legalPerson?.documentNumber | mask: '000.000.000-00' }}
          </p>
          <p data-e2e="company-doc" class="subtitle" id="documentNumber" *ngIf="legalPerson?.documentNumber.length == 14">
            {{ legalPerson?.documentNumber | mask: '00.000.000/0000-00' }}
          </p>
        </div>
      </div>
  </div>
  <div class="col-5 margintop22 actionButtons" *ngIf="!isLoading">
    <button data-e2e="open-delivery-addresses-modal-btn"  id="AddressButton" type="button" class="btn btn-repom btn-repom-user" (click)="showDeliveryAddressesModal()">
      <i class="ico ico-address"></i>Endereços para entrega<span class="btn-animation"></span></button>
      <button *accessControl="101219" data-e2e="open-add-user-modal-btn" id="AddUserButton" type="button" class="btn btn-repom btn-repom-user" (click)="showAddUserModal()">
        <i class="ico ico-include-user "></i>Incluir usuário<span class="btn-animation"></span></button>
    </div>

    <div class="grid">

      <div class="Dashboard_col_1" *ngIf="!isLoading">
        <div class="container-repom">
          <div class="box-title">
            <span data-e2e="register-title">Cadastro</span>
          </div>
            <div class="registrationTitle" data-e2e="company-subtitle">Dados da empresa</div>
            <div class="row">
              <div class="col-6 company-description">
                <p data-e2e="company-fantasy-label">Nome fantasia:</p>
                <p data-e2e="company-municipal-subscription-label">Inscrição Municipal:</p>
                <p data-e2e="company-state-subscription-label">Inscrição Estadual:</p>
                <p data-e2e="company-rntrc-label">RNTRC:</p>
              </div>
              <div class="col-6">
                <p data-e2e="company-fantasy-data" id="tradingName">{{ legalPerson?.tradingName }}</p>
                <p data-e2e="company-municipal-subscription-data" id="stateRegister">{{ legalPerson?.stateRegister }}</p>
                <p data-e2e="company-state-subscription-data" id="corporateTaxRegister">{{ legalPerson?.corporateTaxRegister }}</p>
                <p data-e2e="company-rntrc-data" id="rntrc">{{ legalPerson?.rntrc }}</p>
              </div>
            </div>
            <hr />
            <div>
              <app-contract-summary
                [commercialCondition]="commercialCondition"
                [principalPaymentMethodType]="principalPaymentMethodType"
              ></app-contract-summary>
            </div>
        </div>
      </div>

      <div class="Dashboard_col_2" *ngIf="!isLoading">
        <app-manager-contract [contractUsers]="contractUsers"></app-manager-contract>
      </div>

    </div>

  </div>
</div>
