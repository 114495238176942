<card-model>
  <ng-container *ngIf="canceledCards$ | async as canceledCards">
    <header class="card-model-header">
      <h1>Tags Canceladas</h1>
      <p>Veja suas TAGs canceladas por período.</p>
    </header>

    <section class="card-model-container">
      <div class="action-center">
        <app-date-picker
          title="Selecione o período"
          request="canceledTags"
          placement="left"
        ></app-date-picker>
      </div>

      <div class="tags-container">
        <p>
          Mostrando período:
          <strong
            >{{ canceledCards?.startDate | date : "dd/MM/yyyy" }} -
            {{ canceledCards?.endDate | date : "dd/MM/yyyy" }}</strong
          >
        </p>

        <div class="stats-cards-container">
          <card-model>
            <div class="stats-card red">
              <span>{{ canceledCards?.amount }}</span>
              <p>
                {{ "Tags canceladas" }}
              </p>
            </div>
          </card-model>
        </div>
      </div>
    </section>
  </ng-container>
</card-model>
