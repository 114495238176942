import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AdvanceDetailsModalComponent } from "./components/advance-details-modal/advance-details-modal.component";

@Component({
  selector: "app-bill-detail-general",
  templateUrl: "./bill-detail-general.component.html",
  styleUrls: ["./bill-detail-general.component.scss"],
})
export class BillDetailGeneralComponent implements OnInit {
  @Input() billSummary: any;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  showAdvanceDetailsModal(): void {
    this.modalService.open(
      AdvanceDetailsModalComponent
    ).componentInstance.billId = this.billSummary.id;
  }
}
