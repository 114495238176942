<div class="succesComponent">
  <div class="modal-header">
    <h4 data-e2e="modal-title" class="modal-title" translate>Tag Cancelada</h4>
    <a
      data-e2e="close-modal-top-btn"
      class="btn-close"
      (click)="dismissModal()"
    >
      <img src="assets/svg/close.svg" />
    </a>
  </div>

  <div class="modal-body">
    <img class="img-success" src="assets/svg/feedback-success.svg" alt="" />

    <div class="text-body">
      <h4>Cancelamento de tag realizado com sucesso!</h4>
      <h5>Veículo {{ isExclude ? 'excluído' : 'mantido' }} da frota</h5>
      <img src="assets/svg/icon-truck.svg" /><span>
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}
      </span>

      <h5>Tag Cancelada</h5>
      <img src="assets/svg/icon-tag.svg" /><span>
        {{ vehicle.serialTag }}
      </span>

      <p class="text-gray">
        As praças de pedágio poderão levar até 6 horas para processar o
        cancelamento da TAG.
      </p>
    </div>

    <div *ngIf="balance > 0" class="complement-body">
      <h5>Transferência de saldo realizada</h5>
      <p class="text-gray">Saldo residual</p>
      <img src="assets/svg/icon-money.svg" /><span>
        {{ balance | currency: 'BRL' : 'symbol' : '1.2-2' }}
      </span>

      <p class="text-gray">
        <span class="left">De:</span><span class="right">Para:</span>
      </p>

      <img src="assets/svg/icon-truck.svg" /><span>
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}
      </span>
      <img class="icon-arrow" src="assets/svg/icon-arrow-navigate.svg" />
      <img src="assets/svg/icon-truck.svg" /><span>
        {{ vehicleToReceiveVPR.licensePlate | mask: 'SSS-0A00' }}
      </span>
    </div>
  </div>

  <div class="modal-footer">
    <div class="content-buttons">
      <button
        data-e2e="cancel"
        class="btn btn-secondary custom"
        (click)="dismissModal()"
        translate
      >
        Fechar
      </button>
    </div>
  </div>
</div>
