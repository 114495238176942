<div class="billServicesComponent">
    <div class="row d-flex align-items-center px-0 my-3 servicesBody">
        <div
          class="btn-group filterServices pl-2"
          dropdown
          [isAnimated]="true"
          [autoClose]="false">
            <button
              dropdownToggle
              type="button"
              id="button-animated"
              aria-controls="dropdown-animated"
              class="btn btn-filter dropdown-toggle"
              data-e2e="filter-dropdown-btn"
              [class.no-shadow]="isShownHierarchy"
              [class.without-border-bottom-radius]="isOpenDropdown"
              [class.active]="isOpenDropdown"
              (click)="toggleDropdown()">
            <img src="assets/img/filter.svg" class="button-icon" />
            Filtros
          </button>
            <div class="filters">
                <ul
                  id="dropdown-animated"
                  *dropdownMenu
                  class="dropdown-menu dropdown-filter pl-2"
                  [class.dropdown-expanded]="isShownHierarchy || isShowServiceType"
                  [class.no-shadow]="isShownHierarchy || isShowServiceType"
                  role="menu"
                  aria-labelledby="button-animated">
                    <li data-e2e="show-hierarchy-toggle-btn" role="menuitem" (click)="toggleShowHierarchy()" *ngIf="isPageMonthlyPayment">
                        <div class="simulate-select">
                            <img src="assets/img/hierarchy_select.svg" />
                            <span data-e2e="hierarchy-label" class="simulate-label">Hierarquia</span>
                            <input data-e2e="select-hierarchy-input" id="hierarchyInput" class="simulate-input" placeholder="Selecione um grupo" [value]="hierarchySelected" />
                            <!-- <p class="simulate-input">Escolha um grupo</p> -->
                            <div class="simulate-button" [class.active]="isShownHierarchy">
                                <img src="assets/img/arrow-select.svg" />
                            </div>
                        </div>
                    </li>
                    <li data-e2e="show-service-type-toggle-btn" role="menuitem" (click)="toggleShowServiceType()" *ngIf="!isPageMonthlyPayment">
                        <div class="simulate-select">
                            <img src="assets/img/group_work.svg" />
                            <span data-e2e="service-type-label" class="simulate-label">Tipo de serviço</span>
                            <input data-e2e="service-type-input" id="hierarchyInput" class="simulate-input" placeholder="Escolha um tipo" [value]="ServiceTypeSelected" />
                            <!-- <p class="simulate-input">Escolha um grupo</p> -->
                            <div class="simulate-button" [class.active]="isShowServiceType">
                                <img src="assets/img/arrow-select.svg" />
                            </div>
                        </div>
                    </li>
                    <li role="menuitem">
                        <div class="content-button">
                            <button data-e2e="clear-filter-btn" id="resetFiltersButton" class="btn btn-primary" (click)="resetFilters()">
                            <img class="button-icon" src="assets/img/ico-delete.svg" />
                            Limpar filtros
                          </button>
                        </div>
                    </li>
                </ul>
                <div class="box-area-hierarchy" *ngIf="isShownHierarchy">
                    <div class="content-extend">
                        <div class="hierarchy" *ngIf="!getBreadcrumbResult?.data">
                            <span data-e2e="empty-hierarchy-msg" class="without-hierarchy">Não há hierarquias para esse contrato.</span>
                        </div>

                        <div class="hierarchy" *ngIf="getBreadcrumbResult?.data">
                            <h4 data-e2e="hierarchy-title" class="text-center mb-2_25rem">Selecione o grupo</h4>
                            <div class="container-hierarchy">
                                <ul class="content-hierarchy">
                                    <li [attr.data-e2e]="'hierarchy-option-' + i" class="items-hierarchy" *ngFor="let hierarchy of getBreadcrumbResult?.data; let i = index" (click)="onApplyHierarchyFilter(hierarchy)">
                                        <div class="info-hierarchy">
                                            <span [attr.data-e2e]="'hierarchy-breadcrumb-' + i" id="breadCrumb{{i}}">{{ hierarchy.breadCrumb }}</span> <br/>
                                            <span [attr.data-e2e]="'hierarchy-description-' + i" id="description_PT{{i}}">{{ hierarchy.description_PT }}</span>
                                        </div>

                                        <button [attr.data-e2e]="'hierarchy-search-btn' + i" id="selectHierarchy{{i}}" class="action-hierarchy">
                                          <img src="assets/img/ico-search.svg" />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-area-service-type" *ngIf="isShowServiceType">
                    <div class="content-extend">
                        <div class="service-type">
                            <h4 data-e2e="type-select-label" class="text-center">Escolha um tipo</h4>
                            <div class="container-service-type">
                                <ul class="content-service-type" *modalityType="1">
                                    <li [attr.data-e2e]="'type-select-' + i" class="items-service-type" *ngFor="let billItemType of PrePaidBillItemTypes, let i = index">
                                        <input [attr.data-e2e]="'type-option-' + i" class="checkbox-input" [checked]="isChecked(billItemType.id)" type="checkbox" [value]="billItemType.id" (change)="onCheckboxChange($event)" />
                                        <span class="checkmark"></span>
                                        <div [attr.data-e2e]="'type-option-name-' + i" class="checkbox-label">{{billItemType.description}}</div>
                                    </li>
                                </ul>

                                <ul class="content-service-type" *modalityType="2">
                                    <li [attr.data-e2e]="'type-select-' + i" class="items-service-type" *ngFor="let billItemType of PostPaidBillItemTypes; let i = index">
                                        <input [attr.data-e2e]="'type-option-' + i" class="checkbox-input" type="checkbox" [checked]="isChecked(billItemType.id)" [value]="billItemType.id" (change)="onCheckboxChange($event)" />
                                        <span class="checkmark"></span>
                                        <div [attr.data-e2e]="'type-option-name-' + i" class="checkbox-label">{{ billItemType.description }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-auto">
            <div class="title d-flex align-items-center">
                <form novalidate>
                    <div class="form-group select-table">
                        <select data-e2e="page-size-select" class="form-control form-select ServicesPageSize" (change)="onPageSizeChange($event)">
                          <option [attr.data-e2e]="'page-size-option-' + index" *ngFor="let i of perPages; let index = index" value="{{i}}">exibir {{i}} linhas</option>
                        </select>
                    </div>
                </form>
            </div>
        </div>

        <div class="col-md-auto">
            <div class="row justify-content-center">
                <div class="col-md-auto custom-box-radius">

                    <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="radio"
                          data-e2e="monthly-payment-radio"
                          id="customRadioInline1"
                          checked value="0"
                          (change)='onRadioChange($event)'
                          name="customRadioInline1"
                          class="custom-control-input form-check-input">
                        <label data-e2e="monthly-payment-label" class="custom-control-label form-check-label" for="customRadioInline1">MENSALIDADES</label>
                        <small
                          data-e2e="monthly-payment-data"
                          id="totalMonthlyPayment"
                          class="d-block text-right">
                          {{ billServiceResult?.totalMonthlyPayment  | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}
                        </small>
                    </div>
                    <!-- Material inline 1 -->
                </div>

                <div class="col-md-auto custom-box-radius">

                    <div class="custom-control custom-radio custom-control-inline">
                        <input
                          data-e2e="other-services-radio"
                          type="radio"
                          id="customRadioInline2"
                          value="1"
                          (change)='onRadioChange($event)'
                          name="customRadioInline1"
                          class="custom-control-input form-check-input">

                        <label data-e2e="other-services-label" class="custom-control-label form-check-label" for="customRadioInline2">OUTROS SERVIÇOS</label>
                        <small
                          data-e2e="other-services-data"
                          id="totalOtherServices"
                          class="d-block text-right">
                          {{ billServiceResult?.totalOtherServices  | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}
                        </small>
                    </div>
                    <!-- Material inline 1 -->
                </div>
            </div>
        </div>

        <div *ngIf="isPageMonthlyPayment" class="col-md-auto search form-inline">
            <label data-e2e="payment-search-label">Pesquisar</label>
            <input
              data-e2e="payment-search-input"
              type="text"
              id="search"
              placeholder="placa e apelido"
              class="form-control ico-insert-text"
              (keyup)="onFullNameKeyup($event)"
              [value]="servicesQuery.search" />

            <button
              data-e2e="payment-search-btn"
              type="button"
              class="btn btn-search d-flex justify-content-between align-items-center pr-4"
              (click)="onSearchClick($event)">
              <i class="ico ico-search-white"></i>
              buscar
            </button>
        </div>
    </div>

    <div class="px-4 ServicesListBody">
        <div class="row mt-2" *ngIf="isPageMonthlyPayment">
            <div class="col-12">
                <div class="row">
                    <div class="col-custom col-custom-left percent-85">
                        <div data-e2e="table-top-description" class="header-table">
                            Descrição
                        </div>
                    </div>

                    <div class="col-custom col-custom-right percent-15">
                        <div data-e2e="table-top-amount" class="header-table">
                            Valores (R$)
                        </div>
                    </div>
                </div>

                <table class="table bill-service">
                    <thead class=" thead ">
                        <tr>
                            <th [ngClass]="bindOrderByClass( 'billItemType') ">
                                <button data-e2e="type-order-btn" class="btn-order-table " translate (click)="onOrderByChange( 'billItemType')">Tipo</button>
                                <div class="round-order " (click)="onOrderByChange( 'billItemType')">
                                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass( 'licensePlate') ">
                                <button data-e2e="plate-order-btn" class="btn-order-table " translate (click)="onOrderByChange( 'licensePlate')">Placa</button>
                                <div class="round-order " (click)="onOrderByChange( 'licensePlate')">
                                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass( 'nickName') ">
                                <button data-e2e="nickname-order-btn" class="btn-order-table " translate (click)="onOrderByChange( 'nickName') ">Apelido</button>
                                <div class="round-order " (click)="onOrderByChange( 'nickName') ">
                                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass( 'hierarchyDescription') ">
                                <button data-e2e="hierarchy-order-btn" class="btn-order-table " translate (click)="onOrderByChange( 'hierarchyDescription') ">Hierarquia</button>
                                <div class="round-order " (click)="onOrderByChange( 'hierarchyDescription') ">
                                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass( 'serial') ">
                                <button data-e2e="tag-order-btn" class="btn-order-table " translate (click)="onOrderByChange( 'serial') ">Tag</button>
                                <div class="round-order " (click)="onOrderByChange( 'serial') ">
                                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                                </div>
                            </th>
                            <th [ngClass]="bindOrderByClass( 'activationDate') ">
                                <button data-e2e="verification-order-btn" class="btn-order-table " translate (click)="onOrderByChange( 'activationDate') ">Data da ativação</button>
                                <div class="round-order " (click)="onOrderByChange( 'activationDate') ">
                                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                                </div>
                            </th>


                            <th [ngClass]="bindOrderByClass( 'amount') ">
                                <button data-e2e="total-order-btn" class="btn-order-table " translate (click)="onOrderByChange( 'amount') ">Valor Total</button>
                                <div class="round-order " (click)="onOrderByChange( 'amount') ">
                                    <img src="../../../assets/svg/order-table.svg " alt=" " />
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <div class="container-loading " *ngIf="isLoading ">
                        <app-loader [isLoading]="isLoading " message="{{ messageLoader }} "></app-loader>
                    </div>

                    <tbody *ngIf="!isLoading ">
                        <tr *ngFor="let service of billServiceResult?.services; let count = index ">
                            <td data-e2e="bill-item-data" id="billItemType{{ count }}">{{ service.billItemType }}</td>
                            <td data-e2e="plate-data" id="licensePlate{{ count }}">{{ service.licensePlate | mask: 'AAA-AAAA' }}</td>
                            <td data-e2e="nickname-data" id="nickName{{ count }}">{{ service.nickName }}</td>
                            <td data-e2e="hierarchy-data" id="hierarchyDescription{{ count }}">{{ service.hierarchyDescription }}</td>
                            <td data-e2e="serial-data" id="serial{{ count }}">{{ service.serial }}</td>
                            <td>
                                <span>{{ service.activationDate | date:'dd/MM/yyyy' }}</span>
                            </td>
                            <td class="services-values-label-text">
                                <span data-e2e="service-amount-sign" [ngClass]="{'': service.amount > 0, 'negative-amount': service.amount < 0 }">
                                    {{service.amount > 0 ? '+' : (service.amount < 0 ? '-' : '') }}
                                </span>
                                <span data-e2e="service-amount-value" id="amount{{count}}">{{ (service.amount > 0 ? service.amount : service.amount * -1) | currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div *ngIf="!isLoading && billServiceResult?.total==0 ">
                    <div data-e2e="empty-research-msg" colspan="12 " class="text-center w-100 py-4 " translate>Nenhum resultado encontrado.</div>
                </div>

                <div *ngIf="!isLoading ">
                    <div class="pagination-custom" *ngIf="billServiceResult?.total>servicesQuery.pageSize">
                        <app-pagination
                          [totalItems]="billServiceResult?.total"
                          [itemsPerPage]="servicesQuery.pageSize"
                          [maxPages]="5"
                          [currentPage]="servicesQuery.page"
                          (pageChangeEvent)="onPaginationChange($event)"
                        ></app-pagination>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2 justify-content-center" *ngIf="!isPageMonthlyPayment">
            <div class="col-8">
                <div class="row">
                    <div class="col-custom col-custom-left percent-75 pr-1">
                        <div class="header-table">
                            Descrições
                        </div>
                    </div>
                    <div class="col-custom col-custom-right percent-25 pl-1">
                        <div class="header-table">
                            Valores (R$)
                        </div>
                    </div>
                </div>
                <table class="table bill-service otherServiceTable">
                    <thead class="thead">
                        <tr>
                            <th [ngClass]="bindOrderByClass('dateProcessing')">
                                <button data-e2e="processind-date-order-btn" class="btn-order-table" translate (click)="onOrderByChange('dateProcessing')">Data de Processamento + Ações</button>
                                <div class="round-order" (click)="onOrderByChange('dateProcessing')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                            <th data-e2e="billing-order-btn" [ngClass]="bindOrderByClass('billItemType')">
                                <button class="btn-order-table" translate (click)="onOrderByChange('billItemType')">Tipo de Serviço</button>
                                <div class="round-order" (click)="onOrderByChange('billItemType')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                            <th data-e2e="amount-order-btn" [ngClass]="bindOrderByClass('amount')">
                                <button class="btn-order-table" translate (click)="onOrderByChange('amount')">Valor Total</button>
                                <div class="round-order" (click)="onOrderByChange('amount')">
                                    <img src="../../../assets/svg/order-table.svg" alt="" />
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <div class="container-loading" *ngIf="isLoading">
                        <app-loader [isLoading]="isLoading" message="{{ messageLoader }}"></app-loader>
                    </div>

                    <tbody *ngIf="!isLoading">
                        <tr *ngFor="let service of billServiceResult?.services; let count = index">
                            <td data-e2e="processing-date-data" id="dateProcessing{{count}}" class="btn-data">
                              <div [ngbTooltip]="getTooltipText(service?.billItemTypeId)">
                                <button
                                  id="btn-details-download-{{count}}"
                                  [attr.data-e2e]="'btn-details-download-' + count"
                                  class="btn btn-details"
                                  type="button"
                                  [disabled]="isBtnDetailsDisabled(service.billItemTypeId)"
                                  (click)="handleBtnDetailsActionByBillItemType(service)"
                                >
                                  <img src="/assets/svg/icon-document-text.svg" alt="Ícone documento"/>
                                </button>
                              </div>
                              {{service.dateProcessing | date:'dd/MM/yyyy HH:mm:ss'}}
                            </td>
                            <td data-e2e="billing-data" *ngIf="service?.billItemTypeId !== 14 && service?.billItemTypeId !== 6 && service?.billItemTypeId !== 15 && service?.billItemTypeId !== 4 && service?.billItemTypeId !== 17" id="billItemType{{count}}">{{service.billItemType}}</td>
                            <td data-e2e="billing-data" *ngIf="service?.billItemTypeId == 14" id="billItemType{{count}}">

                                Taxa de Recarga ({{ service.rechargeFee | currency: ' ':'symbol':'0.2-2':'pt-BR' }}%) sobre o valor da recarga de {{ service.rechargeAmount | currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}

                                <span *ngIf="service?.prePaidTransactionId > 0">
                                    - Cartão  <span class="creditCard" [ngSwitch]="service.creditCardBrandTypeId">
                                        <span data-e2e="card-flag" *ngSwitchCase="1">Mastercard</span>
                                        <span data-e2e="card-flag" *ngSwitchCase="2">Hipercard</span>
                                        <span data-e2e="card-flag" *ngSwitchCase="3">Visa</span>
                                        <span data-e2e="card-flag" *ngSwitchCase="4">American express</span>
                                        <span data-e2e="card-flag" *ngSwitchCase="5">Elo</span>
                                        <span data-e2e="card-flag" *ngSwitchCase="6">Diners Club</span>
                                    </span> <br>**** **** **** {{ service.creditCard }}
                                </span>
                                <span *ngIf="service?.pixTransactionId > 0">
                                    via PIX
                                </span>
                            </td>
                            <td class="tollVoucherAntecipationService service-content" *ngIf="service?.billItemTypeId == 6" id="billItemType{{count}}">
                                <div class="row">
                                    <i class="ico ico-alert col-1" placement="top" ngbTooltip="Percentual cobrado sobre o valor de antecipação de vale-pedágio abatido na fatura"></i>
                                    <span data-e2e="antecipation-data" class="col-11">
                                        {{ service.billItemType }} ({{ service.tollVoucherAntecipationService == 0 || !service.tollVoucherAntecipationService ? 'Bonificado' : service.tollVoucherAntecipationService | currency: ' ':'symbol':'0.2-2':'pt-BR' }}{{ service.tollVoucherAntecipationService == 0 || !service.tollVoucherAntecipationService ? '' : '%' }})
                                    </span>
                                </div>
                            </td>
                            <td class="volumeTaxService service-content" *ngIf="service?.billItemTypeId == 15" id="billItemType{{count}}">
                                <div class="row">
                                    <i class="ico ico-alert col-1" placement="top" ngbTooltip="Taxa percentual cobrada sobre volume de transações de pedágio e estacionamento"></i>
                                    <span class="col-11">
                                        <span data-e2e="volume-tax-data">{{service.billItemType}}</span > (<span data-e2e="volume-tax-percent">{{ service.volumeFeeService | currency: ' ':'symbol':'0.2-2':'pt-BR' }}</span>%)
                                    </span>
                                </div>
                            </td>
                            <td class="InativeTagService service-content" *ngIf="service?.billItemTypeId == 4 || service?.billItemTypeId == 17" id="billItemType{{count}}">
                                <div class="row">
                                    <i class="ico ico-alert col-1" placement="top" ngbTooltip="Mensalidade de tags disponíveis em estoque há mais de 90 dias"></i>
                                    <span data-e2e="inative-tag-data" class="col-11">
                                        {{service.billItemType}}
                                    </span>
                                </div>
                            </td>
                            <td class="services-values-label-text">
                                <span data-e2e="amount-sign" [ngClass]="{'': service?.amount > 0, 'negative-amount':service?.amount < 0 }">{{service?.amount>0?'+':(service?.amount<0?'-':'')}}</span>
                                <span data-e2e="amount-number" id="amount{{count}}">{{ (service?.amount>0?service?.amount: service?.amount*-1)| currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div *ngIf="!isLoading && billServiceResult?.total==0">
                    <div data-e2e="empty-result" colspan="12" class="text-center w-100 py-4" translate>Nenhum resultado encontrado.</div>
                </div>

                <div *ngIf="!isLoading">
                    <div class="pagination-custom" *ngIf="billServiceResult?.total>servicesQuery.pageSize">
                        <app-pagination
                          [totalItems]="billServiceResult?.total"
                          [itemsPerPage]="servicesQuery.pageSize"
                          [maxPages]="5"
                          [currentPage]="servicesQuery.page"
                          (pageChangeEvent)="onPaginationChange($event)"
                        ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
