<div class="container-loading"
  *ngIf="isLoading"
  id="container-loader-page"
  data-e2e="container-loader-page"
>
  <app-loader
    id="loader-page"
    data-e2e="loader-page"
    [isLoading]="isLoading"
    message="{{ 'Loading...' | translate }}"
  ></app-loader>
</div>

<main class="UserConsultantCreateUpdateComponent" *ngIf="!isLoading">
  <h1 class="title">{{ pageTitle }}</h1>

  <div class="bloc">
    <form [formGroup]="userConsultantForm">
      <h2 class="bloc__title">Dados pessoais</h2>

      <h3 class="bloc__subtitle">Nome completo e CPF</h3>
      <section class="bloc__grid col2-1">
        <!-- Name -->
        <div class="toll-input-box">
          <label class="toll-input-box__label" for="input-fullname">Nome <span>*</span></label>
          <input
            class="toll-input-box__input"
            [class.is-invalid]="
              userConsultantForm.controls.fullName.invalid &&
              userConsultantForm.controls.fullName.touched
            "
            id="input-fullname"
            data-e2e="input-fullname"
            formControlName="fullName"
            type="text"
            placeholder="Nome completo"
            required
            minlength="1"
          />
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.fullName.invalid &&
              userConsultantForm.controls.fullName.touched
            "
            id="msg-error-input-fullname"
            errorMessage="{{ generateErrorMessageByFormControlName('fullName') }}"
          >
          </app-vmessage>
        </div>

        <!-- DocumentNumber -->
        <div class="toll-input-box">
          <label class="toll-input-box__label" for="input-documentnumber">CPF <span>*</span></label>
          <input
            class="toll-input-box__input"
            [class.is-invalid]="
              (userConsultantForm.controls.documentNumber.invalid &&
              userConsultantForm.controls.documentNumber.touched)
              ||
              (userConsultantForm.controls.documentNumber.valid &&
              (!documentNumberValid && !isValidatingDocument))
            "
            [class.is-disable]="activePage == pageType.UPDATE"
            id="input-documentnumber"
            data-e2e="input-documentnumber"
            formControlName="documentNumber"
            type="text"
            placeholder="000.000.000-00"
            mask="000.000.000-00"
            required
            (blur)="validateUserByDocumentAndGroup()"
          />
          <span class="bloc__loading-msg" *ngIf="isValidatingDocument">Validando...</span>
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.documentNumber.invalid &&
              userConsultantForm.controls.documentNumber.touched
            "
            id="msg-error-input-documentnumber"
            errorMessage="{{ generateErrorMessageByFormControlName('documentNumber') }}"
          >
          </app-vmessage>
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.documentNumber.valid &&
              (!documentNumberValid && !isValidatingDocument)
            "
            data-e2e="msg-input-documentnumber-already-exists"
            errorMessage="{{ 'Usuário já está cadastrado. Por favor, informe outro CPF.' }}"
          >
          </app-vmessage>
        </div>
      </section>

      <h3 class="bloc__subtitle">Data de nascimento</h3>
      <section class="bloc__grid col3">
        <!-- Day -->
        <div class="toll-select-box">
          <label class="toll-select-box__label" for="select-dateofbirth-day">Dia <span>*</span></label>
          <select
            class="toll-select-box__select"
            [class.is-invalid]="
              userConsultantForm.controls.dateOfBirthDay.invalid &&
              userConsultantForm.controls.dateOfBirthDay.touched
            "
            formControlName="dateOfBirthDay"
            id="select-dateofbirth-day"
            data-e2e="select-dateofbirth-day"
          >
            <option id="day-default-value" selected value="">Selecione</option>
            <option
              *ngFor="let day of days"
              id="day-{{day.desc}}"
              [attr.data-e2e]="'day-' + day.desc"
              [value]="day.value"
            >
              {{day.desc}}
            </option>
          </select>
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.dateOfBirthDay.invalid &&
              userConsultantForm.controls.dateOfBirthDay.touched
            "
            id="msg-error-select-dateofbirth-day"
            errorMessage="{{ generateErrorMessageByFormControlName('dateOfBirthDay') }}"
          >
          </app-vmessage>
        </div>

         <!-- Month -->
        <div class="toll-select-box">
          <label class="toll-select-box__label" for="select-dateofbirth-month">Mês <span>*</span></label>
          <select
            class="toll-select-box__select"
            [class.is-invalid]="
              userConsultantForm.controls.dateOfBirthMonth.invalid &&
              userConsultantForm.controls.dateOfBirthMonth.touched
            "
            formControlName="dateOfBirthMonth"
            id="select-dateofbirth-month"
            data-e2e="select-dateofbirth-month"
          >
            <option id="month-default-value" selected value="">Selecione</option>
            <option
              *ngFor="let month of months"
              id="month-{{month.desc}}"
              [attr.data-e2e]="'month-' + month.desc"
              [value]="month.value"
            >
              {{month.desc}}
            </option>
          </select>
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.dateOfBirthMonth.invalid &&
              userConsultantForm.controls.dateOfBirthMonth.touched
            "
            id="msg-error-select-dateofbirth-month"
            errorMessage="{{ generateErrorMessageByFormControlName('dateOfBirthMonth') }}"
          >
          </app-vmessage>
        </div>

        <!-- Year -->
        <div class="toll-select-box">
          <label class="toll-select-box__label" for="select-dateofbirth-year">Ano <span>*</span></label>
          <select
            class="toll-select-box__select"
            [class.is-invalid]="
              userConsultantForm.controls.dateOfBirthYear.invalid &&
              userConsultantForm.controls.dateOfBirthYear.touched
            "
            formControlName="dateOfBirthYear"
            id="select-dateofbirth-year"
            data-e2e="select-dateofbirth-year"
          >
            <option id="year-default-value" selected value="">Selecione</option>
            <option
              *ngFor="let year of years"
              id="year-{{year}}"
              [attr.data-e2e]="'year-' + year"
              [value]="year"
            >
              {{year}}
            </option>
          </select>
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.dateOfBirthYear.invalid &&
              userConsultantForm.controls.dateOfBirthYear.touched
            "
            id="msg-error-select-dateofbirth-year"
            errorMessage="{{ generateErrorMessageByFormControlName('dateOfBirthYear') }}"
          >
          </app-vmessage>
        </div>
      </section>

      <h2 class="bloc__title">Contato</h2>
      <h3 class="bloc__subtitle">Telefone de contato e e-mail</h3>
      <section class="bloc__grid col1-2">
        <!-- Telephone -->
        <div class="toll-input-box">
          <label class="toll-input-box__label" for="input-telephone">Telefone <span>*</span></label>
          <input
            class="toll-input-box__input"
            [class.is-invalid]="
              userConsultantForm.controls.telephoneNumber.invalid &&
              userConsultantForm.controls.telephoneNumber.touched
            "
            formControlName="telephoneNumber"
            id="input-telephone"
            data-e2e="input-telephone"
            type="text"
            placeholder="+55 (00) 00000-0000"
            [mask]="getPhoneMask()"
            required
          />
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.telephoneNumber.invalid &&
              userConsultantForm.controls.telephoneNumber.touched
            "
            id="msg-error-input-telephone"
            errorMessage="{{ generateErrorMessageByFormControlName('telephoneNumber') }}"
          >
          </app-vmessage>
        </div>

        <!-- Email -->
        <div class="toll-input-box">
          <label class="toll-input-box__label" for="input-email">E-mail <span>*</span></label>
          <input
            class="toll-input-box__input"
            [class.is-invalid]="
              (userConsultantForm.controls.emailAddress.invalid &&
              userConsultantForm.controls.emailAddress.touched)
              ||
              (userConsultantForm.controls.emailAddress.valid &&
              (!emailAddressValid && !isValidatingEmail))
            "
            formControlName="emailAddress"
            id="input-email"
            data-e2e="input-email"
            type="text"
            placeholder="Digite a primeira parte do e-mail"
            maxlength="40"
            required
            (blur)="validateUserByEmailAndGroup()"
          />
<!--          <span class="bloc__placeholder-fixed">{{ '@edenred.com' }}</span>-->
          <span class="bloc__loading-msg" *ngIf="isValidatingEmail" id="msg-validating">Validando...</span>
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.emailAddress.invalid &&
              userConsultantForm.controls.emailAddress.touched
            "
            id="msg-error-input-email"
            errorMessage="{{ generateErrorMessageByFormControlName('emailAddress') }}"
          >
          </app-vmessage>
          <app-vmessage
            *ngIf="
              userConsultantForm.controls.emailAddress.valid &&
              (!emailAddressValid && !isValidatingEmail)
            "
            data-e2e="msg-input-email-already-exists"
            errorMessage="{{ 'O e-mail informado já está em uso. Por favor, informe um e-mail diferente.' }}"
          >
          </app-vmessage>
        </div>
      </section>

      <!-- Protocol -->
      <h2 class="bloc__title"></h2>
      <div class="toll-input-box">
        <label class="toll-input-box__label" for="input-protocol">Informe o protocolo de solicitação <span>*</span></label>
        <input
          class="toll-input-box__input"
          [class.is-invalid]="
            userConsultantForm.controls.protocol.invalid &&
            userConsultantForm.controls.protocol.touched
          "
          formControlName="protocol"
          id="input-protocol"
          data-e2e="input-protocol"
          type="text"
          placeholder="Digite"
          maxlength="40"
          required
        />
        <app-vmessage
          *ngIf="
            userConsultantForm.controls.protocol.invalid &&
            userConsultantForm.controls.protocol.touched
          "
          id="msg-error-input-protocol"
          errorMessage="{{ generateErrorMessageByFormControlName('protocol') }}"
        >
        </app-vmessage>
      </div>

      <section class="bloc__btn">
        <button
          class="btn btn-tertiary"
          id="form-cancel-btn"
          data-e2e="form-cancel-btn"
          (click)="cancelForm()"
        >
          Cancelar
        </button>

        <button
          class="btn btn-secondary"
          id="form-save-btn"
          data-e2e="form-save-btn"
          [disabled]="userConsultantForm.invalid || !emailAddressValid || !documentNumberValid"
          (click)="openConfirmationModal()"
        >
          Salvar
        </button>
      </section>
    </form>
  </div>
</main>
