<div class="FinancialBlockModalComponent">
    <div class="modal-content">
        <div class="modal-header">
            <div class="box-title">
              <span>Bloqueio Financeiro</span>
            </div>
            <div class="blueContainer"></div>
            <div class="line"></div>
            <div class="contractInfo">
              <p class="name">{{contract.description}}</p>
              <p class="contract">
                <span class="document">{{contract?.documentNumberCompanyContract | mask: '00.000.000/0000-00'}}</span>
                | Contrato: <span class="id">{{ contract?.id }}</span>
              </p>
            </div>
            <button type="button" class="close" (click)="dismissModal()">
              <span aria-hidden="true">×</span>
            </button>
          </div> 
          <div class="modal-body show row prepaid" *modalityType="contractModality.PREPAID">
            <div class="col"><img src="../../../assets/img/contract_block.svg" /></div>
            <div class="col">
              <div class="title">Seu contrato está bloqueado por falta de saldo.</div>
              <div class="message">Realize uma nova recarga para continuar utilizando nossos serviços ou entre em contato com a Central de Atendimento através do chat ou Fale Conosco disponíveis na plataforma.</div>
            </div>
          </div>
          <div class="modal-body show row" *modalityType="contractModality.POSTPAID">
            <div class="col"><img src="../../../assets/img/contract_block.svg" /></div>
            <div class="col">
              <div class="title">Seu contrato está bloqueado por falta de pagamento.</div>
              <div class="message">Regularize a situação para continuar usando nossos serviços ou entre em contato pela nossa central de atendimento através do Chat,</div>
              <div class="message">E-mail:</div>
              <div class="email">suportepedagio&#64;edenred.com.br</div>
              <div class="message">Ou pela central de atendimento: 4020-8205.</div>
            </div>
          </div>
         <div class="modal-footer modal-footer-gray">
            <div class="col-12">
              <button type="button" class="btn btn-tertiary mr-4" data-dismiss="modal" aria-label="Close" (click)="dismissModal()">Fechar</button>
              <button *modalityType="contractModality.PREPAID" type="button" (click)="RedirectToPage(1)" class="btn btn-secondary position-relative">Regularizar contrato</button>
              <button *modalityType="contractModality.POSTPAID" type="button" (click)="RedirectToPage(2)" class="btn btn-secondary position-relative">Regularizar fatura</button>
            </div>
          </div>
    </div>
</div>