import {Component, inject, OnInit} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { VprCreditReceivedService } from './services/vpr-credit-received.service';
import { FormBuilder } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import { GetVprCreditReceivedQueryResult, TripDocuments } from './dtos/get-vpr-credit-received-query-result.dto';
import { GetVprCreditReceivedQueryArguments } from './dtos/get-vpr-credit-received-query-arguments.dto';
import moment from 'moment';
import { NotifyService } from '@services/notify.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowDocumentsModalComponent } from './components/show-documents-modal/show-documents-modal.component';
import {ContractService} from "@services/contract.service";
defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-vpr-credit-received.',
  templateUrl: './vpr-credit-received.component.html',
  styleUrls: ['./vpr-credit-received.component.scss']
})
export class VprCreditReceivedComponent implements OnInit {

  private contractId: any;
  isLoading: boolean;
  perPages = [];
  types = [
    { name: 'Crédito de vale-pedágio', id: 5 },
    { name: 'Cancelamento de crédito de vale-pedágio', id: 4 },
    { name: 'Expiração da viagem', id: 27 },
    { name: 'Estorno Expiração da viagem', id: 28 },
  ];

  getVprCreditReceivedQueryResult: GetVprCreditReceivedQueryResult;
  getVprCreditReceivedQueryArguments: GetVprCreditReceivedQueryArguments;
  filterDebounce: Subject<GetVprCreditReceivedQueryArguments> = new Subject<GetVprCreditReceivedQueryArguments>();
  isShownPeriod: boolean = false;
  isDatePickerOpen: boolean;
  isOpen: boolean;
  open: boolean;
  date?: any;
  today = new Date();
  selectedDate = new Date();
  dayCustomGeneral : Date[];
  isOpenDropdown: boolean;
  minDate: Date;
  maxDate: Date;
  isShowType: boolean;
  element: HTMLElement;
  description: any;
  oldDate: Date;
  tiposelecionado = 'Selecione';
  radioType: string;
  disabled = false;
  message:string;
  search: string = null
  noTransactions: boolean
  tripDocuments: TripDocuments[]
  formExtract = this.formBuilder.group({
    radioType: null,
  });

  formDateGeneral = this.formBuilder.group({
    dateGeneral: "7"
  });

  //Injections
  contractService: ContractService;

  constructor(
    private modalService:NgbModal,
    private notifyService: NotifyService,
    private vprCreditReceivedService: VprCreditReceivedService,
    private localeService: BsLocaleService,
    private formBuilder: FormBuilder,
  ) {
    this.contractService = inject(ContractService);
  }

  ngOnInit() {
    this.settingComponent();
    this.getTollVoucherRechargeQuery(this.getVprCreditReceivedQueryArguments)
    this.getContractId();
  }

  private settingComponent(): void {
    this.perPages = [10, 20, 50];
    this.minDate = new Date(Date.now());
    this.maxDate = new Date(Date.now());
    this.minDate.setMonth(this.minDate.getMonth()-13);
    this.localeService.use('pt-br');

    this.getVprCreditReceivedQueryResult = {
      total: 0,
      items: []
    };
    this.oldDate = new Date();
    this.oldDate.setDate(this.oldDate.getDate() - 7);
    this.getVprCreditReceivedQueryArguments = new GetVprCreditReceivedQueryArguments();
    this.getVprCreditReceivedQueryArguments.page = 1;
    this.getVprCreditReceivedQueryArguments.pageSize = this.perPages[0];
    this.getVprCreditReceivedQueryArguments.orderBy = "";
    this.getVprCreditReceivedQueryArguments.desc = false;
    this.getVprCreditReceivedQueryArguments.search = "";
    this.getVprCreditReceivedQueryArguments.transactionTypeId = null;
    this.getVprCreditReceivedQueryArguments.FromDate = this.oldDate;
    this.getVprCreditReceivedQueryArguments.ToDate = new Date();
    this.filterDebounce.pipe(debounceTime(300)).subscribe(filter => this.getTollVoucherRechargeQuery(filter));

  }

  private getTollVoucherRechargeQuery(args: GetVprCreditReceivedQueryArguments){
    this.isLoading = true;
    this.message = 'Carregando...';
    var daysBetween = (new Date(args.ToDate).getTime() - new Date(args.FromDate).getTime())/1000/60/60/24;
    if(daysBetween>90){
      this.notifyService.clear();
      this.notifyService.showError("Período de consulta","O período de consulta não pode ser maior que 90 dias");
      this.resetFilters();
      return;
    }
    this.vprCreditReceivedService
      .getTollVoucherRecharge(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          if(success.total == 0){
            this.noTransactions = true
          }
          else{
            this.noTransactions = false
          }
          this.getVprCreditReceivedQueryResult = success;


        },
        (error) => {
          this.notifyService.clear();
          console.error(error);
        }
      );
  }

  private getContractId() {
    this.contractId = this.contractService.getContractId();
  }

  onOrderByChange(field: string) {
    if (this.getVprCreditReceivedQueryArguments.orderBy === field) {
      this.getVprCreditReceivedQueryArguments.desc = !this.getVprCreditReceivedQueryArguments.desc;
    } else {
      this.getVprCreditReceivedQueryArguments.orderBy = field;
      this.getVprCreditReceivedQueryArguments.desc = true;
    }
    this.getTollVoucherRechargeQuery(this.getVprCreditReceivedQueryArguments);
  }

  onPageSizeChange(event: any) {
    this.getVprCreditReceivedQueryArguments.page = 1;
    this.getVprCreditReceivedQueryArguments.pageSize = event.target.value;
    this.getTollVoucherRechargeQuery(this.getVprCreditReceivedQueryArguments);
  }

  onPaginationChange(page: number) {
    if(this.getVprCreditReceivedQueryArguments.page !== page){
      this.getVprCreditReceivedQueryArguments.page = page;
      this.getTollVoucherRechargeQuery(this.getVprCreditReceivedQueryArguments);
    }
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.getVprCreditReceivedQueryArguments.orderBy == field && !this.getVprCreditReceivedQueryArguments.desc,
      'order-by-desc': this.getVprCreditReceivedQueryArguments.orderBy == field && this.getVprCreditReceivedQueryArguments.desc
    }
  }

  onFullNameKeyup($event: any) {
    this.getVprCreditReceivedQueryArguments.page = 1;
    this.getVprCreditReceivedQueryArguments.search = $event.target.value;
    this.search = $event.target.value;
    this.filterDebounce.next(this.getVprCreditReceivedQueryArguments);
  }

  onSearchClick (event: any){
   this.getTollVoucherRechargeQuery(this.getVprCreditReceivedQueryArguments);
  }

  onFilterDateGeneral(values: Date): void {
    values[0].setHours(0,0,0,0);
    values[1].setHours(23,59,59,0);
    this.toggleDateRangePickerGeneral();
    this.getVprCreditReceivedQueryArguments.page = 1;
    this.getVprCreditReceivedQueryArguments.FromDate = values[0];
    this.getVprCreditReceivedQueryArguments.ToDate = values[1];
    this.filterDebounce.next(this.getVprCreditReceivedQueryArguments);
  }

  setSevenDaysLastGeneral() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 7);
    this.dayCustomGeneral = [this.selectedDate, this.today];
    return this.dayCustomGeneral;
  }

  setThirtyDaysLastGeneral() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 30);
    this.dayCustomGeneral = [this.selectedDate, this.today];
    return this.dayCustomGeneral;
  }

  setFifteenDaysLastGeneral() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 15);
    this.dayCustomGeneral = [this.selectedDate, this.today];
    return this.dayCustomGeneral;
  }

  toggleDropdown(){
    this.isOpenDropdown = !this.isOpenDropdown;
    this.isShownPeriod = false;
    this.open = false;
    this.isShowType = false;
    this.hideDate();
  }

  toggleShowPeriod(){
    this.isDatePickerOpen = !this.isDatePickerOpen;
  }

  toggleDateRangePickerGeneral() {
    this.isShownPeriod = !this.isShownPeriod;
    this.isOpen = !this.isOpen;
    this.isShowType = false;
    this.toggleShowPeriod();
  }

  resetFilters() {
    this.formExtract.get('radioType').reset();
    this.tiposelecionado = 'Selecione';
    this.formDateGeneral.get('dateGeneral').reset();
    this.dayCustomGeneral = [];
    this.formDateGeneral = this.formBuilder.group({
      dateGeneral: "7"
    });
    this.getVprCreditReceivedQueryArguments.page = 1;
    this.getVprCreditReceivedQueryArguments.pageSize = this.perPages[0];
   //this.getVprCreditReceivedQueryArguments.filter = 1;
    this.getVprCreditReceivedQueryArguments.search = "";
    this.search = null;
    this.getVprCreditReceivedQueryArguments.transactionTypeId = null;
    this.getVprCreditReceivedQueryArguments.FromDate = this.oldDate;
    this.getVprCreditReceivedQueryArguments.ToDate = new Date();
    this.getTollVoucherRechargeQuery(this.getVprCreditReceivedQueryArguments);
  }

  hideDate() {
    this.element = document.getElementById('hideGeneralDate') as HTMLElement;
    this.element?.click();
    this.element = document.getElementById('hideHierarchyDate') as HTMLElement;
    this.element?.click();
    this.element = document.getElementById('hideVehicleDate') as HTMLElement;
    this.element?.click();
  }

  toggleShowType() {
    this.isShowType = !this.isShowType;
    this.isShownPeriod = false;
    this.isOpen = false;
  }

  onFilterTypeClick($event){
    this.tiposelecionado = $event.name;
    this.getVprCreditReceivedQueryArguments.transactionTypeId = $event.id;
    this.getVprCreditReceivedQueryArguments.page = 1;
    this.getVprCreditReceivedQueryArguments.search = null;
    this.search = null;
    this.isShowType = false;
    this.filterDebounce.next(this.getVprCreditReceivedQueryArguments)
  }

  downloadCsv() {
    this.isLoading = true;
    let now = new Date();
    let date = moment(now).format('DDMMYYYY');
    this.message = 'Download in progress...';

      this.vprCreditReceivedService.getTollVoucherRechargeCSVFile(this.getVprCreditReceivedQueryArguments)
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'Créditos Recebidos_' + this.contractId + "_" + date + '.csv';
        element.click();
      });
      this.isLoading = false;
  }

  onModelDocumentsOpen(tripCode:number, tripDocuments:TripDocuments[]){
     let modal = this.modalService.open(ShowDocumentsModalComponent);
      modal.componentInstance.tripDocuments = tripDocuments
      modal.componentInstance.tripCode = tripCode
    }
}
