<div class="container-fluid page-myaccount billListComponent">
  <div class="row">
    <header class="col-12 Dashboard_Overview">
      <h1 data-e2e="page-title">/ Faturas</h1>
    </header>
    <br/>
    <br/>
    <br/>
    <section class="seletor-prepaid-responsive" *modalityType="contractModality.PREPAID"></section>

    <section class="col-12 d-flex justify-content-center seletor-responsive" *modalityType="contractModality.POSTPAID">
      <ul
        class="nav nav-tabs d-flex justify-content-between nav-justified"
        id="myTab"
        role="tablist"
      >
        <li class="nav-item">
          <a
            data-e2e="all-status-filter"
            (click)="
              status = ['Pago', 'Aberto', 'Fechado', 'A Pagar', 'Atrasado', 'Fechamento']
            "
            class="nav-link active"
            id="nav1-tab"
            data-bs-toggle="tab"
            href="#nav1"
            role="tab"
            aria-controls="nav1"
            aria-selected="true"
          >
            Todas
          </a>
        </li>
        <li class="nav-item">
          <a
            data-e2e="paid-filter"
            (click)="status = ['Pago']"
            class="nav-link"
            id="nav2-tab"
            data-bs-toggle="tab"
            href="#nav2"
            role="tab"
            aria-controls="nav2"
            aria-selected="false"
          >Pagas
          </a>
        </li>
        <li class="nav-item">
          <a
            data-e2e="peding-filter"
            (click)="status = ['A Pagar', 'Atrasado']"
            class="nav-link pending-tab-element"
            id="nav3-tab"
            data-bs-toggle="tab"
            href="#nav3"
            role="tab"
            aria-controls="nav3"
            aria-selected="false"
          >
            <span id="pending-tab-text">Pendentes</span>
            <span
              *ngIf="pendentsNumber > 0"
              id="pending-tab-notification"
              class="numberNotification"
            >{{ pendentsNumber > 9 ? '9+' : pendentsNumber }}</span>
          </a>
        </li>
      </ul>
    </section>

    <br/>
    <br/>
    <main class="col-12 mt-5 Dashboard_col_1">
      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ message }}"
        ></app-loader>
      </div>
      <div *ngIf="!isLoading">
        <div style="padding-bottom: 1rem" *ngFor="let bill of bills | billStatusFilter: status; let count = index">
          <app-bill-card [bill]="bill" [count]="count" [status]="status" [isLoading]="isLoading" [inContractModalityTypeId]="contractModalityTypeId"></app-bill-card>
        </div>
      </div>
    </main>
  </div>
</div>
