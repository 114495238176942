<div class="cdkTreeComponent">
  <div class="cdk-tree">
    <cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <!-- This is the tree node template for leaf nodes -->
      <cdk-nested-tree-node *cdkTreeNodeDef="let node" class="tree-node" #nestedTreeLeafNodeElement>
        <!-- use a disabled button to provide padding for tree leaf -->
        <div class="node-content node-{{ node.description_PT}}" [ngClass]="nestedTreeLeafNodeElement.ariaLevel == '1' ? (treeControl.isExpanded(node) ? 'node-content-first-expanded node-content-first' : 'node-content-first') : (treeControl.isExpanded(node) && node.children.length > 0 ? 'node-content-expanded' : '')" (click)="showVehicleList(node)">
          <button mat-icon-button disabled class="toggleExpand"></button>
          <span class="nodeName" [ngClass]="node.children.length == 0 && node.vehicleCount !== 0  ? 'nodeNameNoChildren' : ''">{{node.description_PT}}</span>
          <span
          *ngIf="node.vehicleCount !== 0"
          data-e2e="vehicle-count-msg"
          class="vehicleCountBlock">
            <span id="vehicleCount{{node.id}}" data-e2e="vehicle-count-number">{{ node.vehicleCount }}</span> veículo(s) associado(s)
        </span>
        </div>
        <div class="transfer-vehicles" [ngClass]="nestedTreeLeafNodeElement.ariaLevel == '1' ? 'transfer-vehicles-first-node' : ''" *ngIf="deleteModal?.isDeleteTree && node.vehicleCount !== 0">
          <a class="col-sm-auto"><img src="/assets/img/caminhao.svg" /></a>
          <p data-e2e="hierarchy-confirmation-path" class="col-sm-auto hierarchy-confirmation-path">{{ ShowBreadCrumb(node.id) }}</p>
          <p data-e2e="hierarchy-confirmation-description" class="col-sm-auto hierarchy-confirmation-description">{{ ShowDescription(node.id) }}</p>
        </div>    
        <button *ngIf="node.vehicleCount == 0 && haveRole('101165') && !viewMode && node.levelItem < 5" class="btnAddHierarchy" [ngClass]="node.children.length == 0 || !treeControl.isExpanded(node)  ? (nestedTreeLeafNodeElement.ariaLevel == '1' ? 'withoutChildrenFirst' : 'withoutChildren') : ''" [popover]="popoverAdcHierarchyFirst"
        [outsideClick]="true"
        placement="right"
        buttonBlock
        [ContractBlock]="[3]"
        >
          +</button>
            <ng-template placement="right" [outsideClick]="true" #popoverAdcHierarchyFirst>
              <div class="box-add-hierarchy flex-column">
                <div
                  class="w-100 d-flex justify-content-between"
                  *ngIf="node.levelItem < 5"
                >
                  <div class="hierarchy-input">
                    <img
                      src="../../../../../../assets/img/hierarchy_select.svg"
                    />
                    <input
                      data-e2e="new-hierarchy-input"
                      class="addTo{{ node.id }}"
                      maxlength="20"
                      #name
                      type="text"
                    />
                    <div class="label-hierarchy" data-e2e="group-level-msg">
                      Grupo nível {{ node.levelItem + 1 }}
                    </div>
                  </div>
  
                  <div class="content-button">
                    <button
                      type="button"
                      class="btn btn-primary"
                      id="{{ node.levelItem + 1 }}"
                      (click)="onSaveHierarchy(node.hierarchicalLevel)"
                      data-e2e="add-to-level-btn"
                    >
                    <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
                      <img
                        src="../../../../../../assets/img/ico-add-b.svg"
                        class="button-icon"
                      />
                      Incluir ao nível {{ node.levelItem + 1 }}
                    </button>
                  </div>
                </div>          
              </div>
            </ng-template>
        <div class="funcs-node" *ngIf="nestedTreeLeafNodeElement.ariaLevel !== '1' && !viewMode">
          <button
          type="button"
          buttonBlock
          [ContractBlock]="[3]"
          *ngIf="(haveRole('101164') || haveRole('101165') || haveRole('101167')) && nestedTreeLeafNodeElement.ariaLevel !== '1'"
          class="btn-popover btn-functionality"
          [popover]="popoverNav"
          [outsideClick]="true"
          placement="right" >
          <img src="../../../assets/svg/icon-popover.svg" alt="" />
        </button>
        <ng-template placement="right" [outsideClick]="true" #popoverNav >
          <div>
            <div class="row hierarchy-funcs">
              <div *ngIf="node.children.length == 0 && haveRole('101167') && node.vehicleCount == 0" class="col">
                <button type="button" (click)="onOpenAdcVehicle(node.id,node.description_PT)" data-e2e="add-vehicle-btn">
                  <i class="ico ico-white ico-truck-travel"></i>
                  Adicionar <br>
                  veículos
                </button>
              </div>

              <div *ngIf="node.children.length == 0 && haveRole('101167') && node.vehicleCount > 0" class="col">
                <button type="button" (click)="showManageVehiclesHierarchyModal(node)" data-e2e="manage-vehicle-btn">
                  <i class="ico ico-white ico-manage"></i>
                  Gerenciar <br>
                  veículos
                </button>
              </div>

              <div *ngIf="haveRole('101165')" class="col">
                <button type="button" data-e2e="edit-level-btn" [popover]="popoverEditHierarchy" [outsideClick]="true"
                placement="right">
                  <i class="ico ico-white ico-edit"></i>
                  Editar<br>
                  nível
                </button>
              </div>

                  <ng-template placement="right" [outsideClick]="true" #popoverEditHierarchy>
                    <div class="box-edit-hierarchy flex-column">
                      <div
                        class="w-100 d-flex justify-content-between"
                      >
                        <div class="hierarchy-input">
                          <img
                            src="../../../../../../assets/img/hierarchy_select.svg"
                          />
                          <input
                            data-e2e="new-hierarchy-input"
                            class="addTo{{ node.id }}"
                            (keyup)="onKeyup(node, $event.target.value)"
                            maxlength="20"
                            type="text"
                            [ngModel]="node.description_PT"
                          />
                          <div class="label-hierarchy" data-e2e="group-level-msg">
                            Renomear nível {{ node.levelItem }}
                          </div>
                        </div>
        
                        <div class="content-button">
                          <button
                            type="button"
                            class="btn btn-primary rename"
                            id="{{ node.levelItem + 1 }}"
                            (click)="SaveHierarchy()"
                            data-e2e="add-to-level-btn"
                            [disabled]="
                            ArrayHierarchyNames.length == 0 || disableSaveButton
                          "
                          >
                           <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
                            <img
                              src="../../../../../../assets/img/ico-add-b.svg"
                              class="button-icon"
                            />
                            Renomear nível {{ node.levelItem}}
                          </button>
                        </div>
                      </div>          
                    </div>
                  </ng-template>

              <div *ngIf="haveRole('101164')"  class="col">
                <button type="button" (click)="showRemoveHierarchyModal(node.hierarchicalLevel)" data-e2e="delete-level-btn">
                  <i class="ico ico-white ico-delete"></i>
                  Excluir <br>
                  nível
                </button>
              </div>

            </div>
          </div>
        </ng-template>  
        </div>
        <div class="funcs-node-first" *ngIf="nestedTreeLeafNodeElement.ariaLevel == '1' && !viewMode">
          <div class="row buttons">
              <button
                class="col-sm-auto"
                *ngIf="node.children.length !== 0"
                (click)="expandAllNodes(treeControl.isExpanded(node))"
                type="button"
                data-e2e="expand-btn">
                <i [ngClass]="!treeControl.isExpanded(node) ? 'ico-expand' : 'ico-collapse'"class="ico ico-white"></i>
                 <p *ngIf="!treeControl.isExpanded(node)">Expandir todos</p>
                 <p *ngIf="treeControl.isExpanded(node)">Recolher todos</p>
              </button>

              <button
                class="col-sm-auto edit btn-functionality"
                buttonBlock
                [ContractBlock]="[3]"
                *ngIf="haveRole('101165')"
                [popover]="popoverEditHierarchy" 
                [outsideClick]="true"
                placement="left"
                type="button"
                data-e2e="edit-btn">
                <i class="ico ico-white ico-edit"></i>
                <p>Editar nível</p>
              </button>

              <ng-template placement="left" [outsideClick]="true" #popoverEditHierarchy>
                <div class="box-edit-hierarchy flex-column">
                  <div
                    class="w-100 d-flex justify-content-between"
                  >
                    <div class="hierarchy-input">
                      <img
                        src="../../../../../../assets/img/hierarchy_select.svg"
                      />
                      <input
                        data-e2e="new-hierarchy-input"
                        class="addTo{{ node.id }}"
                        (keyup)="onKeyup(node, $event.target.value)"
                        maxlength="20"
                        type="text"
                        [ngModel]="node.description_PT"
                      />
                      <div class="label-hierarchy" data-e2e="group-level-msg">
                        Renomear nível {{ node.levelItem }}
                      </div>
                    </div>
    
                    <div class="content-button">
                      <button
                        type="button"
                        class="btn btn-primary rename"
                        id="{{ node.levelItem + 1 }}"
                        (click)="SaveHierarchy()"
                        data-e2e="add-to-level-btn"
                        [disabled]="
                        ArrayHierarchyNames.length == 0 || disableSaveButton
                      "
                      >
                      <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
                        <img
                          src="../../../../../../assets/img/ico-add-b.svg"
                          class="button-icon"
                        />
                        Renomear nível {{ node.levelItem}}
                      </button>
                    </div>
                  </div>          
                </div>
              </ng-template>

              <button
              class="col-sm-auto"
              *ngIf="node.children.length == 0 && haveRole('101167') && node.vehicleCount == 0"
              class="edit btn-functionality"
              (click)="onOpenAdcVehicle(node.id,node.description_PT)"
              type="button"
              data-e2e="edit-btn">
              <i class="ico ico-white ico-truck-travel"></i>
              <p> Adicionar 
                veículos</p>
            </button>

            <button
            class="col-sm-auto"
            *ngIf="node.children.length == 0 && haveRole('101167') && node.vehicleCount > 0"
            class="edit btn-functionality"
            (click)="showManageVehiclesHierarchyModal(node)"
            type="button"
            data-e2e="edit-btn">
            <i class="ico ico-white ico-manage"></i>
            <p> Gerenciar
              veículos</p>
          </button>

                <button
                class="col-sm-auto"
                buttonBlock
                [ContractBlock]="[3]"
                *ngIf="haveRole('101164')"
                class="delete btn-functionality"
                type="button"
                (click)="showRemoveHierarchyModal(node.hierarchicalLevel)"
                data-e2e="delete-btn">
                <i class="ico ico-white ico-delete"></i>
                <p>Excluir nível</p>
              </button>           
            </div>
        </div>           
      </cdk-nested-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" class="tree-node" #nestedTreeExpandableNodeElement>
        <div [ngClass]="nestedTreeExpandableNodeElement.ariaLevel == '1' ? (treeControl.isExpanded(node) ? 'node-content-first-expanded node-content-first' : 'node-content-first') : (treeControl.isExpanded(node) && node.children.length > 0 ? 'node-content-expanded' : '')" class="node-{{ node.description_PT}} node-content">
          <button class="toggleExpand" mat-icon-button [attr.aria-label]="'Toggle ' + node.description_PT" cdkTreeNodeToggle>
              <mat-icon class="mat-icon-rtl-mirror" [ngClass]="treeControl.isExpanded(node) ? 'mat-icon-expanded' : 'mat-icon-collapse'">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <span class="nodeName" [ngClass]="node.children.length == 0 && node.vehicleCount !== 0 ? 'nodeNameNoChildren' : ''">{{node.description_PT}}</span>   
            <span
            *ngIf="node.vehicleCount !== 0"
            (click)="showVehicleList(node)"
            data-e2e="vehicle-count-msg"
            class="vehicleCountBlock">
              <span id="vehicleCount{{node.id}}" data-e2e="vehicle-count-number">{{ node.vehicleCount }}</span> veículo(s) associado(s)
          </span>
        </div>  
        <div class="transfer-vehicles"  [ngClass]="nestedTreeExpandableNodeElement.ariaLevel == '1' ? 'transfer-vehicles-first-node' : ''" *ngIf="deleteModal?.isDeleteTree && node.vehicleCount !== 0">
          <a class="col-sm-auto"><img src="/assets/img/caminhao.svg" /></a>
          <p data-e2e="hierarchy-confirmation-path" class="col-sm-auto">{{ ShowBreadCrumb(node.id) }}</p>
          <p data-e2e="hierarchy-confirmation-description" class="col-sm-auto">{{ ShowDescription(node.id) }}</p>
        </div>   
        <button class="btnAddHierarchy" [ngClass]="node.children.length == 0 || !treeControl.isExpanded(node)  ? (nestedTreeExpandableNodeElement.ariaLevel == '1' ? 'withoutChildrenFirst' : 'withoutChildren') : ''"  [popover]="popoverAdcHierarchyFirst"
        [outsideClick]="true"
        placement="right"
        *ngIf="node.vehicleCount == 0 && haveRole('101165') && !viewMode && node.levelItem < 5"
        buttonBlock
        [ContractBlock]="[3]"
        >
          +</button>
            <ng-template placement="right" [outsideClick]="true" #popoverAdcHierarchyFirst>
              <div class="box-add-hierarchy flex-column">
                <div
                  class="w-100 d-flex justify-content-between"
                  *ngIf="node.levelItem < 5"
                >
                  <div class="hierarchy-input">
                    <img
                      src="../../../../../../assets/img/hierarchy_select.svg"
                    />
                    <input
                      data-e2e="new-hierarchy-input"
                      class="addTo{{ node.id }}"
                      maxlength="20"
                      #name
                      type="text"
                    />
                    <div class="label-hierarchy" data-e2e="group-level-msg">
                      Grupo nível {{ node.levelItem + 1 }}
                    </div>
                  </div>
  
                  <div class="content-button">
                    <button
                      type="button"
                      class="btn btn-primary"
                      id="{{ node.levelItem + 1 }}"
                      (click)="onSaveHierarchy(node.hierarchicalLevel)"
                      data-e2e="add-to-level-btn"
                    >
                    <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
                      <img
                        src="../../../../../../assets/img/ico-add-b.svg"
                        class="button-icon"
                      />
                      Incluir ao nível {{ node.levelItem + 1 }}
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
        <div class="funcs-node" *ngIf="nestedTreeExpandableNodeElement.ariaLevel !== '1' && !viewMode">
          <button
          type="button"
          buttonBlock
          [ContractBlock]="[3]"
          *ngIf="(haveRole('101164') || haveRole('101165') || haveRole('101167')) && nestedTreeExpandableNodeElement.ariaLevel !== '1'"
          class="btn-popover btn-functionality"
          [popover]="popoverNav"
          [outsideClick]="true"
          placement="right" >
          <img src="../../../assets/svg/icon-popover.svg" alt="" />
        </button>
        <ng-template placement="right" [outsideClick]="true" #popoverNav >
          <div>
            <div class="row hierarchy-funcs">
              <div *ngIf="node.children.length == 0 && haveRole('101167') && node.vehicleCount == 0" class="col">
                <button type="button" (click)="onOpenAdcVehicle(node.id,node.description_PT)" data-e2e="add-vehicle-btn">
                  <i class="ico ico-white ico-truck-travel"></i>
                  Adicionar <br>
                  veículos
                </button>
              </div>

              <div *ngIf="node.children.length == 0 && haveRole('101167') && node.vehicleCount > 0" class="col">
                <button type="button" (click)="showManageVehiclesHierarchyModal(node)" data-e2e="manage-vehicle-btn">
                  <i class="ico ico-white ico-manage"></i>
                  Gerenciar <br>
                  veículos
                </button>
              </div>

              <div *ngIf="haveRole('101165')" class="col">
                <button type="button" data-e2e="edit-level-btn" [popover]="popoverEditHierarchy" [outsideClick]="true"
                placement="right">
                  <i class="ico ico-white ico-edit"></i>
                  Editar<br>
                  nível
                </button>
              </div>

                  <ng-template placement="right" [outsideClick]="true" #popoverEditHierarchy>
                    <div class="box-edit-hierarchy flex-column">
                      <div
                        class="w-100 d-flex justify-content-between"
                      >
                        <div class="hierarchy-input">
                          <img
                            src="../../../../../../assets/img/hierarchy_select.svg"
                          />
                          <input
                            data-e2e="new-hierarchy-input"
                            class="addTo{{ node.id }}"
                            (keyup)="onKeyup(node, $event.target.value)"
                            maxlength="20"
                            type="text"
                            [ngModel]="node.description_PT"
                          />
                          <div class="label-hierarchy" data-e2e="group-level-msg">
                            Renomear nível {{ node.levelItem }}
                          </div>
                        </div>
        
                        <div class="content-button">
                          <button
                            type="button"
                            class="btn btn-primary rename"
                            id="{{ node.levelItem + 1 }}"
                            (click)="SaveHierarchy()"
                            data-e2e="add-to-level-btn"
                            [disabled]="
                            ArrayHierarchyNames.length == 0 || disableSaveButton
                          "
                          >
                            <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
                            <img
                              src="../../../../../../assets/img/ico-add-b.svg"
                              class="button-icon"
                            />
                            Renomear nível {{ node.levelItem}}
                          </button>
                        </div>
                      </div>          
                    </div>
                  </ng-template>

              <div *ngIf="haveRole('101164')"  class="col">
                <button type="button" (click)="showRemoveHierarchyModal(node.hierarchicalLevel)" data-e2e="delete-level-btn">
                  <i class="ico ico-white ico-delete"></i>
                  Excluir <br>
                  nível
                </button>
              </div>

            </div>
          </div>
        </ng-template>  
        </div>
        <div class="funcs-node-first"  *ngIf="nestedTreeExpandableNodeElement.ariaLevel == '1' && !viewMode">
          <div class="row buttons">
              <button
                class="col-sm-auto"
                *ngIf="node.children.length !== 0"
                (click)="expandAllNodes(treeControl.isExpanded(node))"
                type="button"
                data-e2e="expand-btn">
                <i [ngClass]="!treeControl.isExpanded(node) ? 'ico-expand' : 'ico-collapse'"class="ico ico-white"></i>
                 <p *ngIf="!treeControl.isExpanded(node)">Expandir todos</p>
                 <p *ngIf="treeControl.isExpanded(node)">Recolher todos</p>
              </button>

              <button
              class="col-sm-auto edit btn-functionality"
              buttonBlock
              [ContractBlock]="[3]"
              *ngIf="haveRole('101165')"
              [popover]="popoverEditHierarchy" 
              [outsideClick]="true"
              placement="left"
              type="button"
              data-e2e="edit-btn">
              <i class="ico ico-white ico-edit"></i>
              <p>Editar nível</p>
            </button>

            <ng-template placement="left" [outsideClick]="true" #popoverEditHierarchy>
              <div class="box-edit-hierarchy flex-column">
                <div
                  class="w-100 d-flex justify-content-between"
                >
                  <div class="hierarchy-input">
                    <img
                      src="../../../../../../assets/img/hierarchy_select.svg"
                    />
                    <input
                      data-e2e="new-hierarchy-input"
                      class="addTo{{ node.id }}"
                      (keyup)="onKeyup(node, $event.target.value)"
                      maxlength="20"
                      type="text"
                      [ngModel]="node.description_PT"
                            />
                    <div class="label-hierarchy" data-e2e="group-level-msg">
                      Renomear nível {{ node.levelItem }}
                    </div>
                  </div>
  
                  <div class="content-button">
                    <button
                      type="button"
                      class="btn btn-primary rename"
                      id="{{ node.levelItem + 1 }}"
                      (click)="SaveHierarchy()"
                      data-e2e="add-to-level-btn"
                      [disabled]="
                      ArrayHierarchyNames.length == 0 || disableSaveButton
                    "
                    >
                    <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
                      <img
                        src="../../../../../../assets/img/ico-add-b.svg"
                        class="button-icon"
                      />
                      Renomear nível {{ node.levelItem}}
                    </button>
                  </div>
                </div>          
              </div>
            </ng-template>

              <button
              class="col-sm-auto"
              *ngIf="node.children.length == 0 && haveRole('101167') && node.vehicleCount == 0"
              class="edit btn-functionality"
              (click)="onOpenAdcVehicle(node.id,node.description_PT)"
              type="button"
              data-e2e="edit-btn">
              <i class="ico ico-white ico-truck-travel"></i>
              <p> Adicionar 
                veículos</p>
            </button>

            <button
            class="col-sm-auto"
            *ngIf="node.children.length == 0 && haveRole('101167') && node.vehicleCount > 0"
            class="edit btn-functionality"
            (click)="showManageVehiclesHierarchyModal(node)"
            type="button"
            data-e2e="edit-btn">
            <i class="ico ico-white ico-manage"></i>
            <p> Gerenciar
              veículos</p>
          </button>

                <button
                class="col-sm-auto"
                buttonBlock
                [ContractBlock]="[3]"
                *ngIf="haveRole('101164')"
                class="delete btn-functionality"
                type="button"
                (click)="showRemoveHierarchyModal(node.hierarchicalLevel)"
                data-e2e="delete-btn">
                <i class="ico ico-white ico-delete"></i>
                <p>Excluir nível</p>
              </button>           
            </div>
        </div>   
        <div [ngClass]="treeControl.isExpanded(node) ? (nestedTreeExpandableNodeElement.ariaLevel == '1' ? 'node-childs-first-expanded' : 'node-childs-expanded') : ''" class="node-childs" [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container cdkTreeNodeOutlet></ng-container>
        </div>
      </cdk-nested-tree-node>
    </cdk-tree>
  </div>
</div>