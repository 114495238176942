import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '@modules/backoffice/page-backoffice/services/routes.service';
import { RoutesPaths } from 'src/app/core/utils/routes/routes-paths';

@Component({
  selector: 'app-card-holidays',
  templateUrl: './card-holidays.component.html',
  styleUrl: './card-holidays.component.scss'
})
export class CardComponent implements OnInit {

  @Output() year : number;
  @Output() changePage = new EventEmitter<boolean>();

  years: number[] = [];

  constructor(private _routesService: RoutesService,
              private _router: Router)
  { }

  ngOnInit(): void {
   const currentYear = new Date().getFullYear();
    this.year = currentYear;
    this.years = [currentYear, currentYear+1]
  }

  onYearChange(event: any) {
    this.year = event.target.value
  }

  redirect() {
    this._router.navigate(['/backoffice/'+ RoutesPaths.INSERT_HOLIDAYS])
  }

}
