import { Component } from '@angular/core';
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";
import { Router } from "@angular/router";

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})
export class TicketsComponent extends AbstractProcessLauncher {

  constructor(protected router: Router) {
    super(router);
  }

}
