<div class="myVehiclesComponent">
  <div class="content-page">
    <div class="container-fluid page-myaccount">
      <div class="row">
        <div class="col-12 Dashboard_Overview header-page">
          <div class="d-flex bd-highlight mb-3">
            <div class="mr-auto p-2 bd-highlight">
              <h1 data-e2e="page-title">/ MEUS VEÍCULOS</h1>
            </div>
            <div class="p-2 bd-highligh col-4 mr-3">
              <form class="input-search">
                <input data-e2e="search-input" id="search" type="text" placeholder="veículo, placa, marca..."
                  (keyup)="onFullNameKeyup($event)" [value]="getVehiclesQueryArguments.search" />
                <button data-e2e="search-btn" id="searchButton" type="button" class="btn btn-search"
                  (click)="onBuscarClick($event)">
                  <img src="assets/img/icon-search.svg" class="button-icon" />
                  <span>Buscar</span>
                </button>
              </form>
            </div>

            <div class="p-2 bd-highligh mr-3" *ngIf="!verificationPermissionByContractType">
              <button data-e2e="import-btn" buttonBlock [ContractBlock]="[3]" *accessControl="101145" #toggleButton
                (click)="showImportVehiclesModal()" id="button-animated"
                class="btn btn-repom btn-import btn-repom-user simulate-select dropdown-toggle" type="button">
                <i class="ico ico-upload"></i>
                importar veículos
                <span class="btn-animation"></span>
              </button>
            </div>

            <div class="p-2 bd-highligh mr-3" *ngIf="!verificationPermissionByContractType">
              <button data-e2e="new-vehicle-btn" id="newVehicleButton" buttonBlock [ContractBlock]="[3]"
                *accessControl="101144" class="btn btn-repom btn-repom-user" type="button"
                data-gtm-event="click_novo_veiculo" (click)="showAddVehicleModal()">
                <i class="ico ico-add "></i>
                novo veículo <span class="btn-animation"></span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="col-12 d-flex justify-content-center">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a data-e2e="registered-filter" class="nav-link active" id="nav1-tab" data-bs-toggle="tab" href="#nav1"
                  (click)="onFilterClick(1)" role="tab" aria-controls="nav1" aria-selected="true">
                  CADASTRADOS

                  <br>

                  <div class="row">
                    <div class="col-lg-4">
                    </div>
                    <div class="col-lg-4">
                      <i class="ico ico-transit"></i>
                    </div>
                    <div class="col-lg-4">
                      <span class="number" id="vehiclesRegistered">
                        {{ VehiclesStatistic?.vehiclesRegistered }}
                      </span>
                    </div>
                  </div>
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" id="nav2-tab" data-bs-toggle="tab" href="#nav2" (click)="onFilterClick(2)"
                  role="tab" data-e2e="no-tag-filter" aria-controls="nav2" aria-selected="false">
                  SEM TAG CADASTRADA

                  <br>

                  <div class="row">
                    <div class="col-lg-4">
                      <span class="percentage" id="percentageVehiclesWithoutTagRegistered">
                        {{percentageVehiclesWithoutTagRegistered | percent:'1.2':'pt-Br' }}
                      </span>
                    </div>
                    <div class="col-lg-4">
                      <i class="ico ico-chain"></i>
                    </div>
                    <div class="col-lg-4">
                      <span class="number" id="vehiclesWithoutTagRegistered">
                        {{ VehiclesStatistic?.vehiclesWithoutTagRegistered }}
                      </span>
                    </div>
                  </div>
                </a>
              </li>

              <li class="nav-item">
                <a data-e2e="active-tag-filter" class="nav-link" id="nav3-tab" data-bs-toggle="tab" href="#nav3"
                  (click)="onFilterClick(3)" role="tab" aria-controls="nav3" aria-selected="false">
                  COM TAG ATIVA
                  <br>
                  <div class="row">
                    <div class="col-lg-4">
                      <span class="percentage" id="percentageVehiclesWithActiveTag">
                        {{ percentageVehiclesWithActiveTag | percent:'1.2':'pt-Br' }}
                      </span>
                    </div>

                    <div class="col-lg-4">
                      <i class="ico ico-truck2"></i>
                    </div>

                    <div class="col-lg-4">
                      <span class="number" id="vehiclesWithActiveTag">
                        {{ VehiclesStatistic?.vehiclesWithActiveTag }}
                      </span>
                    </div>
                  </div>
                </a>
              </li>

              <li class="nav-item">
                <a data-e2e="blocked-tag-filter" class="nav-link" id="nav4-tab" data-bs-toggle="tab" href="#nav4"
                  (click)="onFilterClick(4)" role="tab" aria-controls="nav4" aria-selected="false">
                  COM TAG BLOQUEADA
                  <br>
                  <div class="row">
                    <div class="col-lg-4">
                      <span class="percentage" id="percentageVehiclesWithLockedTag">
                        {{ percentageVehiclesWithLockedTag | percent:'1.2':'pt-Br' }}
                      </span>
                    </div>

                    <div class="col-lg-4">
                      <i class="ico ico-block"></i>
                    </div>

                    <div class="col-lg-4">
                      <span class="number" id="vehiclesWithLockedTag">
                        {{ VehiclesStatistic?.vehiclesWithLockedTag }}
                      </span>
                    </div>
                  </div>
                </a>
              </li>

              <li class="nav-item">
                <a data-e2e="deleted-vehicle-filter" class="nav-link" id="nav5-tab" data-bs-toggle="tab" href="#nav5"
                  (click)="onFilterClick(5)" role="tab" aria-controls="nav5" aria-selected="false">
                  EXCLUÍDOS
                  <br>
                  <div class="row">
                    <div class="col-lg-4">
                    </div>

                    <div class="col-lg-4">
                      <i class="ico ico-delete"></i>
                    </div>

                    <div class="col-lg-4">
                      <span class="number" id="excludedVehicles">{{ VehiclesStatistic?.excludedVehicles }}</span>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div class="container col-md-12 mt-5">
            <div class="card">
              <div class="card-body pt-0">
                <div class="title d-flex align-items-center">
                  <form novalidate class="p-4">
                    <div class="form-group select-table">
                      <label data-e2e="line-filter-label" translate>Quantidade de linhas</label>
                      <select data-e2e="line-filter-select" class="form-control form-select"
                        (change)="onPageSizeChange($event)">
                        <option [attr.data-e2e]="'line-filter-option-' + i" *ngFor="let i of perPages" value="{{i}}">
                          exibir {{i}} linhas</option>
                      </select>
                    </div>
                  </form>

                  <button *accessControl="103003" id="btn-batch-management" data-e2e="btn-batch-management"
                    class="btn btn-repom btn-batch-management" type="button" routerLink="/gestao-lote"
                    routerLinkActive="is-active">
                    <i class="ico ico-pencil-line"></i>
                    Gestão em lote
                    <span class="btn-animation"></span>
                  </button>

                  <button data-e2e="export-list-btn" [disabled]="noTransactions" type="button"
                    class="btn btn-download btn-repom" (click)="Download()">
                    Exportar
                    <i class="ico ico-export"></i>
                    <span class="btn-animation"></span>
                  </button>
                </div>
                <div class="px-4">

                  <!-- // TODO: Verificar a possibilidade de separar essa table em um componente para ficar mais organizada -->
                  <table class="table vehicles">
                    <thead class="thead">
                      <tr>
                        <th [ngClass]="bindOrderByClass('licensePlate')">
                          <button data-e2e="sort-plate-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('licensePlate')">Veículo +
                            ações</button>
                          <div class="round-order" (click)="onOrderByChange('licensePlate')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('nickName')">
                          <button data-e2e="sort-nickname-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('nickName')">Apelido</button>
                          <div class="round-order" (click)="onOrderByChange('nickName')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('descriptionVehicleTypePT')">
                          <button data-e2e="sort-type-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('descriptionVehicleTypePT')">Tipo</button>
                          <div class="round-order" (click)="onOrderByChange('descriptionVehicleTypePT')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('descriptionAxlePt')">
                          <button data-e2e="sort-axel-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('descriptionAxlePt')">Eixos</button>
                          <div class="round-order" (click)="onOrderByChange('descriptionAxlePt')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('hierarchyName')">
                          <button data-e2e="sort-hierarchy-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('hierarchyName')">Hierarquia</button>
                          <div class="round-order" (click)="onOrderByChange('hierarchyName')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('aggregateDriver')">
                          <button data-e2e="sort-driver-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('aggregateDriver')">Agregado</button>
                          <div class="round-order" (click)="onOrderByChange('aggregateDriver')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('descriptioncontractVehicleTagStatusTypeIdPt')">
                          <button data-e2e="sort-status-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('descriptioncontractVehicleTagStatusTypeIdPt')">Status</button>
                          <div class="round-order"
                            (click)="onOrderByChange('descriptioncontractVehicleTagStatusTypeIdPt')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('serialTag')">
                          <button data-e2e="sort-tag-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('serialTag')">Tag</button>
                          <div class="round-order" (click)="onOrderByChange('serialTag')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('startDate')">
                          <button data-e2e="sort-active-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('startDate')">Ativação</button>
                          <div class="round-order" (click)="onOrderByChange('startDate')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                        <th [ngClass]="bindOrderByClass('contractVehicleTagServiceTypeId')">
                          <button data-e2e="sort-service-btn" class="btn-order-table" translate
                            (click)="onOrderByChange('contractVehicleTagServiceTypeId')">Serviço</button>
                          <div class="round-order" (click)="onOrderByChange('contractVehicleTagServiceTypeId')">
                            <img src="../../../assets/svg/order-table.svg" alt="" />
                          </div>
                        </th>

                      </tr>
                    </thead>
                    <div class="container-loading" *ngIf="isLoading">
                      <app-loader [isLoading]="isLoading" message="{{ message }}"></app-loader>
                    </div>

                    <tbody>
                      <tr *ngFor="let vehicle of getVehiclesQueryResult.vehicles; let i = index">
                        <td>

                          <!-- // TODO: Verificar de separar o popover em um componente -->
                          <ng-template #actionPopover placement="right" [outsideClick]="true">
                            <div class="row my-1">
                              <div class="head-info" [attr.data-e2e]="'popoverPlate-label-' + i" translate>Placa:</div>
                              <div class="content-info" id="popoverLicensePlate{{i}}"
                                [attr.data-e2e]="'popoverPlate-data-' + i">
                                {{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}
                              </div>
                            </div>

                            <div class="row my-1">
                              <div class="head-info" [attr.data-e2e]="'popoverNickname-label-' + i" translate>Apelido:
                              </div>
                              <div class="content-info" id="popoverNickName{{i}}"
                                [attr.data-e2e]="'popoverNickname-data-' + i">{{ vehicle?.nickName }}</div>
                            </div>

                            <div class="row my-1">
                              <div class="head-info" [attr.data-e2e]="'popoverTag-label-' + i" translate>Tag:</div>
                              <div class="content-info" id="popoverSerialTag{{i}}"
                                [attr.data-e2e]="'popoverTag-label-' + i">{{ vehicle?.serialTag }}</div>
                            </div>

                            <div class="row my-1">
                              <div class="head-info" [attr.data-e2e]="'popoverType-label-' + i" translate>Tipo:</div>
                              <div class="content-info" id="popoverDescriptionVehicleTypePT{{i}}"
                                [attr.data-e2e]="'popoverType-data-' + i">{{ vehicle?.descriptionVehicleTypePT }}</div>
                            </div>

                            <div class="row my-1">
                              <div class="head-info" [attr.data-e2e]="'popoverBrand-label-' + i" translate>Marca:</div>
                              <div class="content-info" id="popoverDescriptionVehicleBrandTypePt{{i}}"
                                [attr.data-e2e]="'popoverBrand-data-' + i">{{ vehicle?.descriptionVehicleBrandTypePt }}
                              </div>
                            </div>

                            <div class="row my-1">
                              <div class="head-info" [attr.data-e2e]="'popoverModel-label-' + i" translate>Modelo:</div>
                              <div class="content-info" [attr.data-e2e]="'popoverModel-data-' + i"
                                id="popoverModel{{i}}">{{ vehicle?.model }}</div>
                            </div>

                            <div class="row my-1">
                              <div class="head-info" [attr.data-e2e]="'popoverAxel-label-' + i" translate>Nº de eixos:
                              </div>
                              <div class="content-info" id="popoverDescriptionAxlePt"
                                [attr.data-e2e]="'popoverAxel-data-' + i">{{ vehicle?.descriptionAxlePt }}</div>
                            </div>

                            <div *ngIf="getVehiclesQueryArguments.filter != 5">
                              <div class="content-buttons-popover mt-4">
                                <div class="item-action-list" *accessControl="101146">
                                  <button type="button" class="btn-popover btn-functionality"
                                    [attr.data-e2e]="'popover-edit-action-label-' + i"
                                    (click)="showEditVehicleModal(vehicle); pop.hide()" buttonBlock
                                    [ContractDualTagBlock]="
                                    verificationPermissionByContractType" [ngbTooltip]="verificationPermissionByContractType ? 'Em contratos com Dual Tag Ativo,
                                    os veículos podem ser editados
                                    somente na página de Abastecimento' : null">
                                    <img class="btn-popover-icon" src="../../../assets/svg/edit.svg" alt="" />
                                  </button>
                                  <span class="flex-start" translate>Editar</span>
                                </div>

                                <div class="item-action-list" *controlAccessBackOffice>
                                  <button type="button" class="btn-popover btn-functionality"
                                    [attr.data-e2e]="'popover-transfer-btn-' + i"
                                    (click)="showTransferVehicleModal(vehicle)"
                                    [disabled]="vehicle?.balanceValePedagio || !vehicle?.hasTag">
                                    <img src="assets/svg/share.svg" />
                                  </button>
                                  <span [attr.data-e2e]="'popover-transfer-label-' + i" class="flex-start"
                                    translate>Transfer vehicle</span>
                                </div>

                                <div class="item-action-list" *controlAccessBackOffice>
                                  <button (click)="goToCancelTag(vehicle)"
                                    [attr.data-e2e]="'popover-cancel-tag-btn-' + i"
                                    class="btn-popover btn-functionality" [ngbTooltip]="
                              vehicle?.contractVehicleTagStatusTypeId !==
                              tagStatus.TEMP_BLOCKED_TAG
                                ? 'Só é possível cancelar tags bloqueadas manualmente'
                                : null
                            " type="button" buttonBlock [ContractBlock]="[2, 3]" [ConditionToBlock]="
                              vehicle?.contractVehicleTagStatusTypeId !==
                                tagStatus.TEMP_BLOCKED_TAG &&
                              vehicle?.contractVehicleTagStatusTypeId !==
                                tagStatus.BALANCE_BLOCKED_TAG &&
                              vehicle?.contractVehicleTagStatusTypeId !==
                                tagStatus.FINAN_BLOCKED_TAG
                            " [idVehicles]="getVehicle(vehicle)"
                                    [ngbTooltip]="verifyTooltipByIdVehicles(vehicle) ? tooltips.dualTagCancel : null">
                                    <img src="assets/svg/backspace-24px.svg" />
                                  </button>
                                  <span [attr.data-e2e]="'popover-cancel-tag-label-' + i" class="flex-start"
                                    translate>Cancel tag</span>
                                </div>

                                <div *controlAccessCustomer>
                                  <div class="item-action-list" *accessControl="101156">
                                    <button [attr.data-e2e]="'popover-changeServ-action-label-' + i" buttonBlock
                                      [ConditionToBlock]="
                            vehicle?.contractVehicleTagStatusTypeId !== 1 &&
                            vehicle?.contractVehicleTagStatusTypeId !== 2" type="button"
                                      class="btn-popover btn-functionality"
                                      (click)="showAlterServiceModal(vehicle); pop.hide()"
                                      [idVehicles]="getVehicle(vehicle)"
                                      [ngbTooltip]="verifyTooltipByIdVehicles(vehicle) ? tooltips.dualTagChangeService : null">
                                      <img class="btn-popover-icon btn-switch"
                                        src="../../../assets/svg/toggle_on-24px.svg" alt="" />
                                    </button>
                                    <span class="flex-start" translate>Alterar serviço</span>
                                  </div>
                                </div>

                                <div *controlAccessCustomer>
                                  <div class="item-action-list" *accessControl="101154">
                                    <button [ContractBlock]="[2,3]" [attr.data-e2e]="'popover-cancel-action-label-' + i"
                                      [ConditionToBlock]="(vehicle?.contractVehicleTagStatusTypeId !== 2 && vehicle?.contractVehicleTagStatusTypeId !== 3 && vehicle?.contractVehicleTagStatusTypeId !== 4) || vehicle?.balanceValePedagio"
                                      (click)="redirectToCancelTag(vehicle); pop.hide()"
                                      [idVehicles]="getVehicle(vehicle)" [ngbTooltip]="getTooltip(vehicle)"
                                      class="btn-popover btn-functionality" type="button" buttonBlock>
                                      <img class="btn-popover-icon btn-backspace"
                                        src="../../../assets/svg/backspace-24px.svg" alt="" />
                                    </button>
                                    <span class="flex-start" translate>Cancelar Tag</span>
                                  </div>
                                </div>

                                <div class="item-action-list" *controlAccessBackOffice>
                                  <button [attr.data-e2e]="'popover-history-btn-' + i" type="button"
                                    class="btn-popover btn-functionality">
                                    <img src="assets/svg/history.svg" />
                                  </button>
                                  <span [attr.data-e2e]="'popover-history-label-' + i" class="flex-start"
                                    translate>History</span>
                                </div>

                                <div class="item-action-list" *controlAccessBackOffice>
                                  <button data-e2e="popover-hierarchy-btn" type="button"
                                    class="btn-popover btn-functionality">
                                    <img src="assets/svg/group-hierarchy.svg" />
                                  </button>
                                  <span [attr.data-e2e]="'popover-hierarchy-label-' + i" class="flex-start"
                                    translate>Group Hierarchy</span>
                                </div>

                                @if (checkPermissionByDeleteModal(vehicle)) {
                                <div class="item-action-list">
                                  <button [attr.data-e2e]="'popover-delete-action-label-' + i"
                                    [disabled]="vehicle.serialTag" [ContractDualTagBlock]="
                                    verificationPermissionByContractType"
                                    [ngbTooltip]="verificationPermissionByContractType ? 'A exclusão de veículos deve ser feita diretamente na página de Abastecimento' : null"
                                    type="button" class="btn-popover btn-functionality"
                                    (click)="showDeleteVehicleModal(vehicle); pop.hide()" buttonBlock>
                                    <img class="btn-popover-icon btn-trash" src="../../../assets/svg/delete.svg"
                                      alt="" />
                                  </button>
                                  <span class="flex-start" translate>Excluir</span>
                                </div>
                                }

                              </div>

                              <div class="d-flex align-items-center justify-content-center">
                                <div *accessControl="101152">
                                  <div class="card-button-content mb-3 popoverButtonLarge"
                                    *ngIf="vehicle?.contractVehicleTagStatusTypeId == 1"
                                    [ngbTooltip]="verifyTooltipByIdVehicles(vehicle) ? tooltips.dualTagBlock : null">
                                    <button [attr.data-e2e]="'popover-block-action-label-' + i" buttonBlock
                                      [ContractBlock]="[2,3]"
                                      [ConditionToBlock]="vehicle?.contractVehicleTagStatusTypeId !== 1"
                                      (click)="redirectToBlockTag(vehicle); pop.hide()"
                                      [idVehicles]="getVehicle(vehicle)"
                                      class=" btn btn-repom btn-repom-user col-12 btn-functionality" translate>
                                      <img class="button-icon mr-2" src="../../../assets/svg/block.svg" alt="" />
                                      Bloquear TAG
                                      <span class="btn-animation"></span>
                                    </button>
                                  </div>
                                </div>

                                <div class="card-button-content mb-3 ml-3 popoverButtonLarge" *accessControl="101155"
                                  [ngbTooltip]="verifyTooltipByIdVehicles(vehicle) ? tooltips.dualTagUnlock : null || vehicle?.contractVehicleTagStatusTypeId == 0 ? 'Para realizar uma substituição de TAG precisamos que o veículo já possua uma tag vinculada' : null ">
                                  <button id="newVehicleButton" buttonBlock [ContractBlock]="[2,3]"
                                    [ConditionToBlock]="vehicle?.contractVehicleTagStatusTypeId !== 1 && vehicle?.contractVehicleTagStatusTypeId !== 2"
                                    [idVehicles]="getVehicle(vehicle)"
                                    (click)="showReplaceTagVehicleModal(vehicle); pop.hide()"
                                    class="btn btn-repom btn-repom-user col-10 btn-functionality"
                                    [attr.data-e2e]="'popover-change-action-label-' + i" translate>
                                    <img class="button-icon mr-2" src="../../../assets/svg/replace-tag.svg" alt="" />
                                    Substituir tag
                                    <span class="btn-animation"></span>
                                  </button>

                                </div>
                              </div>

                              <div *accessControl="101153">
                                <div class="card-button-content mb-3 popoverButtonLarge"
                                  *ngIf="vehicle?.contractVehicleTagStatusTypeId == 2"
                                  [ngbTooltip]="verifyTooltipByIdVehicles(vehicle) ? tooltips.dualTagBlock : null">
                                  <button [attr.data-e2e]="'popover-unblock-action-label-' + i" buttonBlock
                                    [ContractBlock]="[2,3]"
                                    [ConditionToBlock]="vehicle?.contractVehicleTagStatusTypeId !== 2"
                                    [idVehicles]="getVehicle(vehicle)"
                                    (click)="redirectToUnlockTag(vehicle); pop.hide()"
                                    class="btn btn-repom btn-repom-user col-10 btn-functionality" translate>
                                    <img class="button-icon mr-2" src="../../../assets/svg/block.svg" alt="" />
                                    Desbloquear TAG
                                    <span class="btn-animation"></span>
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div class="reinclude-vehicle-button" *ngIf="getVehiclesQueryArguments.filter == 5">
                              <div class="card-button-content mb-3 popoverButtonLarge">
                                <button [attr.data-e2e]="'popover-reinclude-action-label-' + i"
                                  (click)="redirectToReincludeVehicleModal(vehicle); pop.hide()"
                                  class="btn btn-repom btn-repom-user col-10 btn-functionality" translate>
                                  <img class="button-icon mr-2" src="../../../assets/img/reinclude_vehicle_icon.svg"
                                    alt="" />
                                  Reincluir na frota
                                  <span class="btn-animation"></span>
                                </button>
                              </div>
                            </div>

                          </ng-template>

                          <button type="button" buttonBlock [ContractBlock]="[3]" class="btn-popover btn-functionality"
                            [attr.data-e2e]="'popover-btn-' + i" [popover]="actionPopover" #pop="bs-popover"
                            [outsideClick]="true" placement="right">
                            <img src="../../../assets/svg/icon-popover.svg" alt="" />
                          </button>

                          <span id="licensePlate-{{i}}" [attr.data-e2e]="'plate-table-data-' + i">{{
                            vehicle?.licensePlate | mask: 'AAA-AAAA' }}</span>
                        </td>

                        <td id="nickName-{{i}}" [attr.data-e2e]="'nickname-table-data-' + i">{{ vehicle?.nickName }}
                        </td>
                        <td id="VehicleType-{{i}}" [attr.data-e2e]="'type-table-data-' + i">{{
                          vehicle?.descriptionVehicleTypePT }}</td>
                        <td id="Axle-{{i}}" [attr.data-e2e]="'axel-table-data-' + i">{{ vehicle?.descriptionAxlePt }}
                        </td>
                        <td id="hierarchy-{{i}}" [attr.data-e2e]="'hierarchy-table-data-' + i">{{ vehicle?.hierarchyName
                          }}</td>

                        <td>
                          <div class="d-flex align-items-center justify-content-center" *ngIf="vehicle.aggregateDriver">
                            <button [attr.data-e2e]="'driver-table-btn-' + i" type="button" class="btn-popover"
                              [popover]="cnpjCpfPopover" [outsideClick]="true" placement="right">
                              <img src="../../../assets/svg/icon-info.svg" alt="" />
                            </button>

                            <span id="aggregateDriver{{i}}"
                              class="custom-text text-uppercase font-weight-bold color-default-font"
                              [attr.data-e2e]="'driver-table-data-' + i" translate>Sim</span>
                          </div>

                          <ng-template #cnpjCpfPopover #actionPopover placement="right" [outsideClick]="true">
                            <span *ngIf="vehicle?.documentNumber?.length == 11"
                              [attr.data-e2e]="'cpf-popover-label-' + i">
                              CPF: <span id="documentNumber{{i}}" [attr.data-e2e]="'cpf-popover-data-' + i">{{
                                vehicle?.documentNumber | mask: '000.000.000-00' }}</span>
                            </span>
                            <span *ngIf="vehicle?.documentNumber?.length == 14"
                              [attr.data-e2e]="'cnpj-popover-label-' + i">
                              CNPJ: <span id="documentNumber{{i}}" [attr.data-e2e]="'cnpj-popover-data-' + i">{{
                                vehicle?.documentNumber | mask: '00.000.000/0000-00' }}</span>
                            </span>
                            <div *ngIf="vehicle?.rntrc" [attr.data-e2e]="'rntrc-popover-label-' + i">
                              RNTRC: <span id="rntrc{{i}}" [attr.data-e2e]="'rntrc-popover-data-' + i">{{ vehicle?.rntrc
                                }}</span>
                            </div>
                          </ng-template>

                          <span id="aggregateDriver{{i}}"
                            class="custom-text text-uppercase font-weight-bold color-default-font"
                            *ngIf="!vehicle?.aggregateDriver" [attr.data-e2e]="'no-driver-popover-label-' + i"
                            translate>Não</span>
                        </td>

                        <td>
                          <div *ngIf="vehicle?.contractVehicleTagStatusTypeId == 0" placement="top"
                            ngbTooltip="Sem tag">
                            <img src="assets/svg/link.svg" />
                          </div>
                          <div *ngIf="vehicle?.contractVehicleTagStatusTypeId == 1" placement="top"
                            ngbTooltip="Tag ativa e liberada para utilização">
                            <img src="assets/svg/ticked.svg" />
                          </div>
                          <div *ngIf="vehicle?.contractVehicleTagStatusTypeId == 2" placement="top"
                            ngbTooltip="Tag bloqueada temporariamente">
                            <img src="assets/img/padlock-vehicles.svg" />
                          </div>
                          <div *ngIf="vehicle?.contractVehicleTagStatusTypeId == 3" placement="top"
                            ngbTooltip="Tag bloqueada por falta de saldo">
                            <img src="assets/img/padlock-vehicles.svg" />
                          </div>
                          <div *ngIf="vehicle?.contractVehicleTagStatusTypeId == 4" placement="top"
                            ngbTooltip="Tag bloqueada pelo financeiro">
                            <img src="assets/img/padlock-vehicles.svg" />
                          </div>
                        </td>

                        <td>
                          <span *ngIf="vehicle?.hasTag" class="custom-text" id="serialTag{{i}}"
                            [attr.data-e2e]="'serial-table-data-' + i">{{ vehicle?.serialTag }}</span>

                          <div *accessControl="101150">
                            <button buttonBlock [ContractBlock]="[2,3,6]" id="redirectToActiveTagsButton{{i}}"
                              type="button" [attr.data-e2e]="'activate-table-btn-' + i"
                              class="btn-activeTag btn-functionality" [routerLink]="['/ativar-tags',vehicle.id]"
                              *ngIf="getVehiclesQueryArguments.filter != 5 && !vehicle?.hasTag">
                              <img class="w-25" src="../../../assets/svg/ticked.svg" alt="" />
                              ativar
                              <span class="btn-animation"></span>
                            </button>
                          </div>
                        </td>
                        <td>
                          <span class="custom-text font-weight-bold" id="startDate{{i}}"
                            [attr.data-e2e]="'date-table-label-' + i">
                            {{ vehicle?.startDate | date: 'dd/MM/yyyy' }}
                          </span>
                        </td>

                        <td id="services{{i}}">
                          <div class="vehicle-service"
                            *ngIf="vehicle?.contractVehicleTagServiceTypeId == 1 || vehicle?.contractVehicleTagServiceTypeId == 2">
                            <a *ngIf="vehicle?.contractVehicleTagServiceTypeId == 1 || vehicle?.contractVehicleTagServiceTypeId == 2"
                              placement="top" ngbTooltip="Pedágio"><img src="assets/svg/icon_pedagio.svg" /></a>
                            <a *ngIf="vehicle?.contractVehicleTagServiceTypeId == 1" placement="top"
                              ngbTooltip="Estacionamento"><img src="assets/svg/icon_estacionamento.svg" /></a>
                          </div>
                        </td>
                      </tr>
                    </tbody>

                  </table>
                  <div *ngIf="getVehiclesQueryResult.total == 0">
                    <div colspan="12" class="text-center w-100 py-4" data-e2e="no-result-msg" translate>Nenhum resultado
                      encontrado.</div>
                  </div>

                  <div class="pagination-custom" *ngIf="getVehiclesQueryResult.total != 0">
                    <app-pagination [totalItems]="getVehiclesQueryResult.total"
                      [itemsPerPage]="getVehiclesQueryArguments.pageSize" [maxPages]="5"
                      [currentPage]="getVehiclesQueryArguments.page" (pageChangeEvent)="onPaginationChange($event)">
                    </app-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>