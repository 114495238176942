<div class="batch-tag-block-unblock">
  <p class="batch-tag-block-unblock__info-text">
    {{ textInfo }}
  </p>

  <section class="batch-tag-block-unblock__subtabs-form">

    <form [formGroup]="searchForm" class="batch-tag-block-unblock__form">

      <div class="form-group select-table">
        <label for="select-page-size">
          Quantidade de linhas
        </label>
        <select class="form-control form-select" id="select-page-size" data-e2e="select-page-size"
          (change)="onPageSizeChange($event)">
          @for (size of pageSizes; track size) {
          <option id="page-size-{{size}}" [attr.data-e2e]="'page-size-' + size" [value]="size">
            exibir {{size}} linhas
          </option>
          }
        </select>
      </div>

      <div class="input-box batch-tag-block-unblock__input-box">
        <img class="input-box__img-before" src="/assets/img/icon-search-g.svg" alt="Ícone lupa">
        <input class="input-box__input batch-tag-block-unblock__input" id="form-search-input"
          data-e2e="form-search-input" formControlName="searchField" type="text"
          placeholder="Busque por veículo, apelido..." (keyup)="onSearchKeyup()">
      </div>
    </form>
  </section>

  @if (tagsSelectedStorage.length > 0) {
  <div class="batch-tag-block-unblock__selected-items">
    <b>{{ tagsSelectedStorage.length }}</b> veículos selecionados
  </div>
  }

  <table class="batch-tag-block-unblock__table">
    <thead class="batch-tag-block-unblock__thead">
      <tr>
        <th class="batch-tag-block-unblock__th-checkbox" id="table-header-checkbox" data-e2e="table-header-checkbox">
          <mat-checkbox (change)="handleHeaderCheckboxChanges($event.checked)"
            [checked]="checkboxSelectionControl.hasValue() && isAllChecked()"
            [indeterminate]="checkboxSelectionControl.hasValue() && !isAllChecked()">
          </mat-checkbox>
        </th>

        <th class="batch-tag-block-unblock__th-license-plate"
          [ngClass]="bindOrderByClass(tableHeadersBlockUnblock.LICENSE_PLATE)">

          <div class="btn-order-table">
            Veículo
          </div>

          <div class="round-order" id="btn-order-license-plate" data-e2e="btn-order-license-plate"
            (click)="onOrderByChange(tableHeadersBlockUnblock.LICENSE_PLATE)">
            <img src="assets/svg/order-table.svg" alt="Ordenação" />
          </div>
        </th>

        <th [ngClass]="bindOrderByClass(tableHeadersBlockUnblock.NICKNAME)">

          <div class="btn-order-table">
            Apelido
          </div>

          <div class="round-order" id="btn-order-nickname" data-e2e="btn-order-nickname"
            (click)="onOrderByChange(tableHeadersBlockUnblock.NICKNAME)">
            <img src="assets/svg/order-table.svg" alt="Ordenação" />
          </div>
        </th>

        <th [ngClass]="bindOrderByClass(tableHeadersBlockUnblock.HIERARCHY_NAME)">

          <div class="btn-order-table">
            Hierarquia
          </div>

          <div class="round-order" id="btn-order-hierarchy" data-e2e="btn-order-hierarchy"
            (click)="onOrderByChange(tableHeadersBlockUnblock.HIERARCHY_NAME)">
            <img src="assets/svg/order-table.svg" alt="Ordenação" />
          </div>
        </th>

        <th class="batch-tag-block-unblock__th-serial-tag"
          [ngClass]="bindOrderByClass(tableHeadersBlockUnblock.SERIAL_TAG)">

          <div class="btn-order-table">
            TAG
          </div>

          <div class="round-order" id="btn-order-serial-tag" data-e2e="btn-order-serial-tag"
            (click)="onOrderByChange(tableHeadersBlockUnblock.SERIAL_TAG)">
            <img src="assets/svg/order-table.svg" alt="Ordenação" />
          </div>
        </th>
      </tr>
    </thead>

    @if (totalItems > 0 && !isLoadingTableData) {
    <tbody>
      @for (item of tagsList; track $index) {
      <tr>
        <td id="table-data-checkbox-{{$index}}" [attr.data-e2e]="'table-data-checkbox-' + $index">
          <mat-checkbox (change)="handleRowCheckboxChanges(item)" [checked]="checkboxSelectionControl.isSelected(item)">
          </mat-checkbox>
        </td>

        <td id="table-data-license-plate-{{$index}}" [attr.data-e2e]="'table-data-license-plate-' + $index"
          class="batch-tag-block-unblock__td batch-tag-block-unblock__td--emphasis">
          {{ item.licensePlate }}
        </td>

        <td id="table-data-nickname-{{$index}}" [attr.data-e2e]="'table-data-nickname-' + $index"
          class="batch-tag-block-unblock__td">
          {{ getFormattedString(item.nickname) }}
        </td>

        <td id="table-data-hierarchy-{{$index}}" [attr.data-e2e]="'table-data-hierarchy-' + $index"
          class="batch-tag-block-unblock__td">
          {{ getFormattedString(item.hierarchy) }}
        </td>

        <td id="table-data-serial-tag-{{$index}}" [attr.data-e2e]="'table-data-serial-tag-' + $index"
          class="batch-tag-block-unblock__td batch-tag-block-unblock__td--emphasis">
          {{ item.serialTag }}
        </td>
      </tr>
      }
    </tbody>
    }
  </table>

  @if (totalItems > tagsQuery.pageSize && !isLoadingTableData) {

  <div class="pagination-custom batch-tag-block-unblock__pagination">

    <app-pagination [totalItems]="totalItems" [itemsPerPage]="tagsQuery.pageSize" [maxPages]="5"
      [currentPage]="tagsQuery.page" (pageChangeEvent)="onPaginationChange($event)"></app-pagination>

  </div>
  }

  @if (isLoadingTableData) {

  <div class="container-loading">

    <app-loader [isLoading]="isLoadingTableData" message="Carregando..."></app-loader>

  </div>
  }
  @else if (tagsList.length == 0) {

  <div class="batch-tag-block-unblock__no-results">

    <app-no-results-msg [inTitle]="noResultMsgTitle" [inSubtitle]="noResultMsgSubtitle"></app-no-results-msg>

  </div>
  }
</div>