<section class="order-timeline" *ngIf="order$ | async as order; else loading">
  <ng-container *ngIf="!order.getTagRequestTrackingQueryResults; else success">
    <main class="empty">
      <strong data-e2e="empty-search-msg">
        Não foi encontrado nenhum pedido para este contrato.
      </strong>
    </main>
  </ng-container>

  <ng-template #success>
    <div class="timeline">
      <div [ngClass]="'completed-' + order.activeStep"></div>
    </div>

    <ng-container
      *ngFor="
        let item of order.getTagRequestTrackingQueryResults;
        let i = index
      "
    >
      <i *ngIf="item.id !== 5" [ngClass]="'ico ico-progress-' + item.id"></i>

      <div
        [class]="'bullet bullet-' + i"
        [ngClass]="{
          active:
            item.isActive ||
            order.activeStep == i + 1 ||
            order.activeStep == i + 2
        }"
      ></div>

      <h2>{{ item?.description }}</h2>

      <button
        id="track"
        *ngIf="item?.id === 5"
        (click)="trackOrderModal(order)"
        [attr.data-e2e]="'track-btn-' + i"
        [disabled]="!item.isActive"
        class="btn btn-repom btn-repom-user track-btn"
        data-target="#userIncludeModal"
        data-toggle="modal"
        type="button"
      >
        <i class="ico ico-Tracking-progress"></i>
        rastrear

        <span class="btn-animation"></span>
      </button>

      <div *controlAccessCustomer>
        <div class="receival-confirmation" *accessControl="101173">
          <button
            *ngIf="item.id == 7 && !item.isActive"
            data-e2e="receival-confirmation-btn"
            (click)="confirmTagReceivalModal(order)"
            class="btn receival-confirmation-btn"
          >
            Confirmar recebimento
          </button>
        </div>
      </div>

      <div class="timeline-signature" [class]="item.stepStatus">
        <p>
          {{ item?.registrationDate | date : "dd/MM/yyyy HH:mm" }}
        </p>
      </div>
    </ng-container>
  </ng-template>
</section>

<ng-template #loading>
  <app-loader
    [isLoading]="loading"
    message="{{ 'Carregando...' }}"
  ></app-loader>
</ng-template>
