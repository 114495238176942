import { Component, OnInit, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsModalRef } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";
import { ContractVehicleTagService } from "src/app/core/services/contractvehicletag.service";
import { Tag } from "src/app/core/models/tag.model";
import { GetVehicleQueryResultDto } from "../../../myvehicles/dtos/get-vehicle-query-result.dto";
import { ReplacementTagAndUserRegister } from "@models/old/contractvehicletag/replacementtaganduserregister.model";
import { NotifyService } from "@services/notify.service";
import { ContractVehicleTagReplacement } from "@models/old/contractvehicletag/contractvehicletagreplacement.model";

@Component({
  selector: "app-tag-replace-modal",
  templateUrl: "./tag-replace-modal.component.html",
  styleUrls: ["./tag-replace-modal.component.css"],
})
export class TagReplaceModalComponent implements OnInit {
  public tagselected: Tag;
  public vehicle: GetVehicleQueryResultDto;
  public replacementTagUserRegister: ReplacementTagAndUserRegister;
  public stepconfirmacao: boolean;
  event: EventEmitter<any> = new EventEmitter();
  onSaving = false;
  isEmpty: any = "";

  constructor(
    private modalService: NgbModal,
    private bsModalRef: BsModalRef,
    private contractVehicleTagService: ContractVehicleTagService,
    protected notifyService: NotifyService
  ) {
    this.stepconfirmacao = true;
  }

  ngOnInit() {}

  onchangeTag(evento) {
    this.tagselected = evento;
  }

  dismissModal() {
    this.modalService.dismissAll();
  }

  saveContractVehicleTagReplacement() {
    const rq = new ContractVehicleTagReplacement();
    rq.contractVehicleId = this.vehicle.contractVehicleId;
    rq.currentTagId = this.vehicle.tagId;
    rq.replacementTagId = this.tagselected.id;
    this.onSaving = true;

    this.contractVehicleTagService
      .saveContractVehicleTagReplacement(rq)
      .pipe(
        finalize(() => {
          this.onSaving = false;
        })
      )
      .subscribe(
        (success) => {
          this.replacementTagUserRegister =
            success.contractVehicleTagAndUserReplacement;
          this.stepconfirmacao = false;
          // this.event.emit(true);
          this.notifyService.showSuccess(
            "Atenção!",
            "Vínculo realizado com sucesso!"
          );
          this.event.emit(success);
        },
        (e) => {
          console.error(e);
        }
      );
  }
}
