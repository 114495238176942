import { Component, OnInit } from '@angular/core';
import { TripExpiratedQueryArguments } from './dtos/trip-expirated-query-arguments';
import { ITripExpirated } from './dtos/trip-expirated.dto';
import { Subject } from 'rxjs';
import { TripExpiratedService } from './services/trip-expirated.service';
import { debounceTime, finalize } from 'rxjs/operators';
import moment from 'moment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Dto } from '@models/dto.model';
import { NotifyService } from '@services/notify.service';
import {Filters, IFilterNameId} from "@models/filters-backoffice.model";

@Component({
  selector: 'app-trip-expirated',
  templateUrl: './trip-expirated.component.html',
  styleUrls: ['./trip-expirated.component.scss'],
})
export class TripExpiratedComponent implements OnInit {
  perPages = [10, 50, 100];

  filterDebounce: Subject<TripExpiratedQueryArguments> =
    new Subject<TripExpiratedQueryArguments>();
  queryArguments: TripExpiratedQueryArguments;
  DtoTransactions: Dto<ITripExpirated[]>;
  message: string = 'Loading...';
  isLoading: boolean = false;
  isSearch: boolean = false;
  noFiles: boolean;

  constructor(
    private _transactionConciliation: TripExpiratedService,
    private _notifyService: NotifyService,
    private _localeService: BsLocaleService,
  ) {
    this.queryArguments = new TripExpiratedQueryArguments();
    this._localeService.use('pt-br');
    this.filterDebounce
      .pipe(debounceTime(300))
      .subscribe((filter) => this.getTripExpiration(filter));
  }

  ngOnInit(): void {
    this.getTripExpiration(this.queryArguments);
  }

  getTripExpiration(args: TripExpiratedQueryArguments) {
    this.isLoading = true;
    this.message = 'Loading...';
    this._transactionConciliation
      .getTripExpiration(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (data: Dto<ITripExpirated[]>) => {
          if (data.totalItems == 0) {
            this.noFiles = true;
          } else {
            this.noFiles = false;
          }
          this.DtoTransactions = data;
        },
        (error) => {
          console.error(error);
          this._notifyService.clear();
          this._notifyService.showError('Ocorreu um erro', error);
          return;
        },
      );
  }

  onPageSizeChange(event: any) {
    this.queryArguments.page = 1;
    this.queryArguments.pageSize = event.target.value;

    this.getTripExpiration(this.queryArguments);
  }

  onOrderByChange(field: string) {
    if (this.queryArguments.orderBy === field) {
      this.queryArguments.desc = !this.queryArguments.desc;
    } else {
      this.queryArguments.orderBy = field;
      this.queryArguments.desc = true;
    }
    this.getTripExpiration(this.queryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.queryArguments.orderBy == field && !this.queryArguments.desc,
      'order-by-desc':
        this.queryArguments.orderBy == field && this.queryArguments.desc,
    };
  }

  onPaginationChange(event: any) {
    this.queryArguments.page = event;
    this.getTripExpiration(this.queryArguments);
  }

  Download() {
    this.isLoading = true;
    let now = new Date();
    let date = moment(now).format('DDMMYYYY');
    this.message = 'Download in progress...';
    this._transactionConciliation

      .getTripExpirationCsv(this.queryArguments)

      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )

      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'Viagens Expiradas_' + date + '.csv';
        element.click();
      });
  }

  resetFilters() {
    this.queryArguments = new TripExpiratedQueryArguments();
    this.getTripExpiration(this.queryArguments);
  }

  handleFiltersParams(params: Filters) {

    if (params.creditDateFilter != null || params.creditDateFilter != undefined ) {
      this.queryArguments.creditFromDate = params.creditDateFilter[0];
      this.queryArguments.creditToDate = params.creditDateFilter[1];
    }

    if (params.expirationDateFilter != null || params.expirationDateFilter != undefined ) {
      this.queryArguments.expirationFromDate = params.expirationDateFilter[0];
      this.queryArguments.expirationToDate = params.expirationDateFilter[1];
    }

    this.getTripExpiration(this.queryArguments);
    this.queryArguments.page = 1;
    this.isSearch = true;
  }

  onFullNameKeyup($event: any) {
    this.isSearch = true;
    this.queryArguments.page = 1;
    this.queryArguments.search = $event.target.value;
    this.filterDebounce.next(this.queryArguments);
  }

  onSearchClick() {
    this.isSearch = true;
    this.getTripExpiration(this.queryArguments);
  }

}
