import { EventEmitterService } from '@services/event-emitter.service';
import { Component, Input, OnInit } from '@angular/core';
import { MyContractService } from '@services/mycontract.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup,FormBuilder, Validators} from '@angular/forms';
import { UserEditDTO } from '../../../mycontract/dtos/user.dto';
import {finalize, lastValueFrom} from 'rxjs';
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';

@Component({
  selector: 'app-user-edit-modal',
  templateUrl: './user-edit-modal.component.html',
  styleUrls: ['./user-edit-modal.component.scss']
})
export class UserEditModalComponent extends AbstractConfigurationBase implements OnInit {


  constructor(private myContractService: MyContractService,
              private bsModalRef: BsModalRef,
              private formBuilder : FormBuilder,
              )
  { super() }

  @Input() user;
  @Input() profileList;
  @Input() isEdit;
  message: string;
  public UserInfos;
  public UserData;
  isLoading: boolean = false;
  edit: boolean = false;
  public editUserForm: FormGroup;
  submitted:boolean=false;
  public form;
  protected emailExists: boolean = false;
  protected emailExisted: string = '';
  step: number;
  titleModal: string;
  subTitleModal: string;
  emailAddress: string;
  groupIdOld: number;
  public profileSelected;
  userEdit: UserEditDTO;
  emailChanged: boolean=false;
  public resultEdit;

  ngOnInit() {
    this.UserInfos = {id: 0, usersCollection: {}};
    this.isLoading = true;
    this.step = 1;
    this.titleModal = "DADOS DO USUÁRIO";
    this.subTitleModal = "Confira se os dados estão corretos e confirme";
    this.ShowInfoUser(this.isEdit);
  }

  createForm(isEdit){
    var isDisableEmail = true;
    if (this.UserData.statusUsuarioId == 1 && isEdit)
    {
      isDisableEmail = false;
    }

    if( this.UserInfos?.usersCollection?.emailAddress)
      this.emailExisted = this.UserInfos.usersCollection.emailAddress

    this.editUserForm = this.formBuilder.group({
      emailAddress: [{value: this.UserInfos.usersCollection.emailAddress, disabled: isDisableEmail},[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      profile: [{value: this.UserData.grupoId, disabled: !isEdit}],
      protocol: [null],
      reason: [null],

    });

    if( isEdit && this.isBackOfficeProfile()){
      this.editUserForm.get('protocol').setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.editUserForm.get('protocol').updateValueAndValidity();
      this.editUserForm.get('reason').setValidators([Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
      this.editUserForm.get('reason').updateValueAndValidity();
    }

      this.emailAddress = this.UserInfos.usersCollection.emailAddress;
    this.groupIdOld = this.UserData.grupoId;
  }

  ShowInfoUser(isEdit){
    this.UserData=this.user;
    this.myContractService.getPerson(this.user.id)
    .subscribe(
       (success) => {
          this.UserInfos = success;
          this.createForm(isEdit);
          if (isEdit){ this.edit = true; this.titleModal = "EDITAR DADOS DO USUÁRIO";}
          this.isLoading = false;
        },
       (error) =>{
         console.log(error)
        }
    )
  }

  editUser(){
    this.edit = true;
    if (this.UserData.statusUsuarioId == 1)
    {
      this.editUserForm.controls['emailAddress'].enable();
    }
    this.editUserForm.controls['profile'].enable();
    this.titleModal = "EDITAR DADOS DO USUÁRIO";
  }


  private async verifyExistEmail(email: string, documentNumber: string): Promise<boolean> | null {
    return await lastValueFrom(this.myContractService.verifyEmail(email, documentNumber));
  }

  private async firstStepValidation(email: string, documentNumber: string): Promise<boolean> | null
  {
    let isValid: boolean = false;

    if (this.UserInfos.usersCollection.emailAddress !== email)
    {
      isValid = await this.verifyExistEmail(email, documentNumber);
    }

    return isValid;
  }

  public async confirmEmail()
  {
    this.isLoading = true;
    this.form = this.editUserForm.value;
    this.emailAddress = this.UserInfos.usersCollection.emailAddress;

    if (this.form.emailAddress != null && this.emailAddress != this.form.emailAddress)
    {
      this.emailExists = await this.firstStepValidation(this.form.emailAddress, this.form.documentNumber);
    }

    if (this.form.emailAddress == null)
    {
      this.form.emailAddress = this.UserInfos.usersCollection.emailAddress;
    }

    this.confirmUser(this.emailExists);
  }

  confirmUser(isValid: boolean): void {
    this.step = 2;
    this.titleModal = "EDITAR DADOS DO USUÁRIO";
    this.profileSelected = this.profileList.find(x => x.id == this.form.profile);

    if (!isValid) {
      this.emailAddress = this.form.emailAddress;
      this.emailChanged = isValid;
    } else {
      this.emailExisted = this.form.emailAddress;
      this.step = 1;
      this.titleModal = "DADOS DO USUÁRIO";
    }

    this.userEdit = {
      email: this.emailAddress,
      groupId: this.profileSelected.id,
      groupIdOld: this.groupIdOld,
      personId: this.UserInfos.usersCollection.id
    };

    this.isLoading = false;
  }

  saveUser()
  {
    this.isLoading = true;
    this.edit = false;
    this.myContractService
    .saveEditUser(this.userEdit)
    .pipe(
      finalize(() => {
        this.titleModal = "EDIÇÃO CONCLUÍDA";
        this.subTitleModal = "Alteração realizada com sucesso";
        this.isLoading = false;
      })
    )
    .subscribe(
      (success: any) => {
        this.resultEdit = success.data;
      },
      (error) => {
        console.error("getFromServerError", error);
        return;
      }
    );
  }

  close() {
    this.bsModalRef.hide();
    if(this.step == 2){
      EventEmitterService.get('updateList').emit();
   }
  }

  /**
   * A cada evento de input deve veriicar se o email informado é o mesmo que já foi verificado
   * @param value
   */
  changeEmailAddress(value): void {
    this.emailExists = value && (this.emailExisted === value?.value);
  }

  protected isBackOfficeProfile(): boolean {
    return this.userProfileService.isBackOffice();
  }

  protected checkRoles(role: string): boolean {
    return this.authService.checkRoles(role);
  }

  protected getDataUserEdit(): any {
    return {
      fullName: this.UserInfos.usersCollection.fullName,
      description: this.profileSelected.description,
      subTitle: this.profileSelected.subTitle,
      documentNumber: this.UserInfos.usersCollection.documentNumber,
      dateofBirth: this.UserInfos.usersCollection.dateofBirth,
      countryCode: this.UserInfos.usersCollection.countryCode,
      prefix: this.UserInfos.usersCollection.prefix,
      telephoneNumber: this.UserInfos.usersCollection.telephoneNumber,
      email: this.emailAddress,
      statusUsuarioDescription: this.UserData.statusUsuarioDescription,
      protocol: this.form.protocol,
      reason: this.form.reason,
    };
  }

  validateFormReason() : boolean {
    return ((this.editUserForm.controls['reason']?.invalid &&
        this.editUserForm.controls['reason']?.touched) ||
        (this.submitted && this.editUserForm.controls['reason']?.invalid))
  }
  validateFormProtocol() : boolean {
   return ((this.editUserForm.controls['protocol']?.invalid &&
        this.editUserForm.controls['protocol']?.touched) ||
        (this.submitted && this.editUserForm.controls['protocol']?.invalid))

 }
  validateFormEmail() : boolean{
    return (((this.editUserForm.controls['emailAddress']?.invalid && this.editUserForm.controls['emailAddress']?.touched)
    || (this.submitted && this.editUserForm.controls['emailAddress']?.invalid)) && !this.emailExists)
  }

 disableButton(){
    return (this.editUserForm.invalid && !this.emailExists)
  }
}
