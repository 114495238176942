<main class="tripExpiratedComponent">
  <div class="container header-page">
    <h1 data-e2e="page-title" translate>Expired Toll Voucher Trips</h1>
  </div>

  <p class="sub-title">Consulte as viagens de vale-pedágio que foram expiradas há mais de 90 dias e possuem saldo residual</p>

  <section class="table-card">
    <div class="table-header">
      <div class="table-header-left">
        <app-filters
          [hasCreditDateFilter]="true"
          [hasExpirationDateFilter]="true"
          (filtersParams)="handleFiltersParams($event)"
          (resetParams)="resetFilters()"
        >
        </app-filters>

        <div class="label-select">
          <label for="select-input">Exibição</label>
          <select
            id="select-input"
            name="perPages"
            data-e2e="perPages"
            (change)="onPageSizeChange($event)"
          >
            <option *ngFor="let item of perPages" [value]="item">
              {{ item + ' linhas' }}
            </option>
          </select>
          <img src="../../assets/img/arrow-select.svg" alt="arrow-select" />
        </div>

        <div class="input-search">
          <input id="search" type="text" (keyup)="onFullNameKeyup($event)" data-e2e="search-vehicle-input" placeholder="Embarcador, transportador ou viagem" [value]="" />
          <button id="searchButton" data-e2e="search-vehicle-btn" class="btn btn-search" (click)="onSearchClick()">
            <img src="assets/img/icon-search.svg" class="button-icon" />
            <span data-e2e="search-vehicle-description">Buscar</span>
          </button>
        </div>

      </div>


      <div class="download-button">
        <button
          [disabled]="noFiles"
          class="btn btn-primary"
          translate
          data-e2e="export-btn"
          (click)="Download()"
        >
          Exportar CSV

          <img class="button-icon" src="../../assets/img/ico-download.svg" />
        </button>
      </div>
    </div>
    <div class="table-main">
      <table class="table">
        <thead class="thead">
          <tr class="table-group">
            <th class="header-table" colspan="6">
              <div>Dados da viagem</div>
            </th>
            <th class="header-table" colspan="2">
              <div>Dados do Transportador</div>
            </th>
            <th class="header-table" colspan="1">
              <div>Dados do Embarcador</div>
            </th>
          </tr>
          <tr class="table-heading">
            <th [ngClass]="bindOrderByClass('tripCode')">
              <div class="btn-order-table">
                Viagem
              </div>
              <div class="round-order" (click)="onOrderByChange('tripCode')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('routeType')">
              <div class="btn-order-table">
                Tipo de Rota
              </div>
              <div class="round-order" (click)="onOrderByChange('routeType')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('creditDate')">
              <div class="btn-order-table">
                Crédito
              </div>
              <div class="round-order" (click)="onOrderByChange('creditDate')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('amount')">
              <div class="btn-order-table">
                Valor Creditado
              </div>
              <div class="round-order" (click)="onOrderByChange('amount')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('tripExpirationDate')">
              <div class="btn-order-table">
                Expiração
              </div>
              <div class="round-order" (click)="onOrderByChange('tripExpirationDate')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('balanceExpirated')">
              <div class="btn-order-table">
                Valor Expirado
              </div>
              <div class="round-order" (click)="onOrderByChange('balanceExpirated')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('contractId')">
              <div class="btn-order-table">
                Contrato Cliente
              </div>
              <div class="round-order" (click)="onOrderByChange('contractId')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('documentNumber')">
              <div class="btn-order-table">
                CNPJ Cliente
              </div>
              <div class="round-order" (click)="onOrderByChange('documentNumber')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
            <th [ngClass]="bindOrderByClass('customerDocument')">
              <div class="btn-order-table">
                CNPJ Embarcador
              </div>
              <div class="round-order" (click)="onOrderByChange('customerDocument')">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="!isLoading && DtoTransactions?.totalItems == 0">
          <tr>
            <td colspan="9">
              <img src="assets/svg/warning-blue.svg" class="empty-search-warning" alt="warning">
              <p class="empty-search-title" translate>No trips found</p>
              <p *ngIf="isSearch" class="empty-search-msg" translate>Please check that you typed it correctly or try again with another valid information.</p>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="!isLoading && DtoTransactions.totalItems !== 0">
          <tr *ngFor="let transaction of DtoTransactions.data; let i = index">
            <td [attr.data-e2e]="">
              {{ transaction?.tripCode }}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.routeType }}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.creditDate }}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.amount | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.tripExpirationDate }}
            </td>

            <td [attr.data-e2e]="">
              {{transaction?.balanceExpirated | currency: 'BRL':'symbol':'0.2-2':'pt-BR'}}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.contractId }}
            </td>

            <td [attr.data-e2e]="">
              {{  transaction?.documentNumber | mask : "00.000.000/0000-00" }}
            </td>

            <td [attr.data-e2e]="">
              {{  transaction?.customerDocument | mask : "00.000.000/0000-00" }}
            </td>

          </tr>
        </tbody>
      </table>

      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ message | translate }}"
        ></app-loader>
      </div>
    </div>

    <div class="pagination-custom"  *ngIf="
    DtoTransactions?.totalItems > queryArguments.pageSize && !isLoading
    ">
      <ngb-pagination
        (pageChange)="onPaginationChange($event)"
        [pageSize]="queryArguments.pageSize"
        [maxSize]="5"
        [collectionSize]="DtoTransactions?.totalItems"
        [(page)]="queryArguments.page"
        [rotate]="true"
        [boundaryLinks]="true"
        [ellipses]="false"
        display="dynamic"
      >
      </ngb-pagination>
    </div>
  </section>
</main>
