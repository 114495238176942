<div class="confirmationComponent">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">Cancelamento de tag</h4>
    <a class="btn-close" (click)="dismissModal()">
      <img src="assets/svg/close.svg"  alt=""/>
    </a>
  </div>

  <div *ngIf="!isLoading">
    <!-- Casos sem VPR-->
    <div
      class="modal-body"
      *ngIf="balance <= 0 && cancelTagForm.value.cancelTag == 1"
    >
      <p class="main-message">
        Você está prestes a realizar o cancelamento da tag vinculada ao veículo
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}, que será<span
          class="maintain"
        >
          mantido na frota do contrato.</span
        >
      </p>
      <span class="sub-message"
        >Ao confirmar, a tag será cancelada e o veículo permanecerá cadastrado
        na frota do contrato.</span
      >
    </div>

    <div
      class="modal-body"
      *ngIf="balance <= 0 && cancelTagForm.value.cancelTag == 2"
    >
      <p class="main-message">
        Você está prestes a realizar o cancelamento da tag vinculada ao veículo
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}, que será<span
          class="exclude"
        >
          excluído da frota do contrato.</span
        >
      </p>
      <span class="sub-message"
        >Ao confirmar, a tag será cancelada e o veículo será excluído da frota
        do contrato.</span
      >
    </div>

    <!-- Casos com transferencia -->
    <div
      class="modal-body"
      *ngIf="balance > 0 && cancelTagForm.value.cancelTag == 1"
    >
      <p class="main-message">
        Você está prestes a realizar o cancelamento da tag vinculada ao veículo
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}, que será<span
          class="maintain"
        >
          mantido na frota do contrato.</span
        >
      </p>
      <span class="sub-message"
        >Ao confirmar, a tag será cancelada e o saldo residual de
        <b>{{ balance | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR' }}</b>
        será transferido ao veículo
        <b>{{ vehicleToReceiveVPR.licensePlate | mask: 'SSS-0A00' }}</b
        >.</span
      >
    </div>

    <div
      class="modal-body"
      *ngIf="balance > 0 && cancelTagForm.value.cancelTag == 2"
    >
      <p class="main-message">
        Você está prestes a realizar o cancelamento da tag vinculada ao veículo
        {{ vehicle.licensePlate | mask: 'SSS-0A00' }}, que será<span
          class="exclude"
        >
          excluído da frota do contrato.</span
        >
      </p>
      <span class="sub-message"
        >Ao confirmar, a tag será cancelada e o saldo residual de
        <b>{{ balance | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR' }}</b>
        será transferido ao veículo
        <b>{{ vehicleToReceiveVPR.licensePlate | mask: 'SSS-0A00' }}</b
        >.</span
      >
    </div>
  </div>

  <div class="modal-loader" *ngIf="isLoading">
    <app-loader
      [isLoading]="isLoading"
      message="{{ 'Loading...' | translate }}"
    >
    </app-loader>
  </div>

  <div class="modal-footer mt-4">
    <button
      class="btn btn-outlined"
      [disabled]="isLoading"
      (click)="previous()"
      translate
    >
      Voltar
    </button>
    <button
      class="btn btn-secondary"
      [disabled]="isLoading"
      (click)="confirm()"
    >
      Confirmar
    </button>
  </div>
</div>
