<card-model>
  <ng-container *ngIf="totalCosts$ | async as totalCosts">
    <header class="card-model-header">
      <h1>Gasto Total da Frota</h1>
    </header>

    <section class="card-model-container">
      <div class="action-center">
        <fieldset>
          <label for="expediture-filter">Período</label>
          <select id="expediture-filter" class="toll-select">
            <option value="" selected>Últimos 3 meses</option>
          </select>
        </fieldset>

        <button
          type="button"
          btn-export
          [service]="'totalCosts'"
          [filters]="filter"
        ></button>
      </div>

      <ng-container *ngIf="totalCosts.labels.length != 0; else empty">
        <charts
          type="doughnut"
          labelPosition="right"
          [data]="totalCosts"
        ></charts>
      </ng-container>
    </section>
  </ng-container>
</card-model>

<ng-template #empty>
  <p class="empty-msg">
    Nenhuma informação encontrada para o período selecionado.
  </p>
</ng-template>
