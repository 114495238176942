<div class="content-page">
  <div class="container-fluid page-myaccount">
    <div class="row">
      <div class="col-12 Dashboard_Overview header-page">
        <div class="d-flex bd-highlight mb-3">
          <div class="mr-auto p-2 bd-highlight">
            <h1 data-e2e="page-title">/ SALDO DOS VEÍCULOS</h1>
          </div>

          <div
            class="p-2 bd-highligh col-4 mr-3"
            *ngIf="getVehiclesBalanceVPRQueryResult.hasGreatherThanZero == true"
          >
            <form class="input-search">
              <input
                data-e2e="search-input"
                id="search"
                type="text"
                placeholder="placa, apelido, tipo..."
                (keyup)="onFullNameKeyup($event)"
                [value]="getVehiclesBalanceVPRQueryArguments.search"
              />

              <button
                id="searchButton"
                type="button"
                data-e2e="search-btn"
                class="btn btn-search"
                (click)="onSearch($event)"
              >
                <img src="assets/img/icon-search.svg" class="button-icon" />
                <span>Buscar</span>
              </button>
            </form>
          </div>
        </div>
      </div>

      <div class="container col-md-12 mt-5">
        <div
          class=""
          *ngIf="
            getVehiclesBalanceVPRQueryResult.total == 0 &&
            fromSearchBar === false
          "
        >
          <div colspan="12" class="text-center w-100 py-4" translate>
            <div class="col-sm-auto box-nonevehicle">
              <span data-e2e="empty-balance-search-msg"
                >NÃO EXISTE SALDO DE VALE-PEDÁGIO <br />
                PARA NENHUM DOS SEUS VEÍCULOS</span
              >
              <img src="../../../assets/svg/Path 2708.svg" />
              <img src="../../../assets/svg/maquina-de-construcao (1).svg" />
            </div>
          </div>
        </div>

        <div class="card">
          <div
            class="card-body pt-0"
            *ngIf="getVehiclesBalanceVPRQueryResult.hasGreatherThanZero == true"
          >
            <section class="title align-items-center action-center">
              <div class="action-center--left">
                <form novalidate class="p-4">
                  <div class="form-group select-table">
                    <label data-e2e="page-size-label" translate
                      >Quantidade de linhas</label
                    >
                    <select
                      data-e2e="page-size-select"
                      class="form-control form-select"
                      (change)="onPageSizeChange($event)"
                    >
                      <option
                        [attr.data-e2e]="'page-size-option-' + index"
                        *ngFor="let i of perPages; let index = index"
                        value="{{ i }}"
                      >
                        exibir {{ i }} linhas
                      </option>
                    </select>
                  </div>
                </form>

                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    [checked]="false"
                    class="custom-control-input c-pointer"
                    id="checkboxSaldoMaiorZero"
                    data-e2e="positive-balance-filter-checkbox"
                    (change)="onBuscarSaldoMaiorZero($event.target.checked)"
                  />
                  <label
                    class="custom-control-label"
                    for="checkboxSaldoMaiorZero"
                  >
                    <p
                      data-e2e="positive-balance-filter-label"
                      class="ml-2 subtitle font-weight-bold c-pointer"
                    >
                      apenas saldo maior que zero
                    </p>
                  </label>
                </div>
              </div>

              <button
                type="button"
                btn-export
                [service]="'vehicleBalance'"
                [filters]="csvFilters"
              ></button>
            </section>

            <main class="px-4">
              <!-- // TODO: Verificar a possibilidade de separar essa table em um componente para ficar mais organizada -->
              <table class="table vehicles">
                <thead class="thead">
                  <tr>
                    <th [ngClass]="bindOrderByClass('licensePlate')">
                      <button
                        data-e2e="sort-plate-btn"
                        class="btn-order-table"
                        translate
                        (click)="onOrderByChange('licensePlate')"
                      >
                        Placa
                      </button>
                      <div
                        class="round-order"
                        (click)="onOrderByChange('licensePlate')"
                      >
                        <img src="../../../assets/svg/order-table.svg" alt="" />
                      </div>
                    </th>
                    <th [ngClass]="bindOrderByClass('nickName')">
                      <button
                        data-e2e="sort-nickname-btn"
                        class="btn-order-table"
                        translate
                        (click)="onOrderByChange('nickName')"
                      >
                        Apelido
                      </button>
                      <div
                        class="round-order"
                        (click)="onOrderByChange('nickName')"
                      >
                        <img src="../../../assets/svg/order-table.svg" alt="" />
                      </div>
                    </th>
                    <th [ngClass]="bindOrderByClass('vehicleType')">
                      <button
                        data-e2e="sort-vehicle-type-btn"
                        class="btn-order-table"
                        translate
                        (click)="onOrderByChange('vehicleType')"
                      >
                        Tipo de veículo
                      </button>
                      <div
                        class="round-order"
                        (click)="onOrderByChange('vehicleType')"
                      >
                        <img src="../../../assets/svg/order-table.svg" alt="" />
                      </div>
                    </th>
                    <th [ngClass]="bindOrderByClass('accountBalance')">
                      <button
                        data-e2e="sort-account-name-btn"
                        class="btn-order-table"
                        translate
                        (click)="onOrderByChange('accountBalance')"
                      >
                        Saldo atual de vale pedágio
                      </button>
                      <div
                        class="round-order"
                        (click)="onOrderByChange('accountBalance')"
                      >
                        <img src="../../../assets/svg/order-table.svg" alt="" />
                      </div>
                    </th>
                  </tr>
                </thead>

                <div class="container-loading" *ngIf="isLoading">
                  <app-loader
                    [isLoading]="isLoading"
                    message="{{ 'Carregando...' }}"
                  ></app-loader>
                </div>

                <tbody>
                  <ng-container
                    *ngIf="
                      getVehiclesBalanceVPRQueryResult.items.length > 0;
                      else empty
                    "
                  >
                    <tr
                      *ngFor="
                        let item of getVehiclesBalanceVPRQueryResult.items;
                        let i = index
                      "
                    >
                      <td class="table__data">
                        <ng-template #actionPopover placement="right"
                        [outsideClick]="true">
                          <div class="row my-1">
                            <div
                              [attr.data-e2e]="'popover-plate-label-' + i"
                              class="head-info"
                              translate
                            >
                              Placa:
                            </div>
                            <div
                              [attr.data-e2e]="'popover-plate-' + i"
                              class="content-info"
                              id="popoverLicensePlate{{ i }}"
                            >
                              {{ item?.licensePlate | mask : "AAA-AAAA" }}
                            </div>
                          </div>

                          <div class="row my-1">
                            <div
                              [attr.data-e2e]="'popover-nickname-label-' + i"
                              class="head-info"
                              translate
                            >
                              Apelido:
                            </div>
                            <div
                              [attr.data-e2e]="'popover-nickname-' + i"
                              class="content-info"
                              id="popoverNickname{{ i }}"
                            >
                              {{ item?.nickname }}
                            </div>
                          </div>

                          <div class="row my-1">
                            <div
                              [attr.data-e2e]="'popover-tag-label-' + i"
                              class="head-info"
                              translate
                            >
                              Tag:
                            </div>
                            <div
                              [attr.data-e2e]="'popover-tag-' + i"
                              class="content-info"
                              id="popoverTag{{ i }}"
                            >
                              {{ item?.tag }}
                            </div>
                          </div>

                          <div class="row my-1">
                            <div
                              [attr.data-e2e]="
                                'popover-vehicle-type-label-' + i
                              "
                              class="head-info"
                              translate
                            >
                              Tipo:
                            </div>
                            <div
                              [attr.data-e2e]="'popover-vehicle-type-' + i"
                              class="content-info"
                              id="popoverVehicleType{{ i }}"
                            >
                              {{ item?.vehicleType }}
                            </div>
                          </div>

                          <div class="row my-1">
                            <div
                              [attr.data-e2e]="
                                'popover-vehicle-brand-label-' + i
                              "
                              class="head-info"
                              translate
                            >
                              Marca:
                            </div>
                            <div
                              [attr.data-e2e]="'popover-vehicle-brand-' + i"
                              class="content-info"
                              id="popoverBrand{{ i }}"
                            >
                              {{ item?.brand }}
                            </div>
                          </div>

                          <div class="row my-1">
                            <div
                              [attr.data-e2e]="
                                'popover-vehicle-model-label-' + i
                              "
                              class="head-info"
                              translate
                            >
                              Modelo:
                            </div>
                            <div
                              [attr.data-e2e]="'popover-vehicle-model-' + i"
                              class="content-info"
                              id="popoverModel{{ i }}"
                            >
                              {{ item?.model }}
                            </div>
                          </div>

                          <div class="row my-1">
                            <div
                              [attr.data-e2e]="
                                'popover-vehicle-axel-label-' + i
                              "
                              class="head-info"
                              translate
                            >
                              Nº de eixos:
                            </div>
                            <div
                              [attr.data-e2e]="'popover-vehicle-axel-' + i"
                              class="content-info"
                              id="popoverAxleWheelType{{ i }}"
                            >
                              {{ item?.axleWheelType }}
                            </div>
                          </div>
                        </ng-template>

                        <button
                        [attr.data-e2e]="'action-popover-btn-' + i"
                        type="button"
                        class="btn-popover"
                        [popover]="actionPopover"
                        [outsideClick]="true"
                        [adaptivePosition]="true"
                        placement="right">
                          <img
                            src="../../../assets/svg/icon-popover.svg"
                            alt=""
                          />
                        </button>

                        <span
                          [attr.data-e2e]="'vehicle-plate-' + i"
                          id="licensePlate{{ i }}"
                          >{{ item?.licensePlate | mask : "AAA-AAAA" }}</span
                        >
                      </td>
                      <td
                        class="table__data"
                        [attr.data-e2e]="'vehicle-nickname-' + i"
                        id="nickname{{ i }}"
                      >
                        {{ item?.nickname }}
                      </td>
                      <td
                        class="table__data"
                        [attr.data-e2e]="'vehicle-type-' + i"
                        id="vehicleType{{ i }}"
                      >
                        {{ item?.vehicleType }}
                      </td>
                      <td
                        class="table__data"
                        [attr.data-e2e]="'account-balance-' + i"
                        class="p1 text-right"
                        id="accountBalance{{ i }}"
                      >
                        {{
                          item?.accountBalance
                            | currency : "BRL" : "symbol" : "0.2-2" : "pt-BR"
                        }}
                      </td>
                    </tr>
                  </ng-container>

                  <ng-template #empty>
                    <tr>
                      <td colspan="4" class="font-weight-bold">
                        Não encontramos nenhum veículo com o dado informado. Por
                        favor, verifique se digitou corretamente ou tente
                        novamente com outra informação válida.
                      </td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>

              <div
                class="pagination-custom"
                *ngIf="getVehiclesBalanceVPRQueryResult.total != 0"
              >
                <app-pagination
                  [totalItems]="getVehiclesBalanceVPRQueryResult.total"
                  [itemsPerPage]="getVehiclesBalanceVPRQueryArguments.pageSize"
                  [maxPages]="5"
                  [currentPage]="getVehiclesBalanceVPRQueryArguments.page"
                  (pageChangeEvent)="onPaginationChange($event)"
                ></app-pagination>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
