import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class GTMMonitorService {

  constructor(private gtmService: GoogleTagManagerService) { }

  postGoogleTagManager(s, idUser): void {
    if (s) {
      const gtmTag = {
        event: 'page',
        pageName: s,
        userId: idUser,
        gtm: {userId: idUser}
      };
      this.gtmService.pushTag(gtmTag);
    }
  }

  postLoginGoogleTagManager(idUser): void {
    if (idUser) {
      const gtmTag = {
        event: 'login',
        userId: idUser,
      };
      this.gtmService.pushTag(gtmTag);
    }
  }

}
