<div class="login">
  <div class="logoVPImage">
    <img src="assets/img/gestao_de_pedagio.png" />
  </div>
  <div class="BGLogin">
    <img src="assets/svg/BG-Login.svg" />
  </div>
  <div class="logos row">
    <div class="edenred col-sm-auto"> <img src="assets/svg/logo-edenred.svg" /></div>
    <div class="ticketLog col-sm-auto"><img src="assets/svg/logo-ticket-log.svg" /></div>
    <div class="repom col-sm-auto"><img src="assets/svg/logo-repom.svg" /></div>
  </div>

  <div class="Login_BoxForm">
    <div class="BoxForm_Content">

      <div class="BoxForm_Title">
        <img src="assets/img/ico-forgot-password.svg">
        <p data-e2e="page-title">Esqueci minha senha</p>
      </div>
      <div class="BoxForm_Form box-margin-top">
        <form action="dashboard.html">
          <div class="input-group mb-3">
            <p data-e2e="user-label" class="Form_TitleInput">Usuário</p>
            <div class="input-group-prepend Form_Prepend">
              <span class="input-group-text"><img src="assets/svg/icon_login_user.svg" class="IconInput"></span>
            </div>
            <input data-e2e="user-input" type="text" class="form-control FormInput_Usuario" placeholder="" aria-label="Username"
              aria-describedby="basic-addon1">
          </div>

          <div class="row">
            <div class="col-lg-12 col-captcha">
              <label data-e2e="captcha-label" class="captcha-label" for="">Digite os seis caracteres  DA imagem:</label>
            </div>
            <div class="col-lg-6">
              <div class="form-group form-icon">
                <label
                  data-e2e="insert-label"
                  for="formNumberOrderInput">INSIRA</label>
                <input
                  data-e2e="number-input"
                  id="formNumberOrderInput"
                  type="text"
                  class="form-control ico-insert-text">
              </div>
            </div>
          </div>

          <div class="custom-control custom-checkbox StayLoggedBox">
          </div>
          <button data-e2e="send-btn" type="submit" class="btn btn-secundary FormLogin_btn">Enviar</button>
        </form>


        <div class="FormLogin_Divider">
          <p>OU</p>
        </div>


        <div class="Login_ForgotPassword">
          <a data-e2e="back-to-login" href="login" class="Login_btn_ForgotPassword">
            Voltar para o Login
          </a>
        </div>


        <div class="BoxForm_LogoRepomResponsive">
          <img src="assets/svg/footer_logo_repom.svg">
        </div>


      </div>
      <!--BoxForm_Form-->


    </div>
    <!--BoxForm_Content-->
  </div>
  <!--Login_BoxForm-->
  <!-- **************** Login | End **************** -->
</div>
