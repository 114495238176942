import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { BillHeaderDto } from '../../dto/bill-header.dto';
import { ContractModality } from '@models/old/contractModality.enum';
import { BillSummary } from '@models/old/invoices/bill-summary.model';
import { BillDetailService } from './services/bill-detail.service';
import { EventEmitterService } from '@services/event-emitter.service';
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';

@Component({
  selector: 'app-bill-detail',
  templateUrl: './bill-detail.component.html',
  styleUrls: ['./bill-detail.component.scss']
})
export class BillDetailComponent extends AbstractConfigurationBase implements OnInit {
  tabs: string[] = ['general', 'vehicles', 'hierarchy']
  selectedTab = this.tabs[0];
  billSummary: BillSummary;
  billHeader: BillHeaderDto;
  billId: number;
  isLoading: boolean;
  updateAfterContractAlertFeedback = new EventEmitter<any>();
  contractModalityTypeId: ContractModality;

  constructor(
    private billDetail: BillDetailService,
    private actRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnDestroy(){
    this.updateAfterContractAlertFeedback.unsubscribe();
  }

  ngOnInit(): void {
    this.contractModalityTypeId = this.contractService.getContractModalityTypeId();
    this.billId = this.actRoute.snapshot.params.id;
    this.updateAfterContractAlertFeedback = EventEmitterService.get('updateAfterContractAlertFeedback');

    if(this.updateAfterContractAlertFeedback.observers) {
      this.updateAfterContractAlertFeedback.subscribe(() => this.loadBillHeader(this.billId));
    }

    this.loadBillSummary(this.billId);
    this.loadBillHeader(this.billId);
  }

  loadBillSummary(id:number) {
    this.billDetail
      .getBillSummary(id)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (success) => {
          this.billSummary = success;

        },
        (error) => {
          console.error(error);
          return;
        }
      );
  }

  loadBillHeader(id:number) {
    this.isLoading = true;
    this.billDetail
      .getBillHeader(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.billHeader = success;
        },
        (error) => {
          console.error(error);
          return;
        }
      );
  }

}
