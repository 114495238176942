<div class="tag-file-inconsist">
  <section class="modal-header">

    <div class="box-title"><span class="tag-file-inconsist__title">Arquivo com inconsistências</span></div>

    <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss">
      <span aria-hidden="true">×</span>
    </button>
  </section>

  <section class="modal-body">

    @if (isLoading) {

      <div>

        <app-loader [isLoading]="isLoading" message="Download..."></app-loader>

      </div>
    }
    @else {
      <div class="tag-file-inconsist__body">
        <h6 class="tag-file-inconsist__body-title">Arquivo com inconsistências encontradas!</h6>

        @if (inFileData.tagActivated > 0) {
          <p class="tag-file-inconsist__body-data" id="data-number-success" data-e2e="data-number-success">
            <b>{{ inFileData.tagActivated }}</b> tags foram ativadas com sucesso!
          </p>
        }

        <p class="tag-file-inconsist__body-data" id="data-number-errors" data-e2e="data-number-errors"><b>{{ inFileData.fileIncosistences }}</b> inconsistências foram encontradas no arquivo.</p>

        <p class="tag-file-inconsist__body-action-text">Por favor, baixe e revise o arquivo com as inconsistências e importe novamente:</p>

        <div class="tag-file-inconsist__body-action-buttons">
          <button
            id="btn-download-vehicles-tags"
            data-e2e="btn-download-vehicles-tags"
            class="btn-not-bg-with-border"
            (click)="downloadFileInconsistencies()"
          >
            <img class="btn-not-bg-with-border__icon icon-document-download" alt="Ícone documento para download">
            Baixar arquivo com inconsistências
          </button>

          <button
            id="btn-download-doc-model"
            data-e2e="btn-download-doc-model"
            class="btn-bg-color-blue"
            (click)="redirectUploadModal()"
            [disabled]="!inHasTagsAndVehiclesAvaliable"
          >
            <img class="btn-bg-color-blue__icon icon-document-upload" alt="Ícone documento para upload">
            Importar novo documento
          </button>
        </div>
      </div>
    }
  </section>

  <section class="modal-footer modal-footer-gray">
    <button
        class="btn btn-secondary"
        id="modal-btn-close-below"
        data-e2e="modal-btn-close-below"
        type="button"
        (click)="dismissModal()"
      >
        Fechar
      </button>
  </section>
</div>
