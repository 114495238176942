<div class="changePasswordModalComponent">
    <div class="modal-content">
        <form class="form" [formGroup]="passwordForm" (ngSubmit)="confirmPasswordChange()">
                <div class="modal-header">
                <div class="box-title">
                    <span *ngIf="step==1" data-e2e="modal-title">Alterar Senha</span>
                    <span *ngIf="step==2" data-e2e="modal-title">Senha alterada com sucesso</span>                                   
                </div>
                <button data-e2e="close-modal-top-btn" type="button" class="close" (click)="close()">
                    <span aria-hidden="true">×</span>
                </button>
                </div>
                <div class="modal-body show" *ngIf="step == 1">
                    <div class="password-form-message">Insira sua senha atual e cadastre uma nova senha</div>
                    <div class="form-content">
                            <div class="form-field">
                                <label for="currentPassword" data-e2e="current-password-label">Senha Atual</label>
                                <div class="form-input">
                                    <input
                                    #currentPassword
                                    (keyup)="returnedValidation.incorrectPassword.status = false"
                                    [ngClass]="(passwordForm.controls['currentPassword']?.invalid && passwordForm.controls['currentPassword']?.touched)
                                    || (submitted && passwordForm.controls['currentPassword']?.invalid) ? 'invalidField' : ''"
                                    class="currentPasswordInput"
                                    data-e2e="current-password-input"
                                    id="currentPassword"
                                    [type]="showPassword[0] ? 'text' : 'password'"
                                    formControlName="currentPassword">
                                    <div class="alert-error-validator" data-e2e="current-password-error-msg"
                                    *ngIf="(passwordForm.controls['currentPassword']?.invalid && passwordForm.controls['currentPassword']?.touched)
                                    || (submitted && passwordForm.controls['currentPassword']?.invalid) || returnedValidation.incorrectPassword.status">
                                    {{ generateErrorMessage(passwordForm.controls['currentPassword'], returnedValidation.incorrectPassword)}}
                                    </div>
                                    <img class="visibility-change-icon" *ngIf="!showPassword[0]" (click)="showPassword[0] = !showPassword[0]" src="../../../assets/img/show-password.png" />
                                    <img class="visibility-change-icon" *ngIf="showPassword[0]" (click)="showPassword[0] = !showPassword[0]" src="../../../assets/img/hide-password.png" />
                                </div>
                            </div>
                            <div class="password-insert-info">Sua nova senha deve ser diferente das suas 10 últimas senhas cadastradas</div>
                            <div class="form-field">
                                <label for="newPassword" data-e2e="new-password-label">Nova senha</label>
                                <div class="form-input">
                                    <input
                                    #newPassword
                                    (keyup)="returnedValidation.repeatedPassword.status = false"
                                    [ngClass]="(passwordForm.controls['newPassword']?.invalid && passwordForm.controls['newPassword']?.touched)
                                    || (submitted && passwordForm.controls['newPassword']?.invalid) ? 'invalidField' : ''"
                                    class="newPasswordInput"
                                    data-e2e="new-password-input"
                                    id="newPassword"
                                    [type]="showPassword[1] ? 'text' : 'password'"
                                    formControlName="newPassword">
                                    <div class="alert-error-validator" data-e2e="new-password-error-msg"
                                    *ngIf="(passwordForm.controls['newPassword']?.invalid && passwordForm.controls['newPassword']?.touched)
                                    || (submitted && passwordForm.controls['newPassword']?.invalid) || returnedValidation.repeatedPassword.status">
                                    {{ generateErrorMessage(passwordForm.controls['newPassword'], returnedValidation.repeatedPassword)}}
                                    </div>
                                    <img class="visibility-change-icon" *ngIf="!showPassword[1]" (click)="showPassword[1] = !showPassword[1]" src="../../../assets/img/show-password.png" />
                                    <img class="visibility-change-icon" *ngIf="showPassword[1]" (click)="showPassword[1] = !showPassword[1]" src="../../../assets/img/hide-password.png" />
                                </div>
                            </div>
                            <div class="form-field">
                                <label for="newPasswordConfirmation" data-e2e="new-password-confirmation-label">Confirmar nova senha</label>
                                <div class="form-input">
                                    <input
                                    [ngClass]="(passwordForm.controls['newPasswordConfirmation']?.invalid && passwordForm.controls['newPasswordConfirmation']?.touched)
                                    || (submitted && passwordForm.controls['newPasswordConfirmation']?.invalid) ? 'invalidField' : ''"
                                    class="newPasswordConfirmationInput"
                                    data-e2e="new-password-confirmation-input"
                                    id="newPasswordConfirmation"
                                    [type]="showPassword[2] ? 'text' : 'password'"
                                    formControlName="newPasswordConfirmation">
                                    <div class="alert-error-validator" data-e2e="new-password-confirmation-error-msg"
                                    *ngIf="(passwordForm.controls['newPasswordConfirmation']?.invalid && passwordForm.controls['newPasswordConfirmation']?.touched)
                                    || (submitted && passwordForm.controls['newPasswordConfirmation']?.invalid)">
                                    {{ generateErrorMessage(passwordForm.controls['newPasswordConfirmation'])}}
                                    </div> 
                                    <img class="visibility-change-icon" *ngIf="!showPassword[2]" (click)="showPassword[2] = !showPassword[2]" src="../../../assets/img/show-password.png" />
                                    <img class="visibility-change-icon" *ngIf="showPassword[2]" (click)="showPassword[2] = !showPassword[2]" src="../../../assets/img/hide-password.png" />
                                </div>
                            </div>
                    </div> 
                   <div class="requirements-content">
                     <app-password-requirements></app-password-requirements> 
                   </div>
                </div>  
                <div class="modal-body show" *ngIf="step == 2">
                    <div class="success-img">
                        <img src="../../../assets/img/success-change-password.svg" />
                    </div>
                    <div class="success-message">Sua senha foi alterada com sucesso.<br> Lembre-se de manter suas informações pessoais seguras. Não compartilhe suas senhas ou informações confidenciais com outras pessoas.</div>
                </div>     
                <div class="modal-footer modal-footer-gray" [ngClass]="step==2 ? 'modal-footer-confirmed' : ''">
                    <button *ngIf="step==1" [disabled]="isLoading" data-e2e="cancel-btn" type="button" class="btn btn-tertiary mr-4" (click)="close()" id="btn-close">Cancelar</button>   
                    <button *ngIf="step==1" data-e2e="confirm-btn" type="submit" class="btn btn-secondary position-relative" [disabled]="passwordForm.invalid || isLoading">
                        <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
                         Confirmar
                    </button> 
                    <button *ngIf="step==2" data-e2e="close-btn" type="button" class="btn btn-secondary position-relative" (click)="close()">
                         Fechar
                    </button>               
                </div>
    </form>
    </div>
</div>

