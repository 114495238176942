import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { MyorderRoutingModule } from './routes/myorder-routing.module';
import { MyorderComponent } from './myorder.component';
import { DetailorderComponent } from './components/detailorder/detailorder.component';
import { NeworderComponent } from './components/neworder/neworder.component';
import { TryAgainModalComponent } from './components/try-again-modal/try-again-modal.component';
import { OrderTrackingComponent } from './components/order-tracking/order-tracking.component';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule } from 'src/app/shared/shared.module';
import { PaymentMethodComponent } from 'src/app/shared/components/payment/payment-method/payment-method.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule,
    MyorderRoutingModule,
    NgbModule,
  ],
  declarations: [
    MyorderComponent,
    DetailorderComponent,
    NeworderComponent,
    OrderTrackingComponent,
    TryAgainModalComponent,
    OrderSuccessComponent
  ],
  providers: [DatePipe, provideNgxMask(), PaymentMethodComponent],
})

export class MyorderModule {}
