<div class="simulate-select">
  <img src="assets/img/calendar_select.svg" />

  <span class="simulate-label">{{ title }}</span>
  <input
    data-e2e="date-range-picker-input"
    type="text"
    id="filterDate"
    placeholder="Selecione"
    class="simulate-form-control"
    #dp="bsDaterangepicker"
    bsDaterangepicker
    [bsConfig]="bsConfig"
    [placement]="position"
    [bsValue]="dateValue"
    [maxDate]="maxDate"
    (bsValueChange)="emitFilter($event)"
    [minDate]="minDate"
    [outsideClick]="false"
  />

  <div class="simulate-button" [class.active]="">
    <img src="assets/img/arrow-select.svg" />
  </div>
</div>
