<div class="addVehicleComponent">
  <div class="modal-content">
    <div class="modal-header">
      <div class="box-title">
        <span data-e2e="modal-title">{{ editMode ? "Editar Veículo" : "Novo Veículo" }}</span>
      </div>

      <button
        data-e2e="modal-top-close"
        (click)="dismissModal()"
        class="close"
        type="button"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body show modal-edit-vehicle">
      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="Carregando..."
        ></app-loader>
      </div>

      <form
        (ngSubmit)="onSaveVehicle()"
        [formGroup]="form"
        *ngIf="!isLoading"
      >
        <div class="row row-header mb-4">
          <div class="col-lg-12 desc">
            <p
              [ngClass]="
                editMode
                  ? 'subtitle color-default-font font-weight-bold'
                  : 'subtitle'
              "
            >
              {{
                editMode
                  ? "Alterar os dados do veículo com placa"
                  : " Preencha o formulário para cadastrar um novo veículo"
              }}

              <span *ngIf="editMode" data-e2e="licensePlate-edit-title" id="licensePlate">{{
                vehicle?.licensePlate | mask: "AAA-AAAA"
              }}</span
              >:
            </p>

            <div class="tag-right" *ngIf="vehicle?.tagId && editMode">
              <div class="row">
                <div data-e2e="attached-tag" class="col-lg-12">
                  <p>TAG Vinculada</p>
                  <span class="px12" id="serialTag">{{
                    vehicle?.serialTag
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-2">
            <div class="form-group">
              <label data-e2e="licensePlate-label">Placa*</label>

              <input
                [controlDisabledByCondition]="editMode && hasTagCondition"
                [controlDisabledByPermission]="[101220]"
                id="inputLicencePlate"
                class="form-control text-uppercase"
                formControlName="licensePlate"
                data-e2e="licensePlate-input"
                [disabled]="hasTagCondition"
                type="text"
                value="{{ vehicle?.licensePlate }}"
                maxlength="8"
                mask="AAA-AAAA"
                [class.is-invalid]="
                  form.controls.licensePlate.invalid &&
                  form.controls.licensePlate.touched
                "
              />

              <app-vmessage
                data-e2e="licensePlate-error"
                *ngIf="
                  form.controls.licensePlate.invalid &&
                  form.controls.licensePlate.touched
                "
                errorMessage="Placa obrigatória"
              >
              </app-vmessage>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="form-group">
              <label data-e2e="vehicleType-label">Tipo*</label>
              <select
                (change)="onChangeVehicleType(vehicleType.value)"
                [controlDisabledByPermission]="[101220]"
                class="form-control form-control-lg select form-select"
                formControlName="vehicleType"
                data-e2e="vehicleType-input"
                id="vehicleType"
                #vehicleType
              >
                <option
                  *ngFor="let vehicleType of ListVehicleType"
                  id="vehicleType{{ vehicleType.id }}"
                  value="{{ vehicleType.id }}"
                >
                  {{ vehicleType.descriptionPt }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="form-group">
              <label data-e2e="nickName-label">Apelido</label>
              <input
                [controlDisabledByPermission]="[101220,101221]"
                [class.is-invalid]="
                  form.controls.nickName.invalid &&
                  form.controls.nickName.touched
                "
                value="{{ vehicle?.nickName }}"
                formControlName="nickName"
                data-e2e="nickName-input"
                class="form-control"
                id="nickName"
                maxlength="20"
                type="text"
              />

              <app-vmessage
                data-e2e="nickName-error"
                *ngIf="
                  form.controls.nickName.invalid &&
                  form.controls.nickName.touched
                "
                errorMessage="Apelido obrigatória"
              >
              </app-vmessage>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-5">
            <div class="form-group">
              <label data-e2e="vehicleBrandTypeId-label">Marca* </label>
              <select
                [controlDisabledByPermission]="[101220]"
                class="form-control form-control-lg select form-select"
                formControlName="vehicleBrandTypeId"
                data-e2e="vehicleBrandTypeId-input"
                id="vehicleBrandTypeId"
              >
                <option
                  [selected]="brandType.id === vehicle?.vehicleBrandTypeId"
                  [attr.data-e2e]="'vehicleBrandTypeId-' + brandType.id"
                  id="vehicleBrandTypeId{{ brandType.id }}"
                  *ngFor="let brandType of ListBrandTypes"
                  value="{{ brandType.id }}"
                >
                  {{ brandType.descriptionPt }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="form-group">
              <label data-e2e="model-label">Modelo </label>
              <input
                [controlDisabledByPermission]="[101220,101221]"
                [class.is-invalid]="
                  form.controls.model.invalid &&
                  form.controls.model.touched
                "
                value="{{ vehicle?.model }}"
                formControlName="model"
                data-e2e="model-input"
                class="form-control"
                maxlength="20"
                type="text"
                id="model"
              />

              <app-vmessage
                data-e2e="model-error"
                *ngIf="
                  form.controls.model.invalid &&
                  form.controls.model.touched
                "
                errorMessage="Modelo obrigatória"
              >
              </app-vmessage>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-5 offset-lg-1">
            <div class="form-group">
              <label>Eixos* </label>
              <select
                [controlDisabledByPermission]="[101220]"
                class="form-control form-control-lg select form-select"
                formControlName="vehicleCategoryId"
                data-e2e="vehicleCategoryId-select"
                id="vehicleCategoryId"
              >
                <option value=""></option>
                <option
                  *ngFor="let AxleWheelType of vehicleCategoryAxleWheelTypes"
                  [attr.data-e2e]="'vehicleCategoryId-' + AxleWheelType.id"
                  id="vehicleCategoryId{{ AxleWheelType.id }}"
                  value="{{ AxleWheelType.id }}"
                >
                  {{ AxleWheelType.descriptionPt }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="form-group">
              <label data-e2e="hierarchyId-label" translate>Hierarquia</label>
              <select
                [controlDisabledByPermission]="[101220,101221]"
                class="form-control form-control-lg select form-select"
                formControlName="hierarchyId"
                data-e2e="hierarchyId-select"
                id="hierarchyId"
              >
                <option value="0" id="inputNull" translate>Nenhuma</option>
                <option
                  *ngFor="let hierarchy of hierarchyType"
                  [attr.data-e2e]="'hierarchyId-' + hierarchy.id"
                  id="hierarchyId{{ hierarchy.id }}"
                  value="{{ hierarchy.id }}"
                >
                  {{ hierarchy.description_PT }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <hr />

        <div class="row justify-content-center">
          <div class="col-lg-4">
            <div class="custom-control custom-checkbox">
              <input
                [controlDisabledByCondition]="editMode && hasTagCondition"
                [controlDisabledByPermission]="[101220]"
                [checked]="vehicle?.aggregateDriver"
                formControlName="aggregateDriver"
                data-e2e="aggregateDriver-input"
                id="checkboxMotoristaAgregado"
                class="custom-control-input"
                type="checkbox"
              />

              <label for="checkboxMotoristaAgregado" class="custom-control-label">
                <p data-e2e="aggregateDriver-label" class="subtitle">
                  Motorista é agregado
                </p>
              </label>
            </div>
          </div>
        </div>

        <div
          *ngIf="form.value.aggregateDriver"
          class="row justify-content-center"
        >
          <div class="col-lg-5">
            <div class="form-group">
              <label data-e2e="rntrc-label">RNTRC do Agregado</label>

              @if (hasTagCondition && this.userProfileService.isCustomerOrRelationshipProfile()) {
               <i class="ico-lock"></i>
              }
              <input
                [controlDisabledByCondition]="editMode && hasTagCondition"
                [controlDisabledByPermission]="[101220]"
                value="{{ vehicle?.rntrc }}"
                formControlName="rntrc"
                data-e2e="rntrc-input"
                class="form-control"
                maxlength="10"
                type="text"
                id="rntrc"
              />
            </div>
          </div>

          <div class="col-lg-5">
            <div class="form-group">
              <label data-e2e="documentNumber-label">Cpf/Cnpj do Agregado</label>

              @if (hasTagCondition && this.userProfileService.isCustomerOrRelationshipProfile()) {
                <i class="ico-lock"></i>
              }
              <input
                [controlDisabledByCondition]="editMode && hasTagCondition"
                [controlDisabledByPermission]="[101220]"
                formControlName="documentNumber"
                data-e2e="documentNumber-input"
                class="form-control"
                [mask]="mask"
                (input)="applyMask()"
                id="documentNumber"
                type="text"
              />

              <app-vmessage
                data-e2e="document-error"
                *ngIf="
                  form.controls.documentNumber.invalid &&
                  form.controls.documentNumber.touched
                "
                errorMessage="Documento inválido"
              >
              </app-vmessage>
            </div>

          </div>
        </div>

        <footer class="modal-footer">
          <button
            class="btn btn-tertiary mr-4"
            (click)="dismissModal()"
            data-e2e="cancel-btn"
            id="btnCancel"
            type="button"
          >
            Cancelar
          </button>

          <button
            [disabled]="!(form.valid && form.dirty)"
            class="btn btn-secondary position-relative"
            id="submitFormAddVehicle"
            data-e2e="add-btn"
            type="submit"
          >
            <span *ngIf="!isSaving">{{
              !editMode ? "INCLUIR VEÍCULO" : "ALTERAR VEÍCULO"
            }}</span>

            <span class="submit-btn" *ngIf="isSaving">Carregando...</span>
            <img
              src="../../../assets/img/loader.gif"
              class="loading-btn"
              *ngIf="isLoading"
              alt="Loading"
            />
          </button>
        </footer>
      </form>
    </div>
  </div>
</div>
