import { Component, OnInit, ElementRef, ViewChild, Renderer2, AfterViewChecked } from '@angular/core';
import { finalize, debounceTime } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { GetVehiclesQueryArguments } from './dtos/get-vehicles-query-arguments.dto';
import { GetVehiclesQueryResultDto } from './dtos/get-vehicles-query-result.dto';
import { GetVehicleQueryResultDto } from './dtos/get-vehicle-query-result.dto';
import moment from 'moment';
import { VehiclesDto } from '@models/old/vehicles.model';
import { Vehicle } from '@models/old/vehicle/vehicle.model';
import { VehicleStatistic } from '@models/old/vehicle/vehicleStatistic.model';
import { MyvehiclesService } from './services/myvehicles.service';
import { NotifyService } from '@services/notify.service';
import { EventEmitterService } from '@services/event-emitter.service';
import { AddVehicleComponent } from './components/add-vehicle-modal/add-vehicle.component';
import { ImportVehiclesModalComponent } from './components/import-vehicles-modal/import-vehicles-modal.component';
import { AlterServiceModalComponent } from './components/alter-service-modal/alter-service-modal.component';
import { DeleteVehicleModalComponent } from './components/delete-vehicle-modal/delete-vehicle-modal.component';
import { TagReplaceModalComponent } from './components/tag-replace-modal/tag-replace-modal.component';
import { TagUnlockModalComponent } from './components/tag-unlock-modal/tag-unlock-modal.component';
import { CancelTagComponent } from './components/tag-cancel-modal/cancel-tag.component';
import { TagBlockModalComponent } from './components/tag-block-modal/tag-block-modal.component';
import { ReincludeVehicleModalComponent } from './components/reinclude-vehicle-modal/reinclude-vehicle-modal.component';
import {
  TransferVehicleComponent
} from "@modules/customer/myvehicles/components/transfer-vehicle/transfer-vehicle.component";
import { contractVehicleTagStatusTypeId } from "@models/backoffice/vehicles-backoffice.dto";
import {
  CancelTagBackOfficeComponent
} from "@modules/customer/myvehicles/components/cancel-tag-backoffice/cancel-tag-backoffice.component";
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";
import { ActivatedRoute, Router } from "@angular/router";
import { TOOLTIPS } from './tooltips-vehicles/tooltips.constants';
import { LocalstorageService } from '@services/localstorage.service';

@Component({
  selector: 'app-myvehicles',
  templateUrl: './myvehicles.component.html',
  styleUrls: ['./myvehicles.component.scss']
})
export class MyvehiclesComponent extends AbstractProcessLauncher implements OnInit {
  title = 'Meus Veículos';
  vehicles: Array<VehiclesDto>;
  getVehiclesQueryArguments: GetVehiclesQueryArguments;
  getVehiclesQueryResult: GetVehiclesQueryResultDto;
  perPages = [];
  filterDebounce: Subject<GetVehiclesQueryArguments> = new Subject<GetVehiclesQueryArguments>();
  bsModalRef: BsModalRef;
  myVehicles: Vehicle[] = [];
  vehicleStatistics: VehicleStatistic;
  VehiclesStatistic: VehicleStatistic;
  isLoading: boolean;
  percentageVehiclesWithActiveTag: number;
  percentageVehiclesWithLockedTag: number;
  percentageVehiclesWithoutTagRegistered: number;
  isOpenDropdown: boolean;
  noTransactions: boolean = false;
  message: string;
  tagStatus = contractVehicleTagStatusTypeId;
  public verificationPermissionByContractType: boolean = false;
  public dualTagVehicles: any;
  getDualTag: any;
  isButtonEnabled: boolean = false;

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('content') content: ElementRef;
  tooltips = TOOLTIPS;

  constructor(
    private modalService: NgbModal,
    protected myVehiclesService: MyvehiclesService,
    protected router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalstorageService

  ) {
    super(router);
  }

  private emmitEventWhenStartComponent(): void {
    EventEmitterService.get('RefreshAfterAlterService').subscribe(data => this.ngOnInit());
    EventEmitterService.get('RefreshVehiclesAfterLinkTag').subscribe(data => this.ngOnInit());
  }

  private startSettingVariables(): void {
    this.vehicles = [];
    this.perPages = [10, 20, 50];

    this.getVehiclesQueryResult = {
      total: 0,
      vehicles: []
    }
  }

  private startGetVehiclesQueryArguments(): void {
    this.getVehiclesQueryArguments = new GetVehiclesQueryArguments();
    this.getVehiclesQueryArguments.page = 1;
    this.getVehiclesQueryArguments.pageSize = 10; // TODO: Pegar do componnete verificar
    this.getVehiclesQueryArguments.orderBy = "licensePlate";
    this.getVehiclesQueryArguments.desc = false;
    this.getVehiclesQueryArguments.filter = 1;
    this.getVehiclesQueryArguments.search = "";
  }

  ngOnInit() {
    this.emmitEventWhenStartComponent();
    this.startSettingVariables();
    this.startGetVehiclesQueryArguments();
    this.filterDebounce.pipe(debounceTime(300)).subscribe(filter => this.getFromServer(filter));
    this.getFromServer(this.getVehiclesQueryArguments);
    this.getVehiclesStatistics();
    this.verificationPermissionByContractType = this.contractService.isDualTag();
    this.vehicles = this.route.snapshot.data['vehicles'] || [];
  }


  showAddVehicleModal() {
    const modalRef = this.modalService.open(AddVehicleComponent);
    modalRef.componentInstance.event.subscribe(() => {
      this.getFromServer(this.getVehiclesQueryArguments);
      this.getVehiclesStatistics();
    })
  }

  showEditVehicleModal(vehicleSelected) {
    const modalRef = this.modalService.open(AddVehicleComponent);
    modalRef.componentInstance.vehicle = vehicleSelected;
    modalRef.componentInstance.event.subscribe(() => {
      this.ngOnInit();
    })
  }

  showDeleteVehicleModal(vehicleSelected) {
    const modalRef = this.modalService.open(DeleteVehicleModalComponent);
    modalRef.componentInstance.vehicle = vehicleSelected;

    modalRef.componentInstance.event.subscribe((receivedEntry) => {
      this.getVehiclesStatistics();
      this.getVehiclesQueryResult.vehicles.splice(this.getVehiclesQueryResult.vehicles.indexOf(receivedEntry), 1);
    })
  }

  showReplaceTagVehicleModal(vehicleSelected) {
    const modalRef = this.modalService.open(TagReplaceModalComponent);
    modalRef.componentInstance.vehicle = vehicleSelected;

    modalRef.componentInstance.event.subscribe((receivedEntry) => {
      vehicleSelected.serialTag = receivedEntry.contractVehicleTagAndUserReplacement.newTagSerial;
    })
  }

  redirectToReincludeVehicleModal(vehicle) {
    const modalRef = this.modalService.open(ReincludeVehicleModalComponent);
    modalRef.componentInstance.vehicle = vehicle;
    modalRef.componentInstance.event.subscribe(() => {
      this.ngOnInit();
    })
  }

  getVehiclesStatistics() {
    this.myVehiclesService
      .getStatistic()
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (success: any) => {
          this.VehiclesStatistic = success;

          // Nota: Não multiplica por 100 porque, o formatador do angular espera: 1 para 100% e 0.5 para 50%. https://angular.io/api/common/PercentPipe
          this.percentageVehiclesWithActiveTag = this.VehiclesStatistic.vehiclesRegistered <= 0 ? 0 : this.VehiclesStatistic.vehiclesWithActiveTag / this.VehiclesStatistic.vehiclesRegistered;
          this.percentageVehiclesWithLockedTag = this.VehiclesStatistic.vehiclesRegistered <= 0 ? 0 : this.VehiclesStatistic.vehiclesWithLockedTag / this.VehiclesStatistic.vehiclesRegistered;
          this.percentageVehiclesWithoutTagRegistered = this.VehiclesStatistic.vehiclesRegistered <= 0 ? 0 : this.VehiclesStatistic.vehiclesWithoutTagRegistered / this.VehiclesStatistic.vehiclesRegistered;

        },
        (error) => {
          console.log('error:', error);
          return;
        }
      );
  }

  getFromServer(args: GetVehiclesQueryArguments) {
    this.message = 'Carregando...'
    this.isLoading = true;
    this.myVehiclesService
      .getVehicles(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.getVehiclesQueryResult = success;
          this.getDualTag = success.vehicles;
          this.noTransactions = success.total == 0;
        },
        (error) => {
          console.error("getFromServerError", error);
          return;
        }
      );
  }

  redirectToUnlockTag(vehicleSelected: GetVehicleQueryResultDto) {
    const modalRef = this.modalService.open(TagUnlockModalComponent);
    modalRef.componentInstance.vehicle = vehicleSelected;

    modalRef.componentInstance.event.subscribe(() => {
      this.getFromServer(this.getVehiclesQueryArguments);
      this.getVehiclesStatistics();
    })
  }

  redirectToCancelTag(vehicleSelected: GetVehicleQueryResultDto) {
    const modalRef = this.modalService.open(CancelTagComponent);
    modalRef.componentInstance.vehicle = vehicleSelected;
    modalRef.componentInstance.event.subscribe(() => {
      this.ngOnInit();
    })
  }

  redirectToBlockTag(vehicleSelected: GetVehicleQueryResultDto) {
    const modalRef = this.modalService.open(TagBlockModalComponent);
    modalRef.componentInstance.vehicle = vehicleSelected;

    modalRef.componentInstance.event.subscribe(() => {
      this.getFromServer(this.getVehiclesQueryArguments);
      this.getVehiclesStatistics();
    })
  }

  onPaginationChange(page: number) {
    this.getVehiclesQueryArguments.page = page;
    this.getFromServer(this.getVehiclesQueryArguments);
  }

  onPageSizeChange(event: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.getVehiclesQueryArguments.page = 1;
    this.getVehiclesQueryArguments.pageSize = event.target.value;
    this.getFromServer(this.getVehiclesQueryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.getVehiclesQueryArguments.orderBy == field && !this.getVehiclesQueryArguments.desc,
      'order-by-desc': this.getVehiclesQueryArguments.orderBy == field && this.getVehiclesQueryArguments.desc
    }
  }

  onOrderByChange(field: string) {
    if (this.getVehiclesQueryArguments.orderBy === field) {
      this.getVehiclesQueryArguments.desc = !this.getVehiclesQueryArguments.desc;
    } else {
      this.getVehiclesQueryArguments.orderBy = field;
      this.getVehiclesQueryArguments.desc = true;
    }

    this.getFromServer(this.getVehiclesQueryArguments);

  }

  onFullNameKeyup($event: any) {
    this.getVehiclesQueryArguments.page = 1;
    this.getVehiclesQueryArguments.search = $event.target.value;
    this.filterDebounce.next(this.getVehiclesQueryArguments);

  }

  onFilterClick(event: any) {
    this.getVehiclesQueryArguments.page = 1;
    this.getVehiclesQueryArguments.filter = event;
    this.filterDebounce.next(this.getVehiclesQueryArguments);
  }

  onBuscarClick(event: any) {
    this.getFromServer(this.getVehiclesQueryArguments);
  }

  showAlterServiceModal(vehicle) {
    const modalRef = this.modalService.open(AlterServiceModalComponent);
    modalRef.componentInstance.vehicle = vehicle;
  }

  showImportVehiclesModal() {
    this.modalService.open(ImportVehiclesModalComponent);
  }


  Download() {
    this.message = 'Download em andamento...'
    this.DownloadCSV(this.getVehiclesQueryArguments);
  }


  DownloadCSV(args: GetVehiclesQueryArguments) {
    let now = new Date;
    let date = moment(now).format('DDMMYYYY')
    this.isLoading = true
    this.myVehiclesService.getCSVFile(args)
      .pipe(
        finalize(() => {
          this.isLoading = false
        })
      )
      .subscribe(x => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'veiculos_' + date + '.csv';
        element.click();
      });
  }

  showTransferVehicleModal(vehicleSelected) {
    const modalRef = this.modalService.open(TransferVehicleComponent);
    modalRef.componentInstance.vehicle = vehicleSelected;

    modalRef.componentInstance.event.subscribe((receivedEntry: any) => {
      Object.assign(vehicleSelected, receivedEntry);
      this.getFromServer(this.getVehiclesQueryArguments);
      this.getVehiclesStatistics();
    });
  }

  goToCancelTag(vehicleSelected: GetVehicleQueryResultDto) {
    const modalRef = this.modalService.open(CancelTagBackOfficeComponent);
    modalRef.componentInstance.vehicle = vehicleSelected;
    this.modalService.activeInstances;
  }

  checkPermissionByDeleteModal(vehicle): boolean {
    return (this.userProfileService.isCustomerOrRelationshipProfile() && this.authService.checkRoles('101147')) ||
      (this.userProfileService.isBackOffice() && !vehicle?.serialTag);
  }

  verifyTooltipByIdVehicles(vehicle: GetVehicleQueryResultDto) {
    if (vehicle.id != null) {
      const dualTagVehicles = this.localStorageService.getListVehiclesDualTag();
      if (dualTagVehicles && (dualTagVehicles.length > 0)) {
        return dualTagVehicles.includes(vehicle.id)
      }
    }
    return false;
  }

  getVehicle(vehicle) {
    return vehicle.id
  }

  getTooltip(vehicle: GetVehicleQueryResultDto): string | null {
    if (vehicle) {
      if (vehicle.dualtag) {
        if (vehicle.contractVehicleTagStatusTypeId === 1) {
          return this.tooltips.dualTagGeneralStatus;
        } else if (vehicle.contractVehicleTagStatusTypeId === 2) {
          return this.tooltips.dualTagCancel;
        }
      } else if (vehicle.contractVehicleTagStatusTypeId === 1) {
        return this.tooltips.dualTagGeneralStatus;
      }
    }
    return null;
  }



}
