<!-- Modal userBlockModal -->
<div class="modal-content alterService">

  <div class="step-1" id="confirmacao" [hidden]="!stepconfirmacao">
    <div class="modal-header">
      <div class="box-title">
        <span>Alterar Serviço</span>

      </div>
      <button class="close" type="button" id="btnx" (click)="dismissModal()"><span aria-hidden="true">×</span></button>

      <div class="row-box-yellow">
        <span class="box-yellow">
          <b>A</b>pós alterar o serviço, a tag estará habilitada para uso em alguns minutos.
        </span>
      </div>
    </div>
    <div class="modal-body">
      <div class="row row-modal-collapse">
        <div class="col-lg-5 col-vehicle-tag">
          <div class="row">
            <div class="col-lg-12">
              <b class="subtitle">Dados do vínculo atual</b>
            </div>
          </div>
       
          <div class="row">
            <div class="col-lg-6">
              <p>Veículo</p>
              <span></span>
              <b class="font-size-75rem">    
               <ng-template #popoverNav placement="right" [outsideClick]="true">
                <div class="row my-1">
                  <div class="head-info" translate>Placa:</div>
                  <div class="content-info" id="descLicensePlate">{{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}</div>
                </div>
                <div class="row my-1">
                  <div class="head-info" translate>Apelido:</div>
                  <div class="content-info" id="descNickName">{{ vehicle?.nickName }}</div>
                </div>
                <div class="row my-1">
                  <div class="head-info" translate>Tag:</div>
                  <div class="content-info" id="descSerialTag">{{ vehicle?.serialTag }}</div>
                </div>
                <div class="row my-1">
                  <div class="head-info" translate>Tipo:</div>
                  <div class="content-info" id="descDescriptionVehicleTypePT">{{ vehicle?.descriptionVehicleTypePT }}</div>
                </div>
                <div class="row my-1">
                  <div class="head-info" translate>Marca:</div>
                  <div class="content-info" id="descDescriptionVehicleBrandTypePt">{{ vehicle?.descriptionVehicleBrandTypePt }}</div>
                </div>
                <div class="row my-1">
                  <div class="head-info" translate>Modelo:</div>
                  <div class="content-info" id="descModel">{{ vehicle?.model }}</div>
                </div>
                <div class="row my-1">
                  <div class="head-info" translate>Nº de eixos:</div>
                  <div class="content-info" id="descDescriptionAxlePt">{{ vehicle?.descriptionAxlePt }}</div>
                </div>
              </ng-template>
              <i class="ico ico-white ico-menu-open"    
              [popover]="popoverNav"
              #pop="bs-popover" 
              [outsideClick]="true" 
              position="right"></i>            
                {{vehicle.licensePlate}}</b>
            </div>

            <div class="col-lg-6">
              <p>TAG</p>
              <span></span>
              <b class="font-size-75rem" id="serialTag">{{vehicle.serialTag}}</b>

            </div>
          </div>
        </div>
        <div class="col-lg-7 col-box-collapse font10 height-replace">       
          <div class="container-repom tag-disp container-min-height containertypeService">
            <div class="row">
              <div class="col-lg-12">
                <label class="label-collapse" for="collapseTagsFor">Tipos de Serviço</label>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 serviceOption">
                <label class="container"><div class="type">Pedágio + Estacionamento</div>
                  <input type="radio"[checked]="service1" id="inputTypeService" value="1" name="Service" (click)="onItemChange($event)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 serviceOption">
                <label class="container"><div class="type">Pedágio</div>
                  <input type="radio" [checked]="service2" id="inputPedagio" value="2" name="Service" (click)="onItemChange($event)">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray">
      <button [disabled]="onSaving" type="button" id="cancelBtn" class="btn btn-tertiary mr-4" (click)="dismissModal()">Cancelar</button>
      <button type="button" [disabled]="buttonDisabled" id="btnAlterService" class="btn btn-primary" (click)="alterServiceContractVehicleTag()">
        <img class="button-icon" *ngIf="!onSaving" src="../../../assets/svg/toggle_on-24px.svg" alt="Alter" />
        <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
        <span>Alterar Serviço</span>
      </button>
    </div>
  </div>

  <div class="step-2" [hidden]="stepconfirmacao">
    <div class="modal-header">
      <div class="box-title">
        <span>Alterar Serviço</span>
      </div>
      <button type="button" class="close" (click)="emitChangeAndDismissModal()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="row-box-yellow">
        <span class="box-yellow">
          <b>A</b>pós alterar o serviço, a tag estará habilitada para uso em alguns minutos.
        </span>
      </div>
    </div>
    <div class="modal-body modal-body-custom">
      <div class="row">
        <div class="col-lg-12">
        </div>
      </div>
      <div class="row row-modal-collapse mt-7rem">
        <div class="w-100 d-flex justify-content-center successContent">
          <div class="col-lg-4">
            <img class="w-100" src="../../../assets/img/bg-place.svg" />
          </div>
          <div class="col-lg-6 tag-service">
            <h3>Serviço Alterado</h3>
            <div>
              <div class="row mb-1">
                <div class="col-lg-5">
                  <strong>PLACA:</strong>
                </div>
                <div class="col-lg-auto">
                  <span>{{vehicle.licensePlate | mask: 'AAA-AAAA' }}</span>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-lg-5">
                  <strong>SERVIÇO:</strong>
                </div>
                <div class="col-lg-auto">
                  <span>{{ServiceDescription}}</span>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <div class="row mt-3 changeBy">
          <div class="col-lg-12 text-center">
            {{contractVehicleTagUserRegister?.changeDate | date: 'dd/MM/yyyy - HH:mm'}} por {{contractVehicleTagUserRegister?.registrationUserFullName}}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray modal-footer-success">
      <button [disabled]="onSaving" id="closeBtn" type="button" class="btn btn-secondary" (click)="emitChangeAndDismissModal()">Fechar</button>
    </div>
  </div>
</div>