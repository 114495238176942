import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { FleetDashboardRoutingModule } from './routes/fleet-dashboard-routing.module';
import { FleetDashboardComponent } from './fleet-dashboard.component';
import { CostsRankingComponent } from './components/costs-ranking/costs-ranking.component';
import { FleetExpeditureEvolutionComponent } from './components/fleet-expediture-evolution/fleet-expediture-evolution.component';
import { FleetStatsComponent } from './components/fleet-stats/fleet-stats.component';
import { TagCanceledComponent } from './components/tag-canceled/tag-canceled.component';
import { TagStockComponent } from './components/tag-stock/tag-stock.component';
import { TotalCostsComponent } from './components/total-costs/total-costs.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FleetDashboardRoutingModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
  ],
  declarations: [
    FleetDashboardComponent,
    CostsRankingComponent,
    FleetExpeditureEvolutionComponent,
    FleetStatsComponent,
    TagCanceledComponent,
    TagStockComponent,
    TotalCostsComponent
  ],
  providers: [DatePipe, provideNgxMask(),CurrencyPipe],
})

export class FleetDashboardModule {}
