<div class="add-hierarchy-vehicle">
  <div class="modal-header">
    <div class="box-title">
      <span>{{modalTitle}}</span>
    </div>
    <button type="button" class="close" (click)="close()" id="closeBtnId">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="row-box-yellow">
    <span class="box-yellow">
      <b>C</b>aso procure um veículo que não esteja na relação abaixo é porque o
      mesmo já possui uma hierarquia. Altere sua hierarquia na tela de veículos ou
      por meio da edição de hierarquia
    </span>
  </div>
  <!-- start step-1 -->
  <div class="mt-5 d-flex align-items-center">
    <div class="mt-5 w-100 text-center">
      <div class="box-breadcrumb">
        <h4 class="mb-0">Escolha os veículos que deseja incluir no grupo:</h4>
        <div class="row justify-content-center align-items-center">
          <p class="col-sm-auto" id="breadCrumbId">{{breadCrumb}}</p>
          <p class="col-sm-auto" id="descriptionId">{{description_PT}}</p>
        </div>
      </div>
      <div>
        <div class="box-tabs-hierarquiadd-vehicle">
          <div class="col-lg-12">
            <label class="label-collapse text-left mb-3">VEÍCULOS DISPONÍVEIS</label>
          </div>
          <div class="tab-header">
            <ul ngbNav #nav="ngbNav" class="nav-tabs">
              <li ngbNavItem>
                <a ngbNavLink (click)="resetForm(1)">Recentes</a>
                <ng-template ngbNavContent>
                  <div class="box-licenseplates">
                    <div *ngIf="isLoading == false && listVehicleAvailable?.total == 0" class="text-center p-2">
                      <b class="subtitle">Não há veículos disponíveis.</b>
                    </div>
                    <div class="container-loading" *ngIf="isLoading">
                      <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
                    </div>
                    <div *ngIf="isLoading == false && listVehicleAvailable?.total !== 0">
                     <div class="custom-control custom-checkbox" *ngFor="let item of listVehicleAvailable?.vehiclesAvailable; let i=index">
                      <div class="d-flex justify-content-around align-items-center">
                        <input type="checkbox" class="custom-control-input" id="{{item.contractVehicleId}}"
                          name="{{item.licensePlate}}" [value]="item.licensePlate" (change)="onCheckChange($event)">
                        <label class="custom-control-label font-weight-bold"
                          for="{{item.contractVehicleId}}">{{item.licensePlate | mask: 'AAA-AAAA' }}</label>
                        <div class="icon-actions" [popover]="actionPopover" [outsideClick]="true">
                          <i class="ico ico-white ico-menu-open"></i>
                        </div>
                      </div>

                      <ng-template #actionPopover placement="right" [outsideClick]="true">
                        <div class="row my-1">
                          <div class="head-info" translate>Placa:</div>
                          <div class="content-info" id="item?.licensePlate">{{ item?.licensePlate | mask: 'AAA-AAAA' }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Apelido:</div>
                          <div class="content-info">{{ item?.nickname }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tag:</div>
                          <div class="content-info">{{ item?.serial }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tipo:</div>
                          <div class="content-info">{{ item?.vehicleType }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Marca:</div>
                          <div class="content-info">{{ item?.vehicleBrand }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Modelo:</div>
                          <div class="content-info">33{{ item?.model }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Nº de eixos:</div>
                          <div class="content-info">{{ item?.axleWheelType }}</div>
                        </div>
                      </ng-template>
                    </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink (click)="resetForm(2)">Todos</a>
                <ng-template ngbNavContent>
                  <div class="box-licenseplates">
                    <div *ngIf="isLoading == false && listVehicleAvailable?.total == 0" class="text-center p-2">
                      <b class="subtitle">Não há veículos disponíveis.</b>
                    </div>
                    <div class="container-loading" *ngIf="isLoading">
                      <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
                    </div>
                    <div *ngIf="isLoading == false && listVehicleAvailable?.total !== 0">
                     <div class="custom-control custom-checkbox" *ngFor="let item of listVehicleAvailable?.vehiclesAvailable">
                      <div class="d-flex justify-content-around align-items-center">
                        <input type="checkbox" class="custom-control-input" id="{{item.contractVehicleId}}"
                          name="{{item.licensePlate}}" [value]="item.licensePlate" (change)="onCheckChange($event)">
                        <label class="custom-control-label font-weight-bold"
                          for="{{item.contractVehicleId}}">{{item.licensePlate | mask: 'AAA-AAAA' }}</label>
                        <div class="icon-actions" [popover]="actionPopover" [outsideClick]="true">
                          <i class="ico ico-white ico-menu-open"></i>
                        </div>
                      </div>

                      <ng-template #actionPopover  placement="right" [outsideClick]="true">
                        <div class="row my-1">
                          <div class="head-info" translate>Placa:</div>
                          <div class="content-info">{{ item?.licensePlate | mask: 'AAA-AAAA' }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Apelido:</div>
                          <div class="content-info">{{ item?.nickname }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tag:</div>
                          <div class="content-info">{{ item?.serial }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tipo:</div>
                          <div class="content-info">{{ item?.vehicleType }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Marca:</div>
                          <div class="content-info">{{ item?.vehicleBrand }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Modelo:</div>
                          <div class="content-info">33{{ item?.model }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Nº de eixos:</div>
                          <div class="content-info">{{ item?.axleWheelType }}</div>
                        </div>
                      </ng-template>
                    </div>
                    </div>
                  </div>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink (click)="resetForm(3)">Pesquisar</a>
                <ng-template ngbNavContent>

                  <div class="row">
                    <div class="col-lg-12">
                      <div class="form-header-search">
                        <input type="text" value="90207 - 80173" placeholder="Placa do veiculo" (keyup)="onFullNameKeyup($event)"
                          class="form-control btn-search-order" [value]="getVehiclesAvailableArguments.search" />
                        <button type="button" class="btn btn-primary"  (click)="onBuscarClick($event)" ><i class="ico ico-search-white"></i>
                          buscar</button>
                      </div>
                    </div>
                    <div [ngClass]="listVehicleAvailable?.total != 0 && listVehicleAvailable?.total > getVehiclesAvailableArguments.pageSize ? '' : 'searchContent'" *ngIf="isLoading == false && listVehicleAvailable?.total == 0" class="text-center col-lg-12">
                      <b class="subtitle">Não há veículos disponíveis.</b>
                    </div>
                    <div [ngClass]="listVehicleAvailable?.total != 0 && listVehicleAvailable?.total > getVehiclesAvailableArguments.pageSize ? '' : 'searchContent'" class="col-lg-12" *ngIf="isLoading">
                      <div class="container-loading">
                        <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
                      </div>
                    </div>
                    <div class="col-lg-12" [ngClass]="listVehicleAvailable?.total != 0 && listVehicleAvailable?.total > getVehiclesAvailableArguments.pageSize ? '' : 'searchContent'" *ngIf="isLoading == false && listVehicleAvailable?.total !== 0">
                     <div class="custom-control custom-checkbox"
                      *ngFor="let item of listVehicleAvailable?.vehiclesAvailable">
                      <div class="d-flex justify-content-around align-items-center">
                        <input type="checkbox" class="custom-control-input" id="{{item.contractVehicleId}}"
                          name="{{item.licensePlate}}" [value]="item.licensePlate" (change)="onCheckChange($event)">
                        <label class="custom-control-label font-weight-bold"
                          for="{{item.contractVehicleId}}">{{item.licensePlate | mask: 'AAA-AAAA' }}</label>
                        <div class="icon-actions" [popover]="actionPopover" [outsideClick]="true">
                          <i class="ico ico-white ico-menu-open"></i>
                        </div>
                      </div>


                      <ng-template #actionPopover  placement="right" [outsideClick]="true">
                        <div class="row my-1">
                          <div class="head-info" translate>Placa:</div>
                          <div class="content-info">{{ item?.licensePlate | mask: 'AAA-AAAA' }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Apelido:</div>
                          <div class="content-info">{{ item?.nickname }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tag:</div>
                          <div class="content-info">{{ item?.serial }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Tipo:</div>
                          <div class="content-info">{{ item?.vehicleType }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Marca:</div>
                          <div class="content-info">{{ item?.vehicleBrand }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Modelo:</div>
                          <div class="content-info">33{{ item?.model }}</div>
                        </div>
                        <div class="row my-1">
                          <div class="head-info" translate>Nº de eixos:</div>
                          <div class="content-info">{{ item?.axleWheelType }}</div>
                        </div>
                      </ng-template>
                    </div>
                    </div>
                  </div>
                </ng-template>
              </li>
            </ul>
          </div>
          <div [ngbNavOutlet]="nav"></div>
        </div>
        <div class="pagination-custom" *ngIf="listVehicleAvailable?.total != 0 && listVehicleAvailable?.total > getVehiclesAvailableArguments.pageSize">
          <app-pagination
            [totalItems]="listVehicleAvailable.total"
            [itemsPerPage]="getVehiclesAvailableArguments.pageSize"
            [maxPages]="5"
            [currentPage]="getVehiclesAvailableArguments.page"
            (pageChangeEvent)="onPaginationChange($event)"
          ></app-pagination>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer modal-footer-gray justify-content-start">
    <div class="col-8 justify-content-between d-flex">
      <button type="button" class="btn btn-default btn-primary mr-4" data-dismiss="modal" id="cancelBtnId" aria-label="Close" (click)="close()">Fechar</button>
      <button id="adcBtnId" [disabled]="vehicleHierarchyForm.value.licensePlate.length == 0 || isLoading" (click)="saveVehicleByLicensePlate()" class="btn btn-repom btn-repom-user"
        type="button"><i class="ico ico-add "></i>Adicionar veículos a esse
        nível <span class="btn-animation"></span></button>
    </div>
  </div>
</div>
<!-- <pre> {{vehicleHierarchyForm.value | json}} </pre> -->
