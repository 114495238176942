<div class="tag-block-unblock-inconsist">
  <section class="modal-header">

    <div class="box-title"><span class="tag-block-unblock-inconsist__title">{{ title }}</span></div>

    <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss">
      <span aria-hidden="true">×</span>
    </button>
  </section>

  <section class="modal-body tag-block-unblock-inconsist__body">

    @if (isLoading) {

      <app-loader [isLoading]="isLoading" message="Carregando..."></app-loader>

    }
    @else {

      <div class="tag-block-unblock-inconsist__text-info">
        <h6 class="tag-block-unblock-inconsist__subtitle">{{ subtitle }}</h6>

        @if (inDataInconsistencies.fileSuccess > 0) {
          <p class="tag-block-unblock-inconsist__data" id="data-number-success" data-e2e="data-number-success">
            <b>{{ inDataInconsistencies.fileSuccess }}</b> {{ tagsSuccessText }}
          </p>
        }

        <p class="tag-block-unblock-inconsist__data" id="data-number-errors" data-e2e="data-number-errors"><b>{{ inDataInconsistencies.fileInconsistencies }}</b> {{ tagsInconsistenciesText }}</p>
      </div>

      <div class="tag-block-unblock-inconsist__table-container">
        <table class="table tag-block-unblock-inconsist__table">
          <thead class="thead tag-block-unblock-inconsist__thead">
            <tr>
              <th class="tag-block-unblock-inconsist__th">
                <div class="btn-order-table">
                  Veículo
                </div>
              </th>

              <th class="tag-block-unblock-inconsist__th">
                <div class="btn-order-table">
                  TAG
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            @for (item of tagsInconsistenciesResultList; track $index) {
              <tr>
                <td id="table-data-modal-license-plate-{{$index}}" [attr.data-e2e]="'table-data-modal-license-plate-' + $index"
                  class="tag-block-unblock-inconsist__td tag-block-unblock-inconsist__td--emphasis"
                >
                  {{ item.licensePlate }}
                </td>

                <td id="table-data-modal-serial-tag-{{$index}}" [attr.data-e2e]="'table-data-modal-serial-tag-' + $index"
                  class="tag-block-unblock-inconsist__td tag-block-unblock-inconsist__td--emphasis"
                >
                  {{ item.serial }}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  </section>

  <section class="modal-footer modal-footer-gray tag-block-unblock-inconsist__footer">
    <button
      class="btn btn-tertiary"
      id="modal-btn-close-below"
      data-e2e="modal-btn-close-below"
      type="button"
      (click)="dismissModal()"
    >
      Fechar
    </button>

    <button
      class="btn btn-secondary tag-block-unblock-inconsist__btn-confirm"
      id="modal-btn-confirm"
      data-e2e="modal-btn-confirm"
      type="button"
      (click)="confirmTryAgain()"
    >
      <img class="tag-block-unblock-inconsist__icon-reload" alt="Ícone cadeado">
      Tentar novamente
    </button>
  </section>
</div>
