<div class="modal-content reinclude-vehicle-modal-component">
    <div class="modal-header">
      <div class="box-title">
        <span data-e2e="modal-title">Reincluir na frota</span>
      </div>
      <button type="button" class="close" (click)="emitAndClose()" id="btn-dismiss">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="modal-body" *ngIf="step == 1">
        <div class="body-title" data-e2e="reinclude-block-title" >Tem certeza de que deseja reincluir o veículo abaixo na frota?</div>
        <div class="row row-animation"></div>
     
        <div class="row row-modal-collapse">
            <div class="col-lg-3"></div>
            <div class="col-lg-6 col-vehicle-tag">
          
              <div class="row vehicleInfos">
                <div class="col-sm-auto">
                  <p>Veículo</p>
                  <span></span>
                  <b class="font-size-75rem">    
                   <ng-template #actionPopover placement="right" [outsideClick]="true" class="popover-template">
                    <div class="row my-1">
                      <div class="head-info col" data-e2e="vehicle-type-label" translate>Tipo:</div>
                      <div class="content-info col" data-e2e="vehicle-type-data" id="descDescriptionVehicleTypePT">{{ vehicle?.descriptionVehicleTypePT }}</div>
                    </div>
                    <div class="row my-1">
                      <div class="head-info col" data-e2e="vehicle-nickName-label" translate>Apelido:</div>
                      <div class="content-info col" data-e2e="vehicle-nickName-data" id="descNickName">{{ vehicle?.nickName }}</div>
                    </div>
                    <div class="row my-1">
                      <div class="head-info col" data-e2e="vehicle-brand-type-label"  translate>Marca:</div>
                      <div class="content-info col" data-e2e="vehicle-brand-type-data"  id="descDescriptionVehicleBrandTypePt">{{ vehicle?.descriptionVehicleBrandTypePt }}</div>
                    </div>
                      
                    <div class="row my-1">
                      <div class="head-info col" data-e2e="vehicle-model-label" translate>Modelo:</div>
                      <div class="content-info col" data-e2e="vehicle-model-data" id="descModel">{{ vehicle?.model }}</div>
                    </div>
                    
                    <div class="row my-1">
                      <div class="head-info col" data-e2e="vehicle-description-axl-label" translate>Nº de eixos:</div>
                      <div class="content-info col" data-e2e="vehicle-description-axl-data" id="descDescriptionAxlePt">{{ vehicle?.descriptionAxlePt }}</div>
                    </div>

                    <div class="row my-1" *ngIf="vehicle?.hierarchyName && vehicle?.hierarchyName !== ''">
                      <div class="head-info col" data-e2e="vehicle-hierarchy-label"  translate>Hierarquia</div>
                      <div class="content-info col" data-e2e="vehicle-hierarchy-data"  id="hierarchy">{{ vehicle?.hierarchyName }}</div>
                    </div>

                    <div class="row my-1" *ngIf="vehicle?.rntrc && vehicle?.rntrc !== ''">
                      <div class="head-info col" data-e2e="vehicle-rntrc-label"  translate>RNTRC do Agregado</div>
                      <div class="content-info col" data-e2e="vehicle-rntrc-data"  id="rntrc">{{ vehicle?.rntrc }}</div>
                    </div>

                    <div class="row my-1" *ngIf="vehicle?.documentNumber && vehicle?.documentNumber !== ''">
                      <div class="head-info col" data-e2e="vehicle-document-number-label"  translate>CPF/CNPJ do Agregado</div>
                      <div class="content-info col" data-e2e="vehicle-document-number-data" id="documentNumber" *ngIf="vehicle?.documentNumber?.length == 11">{{ vehicle?.documentNumber | mask: '000.000.000-00'  }}</div>
                      <div class="content-info col" data-e2e="vehicle-document-number-data" id="documentNumber" *ngIf="vehicle?.documentNumber?.length == 14">{{ vehicle?.documentNumber | mask: '00.000.000/0000-00'  }}</div>                 
                    </div>
                
                  </ng-template>
                  <i class="ico ico-white ico-menu-open" id="descVehiclelicensePlate"   
                  [popover]="actionPopover"
                  #pop="bs-popover" 
                  [outsideClick]="true" 
                  position="right"></i>            
                    <span data-e2e="vehicle-license-plate-data">{{vehicle.licensePlate | mask: 'AAA-AAAA'}}</span></b>
                </div>
    
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>
      </div>
      <div class="modal-body" *ngIf="step == 2">
        <div class="row container-confirmation">
            <div class="col-4 image-content">
              <img src="/assets/img/bg-place.svg" />
            </div>
            <div class="col-8 confirmation-text">
              <h3 data-e2e="reinclude-confirmation-title">Veículo Reincluído</h3>
              <div class="confirmation-vehicle-infos">
                <div class="row my-1">
                    <div class="head-info" data-e2e="vehicle-license-plate-label" translate>Placa:</div>
                    <div class="content-info" data-e2e="vehicle-license-plate-data" id="descLicensePlate">{{ vehicle?.licensePlate | mask: 'AAA-AAAA' }}</div>
                  </div>
                  <div class="row my-1">
                    <div class="head-info" data-e2e="vehicle-type-label" translate>Tipo:</div>
                    <div class="content-info" data-e2e="vehicle-type-data" id="descDescriptionVehicleTypePT">{{ vehicle?.descriptionVehicleTypePT }}</div>
                  </div>
                  <div class="row my-1">
                    <div class="head-info" data-e2e="vehicle-nickName-label" translate>Apelido:</div>
                    <div class="content-info" data-e2e="vehicle-nickName-data" id="descNickName">{{ vehicle?.nickName }}</div>
                  </div>
                  <div class="row my-1">
                    <div class="head-info" data-e2e="vehicle-brand-type-label"  translate>Marca:</div>
                    <div class="content-info" data-e2e="vehicle-brand-type-data"  id="descDescriptionVehicleBrandTypePt">{{ vehicle?.descriptionVehicleBrandTypePt }}</div>
                  </div>
                  <div class="row my-1">
                    <div class="head-info" data-e2e="vehicle-model-label" translate>Modelo:</div>
                    <div class="content-info" data-e2e="vehicle-model-data" id="descModel">{{ vehicle?.model }}</div>
                  </div>               
                  <div class="row my-1">
                    <div class="head-info" data-e2e="vehicle-description-axl-label" translate>Nº de eixos:</div>
                    <div class="content-info" data-e2e="vehicle-description-axl-data" id="descDescriptionAxlePt">{{ vehicle?.descriptionAxlePt }}</div>
                  </div>
                  <div class="row my-1" *ngIf="vehicle?.hierarchyName && vehicle?.hierarchyName !== ''">
                    <div class="head-info" data-e2e="vehicle-hierarchy-label" translate>Hierarquia</div>
                    <div class="content-info" data-e2e="vehicle-hierarchy-data" id="hierarchy">{{ vehicle?.hierarchyName }}</div>
                  </div>

                  <div class="row my-1" *ngIf="vehicle?.rntrc && vehicle?.rntrc !== ''">
                    <div class="head-info" data-e2e="vehicle-rntrc-label" translate>RNTRC do Agregado</div>
                    <div class="content-info" data-e2e="vehicle-rntrc-data" id="rntrc">{{ vehicle?.rntrc }}</div>
                  </div>

                  <div class="row my-1" *ngIf="vehicle?.documentNumber && vehicle?.documentNumber !== ''">
                    <div class="head-info" data-e2e="vehicle-document-number-label" translate>CPF/CNPJ do Agregado</div>
                    <div class="content-info" data-e2e="vehicle-document-number-data" id="documentNumber" *ngIf="vehicle?.documentNumber?.length == 11">{{ vehicle?.documentNumber | mask: '000.000.000-00'  }}</div>
                    <div class="content-info" data-e2e="vehicle-document-number-data" id="documentNumber" *ngIf="vehicle?.documentNumber?.length == 14">{{ vehicle?.documentNumber | mask: '00.000.000/0000-00'  }}</div>                 
                  </div>
                  <div class="author mt-4">
                    <p id="changeDate"><span data-e2e="changeDate">{{ success?.changeDate | date:'dd/MM/yyyy HH:mm' }}</span> por
                      <span data-e2e="changeUserName">{{ success?.changeUserName  }}</span></p>
                  </div>
              </div>
            </div>
          </div>
      </div>
      <div class="modal-footer modal-footer-gray">
        <button data-e2e="close-btn" *ngIf="step == 2" type="button" class="btn btn-secondary mr-4" (click)="emitAndClose()" id="btn-close">Fechar</button>
        <button data-e2e="cancel-btn" *ngIf="step == 1" [disabled]="onSaving" type="button" class="btn btn-tertiary mr-4" (click)="close()" id="btn-close">Cancelar</button>
        <button data-e2e="reinclude-btn" *ngIf="step == 1" [disabled]="onSaving" class="btn btn-primary ml-4" (click)="reincludeVehicle()" id="btn-confirm">
          <img class="button-icon mr-2" *ngIf="!onSaving" src="../../../assets/img/reinclude_vehicle_icon.svg" alt="" />
          <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
         Reincluir o veículo
        </button>
      </div>
  
  </div>