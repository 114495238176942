@if (isLoading) {
  <div class="container-loading">

    <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>

  </div>
}
@else {
  <mat-card class="batch-management-card">

    <div class="batch-management-card__header">

      <section class="batch-management-card__tabs">

        <app-tabs [inTabs]="tabs" [inTabSelected]="tabSelected" (outChangeTabSelected)="handleTabsSelection($event)"></app-tabs>

      </section>

      <section>
        @switch (tabSelected) {
          @case (tabType.TagActivate) {
            <button
              id="btn-tag-activate-doc-upload"
              data-e2e="btn-tag-activate-doc-upload"
              class="btn-bg-color-blue"
              (click)="openTagActivateUploadModal()"
              [disabled]="!hasTagsAndVehiclesAvaliable"
            >
              <img class="btn-bg-color-blue__icon icon-document-upload" alt="Ícone documento para upload">
              Importar novo documento
            </button>
          }
          @case (tabType.TagBlock) {
            <button
              id="btn-tag-block"
              data-e2e="btn-tag-block"
              class="btn-bg-color-blue"
              [disabled]="tagsBlockUnblockSelected.length == 0"
              (click)="openTagBlockUnblockModal()"
            >
              <img class="btn-bg-color-blue__icon icon-padlock-closed" alt="Ícone cadeado fechado">
              Bloquear tags
            </button>
          }
          @case (tabType.TagUnblock) {
            <button
              id="btn-tag-unblock"
              data-e2e="btn-tag-unblock"
              class="btn-bg-color-blue"
              [disabled]="tagsBlockUnblockSelected.length == 0"
              (click)="openTagBlockUnblockModal()"
            >
              <img class="btn-bg-color-blue__icon icon-padlock-opened" alt="Ícone cadeado aberto">
              Desbloquear tags
            </button>
          }
        }
      </section>
    </div>

    <div>
      @switch (tabSelected) {
        @case (tabType.TagActivate) {

          <app-batch-tag-activate
            [inReloadRequest]="controlReloadRequestComponent"
            [inHasTagsAndVehiclesAvaliable]="hasTagsAndVehiclesAvaliable"
          ></app-batch-tag-activate>

        }
        @case (tabType.TagBlock) {

          <app-batch-tag-block-unblock
            [inTabTypeActive]="tabType.TagBlock"
            (outTagsSelected)="handleTagsSelected($event)"
          >
          </app-batch-tag-block-unblock>

        }
        @case (tabType.TagUnblock) {

          <app-batch-tag-block-unblock
            [inTabTypeActive]="tabType.TagUnblock"
            (outTagsSelected)="handleTagsSelected($event)"
          >
          </app-batch-tag-block-unblock>

        }
        @case (tabType.TagBlockUnblockHistory) {

          <app-batch-tag-block-unblock-history></app-batch-tag-block-unblock-history>
        }
      }
    </div>
  </mat-card>
}

