import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { finalize, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale, ptBrLocale } from 'ngx-bootstrap/chronos';
import moment from 'moment';
defineLocale('pt-br', ptBrLocale);

import { TicketsService } from './../../services/tickets.service';
import { GetTransactionTicketsDto } from '../../dtos/getTransactionTickets.dto';
import { GetTicketsQueryArguments } from '../../dtos/getTicketsQueryArguments.dto';
import { GetTicketsQueryResultDto } from '../../dtos/getTicketsQueryResult.dto';
import { HierarchyDto } from '../../dtos/hierarchyChain.dto';
import { GetHierarchyBreadcrumbDto } from '../../dtos/getHierarchyBreadcrumb.dto';
import { NotifyService } from '@services/notify.service';
import { TagModelReference } from '../../../../../core/models/tagModelReference.model';

@Component({
  selector: 'app-tab-general',
  templateUrl: './tab-general.component.html',
  styleUrls: ['./tab-general.component.scss']
})
export class TabGeneralComponent implements OnInit {
  inlineDateTime: { chosenLabel: string; startDate: moment.Moment; endDate: moment.Moment };
  items: Array<GetTransactionTicketsDto>;
  getTicketsQueryArguments: GetTicketsQueryArguments;
  getTicketsQueryResult: GetTicketsQueryResultDto;
  filterDebounce: Subject<GetTicketsQueryArguments> = new Subject<GetTicketsQueryArguments>();
  hierarchy_chain: Array<HierarchyDto>;
  getBreadcrumbResult: GetHierarchyBreadcrumbDto;
  perPages = [];
  orderToSelect = [];
  total: number;
  isShownPeriod: boolean = false;
  isShownValue: boolean = false;
  isShownHierarchy: boolean = false;
  isDatePickerOpen: boolean;
  isOpen: boolean;
  open: boolean;
  date?: any;
  today = new Date();
  selectedDate = new Date();
  dayCustomGeneral: Date[];
  isOpenDropdown: boolean;
  minDate: Date;
  maxDate: Date;
  isShowType: boolean;
  element: HTMLElement;
  idHierarchy: any;
  MinimumAmount: any;
  MaximumAmount: any;
  HierarchyId: any;
  valuesCustom: any = 'Selecione';
  description: any;
  hierarchySelected: any = 'Selecione o grupo';
  oldDate: Date;
  tiposelecionado = 'Selecione';
  radioType: string;
  disabled = false;
  isLoading: boolean;
  message: string;
  noTransactions: boolean;

  types = [
    { name: 'Pedágio', id: 1 },
    { name: 'Estacionamento', id: 0 }
  ];
  public formExtract = this.formBuilder.group({
    radioType: null,
  });


  public formDateGeneral = this.formBuilder.group({
    dateGeneral: "30"
  });

  public formValues = this.formBuilder.group({
    minValue: null,
    maxValue: null,
  });

  constructor(
    private ticketsService: TicketsService,
    private localeService: BsLocaleService,
    private formBuilder: FormBuilder,
    private notifyService: NotifyService
  ) {
    this.minDate = new Date(Date.now());
    this.maxDate = new Date(Date.now());
    this.minDate.setMonth(this.minDate.getMonth() - 13);
    this.localeService.use('pt-br');

    this.items = [];
    this.perPages = [5, 10, 20, 50];

    this.getTicketsQueryResult = {
      total: 0,
      items: []
    };

    this.orderToSelect = [
      {
        order: 'PassengerDate',
        description: 'Data de passagem'
      },
      {
        order: 'ProcessingDate',
        description: 'Data do processamento'
      }
    ];
    this.oldDate = new Date();
    this.oldDate.setDate(this.oldDate.getDate() - 30);
    this.getTicketsQueryArguments = new GetTicketsQueryArguments();
    this.getTicketsQueryArguments.page = 1;
    this.getTicketsQueryArguments.pageSize = this.perPages[0];
    this.getTicketsQueryArguments.orderBy = "PassengerDate";
    this.getTicketsQueryArguments.desc = false;
    this.getTicketsQueryArguments.search = "";
    this.getTicketsQueryArguments.MinimumAmount = 0;
    this.getTicketsQueryArguments.MaximumAmount = 0;
    this.getTicketsQueryArguments.HierarchyId = 0;
    this.getTicketsQueryArguments.startDate = this.oldDate;
    this.getTicketsQueryArguments.endDate = new Date();

    this.filterDebounce.pipe(debounceTime(300)).subscribe(filter => this.getGeneralQuery(filter));

  }

  ngOnInit() {
    this.getGeneralQuery(this.getTicketsQueryArguments);
    this.getHierarchyBreadcrumb();
  }


  getTagModelReference(tagModelReferenceId: TagModelReference): string {
    switch (tagModelReferenceId) {
      case TagModelReference.Tag:
        return 'Tag';
      case TagModelReference.DualTag:
        return 'Dual Tag';
    }
  }

  getGeneralQuery(args: GetTicketsQueryArguments) {
    //this.getTicketsQueryResult = null;
    this.isLoading = true;
    this.message = 'Carregando...';
    var daysBetween = (new Date(args.endDate).getTime() - new Date(args.startDate).getTime()) / 1000 / 60 / 60 / 24;
    if (daysBetween > 90) {
      this.notifyService.clear();
      this.notifyService.showError("Período de consulta", "O período de consulta não pode ser maior que 90 dias");
      this.isLoading = false;
      return;
    }
    this.ticketsService
      .getGeneralQuery(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          if (success.total == 0) {
            this.noTransactions = true
          }
          else {
            this.noTransactions = false
          }
          this.getTicketsQueryResult = success;
        },
        (error) => {
          this.notifyService.clear();
          console.error(error)
        }
      );
  }

  getHierarchyBreadcrumb() {
    this.ticketsService
      .getHierarchyBreadcrumb()
      .pipe(
        finalize(() => {

        })
      )
      .subscribe(
        (success) => {
          this.getBreadcrumbResult = success;
        },
        (error) => {
          this.notifyService.clear();
          console.error(error);
        }
      );
  }

  onApplyHierarchyFilter(hierarchyId) {
    this.idHierarchy = hierarchyId.id;
    this.description = hierarchyId.description_PT;
    this.getTicketsQueryArguments.HierarchyId = this.idHierarchy;
    this.getGeneralQuery(this.getTicketsQueryArguments);
    this.isShownHierarchy = false;
    this.hierarchySelected = this.description;
  }

  onApplyFilter(object: any) {
    this.MinimumAmount = object.minValue;
    this.MaximumAmount = object.maxValue;
    this.getTicketsQueryArguments.MinimumAmount = this.MinimumAmount;
    this.getTicketsQueryArguments.MaximumAmount = this.MaximumAmount;
    this.getGeneralQuery(this.getTicketsQueryArguments);
    this.isShownValue = false;
    this.valuesCustom = this.MinimumAmount + '-' + this.MaximumAmount;
  }

  onPaginationChange(page: number) {
    if (this.getTicketsQueryArguments.page !== page) {
      this.getTicketsQueryArguments.page = page;
      this.getGeneralQuery(this.getTicketsQueryArguments);
    }
  }

  onOrderBySelect(event: any) {
    this.getTicketsQueryArguments.page = 1;
    this.getTicketsQueryArguments.orderBy = event.target.value;
    this.getGeneralQuery(this.getTicketsQueryArguments);
  }

  onPageSizeChange(event: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.getTicketsQueryArguments.page = 1;
    this.getTicketsQueryArguments.pageSize = event.target.value;
    this.getGeneralQuery(this.getTicketsQueryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.getTicketsQueryArguments.orderBy == field && !this.getTicketsQueryArguments.desc,
      'order-by-desc': this.getTicketsQueryArguments.orderBy == field && this.getTicketsQueryArguments.desc
    }
  }

  onOrderByChange(field: string) {
    if (this.getTicketsQueryArguments.orderBy === field) {
      this.getTicketsQueryArguments.desc = !this.getTicketsQueryArguments.desc;
    } else {
      this.getTicketsQueryArguments.orderBy = field;
      this.getTicketsQueryArguments.desc = true;
    }
    this.getGeneralQuery(this.getTicketsQueryArguments);
  }

  onFullNameKeyup($event: any) {
    this.getTicketsQueryArguments.page = 1;
    this.getTicketsQueryArguments.search = $event.target.value;
    this.filterDebounce.next(this.getTicketsQueryArguments);
  }

  onSearchClick(event: any) {
    this.getGeneralQuery(this.getTicketsQueryArguments);
  }

  onFilterDateGeneral(values: Date): void {
    values[0].setHours(0, 0, 0, 0);
    values[1].setHours(23, 59, 59, 0);
    this.toggleDateRangePickerGeneral();
    this.getTicketsQueryArguments.page = 1;
    this.getTicketsQueryArguments.startDate = values[0];
    this.getTicketsQueryArguments.endDate = values[1];
    this.filterDebounce.next(this.getTicketsQueryArguments)
  }

  setSevenDaysLastGeneral() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 7);
    this.dayCustomGeneral = [this.selectedDate, this.today];
    return this.dayCustomGeneral;
  }

  setThirtyDaysLastGeneral() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 30);
    this.dayCustomGeneral = [this.selectedDate, this.today];
    return this.dayCustomGeneral;
  }

  setFifteenDaysLastGeneral() {
    this.selectedDate = new Date();
    this.selectedDate.setDate(this.selectedDate.getDate() - 15);
    this.dayCustomGeneral = [this.selectedDate, this.today];
    return this.dayCustomGeneral;
  }

  toggleDropdown() {
    this.isOpenDropdown = !this.isOpenDropdown;
    this.isShownPeriod = false;
    this.open = false;
    this.isShownHierarchy = false;
    this.isShownValue = false;
    this.isShowType = false;
    this.hideDate();
  }

  toggleShowPeriod() {
    this.isDatePickerOpen = !this.isDatePickerOpen;
  }

  toggleDateRangePickerGeneral() {
    this.isShownPeriod = !this.isShownPeriod;
    this.isOpen = !this.isOpen;
    this.isShownHierarchy = false;
    this.isShownValue = false;
    this.isShowType = false;
    this.toggleShowPeriod();
  }

  toggleShowValue() {
    this.isShownValue = !this.isShownValue;
    this.isShownPeriod = false;
    this.isShownHierarchy = false;
    this.isOpen = false;
    this.isShowType = false;
  }

  toggleShowHierarchy() {
    this.isShownHierarchy = !this.isShownHierarchy;
    this.isShownPeriod = false;
    this.isShownValue = false;
    this.isOpen = false;
    this.isShowType = false;
  }

  resetFilters() {
    this.formExtract.get('radioType').reset();
    this.tiposelecionado = 'Selecione';
    this.formDateGeneral.get('dateGeneral').reset();
    this.dayCustomGeneral = [];
    this.valuesCustom = null;
    this.hierarchySelected = null;
    this.getTicketsQueryArguments.page = 1;
    this.getTicketsQueryArguments.pageSize = this.perPages[0];
    this.getTicketsQueryArguments.filter = 1;
    this.getTicketsQueryArguments.TransactionType = 0;
    this.getTicketsQueryArguments.MinimumAmount = 0;
    this.getTicketsQueryArguments.MaximumAmount = 0;
    this.getTicketsQueryArguments.search = "";
    this.getTicketsQueryArguments.startDate = this.oldDate;
    this.getTicketsQueryArguments.endDate = new Date();
    this.getTicketsQueryArguments.HierarchyId = 0;
    this.getTicketsQueryArguments.isEstacionamento = null;
    this.getGeneralQuery(this.getTicketsQueryArguments);
  }

  toggleDateAfterChangeTab(tab) {
    if (tab == 1) {
      if (this.isOpenDropdown) {
        if (this.isDatePickerOpen) {
          this.showDate()
        }
      }
      else {
        this.isDatePickerOpen = false;
        this.hideDate()
      }
    }
    else {
      if (this.isDatePickerOpen) {
        this.hideDate()
      }
    }
  }

  hideDate() {
    this.element = document.getElementById('hideGeneralDate') as HTMLElement;
    this.element?.click();
  }

  showDate() {
    this.element = document.getElementById('showGeneralDate') as HTMLElement;
    this.element?.click();
  }

  toggleShowType() {
    this.isShowType = !this.isShowType;
    this.isShownPeriod = false;
    this.isShownValue = false;
    this.isOpen = false;
  }

  onFilterTypeClick($event) {
    this.tiposelecionado = $event.name;
    this.getTicketsQueryArguments.isEstacionamento = $event.id === 0;
    this.getTicketsQueryArguments.page = 1;
    this.getTicketsQueryArguments.search = null;
    this.isShowType = false;
    this.filterDebounce.next(this.getTicketsQueryArguments)
  }

  hideDropdown() {
    this.element = document.getElementById('button-animated') as HTMLElement;
    this.element.click();
  }

  Download() {
    this.message = 'Download em andamento...'
    this.DownloadCSV(this.getTicketsQueryArguments);
  }


  public DownloadCSV(args: GetTicketsQueryArguments) {
    let now = new Date;
    let date = moment(now).format('YYYY_MM_DD')
    this.isLoading = true
    this.ticketsService.GetCSVFile(args)
      .pipe(
        finalize(() => {
          this.isLoading = false
        })
      )
      .subscribe(x => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'Detalhamento de Passagens_' + date + '.csv';
        element.click();
      });
  }


}
