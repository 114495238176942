<div class="modal-content ">

  <div class="modal-header">
    <div class="box-title">
      <span>Desbloquear TAG</span>
    </div>
    <button type="button" class="close" (click)="close()" id="btn-close">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="row-box-yellow">
      <span class="box-yellow">
        <b>O</b> desbloqueio da TAG tem um prazo de até 6 horas para ser<br> efetivado em todos os estabelecimentos do Brasil.  
      </span>
    </div>
  </div>

  <div class="modal-body">
    <div class="row row-animation">
      <h3 class="mt-5 font-weight-bold">Tem certeza de deseja desbloquear a tag abaixo:</h3>
    </div>
    <div class="row row-modal-collapse">
      <div class="col-lg-12 col-vehicle-tag">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-lg-4">
            <p>Veículo</p>
            <span></span>
            <b id="licensePlate">{{ vehicle.licensePlate | mask: 'AAA-AAAA' }}</b>
          </div>
          <div class="col-lg-4">
            <p>TAG</p>
            <span></span>
            <b id="serialTag">{{ vehicle.serialTag }} </b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer modal-footer-gray">
    <button [disabled]="onSaving" type="button" class="btn btn-tertiary mr-4" (click)="close()" id="btn-cancel">Cancelar</button>
    <button [disabled]="onSaving" class="btn btn-primary ml-4" (click)="unlockTag()" id="btn-confirm">
      <img class="button-icon" *ngIf="!onSaving" src="/assets/img/unlock.svg" />
      <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
      Confirmar desbloqueio
    </button>
  </div>
