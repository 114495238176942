import { PaymentMethodComponent } from 'src/app/shared/components/payment/payment-method/payment-method.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RechargeComponent } from './recharge.component';
import { ChangeAutoRechargeModalComponent } from './components/change-auto-recharge-modal/change-auto-recharge-modal.component';
import { RechargeRoutingModule } from './routes/recharge-routing.module';
import { NgxCurrencyDirective } from "ngx-currency";
import { CarouselModule } from 'primeng/carousel';
import { SharedModule } from 'src/app/shared/shared.module';
import { RechargeFeeModalComponent } from './components/recharge-fee-modal/recharge-fee-modal.component';

@NgModule({
  imports: [
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SharedModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RechargeRoutingModule,
    NgxCurrencyDirective,
    CarouselModule,
  ],
  declarations: [
    RechargeComponent,
    ChangeAutoRechargeModalComponent,
    RechargeFeeModalComponent
  ],
  providers: [provideNgxMask(), PaymentMethodComponent],
})

export class RechargeModule {}
