<div class="SelectProfileComponent">
  <div class="container header-page">
    <h1 *ngIf="isFinancialBlockingPosPaidPath; else selectProfileDefault" data-e2e="page-title" translate>
      Financial blocking and unblocking
    </h1>

    <ng-template #selectProfileDefault data-e2e="page-title">
      <span>Toll Management</span>
    </ng-template>
  </div>

  <app-card-select-contracts [contractList]="contractAvailables" [totalContractsList]="totalContracts"
    (emitterValuesForm)="loadContracts(true, $event)" [haveManyContracts]="true"
    (emitterContract)="verifyContract($event)" (emitterOpenModal)="showEditContractNameModal($event)"
    (pageChangeEvent)="onPaginationChange($event)" [isLoading]="isLoading" [title]="dynamicTitle"
    (searchFormValues)="handleSearchFormValues($event)" [searchFieldParams]="searchFieldParams">
  </app-card-select-contracts>


</div>