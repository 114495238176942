import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';

import { UserConsultantQueryArgs } from './models/user-consultant-query.arguments';
import { UserConsultantDeleteModalComponent } from './components/user-consultant-delete-modal/user-consultant-delete-modal.component';
import { UserConsultantViewService } from './services/user-consultant-view.service';
import { TableHeadersUserConsultantConst } from './constants/const-user-consultant-delete';
import moment from 'moment';
import {RoutesPaths} from "../../../core/utils/routes/routes-paths";
import {UserDetailsViewDto} from "@modules/backoffice/user-consultant-view/dtos/user-details-view.dto";

@Component({
  selector: 'app-user-consultant-view',
  templateUrl: './user-consultant-view.component.html',
  styleUrls: ['./user-consultant-view.component.scss']
})
export class UserConsultantViewComponent  {
  isLoading: boolean;
  isLoadingTableData: boolean;
  messageLoader: string;
  tableHeaders = TableHeadersUserConsultantConst;
  pageSize: number[];
  userQueryArgs: UserConsultantQueryArgs;
  usersDetailsList: UserDetailsViewDto[];
  totalItems: number;
  userConsultantSearchForm: FormGroup;
  haveResults: boolean;

  constructor(
    private _userConsultantViewService: UserConsultantViewService,
    private _modalService: NgbModal,
    private _router: Router,
  ) {
    this.settingsInitClass();
    this.getRelationshipConsultantsList();
  }

  settingsInitClass(): void {
    this.isLoading = true;
    this.haveResults = true;
    this.messageLoader = 'Carregando...';
    this.pageSize = [5, 10, 20, 50];
    this.usersDetailsList = [];
    this.userQueryArgs = new UserConsultantQueryArgs();

    this.userConsultantSearchForm = new FormGroup({
      searchField: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-zÀ-ÖØ-öø-ÿ]+( [A-Za-zÀ-ÖØ-öø-ÿ]+)*$')]),
      pageSize: new FormControl('5', [Validators.required]),
    });
  }

  getRelationshipConsultantsList() {
    this.isLoading = false;
    this.isLoadingTableData = true;

    this._userConsultantViewService
      .getRelationshipConsultantsList(this.userQueryArgs)
      .pipe(
        finalize(() => {
          this.isLoadingTableData = false;
        })
      )
      .subscribe({
        next: (success) => {
          if (success.success) {
            this.usersDetailsList = success.data;
            this.totalItems = success.totalItems;
            this.haveResults = this.usersDetailsList.length > 0;
          }
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }

  DownloadCsv() {
    this.isLoadingTableData = true;
    this.messageLoader = 'Download em processo...';

    let now = new Date();
    let date = moment(now).format('DDMMYYYY');

    this._userConsultantViewService
      .getRelationshipConsultantsListCSV(this.userQueryArgs)
      .pipe(
        finalize(() => {
          this.isLoadingTableData = false;
          this.messageLoader = 'Carregando...';
        }),
      )
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });

        element.href = URL.createObjectURL(newBlob);
        element.download = 'Consultores cadastrados ' + date + '.csv';
        element.click();
      });
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.userQueryArgs.sortField == field && !this.userQueryArgs.desc,
      'order-by-desc':
        this.userQueryArgs.sortField == field && this.userQueryArgs.desc,
    };
  }

  onOrderByChange(field: string) {
    if (this.userQueryArgs.sortField == field) {
      this.userQueryArgs.desc = !this.userQueryArgs.desc;
    }
    else {
      this.userQueryArgs.sortField = field;
      this.userQueryArgs.desc = true;
    }

    this.userQueryArgs.page = 1;
    this.getRelationshipConsultantsList();
  }

  onPaginationChange(page: number) {
    this.userQueryArgs.page = page;
    this.getRelationshipConsultantsList();
  }

  onPageSizeChange(event: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.userQueryArgs.page = 1;
    this.userQueryArgs.pageSize = event.target.value;
    this.getRelationshipConsultantsList();
  }

  openDeleteModal(user: UserDetailsViewDto) {
    const modal = this._modalService.open(UserConsultantDeleteModalComponent);
    modal.componentInstance.userDetails = user;

    modal.componentInstance.eventHandlePage.subscribe(() => {
      this.userQueryArgs = new UserConsultantQueryArgs();
      this.getRelationshipConsultantsList();
    });
  }

  navigateToUpdatePage(userId: number) {
    this._router.navigate(['/backoffice/gestao-acessos/editar-consultor', userId]);
  }

  onSearchKeyUp(event: any) {
    if (this.userConsultantSearchForm.valid) {
      this.userQueryArgs.page = 1;
      this.userQueryArgs.search = event.target.value;
      this.getRelationshipConsultantsList();
    }
    else if (this.userConsultantSearchForm.controls.searchField.value == '') {
      this.userQueryArgs.page = 1;
      this.userQueryArgs.search = '';
      this.getRelationshipConsultantsList();
    }
    else {
      this.userConsultantSearchForm.controls.searchField.markAsTouched();
    }
  }

  onBlur() {
    if (this.userConsultantSearchForm.controls.searchField.value == '') {
      this.userConsultantSearchForm.controls.searchField.reset();
      this.userConsultantSearchForm.controls.searchField.markAsUntouched();
    }
  }

}
