import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BillHierarchyQueryArguments } from './dto/bill-hierarchy-query.arguments';
import { BillHierarchyResultDto,Item } from './dto/bill-hierarchy-result.dto';
import { BillDetailHierarchy} from './services/bill-detail-hierarchy.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BillHierarchyBreadCrumbResultDto } from './dto/bill-hierarchy-bread-crumb-result.dto';



@Component({
  selector: 'app-bill-detail-hierarchy',
  templateUrl: './bill-detail-hierarchy.component.html',
  styleUrls: ['./bill-detail-hierarchy.component.scss']
})
export class BillDetailHierarchyComponent implements OnInit {

  billId:number;
  BillHierarchyQueryArguments: BillHierarchyQueryArguments;
  BillHierarchyResultDto: BillHierarchyResultDto;
  filterDebounce: Subject<BillHierarchyQueryArguments> = new Subject<BillHierarchyQueryArguments>();
  //BillHierarchyBreadCrumbResultDto: BillHierarchyBreadCrumbResultDto;

  isLoading: boolean;
  perPages = []
  vehicleOpen: Array<boolean> = []
  Empty: boolean;
  open: boolean;
  isOpenDropdown: boolean;


  constructor(private billDetail: BillDetailHierarchy,  private actRoute: ActivatedRoute) {
    this.billId = this.actRoute.snapshot.params.id;
  }


  ngOnInit(){
    this.getHierarchyList();
    this.open = false;
    this.BillHierarchyResultDto = {
      success: true,
      message: '',
      data: []
    };


    this.perPages = [10, 20, 50];
    this.BillHierarchyQueryArguments = new BillHierarchyQueryArguments();
    this.BillHierarchyQueryArguments.id = this.billId;
    this.BillHierarchyQueryArguments.page = 1;
   // this.BillHierarchyQueryArguments.sortField = null;
   // this.BillHierarchyQueryArguments.search = null;
   // this.BillHierarchyQueryArguments.pageSize = 10;
    this.BillHierarchyQueryArguments.pageSize = 2147483647;
    this.BillHierarchyQueryArguments.sortField = 'amountTotal';
    this.BillHierarchyQueryArguments.SearchHierarchy = ''
    this.BillHierarchyQueryArguments.desc = false;
    this.filterDebounce.pipe(debounceTime(300)).subscribe((filter) => this.getBillDetailHierarchy(filter));
    this.getBillDetailHierarchy(this.BillHierarchyQueryArguments);

  }

  getBillDetailHierarchy(args: BillHierarchyQueryArguments){
    this.isLoading = true;
    this.billDetail.getBillHierarchy(args)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (success) => {
        this.VehiclesList(success)
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }

  getHierarchyList(){
    this.isLoading = true;
    this.billDetail.getHierarchyBreadCrumbList()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
      (success) => {
        this.HierarchyList(success)
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }


  getBreadCrumb(id){
    let hierarchy = this.Hierarchy ? this.Hierarchy.data.filter(item => item.id === id) : null;
    return hierarchy ? hierarchy.map(item => item.breadCrumb).toString() : '';
  }

  public Hierarchy
  HierarchyList(list){
   this.Hierarchy = list
  }


  VehiclesList(list) {
    this.BillHierarchyResultDto = list

    if(list.data.toString() == ''){
     this.Empty = true;
    }
    else{
      this.Empty = false;
    }

    for(var count=0;count<list.data.length;count++){
      this.vehicleOpen[count] = false
    }


  }

  toogle() {
      this.isOpenDropdown = !this.isOpenDropdown;
      this.open =  !this.open;
    }


onFullNameKeyup($event: any) {
  this.BillHierarchyQueryArguments.page = 1;
  this.BillHierarchyQueryArguments.SearchHierarchy = $event.target.value;
  this.filterDebounce.next(this.BillHierarchyQueryArguments);

  this.getBillDetailHierarchy(this.BillHierarchyQueryArguments);

}

onSearchClick(event: any) {
    this.BillHierarchyQueryArguments.page = 1;
    this.getBillDetailHierarchy(this.BillHierarchyQueryArguments);
}

SelectVehicle(i){
  for(var count=0;count<this.BillHierarchyResultDto.data.length;count++){
    this.vehicleOpen[count] = false
  }
  this.vehicleOpen[i] = true

}

ChangeClassSelect(i){
  if(this.vehicleOpen[i] == true){
    return 'selected'
  }
  else{
    return ''
  }
}


}
