import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HierarchyComponent } from './hierarchy.component';
import { AddVehicleHierarchyComponent } from './components/add-vehicle-hierarchy/add-vehicle-hierarchy.component';
import { ManageVehiclesHierarchyComponent } from './components/manage-vehicles-hierarchy/manage-vehicles-hierarchy.component';
import { RemoveHierarchyModalComponent } from './components/remove-hierarchy-modal/remove-hierarchy-modal.component';
import { ViewVehicleComponent } from './components/view-vehicle/view-vehicle.component';
import { HierarchyRoutingModule } from './routes/hierarchy-routing.module';
import { TreeNgxModule } from "tree-ngx";
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CdkTreeModule} from '@angular/cdk/tree';
import { CdkTreeComponent } from './components/cdk-tree/cdk-tree.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule} from '@angular/material/button';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TreeNgxModule,
    HierarchyRoutingModule,
    CdkTreeModule,
    BsDropdownModule,
    MatIconModule,
    MatButtonModule,
    PopoverModule.forRoot(),
  ],
  declarations: [
   HierarchyComponent,
   AddVehicleHierarchyComponent,
   ManageVehiclesHierarchyComponent,
   RemoveHierarchyModalComponent,
   ViewVehicleComponent,
   CdkTreeComponent
  ],
  providers: [provideNgxMask()],
})

export class HierarchyModule {}
