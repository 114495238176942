<div class="container-fluid page-myaccount">
    <div class="row">
        <div class="col-12 Dashboard_Overview">
            <h1 data-e2e="page-title">/ PASSAGENS</h1>
        </div>

        <div class="col-lg-12">
            <div class="container col-md-12 mt-5">
                <tabset #tabset class="tabset tabset-custom">
                    <tab
                      data-e2e="general-tab"
                      heading="Geral"
                      id="tab1"
                      (selectTab)="
                        tabGeneral.toggleDateAfterChangeTab(1);
                        tabVehicles.toggleDateAfterChangeTab(1);
                        tabHierarchy.toggleDateAfterChangeTab(1);">
                      <app-tab-general #tabGeneral></app-tab-general>
                    </tab>

                    <tab
                      data-e2e="vehicles-tab"
                      heading="Veículos"
                      id="tab2"
                      (selectTab)="
                        tabGeneral.toggleDateAfterChangeTab(2);
                        tabVehicles.toggleDateAfterChangeTab(2);
                        tabHierarchy.toggleDateAfterChangeTab(2);">
                        <app-tab-vehicles #tabVehicles></app-tab-vehicles>
                    </tab>
                    <tab
                      data-e2e="hierarchy-tab"
                      heading="Hierarquia"
                      id="tab3"
                      (selectTab)="
                        tabGeneral.toggleDateAfterChangeTab(3);
                        tabVehicles.toggleDateAfterChangeTab(3);
                        tabHierarchy.toggleDateAfterChangeTab(3);">
                        <app-tab-hierarchy #tabHierarchy></app-tab-hierarchy>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>