<div class="editContractEmailModalComponent">
    <div class="modal-content">
        <form class="form" [formGroup]="emailForm" (ngSubmit)="onSubmit()">
                <div class="modal-header">
                <div class="box-title">
                    <span *ngIf="step==1" data-e2e="modal-title">Novo E-mail</span>
                    <span *ngIf="step==2" data-e2e="modal-title">código de verificação</span> 
                    <span *ngIf="step==3" data-e2e="modal-title">e-mail alterado com sucesso!</span>                                   
                </div>
                <button data-e2e="close-modal-top-btn" type="button" class="close" (click)="close()">
                    <span aria-hidden="true">×</span>
                </button>
                </div>
                <div class="modal-body show" *ngIf="step == 1">
                    <div class="email-form-message">Você receberá um código de confirmação no seu novo e-mail</div>
                    <div class="form-content">
                            <div class="form-field">
                                <label for="newEmail" data-e2e="new-email-label">Informe o novo e-mail</label>
                                <div class="form-input">
                                    <input
                                    [ngClass]="(emailForm.controls['newEmail']?.invalid && emailForm.controls['newEmail']?.touched)
                                    || (submitted && emailForm.controls['newEmail']?.invalid) ? 'invalidField' : ''"
                                    class="newEmailInput"
                                    data-e2e="new-email-input"
                                    id="newEmail"
                                    type="text"
                                    formControlName="newEmail">
                                    <div class="alert-error-validator" data-e2e="new-email-error-msg"
                                    *ngIf="(emailForm.controls['newEmail']?.invalid && emailForm.controls['newEmail']?.touched)
                                    || (submitted && emailForm.controls['newEmail']?.invalid)">
                                    {{ generateErrorMessage(emailForm.controls['newEmail'])}}
                                    </div>
                                </div>
                            </div>

                            <div class="form-field">
                                <label for="confirmationEmail" data-e2e="confirmation-email-label">Confirme o novo e-mail</label>
                                <div class="form-input">
                                    <input
                                    [ngClass]="(emailForm.controls['confirmationEmail']?.invalid && emailForm.controls['confirmationEmail']?.touched)
                                    || (submitted && emailForm.controls['confirmationEmail']?.invalid) ? 'invalidField' : ''"
                                    class="confirmationEmailInput"
                                    data-e2e="confirmation-email-input"
                                    id="confirmationEmail"
                                    type="text"
                                    formControlName="confirmationEmail">
                                        
                                    <div class="alert-error-validator" data-e2e="confirmation-email-error-msg"
                                    *ngIf="(emailForm.controls['confirmationEmail']?.invalid && emailForm.controls['confirmationEmail']?.touched)
                                    || (submitted && emailForm.controls['confirmationEmail']?.invalid)">
                                    {{ generateErrorMessage(emailForm.controls['confirmationEmail'])}}
                                    </div>
                                </div>
                            </div>
                         </div>   
                </div>
                <div class="modal-body show" *ngIf="step == 2">
                    <div class="message-update-email">
                        Insira o código que enviamos ao seu e-mail <strong>{{ this.emailForm.value.newEmail }}</strong>, para seguir com a alteração
                    </div>
                    <app-verification-code [type]="updateType.email"></app-verification-code>
                </div>
                <div class="modal-body show" *ngIf="step == 3">
                    <div class="success-img">
                        <img src="../../../assets/img/success-update.svg" />
                    </div>
                    <div class="success-message">Seu e-mail foi alterado de <strong>{{ currentEmailAddress }}</strong> para <strong>{{ this.emailForm.value.newEmail }}</strong>. Fique atento, as comunicações serão enviadas para o novo endereço de e-mail cadastrado.</div>
                </div>
                <div class="modal-footer modal-footer-gray" [ngClass]="step==3 ? 'modal-footer-confirmed' : ''">
                    <button *ngIf="step==1 || step==2" data-e2e="cancel-btn" type="button" class="btn btn-tertiary mr-4" (click)="close()" id="btn-close">Cancelar</button>
                    <button *ngIf="step==1" data-e2e="send-code-btn" type="submit" class="btn btn-secondary position-relative" [disabled]="emailForm.invalid || isLoading">
                        <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
                         Enviar Código
                    </button>   
                    <button *ngIf="step==2" data-e2e="confirm-btn" type="button" class="btn btn-secondary position-relative" [disabled]="isLoading || code?.length < 6 || !code" (click)="confirm()">
                        <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
                         Confirmar
                    </button> 
                    <button *ngIf="step==3" data-e2e="close-btn" type="button" class="btn btn-secondary position-relative" (click)="close()">
                         Fechar
                    </button>               
                </div>
    </form>
    </div>
</div>

