<div class="modal-content">
  <div class="modal-header">
    <ng-template #tutorialBtnTootltip>
      <p>Você pode ativar e desativar o tutorial clicando aqui.</p>

      <span (click)="onDismissTutorial()">Entendi, não mostrar mais</span>
    </ng-template>

    <div
      tooltipClass="tutorial-btn-tootltip"
      [ngbTooltip]="tutorialBtnTootltip"
      [ngClass]="{ active: tutorialMode != null }"
      class="tutorial-btn"
      [closeDelay]="2000"
      placement="right"
    >
      <div (click)="startTutorial($event)" class="tutorial-btn-content">
        <img src="../../../../assets/svg/lamp.svg" class="tutorial-img" />
        <a class="tutorial-txt"><p>Tutorial rápido</p></a>
      </div>
    </div>

    <div class="box-title">
      <span>Alertas de Limite</span>
    </div>

    <button (click)="dismissModal()" class="close" type="button">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="container-loading mb-5" *ngIf="isLoading">
    <app-loader
      [isLoading]="isLoading"
      message="{{ 'Carregando...' }}"
    ></app-loader>
  </div>

  <div class="modal-body show" *ngIf="!isLoading">
    <section class="alert-configuration" *ngIf="!isSuccessful">
      <h3>Configure seus alertas de consumo do limite:</h3>

      <hr class="division-line" />

      <div class="orange-box">
        <p>
          <span>A</span>tive a quantidade de alertas que deseja receber e
          preencha os valores
        </p>
      </div>

      <form (ngSubmit)="onSubmit()" #form="ngForm">
        <div class="row">
          <div class="alert-line">
            <img
              class="next-circle"
              src="../../../../assets/svg/next-circle.svg"
              alt=""
            />
            <img
              class="next-circle"
              src="../../../../assets/svg/next-circle.svg"
              alt=""
            />
          </div>

          <div class="alert-container" *ngFor="let alert of alerts; index as i">
            <div
              ngbTooltip="Você será alertado quando o seu consumo atingir a porcentagem inserida."
              class="alert-title"
              [openDelay]="300"
              placement="top"
            >
              <img src="../../../../assets/svg/info.svg" />
              <div class="">
                <p>Alerta {{ i + 1 }}</p>
              </div>
            </div>

            <div class="circle circle-1"></div>
            <div class="circle circle-2"></div>

            <div class="alert-box" [@boxPosition]="alert.order">
              <div class="alert-activation-box">
                <div class="checkbox-primary">
                  <input
                    (click)="onEnableAlert(i)"
                    [checked]="alert.enabled"
                    class="checkbox-input"
                    type="checkbox"
                  />
                  <span class="checkmark"></span>
                </div>
              </div>

              <fieldset
                class="alert-content text-center"
                [disabled]="!alert.enabled"
              >
                <label for="">Digite a porcentagem</label>

                <div class="percentage-symbol">%</div>
                <input
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="alert.percentageLimit"
                  (change)="checkInputLimit(alert)"
                  type="number"
                  [name]="i"
                />
              </fieldset>
            </div>
          </div>
        </div>

        <hr />

        <div class="row email-alerts">
          <div class="email-content">
            <div class="checkbox-email">
              <input
                [(ngModel)]="sendEmailNotification"
                name="emailNotification"
                class="checkbox-input"
                type="checkbox"
              />
              <span class="checkmark"></span>
            </div>
  
            <span class="email-alerts-txt"
              >Desejo também receber alertas por e-mail</span
            >
          </div>
       
        </div>

        <div class="modal-footer d-fex justify-content-between">
          <button
            class="btn btn-tertiary mr-4"
            (click)="dismissModal()"
            id="btnCancel"
            type="button"
          >
            Cancelar
          </button>

          <button
            class="btn btn-primary position-relative"
            id="submitFormAddAlert"
            type="submit"
          >
            <img
              src="../../../../assets/svg/save.svg"
              *ngIf="!isLoading"
              class="mr-2"
            />
            <span *ngIf="!isLoading">Salvar Alertas</span>

            <span *ngIf="isLoading" class="submit-btn mr-3">Carregando...</span>
            <img
              src="../../../assets/img/loader.gif"
              class="loading-btn"
              *ngIf="isLoading"
              alt="Loading"
            />
          </button>

          <div style="width: 130px"></div>
        </div>
      </form>
    </section>

    <section class="success-view" *ngIf="isSuccessful">
      <div class="row">
        <div class="col-5">
          <svg width="270" height="270">
            <use
              href="../../../../assets/svg/field-bg-checkbox.svg#place"
            ></use>
          </svg>

          <p class="notification-box">
            Você será notificado dos alertas de limite por e-mail e aqui na
            plataforma. Fique atento na área de notificações.
          </p>

          <img
            src="../../../../assets/svg/top-curve-dotted-arrow.svg"
            class="red-arrow"
            alt=""
          />

          <svg class="alert-bell-notification" viewBox="-5 -15 75 68">
            <use href="../../../../assets/svg/alert-bell.svg#alertBell"></use>
          </svg>
        </div>

        <div class="col-7">
          <h1>Alertas Configurados</h1>

          <div class="alerts-container">
            <div
              *ngFor="let alert of alerts; index as i"
              class="row alert-box-success"
            >
              <div class="col-4 alert-details">
                <h6>Alerta {{ i + 1 }}</h6>

                <span class="percentage-amount">{{
                  alert.percentageLimit
                }}</span>
                <span>%</span>
              </div>

              <div class="col-5 alert-status">
                <div>
                  <div class="checkbox-primary">
                    <input
                      [checked]="alert.enabled"
                      onclick="return false;"
                      class="checkbox-input"
                      type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </div>
                </div>

                <p *ngIf="alert.enabled">Ativado</p>
                <p *ngIf="!alert.enabled">Desativado</p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-secondary" (click)="dismissModal()">
            Fechar
          </button>
        </div>
      </div>
    </section>

    <section class="tutorial-section" *ngIf="tutorialMode">
      <div [@tutorialBground]="tutorialMode"></div>

      <article *ngIf="tutorialMode === 'intro'" class="tutorial-intro row">
        <div class="col-4">
          <img
            class="tutorial-intro-img"
            src="../../../../assets/svg/texting-person.svg"
          />
        </div>

        <div class="col-7 mt-5 ml-4">
          <h1 class="py-4">Olá,</h1>
          <h2>
            Agora você poderá configurar alertas de consumo do limite do seu
            contrato!
          </h2>

          <img
            src="../../../../assets/svg/top-curve-dotted-arrow.svg"
            class="red-arrow"
            alt=""
          />

          <button
            class="btn btn-primary"
            (click)="onChangeTutorialSteps('STEP_1')"
          >
            Veja como é simples<i class="ico ico-double-arrow"></i>
          </button>
        </div>
      </article>

      <article *ngIf="tutorialMode === 'step1'" class="tutorial-step1">
        <div class="tutorial-tooltip-box tooltip-alert-title">
          <div class="tutorial-tooltip">
            O seu Alerta 1 já vem configurado automaticamente com o valor de 90%, mas você poderá alterar esse valor a qualquer momento.
          </div>

          <div class="arrow-down"></div>
        </div>

        <div class="tutorial-tooltip-box tooltip-alert-box">
          <div class="arrow-up"></div>

          <div class="tutorial-tooltip">
            <p>
              Quando o seu consumo chegar a 90% do seu limite contratado você será alertado na aba de notificações do portal e por e-mail, caso deseje.
            </p>

            <img
              src="../../../../assets/svg/top-curve-dotted-arrow.svg"
              class="dotted-arrow"
            />

            <img
              src="../../../../assets/svg/alert-bell.svg"
              class="bell-icon"
            />

            <button
              class="btn btn-primary"
              (click)="onChangeTutorialSteps('STEP_2')"
            >
              Veja um exemplo <i class="ico ico-double-arrow"></i>
            </button>
          </div>
        </div>

        <div class="tutorial-tooltip-box tooltip-alert-2">
          <div class="tutorial-tooltip">
            Marque ou desmarque para ativar ou desativar o alerta desejado
          </div>
          <div class="arrow-down"></div>
        </div>

        <div class="tutorial-tooltip-box tooltip-alert-3">
          <div class="tutorial-tooltip">
            Marque ou desmarque para ativar ou desativar o alerta desejado
          </div>

          <div class="arrow-down"></div>
        </div>
      </article>

      <article *ngIf="tutorialMode === 'step2'" class="tutorial-step2">
        <button
          class="btn btn-primary"
          (click)="onChangeTutorialSteps('STEP_1')"
        >
          <img class="mr-2" src="../../../../assets/img/ico-arrow-left.svg" />

          Voltar para dicas
        </button>

        <div class="tutorial-tooltip-box tutorial-box-example">
          <div class="tutorial-tooltip">
            <h4>Exemplo:</h4>

            <span>Limite contratado:</span>
            <p class="example-txt">R$ 1.000,00</p>

            <br>

            <span class="mt-2">Alerta 2:</span>
            <p class="example-txt">50%</p>

            <p class="mt-2">
              Neste exemplo, com o alerta de 50% configurado, quando o seu
              consumo chegar a R$ 500,00, você receberá um alerta.
            </p>
          </div>

          <div class="arrow-right"></div>
        </div>

        <div class="alert-container">
          <div class="alert-title">
            <div class="">
              <p>Alerta 2</p>
            </div>
          </div>

          <div class="circle circle-1"></div>
          <div class="circle circle-2"></div>

          <div class="alert-box">
            <div class="alert-activation-box">
              <div class="checkbox-primary">
                <input
                  onclick="return false;"
                  class="checkbox-input"
                  type="checkbox"
                  checked
                />
                <span class="checkmark"></span>
              </div>
            </div>

            <fieldset class="alert-content text-center">
              <label for="">Digite a porcentagem</label>

              <div class="percentage-symbol">%</div>
              <input value="50" type="number" disabled />
            </fieldset>
          </div>
        </div>

        <div class="tutorial-right-box">
          <span>Viu como é fácil?</span>
          <br />
          <span
            >Agora é só configurar os seus alertas de acordo com o seu gosto!</span
          >
          <br />

          <span>obs:</span>
          <p>
            Todos os alertas configurados podem ser alterados ou removidos a qualquer momento.
          </p>
        </div>
      </article>

      <button
        class="btn btn-secondary tutotial-ext-btn"
        (click)="tutorialMode = null"
      >
        Fechar Tutorial
      </button>
    </section>
  </div>
</div>
