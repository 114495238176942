import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { VehicleStatistics } from "@models/statistics.model";
import { Vehicle } from "@models/vehicle.model";
import { VehicleCategoryAxleWheelType } from "@models/old/vehicle/vehicleCategoryAxleWheelType.model";
import { Hierarchy } from "@models/old/hierarchy.model";
import { VehicleStatistic } from "@models/old/vehicle/vehicleStatistic.model";
import { GetVehiclesQueryResultDto } from "../dtos/get-vehicles-query-result.dto";
import { GetVehicleQueryResultDto } from "../dtos/get-vehicle-query-result.dto";
import { DeleteVehiclesQueryResultDto } from "../dtos/delete-vehicles-query-result.dto";
import { GetHierarchyWithoutChildrensResultDto } from "../dtos/get-hierarchy-without-childrens-result.dto";
import { GetVehiclesQueryArguments } from "../dtos/get-vehicles-query-arguments.dto";
import generateCVS from "@functions/generateCVS";
import { ContractVehicleTagReplacement } from "@models/old/contractvehicletag/contractvehicletagreplacement.model";
import {
  ContractVehicleTagReplacementResult
} from "@models/old/contractvehicletag/contractvehicletagreplacementresult.model";

@Injectable({
  providedIn: "root",
})
export class MyvehiclesService {
  private _base = environment.apiTMVehicle;
  private _tmtag = environment.apiTMTag;
  private _httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*",
    }),
  };

  notExistsItems: boolean = false;
  apiURL: string;

  constructor(protected _http: HttpClient) { }

  public async saveVehicle(entity: Vehicle): Promise<Object> {
    try {
      let apiUrl = `${this._base}/Vehicle`;
      if (entity.aggregateDriver === false) {
        entity.documentNumber = "";
        entity.rntrc = "";
      }
      if (entity.id > 0)
        var result = await this._http
          .put(apiUrl, entity, this._httpOptions)
          .toPromise();
      else
        var result = await this._http
          .post(apiUrl, entity, this._httpOptions)
          .toPromise();

      return result;
    } catch (e) {
      console.error(e.message);

      if (e.error === undefined) throw "Ocorreu um erro ao salvar o veículo";
      else throw e;

      return null;
    }
  }

  public async getVehicleCategoryAxleWheelType(
    idVehicleType: Number
  ): Promise<Array<VehicleCategoryAxleWheelType>> {
    try {
      var apiUrl = `${this._base}/VehicleCategory/GetVehicleCategoryAxleWheelTypeByVehicleTypeId?VehicleTypeId=${idVehicleType}`;
      var result = await this._http
        .get<Array<VehicleCategoryAxleWheelType>>(apiUrl)
        .toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

  public async getHierarchies(): Promise<Hierarchy> {
    try {
      var apiUrl = `${this._base}/Hierarchy/GetHierarchies`;
      var result = await this._http.get<Hierarchy>(apiUrl).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

  async getVehiclesByContractId(): Promise<Vehicle[]> {
    try {
      let apiUrl = `${this._base}/Vehicle/GetVehicles`;
      var result = await this._http.get<Vehicle[]>(apiUrl).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

  async getVehicleStatisticByContractId(): Promise<VehicleStatistic> {
    try {
      let apiUrl = `${this._base}/Vehicle/GetVehicleStatisticsByContract`;
      var result = await this._http.get<VehicleStatistic>(apiUrl).toPromise();
      return result;
    } catch (e) {
      console.error(e.message);
      return null;
    }
  }

  GetVehicleTagDetails(contract) {
    return this._http.get<any>(
      this._tmtag +
      `/api/TagVehicle/GetVehicleTagDetails?contractVehicleId=` +
      contract
    );
  }
  getVehicleType() {
    return this._http.get<any[]>(`${this._base}/VehicleType/GetVehicleTypes`);
  }
  getBrandTypes() {
    return this._http.get<any[]>(`${this._base}/BrandType/GetBrandTypes`);
  }
  getStatistic(): Observable<VehicleStatistics> {
    return this._http.get<VehicleStatistics>(`${this._base}/Vehicle/GetVehicleStatistics`);
  }
  onAddVehicle(addVehicle: any) {
    return this._http.post(`${this._base}/Vehicle`, addVehicle);
  }
  onEditVehicle(addVehicle: any) {
    return this._http.put(`${this._base}/Vehicle`, addVehicle);
  }
  insertAndUpdate(addVehicle: any) {
    return this._http.put(`${this._base}/Vehicle/InsertAndUpdate`, addVehicle);
  }
  getVehicles(args: GetVehiclesQueryArguments) {
    return this._http.get<GetVehiclesQueryResultDto>(
      `${this._base}/Vehicle/GetVehicles`,
      { params: args.getHttpParams }
    );
  }
  getHierarchy() {
    return this._http.get<any[]>(`${this._base}/GroupHierarchy/GetHierarchy`);
  }
  getVehicleById(id: number) {
    return this._http.get<GetVehiclesQueryResultDto>(
      `${this._base}/Vehicle/GetVehicles/${id}`
    );
  }
  getVehicleBoard(board: number) {
    return this._http.get<GetVehicleQueryResultDto>(
      `${this._base}/Vehicle/GetVehicle/${board}`
    );
  }
  deleteVehicleId(id: number) {
    return this._http.delete<DeleteVehiclesQueryResultDto>(
      `${this._base}/Vehicle/${id}`
    );
  }
  getHierarchyWithoutChildrens() {
    return this._http.get<GetHierarchyWithoutChildrensResultDto[]>(
      `${this._base}/GroupHierarchy/GetHierarchyWithoutChildrensList`
    );
  }

  //Download CSV
  getCSVFile(args: GetVehiclesQueryArguments) {
    let uri = this._base + `/Vehicle/GetVehiclesCsv`;
    return this._http
      .get(uri, {
        observe: "response",
        responseType: "blob",
        params: args.getHttpParams,
      })
      .pipe(
        map((response) => {
          return generateCVS(response);
        })
      );
  }

  cancelTag(cancelTag: any) {
    return this._http.post(
      environment.apiTMTag +
      `/api/ContractVehicleTag/SaveContractVehicleTagCancel`,
      cancelTag
    );
  }

  saveVehiclesByUploadFile(file: any) {
    return this._http.post(`${this._base}/Vehicle/AddBatchVehicle`, file);
  }

  blockTag(blockTag: any) {
    return this._http.post(`${this._tmtag}/api/ContractVehicleTag/SaveContractVehicleTagBlock`, blockTag);
  }

  unlockTag(unlockTag: any) {
    return this._http.post(`${this._tmtag}/api/ContractVehicleTag/SaveContractVehicleTagUnblock`, unlockTag);
  }

  public saveContractVehicleTagReplacement(
    entity: ContractVehicleTagReplacement,
  ): Observable<ContractVehicleTagReplacementResult> {
    const apiUrl = `${environment.apiTMTag}/api/ContractVehicleTag/SaveContractVehicleTagReplacement`;
    const body = JSON.stringify(entity);
    const result = this._http.put<ContractVehicleTagReplacementResult>(
      apiUrl,
      body,
      this._httpOptions,
    );
    return result;
  }

  onTransferWithTagReplacementAsync(vehicle: any) {
    return this._http.post(
      environment.apiTMTag + `/api/TagVehicle/TransferWithTagReplacementAsync`,
      vehicle,
    );
  }

  onTransferVehicleWithoutTag(vehicle: any) {
    return this._http.post(
      environment.apiTMTag + `/api/TagVehicle/TransferWithoutTagAsync`,
      vehicle,
    );
  }

  onTransferVehicle(vehicle: any) {
    return this._http.post(
      environment.apiTMTag + `/api/TagVehicle/TransferAsync`,
      vehicle,
    );
  }
}
