<form #f="ngForm" (ngSubmit)="saveNewTag(f)" class="modal-content">
  <header class="modal-header">
    <div class="box-title">
      <span>Incluir nova TAG</span>
    </div>
    <button type="button" class="close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </header>

  <main class="modal-body show">
    <div class="row row-header">
      <div class="col-lg-12 desc">
        <p class="title">É possível inserir uma nova tag adquirida</p>
        <p class="subtitle"></p>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-6">
        <fieldset class="form-group">
          <label for="formNomeInput" class="text-center d-block"
            >Digite o número da tag</label
          >
          <input
            type="text"
            ngModel
            class="form-control ico-inserttext text-uppercase"
            #serial="ngModel"
            name="serial"
            id="serial"
            required
            minlength="16"
            maxlength="16"
          />

          <p
            *ngIf="serial.invalid && serial.touched"
            class="font-weight-bold text-danger"
          >
            Por favor, adicione uma tag válida
          </p>

          <p *ngIf="error" class="font-weight-bold text-danger">
            {{ error.message }}
            <a
              *ngIf="
                error.data?.tagRequestStatusTypeId === orderStatus.IN_TRANSIT
              "
              ><u (click)="onCallOrderConfirmationModal(orderId)">Clique aqui</u> para
              confirmar seu pedido</a
            >
          </p>
        </fieldset>
      </div>
    </div>
  </main>

  <footer class="modal-footer">
    <button
      type="button"
      id="CancelButton"
      class="btn btn-tertiary mr-4"
      (click)="modalRef.hide()"
    >
      Cancelar
    </button>

    <button
      type="submit"
      id="ConfirmButton"
      class="btn btn-secondary"
      [disabled]="f.invalid"
    >
      <span *ngIf="!onSaving">Confirmar</span>

      <img
        class="loading-btn"
        *ngIf="onSaving"
        src="../../../assets/img/loader.gif"
        alt="Loading"
      />
    </button>
  </footer>
</form>
