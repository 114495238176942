import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { finalize, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteDeliveryAddressComponent } from '../delete-delivery-address/delete-delivery-address.component';
import { AddDeliveryAddressComponent } from '../add-delivery-address/add-delivery-address.component';
import { EditDeliveryAddressComponent } from '../edit-delivery-address/edit-delivery-address.component';
import { ResultEventAddress } from '@models/old/resultEventAddress.model';
import { GetDeliveryAddressesQueryArguments } from '@models/get-delivery-addresses-query-arguments.model';
import { AddressService } from '@services/address.service';

@Component({
  selector: 'app-delivery-addresses-modal',
  templateUrl: './delivery-addresses-modal.component.html',
  styleUrls: ['./delivery-addresses-modal.component.scss']
})
export class DeliveryAddressesModalComponent implements OnInit {

  @Input() fromOrder:boolean;
  deliveryAddresses: any;
  getDeliveryAddressesQueryArguments: GetDeliveryAddressesQueryArguments
  search:String = null
  isLoading: boolean;
  filterDebounce: Subject<GetDeliveryAddressesQueryArguments> = new Subject<GetDeliveryAddressesQueryArguments>();
  event: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: NgbModal, private deliveryAddressesModalService: AddressService) {
    this.getDeliveryAddressesQueryArguments = new GetDeliveryAddressesQueryArguments();
    this.getDeliveryAddressesQueryArguments.search = "";
    this.filterDebounce.pipe(debounceTime(300)).subscribe(filter => this.getPersonAddress(this.getDeliveryAddressesQueryArguments));
   }
  
  close() {
    this.modalService.dismissAll();
   } 

  ngOnInit() {
    this.getPersonAddress(this.getDeliveryAddressesQueryArguments)
  }

  showRemoveAddressModal(address){
    const modalRef = this.modalService.open(DeleteDeliveryAddressComponent)
    modalRef.componentInstance.AddressObject = address;
  }

  showAddAddressModal(){
    const modalRef = this.modalService.open(AddDeliveryAddressComponent)
    modalRef.componentInstance.AddressObject = null;
    modalRef.componentInstance.PreviousAddressObject = null;
    modalRef.componentInstance.personId = this.deliveryAddresses.personId;
    modalRef.componentInstance.fromOrder = this.fromOrder;
    modalRef.componentInstance.event.subscribe(result => {
      if(this.fromOrder){
        this.selectAddressToOrder(result.id,result.zipCode)
      }
      else{
        this.close();
      }
    });
  }

  showEditAddressModal(address){
    const modalRef = this.modalService.open(EditDeliveryAddressComponent)
    modalRef.componentInstance.AddressObject = address;
    modalRef.componentInstance.PreviousAddressObject = address;
    modalRef.componentInstance.personId = this.deliveryAddresses.personId;
    modalRef.componentInstance.fromOrder = this.fromOrder;
    modalRef.componentInstance.event.subscribe(result => {
      if(this.fromOrder){
        this.selectAddressToOrder(result.id,result.zipCode)
      }
      else{
        this.close();
      }
    });
  }


  onFullNameKeyup($event: any) {
    this.getDeliveryAddressesQueryArguments.search = $event.target.value;
    this.filterDebounce.next(this.getDeliveryAddressesQueryArguments);
  }

  onSearchClick() {
    this.getPersonAddress(this.getDeliveryAddressesQueryArguments);
  }

  getPersonAddress(args: GetDeliveryAddressesQueryArguments) {
    this.isLoading = true;
    this.deliveryAddressesModalService.getPersonAddressByContractId(args)
    .pipe(
      finalize(() => {
        this.isLoading = false;  
      })
    )
    .subscribe(
      (success) => {
        this.deliveryAddresses = success;
      },
      (error) => {
        // this.alertService.danger(error.message);
        return;
      }
    );
  }

  selectAddressToOrder(id,zipCode){
    const result = new ResultEventAddress();
    result.id = id;
    result.zipCode = zipCode
    this.event.emit(result);
    this.close();
  }

}
