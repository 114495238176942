import { Component, OnInit } from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { Tag } from "src/app/core/models/tag.model";
import { VehiclesAvailableQueryResult } from "@models/old/vehicle/vehiclesAvailableQueryResult.model";
import { EventEmitterService } from "@services/event-emitter.service";
import { TagLinkModalComponent } from "./components/tag-link-modal/tag-link-modal.component";
import { VeiculosDisponiveisComponent } from "src/app/shared/components/vehicle/veiculos-disponiveis/veiculos-disponiveis.component";
import { TagsDisponiveisComponent } from "src/app/shared/components/tag/tags-disponiveis/tags-disponiveis.component";
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";
import { TicketsService } from "../tickets/services/tickets.service";

@Component({
  selector: "app-tagactivation",
  templateUrl: "./tagactivation.component.html",
  styleUrls: ["./tagactivation.component.scss"],
})
export class TagactivationComponent extends AbstractProcessLauncher implements OnInit {
  tagselected: Tag;
  contractvehicleselected: VehiclesAvailableQueryResult;
  bsModalRef: BsModalRef;
  isEmpty: any;

  constructor(
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private ticketsService: TicketsService,
    protected _router: Router
  ) {
    super(_router);
  }

  private emmitEventWhenStartComponent(): void {
    EventEmitterService.get("ReturnToOrigin").subscribe((data) =>
      this.refreshAfterLinkTag()
    );
  }

  ngOnInit(): void {
    this.emmitEventWhenStartComponent();
    this.isEmpty = "isUsable";
  }

  refreshAfterLinkTag() {
    this.contractvehicleselected = null;
    this.tagselected = null;
    this.refreshList();
  }

  onchangeTag(tag) {
    this.tagselected = tag;
  }
  onchangeVehicle(vehicle) {
    this.contractvehicleselected = vehicle;
  }

  saveContractVehicleTag() {
    let fromVehiclePage;
    if (typeof this.route.snapshot.params.id !== "undefined") {
      fromVehiclePage = true;
    } else {
      fromVehiclePage = false;
    }
    const initialState = { fromVehiclePage: fromVehiclePage };
    this.bsModalRef = this.modalService.show(TagLinkModalComponent, {
      initialState,
    });
    this.bsModalRef.content.closeBtnName = "Close";
    this.bsModalRef.content.tagselected = this.tagselected;
    this.bsModalRef.content.vehicleselected = this.contractvehicleselected;
    this.bsModalRef.content.event.subscribe((result) => {
      if (result) {
        this.contractvehicleselected = null;
        this.tagselected = null;
        this.refreshList();
      }
    });
  }

  refreshList() {
    VeiculosDisponiveisComponent.refreshList.emit();
    TagsDisponiveisComponent.refreshList.emit();
  }


}
