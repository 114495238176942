<main class="transactionConciliationComponent">
  <div class="container header-page">
    <h1 data-e2e="page-title" translate>Transaction Conciliation</h1>
  </div>

  <section class="table-card">
    <div class="table-header">
      <div class="table-header-left">
        <app-filters
          [hasTransctionDateFilter]="true"
          [hasRadioFilter]="true"
          [hasFlowStepAndRefusalFilter]="true"
          [conciliationSteps]="conciliationStepsFilters"
          [refusalTypes]="refusalTypesFilters"
          (filtersParams)="handleFiltersParams($event)"
          (resetParams)="resetFilters()"
        >
        </app-filters>

        <div class="label-select">
          <label for="select-input">Exibição</label>
          <select
            id="select-input"
            name="perPages"
            data-e2e="perPages"
            (change)="onPageSizeChange($event)"
          >
            <option *ngFor="let item of perPages" [value]="item">
              {{ item + ' linhas' }}
            </option>
          </select>
          <img src="../../assets/img/arrow-select.svg" alt="arrow-select" />
        </div>
      </div>

      <div class="download-button">
        <button
          [disabled]="noFiles"
          class="btn btn-primary"
          translate
          data-e2e="export-btn"
          (click)="Download()"
        >
          Download CSV

          <img class="button-icon" src="../../assets/img/ico-download.svg" />
        </button>
      </div>
    </div>
    <div class="table-main">
      <table class="table">
        <thead class="thead">
          <tr>
            <th
              *ngFor="let column of tableHeader"
              [ngClass]="bindOrderByClass(column)"
            >
              <div
                *ngIf="column !== ''"
                class="btn-order-table"
                [attr.data-e2e]="'sort-' + column + '-date-btn'"
                translate
              >
                {{ column }}
              </div>

              <div class="round-order" (click)="onOrderByChange(column)">
                <img src="../assets/svg/order-table.svg" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody *ngIf="!isLoading && DtoTransactions?.totalItems == 0">
          <p class="empty-search-msg" translate>No Transactions Found</p>
        </tbody>

        <tbody *ngIf="!isLoading && DtoTransactions.totalItems !== 0">
          <tr *ngFor="let transaction of DtoTransactions.data; let i = index">
            <td [attr.data-e2e]="'table-sales-' + i">
              {{ transaction?.registrationDate }}
            </td>

            <td [attr.data-e2e]="'table-payment-' + i">
              {{ transaction?.guidTransactionId }}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.stepTransaction }}
            </td>

            <td [attr.data-e2e]="">
              {{ transaction?.errorDescriptionPT }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ message | translate }}"
        ></app-loader>
      </div>
    </div>

    <div class="pagination-custom"  *ngIf="
    DtoTransactions?.totalItems > queryArguments.pageSize && !isLoading
    ">
      <ngb-pagination
        (pageChange)="onPaginationChange($event)"
        [pageSize]="queryArguments.pageSize"
        [maxSize]="5"
        [collectionSize]="DtoTransactions?.totalItems"
        [(page)]="queryArguments.page"
        [rotate]="true"
        [boundaryLinks]="true"
        [ellipses]="false"
        display="dynamic"
      >
      </ngb-pagination>
    </div>
  </section>
</main>
