import {Component, Renderer2} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ConfirmationSeparateRequestComponent } from './components/confirmation-separate-request/confirmation-separate-request.component';
import { SeparateRequestComponent } from './components/separate-request/separate-request.component';
import { ReportTrackingComponent } from './components/report-tracking/report-tracking.component';
import { ViewTrackingComponent } from './components/view-tracking/view-tracking.component';
import { RequestItemsQueryResultDto } from './dtos/request-items-query-result.dto';
import { RequestQueryArguments } from './dtos/request-query-arguments.dto';
import { RequestQueryResultDto } from './dtos/request-query-result.dto';
import { RequestService } from './services/request.service';
import { Filters } from "@models/filters-backoffice.model";
import moment from 'moment';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  providers: [NgbDropdownConfig],
})
export class RequestComponent {
  hideTooltip = [];
  getOrders: RequestQueryResultDto;

  orderQueryArguments: RequestQueryArguments;
  filterDebounce: Subject<RequestQueryArguments> =
    new Subject<RequestQueryArguments>();
  perPages: number[] = [];
  total: number;
  isLoading: boolean;
  isOpenDropdown: boolean;
  isOpen: boolean;
  open: boolean;
  isShowStatus: boolean;
  statusCustom: any = 'Selecione';
  isDownloading: boolean;
  tagRequest = {
    types: [
      { name: 'Realizado', id: 1 },
      { name: 'Em separação', id: 3 },
      { name: 'Enviado para Transportadora', id: 4 },
      { name: 'Em transporte', id: 5 },
    ],
  };
  objt: {};
  private orderOpenStatusMap: Map<number, boolean> = new Map<number, boolean>();

  constructor(
    config: NgbDropdownConfig,
    private requestService: RequestService,
    private modalService: NgbModal,
    public router: Router,
    private _renderer: Renderer2
  ) {
    config.autoClose = false;

    this.getOrders = {
      total: 0,
      items: [],
    };

    this.perPages = [10, 20, 50];
    this.orderQueryArguments = new RequestQueryArguments();
    this.orderQueryArguments.page = 1;
    this.orderQueryArguments.pageSize = 10;
    this.orderQueryArguments.orderBy = 'registrationDate';
    this.orderQueryArguments.tagRequestStatusType = [];
    this.orderQueryArguments.desc = false;
    this.orderQueryArguments.filter = 1;

    this.filterDebounce
      .pipe(debounceTime(500))
      .subscribe((filter) => this.getOrdersQuery(filter));
    this.getOrdersQuery(this.orderQueryArguments);
  }

  getOrdersQuery(args: RequestQueryArguments) {
    this.isLoading = true;

    this.requestService
      .getOrders(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        (success) => {
          this.getOrders = success;
        },
        (error) => {
          return;
        },
      );
  }

  onPaginationChange(event: any) {
    this.orderQueryArguments.page = event;
    this.getOrdersQuery(this.orderQueryArguments);
  }

  onPageSizeChange(event: any) {
    this.orderQueryArguments.page = 1;
    this.orderQueryArguments.pageSize = event.target.value;
    this.getOrdersQuery(this.orderQueryArguments);
  }


  handleFiltersParams(event: Filters) {
    this.orderQueryArguments.page = 1;
    this.orderQueryArguments.tagRequestStatusType = event.statusTypeFilter;
    this.filterDebounce.next(this.orderQueryArguments);
  }

  resetFilters() {
    this.statusCustom = 'Selecione';
    this.orderQueryArguments.page = 1;
    this.orderQueryArguments.orderBy = 'registrationDate';
    this.orderQueryArguments.tagRequestStatusType = [];
    this.orderQueryArguments.desc = false;
    this.orderQueryArguments.filter = 1;
    this.getOrdersQuery(this.orderQueryArguments);
  }

  reportTracking(order: RequestItemsQueryResultDto) {
    const modalRef = this.modalService.open(ReportTrackingComponent);
    modalRef.componentInstance.order = order;

    modalRef.componentInstance.event.subscribe((receivedEntry: any) => {
      order.trackingCode = receivedEntry.trackingCode;
      order.logisticPartnerLink = receivedEntry.logisticPartnerLink;
      order.logisticPartnerImage = receivedEntry.logisticPartnerImage;
      order.tagRequestStatusTypeId = receivedEntry.tagRequestStatusTypeId;
      order.tagRequestStatusType = receivedEntry.tagRequestStatusType;
      order.protocol = receivedEntry.protocol;
    });
  }

  redirectToSeparateOrder(order: RequestItemsQueryResultDto) {
    this.TagRequestUpdateStatus(order);
    const modalRef = this.modalService.open(SeparateRequestComponent);
    modalRef.componentInstance.order = order;

    modalRef.componentInstance.event.subscribe((receivedEntry: any) => {
      order.tagRequestStatusTypeId = 4;
      order.tagRequestStatusType = 'Enviado para Transportadora';
      order.tagRequestSeparationCount =
        receivedEntry.tagRequestDetailsUserChangeResult.tagRequestSeparationCount;
      order.tagCount = receivedEntry.tagRequestDetailsUserChangeResult.tagCount;
      order.changeResponsibilityName =
        receivedEntry.tagRequestDetailsUserChangeResult.changeResponsibilityName;
      order.changeDate =
        receivedEntry.tagRequestDetailsUserChangeResult.changeDate;
    });
  }

  viewTracking(order: any) {
    const modalRef = this.modalService.open(ViewTrackingComponent);
    modalRef.componentInstance.order = order;
  }

  redirectToPrint(orderSelected: RequestItemsQueryResultDto) {
    const modalRef = this.modalService.open(
      ConfirmationSeparateRequestComponent,
    );
    let titles = {
      title: 'Imprimir pedido',
      subtitle: 'Enviado para a transportadora',
    };
    modalRef.componentInstance.titles = titles;
    modalRef.componentInstance.order = orderSelected;
  }

  TagRequestUpdateStatus(orderSelected: any) {
    if (orderSelected.tagRequestStatusTypeId === 3) {
      return;
    }
    this.objt = {
      tagRequestId: orderSelected.id,
      tagRequestStatusTypeId: 3,
    };
    this.requestService
      .TagRequestUpdateStatus(this.objt)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          orderSelected.tagRequestStatusTypeId = 3;
          orderSelected.tagRequestStatusType = 'Em separação';
        }),
      )
      .subscribe(
        (success) => {},
        (error) => {
          console.error(error);
        },
      );
  }

  updateCardBodyHeightBasedOnOrder(index: number, isOpen: boolean): void {
    const lastThreeOrders: RequestItemsQueryResultDto[] = this.getOrders.items.slice(-3);
    const selectedOrder: RequestItemsQueryResultDto = this.getOrders.items[index];
    const cardBodyElement = document.querySelector('.card-body');

    this.orderOpenStatusMap.set(index, !isOpen);

    // Verifica se o pedido selecionado está entre os três últimos e se não está aberto
    if (lastThreeOrders.includes(selectedOrder) && !isOpen) {
      this._renderer.setStyle(cardBodyElement, 'height', '72rem');
    } else {
      // Verifica se algum dos três últimos pedidos está aberto
      const allValuesAreFalse: boolean = Array.from(this.orderOpenStatusMap.values()).every(value => value === false);

      if(allValuesAreFalse) {
        this._renderer.setStyle(cardBodyElement, 'height', 'auto');
      }
    }
  }

  Download() {
    this.DownloadCSV(this.orderQueryArguments);
  }

  public DownloadCSV(args: RequestQueryArguments) {
    let now = new Date();
    let date = moment(now).format('YYYY_MM_DD_HH_mm_ss');
    this.isDownloading = true;
    this.requestService
      .GetCSVFile(args)
      .pipe(
        finalize(() => {
          this.isDownloading = false;
        })
      )
      .subscribe((x) => {
        const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'RelatorioPedidos ' + date + '.csv';
        element.click();
      });
  }
}
