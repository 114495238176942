
<main class="CardComponent">
  <section class="table-card">
    <div class="table-header">
        <div class="toll-select-box">
          <label class="toll-select-box__label" for="select-input">Ano</label>
          <select
            id="select-input"
            name="perPages"
            data-e2e="perPages"
            class="toll-select-box__select"
            (change)="onYearChange($event)"
          >
            <option *ngFor="let item of years" [value]="item">
              {{ item }}
            </option>
          </select>

        </div>
        <div class="redirect-button">
          <button
            class="btn btn-create-holiday"
            data-e2e="export-btn"
            (click)="redirect()"
          >
          <img src="assets/img/ico-add-w.svg"/>
          Incluir feriado
          </button>
        </div>
    </div>
    <div class="table-main">
      @if(year){
        <app-table-holidays  [year]="year">
        </app-table-holidays>
      }
    </div>
  </section>
</main>
