import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { Subject, finalize } from 'rxjs';
import { FilterPageSizes, NoResultsMessages, TabType, TableHeadersBatchTagBlockUnblock, TagBlockUnblockTexts, TagStatusType } from '@modules/customer/batch-management/dtos/batch-management-consts';
import { TagBlockUnblockQuery } from '@modules/customer/batch-management/dtos/tag-block-unblock-query';
import { ITagBlockUnblockDto } from '@modules/customer/batch-management/dtos/tag-block-unblock.dto';
import { BatchManagementService } from '@modules/customer/batch-management/services/batch-management.service';
import { AbstractConfigurationBase } from 'src/app/shared/abstracts/abstract-configuration-base';

@Component({
  selector: 'app-batch-tag-block-unblock',
  templateUrl: './batch-tag-block-unblock.component.html',
  styleUrl: './batch-tag-block-unblock.component.scss'
})
export class BatchTagBlockUnblockComponent extends AbstractConfigurationBase implements OnInit {
  @Input() inTabTypeActive: TabType;
  @Output() outTagsSelected: EventEmitter<ITagBlockUnblockDto[]> = new EventEmitter();

  readonly tabTypes = TabType;
  readonly pageSizes = FilterPageSizes;
  readonly tableHeadersBlockUnblock = TableHeadersBatchTagBlockUnblock;
  readonly noResultsMessages = NoResultsMessages;

  checkboxSelectionControl = new SelectionModel<ITagBlockUnblockDto>(true, [], true);
  textInfo: string;
  noResultMsgTitle: string;
  noResultMsgSubtitle: string;
  searchForm: FormGroup;
  filterDebounce: Subject<void> = new Subject<void>();
  isLoadingTableData: boolean;
  tagsQuery: TagBlockUnblockQuery;
  tagsList: ITagBlockUnblockDto[];
  totalItems: number;
  tagsSelectedStorage: ITagBlockUnblockDto[];

  constructor(
    private _batchManagementService: BatchManagementService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.settingsInitClass();
    this.settingsTabType();
    this.requestAllTagsToBeBlockedUnblocked();
  }

  private settingsInitClass(): void {
    this.setQueryParamsDefault();

    this.tagsList = [];
    this.tagsSelectedStorage = [];

    this.searchForm = new FormGroup({
      searchField: new FormControl(''),
    });
  }

  private settingsTabType(): void {
    if (this.inTabTypeActive === TabType.TagBlock) {
      this.textInfo = TagBlockUnblockTexts.BLOCK_INFO_TEXT;
      this.noResultMsgTitle = NoResultsMessages.TAG_BLOCK_TITLE;
      this.noResultMsgSubtitle = NoResultsMessages.TAG_BLOCK_SUBTITLE;
      return;
    }

    this.textInfo = TagBlockUnblockTexts.UNBLOCK_INFO_TEXT;
    this.noResultMsgTitle = NoResultsMessages.TAG_UNBLOCK_TITLE;
    this.noResultMsgSubtitle = NoResultsMessages.TAG_UNBLOCK_SUBTITLE;
  }

  private setQueryParamsDefault() {
    this.tagsQuery = new TagBlockUnblockQuery();

    this.tagsQuery.contractVehicleTagStatusTypeId =
      this.inTabTypeActive === TabType.TagBlock ?
        TagStatusType.ACTIVE_ALLOW_BLOCK :
        TagStatusType.BLOCKED_ALLOW_UNBLOCK;

    this.tagsQuery.contractId = this.getContractId();
  }

  private getContractId(): number {
    return Number.parseInt(this.authService.getContractId());
  }

  private requestAllTagsToBeBlockedUnblocked() {
    this.isLoadingTableData = true;

    this._batchManagementService
      .getAllTagsToBeBlockedUnblockedContract(this.tagsQuery)
      .pipe(
        finalize(() => {
          this.isLoadingTableData = false;
        })
      )
      .subscribe({
        next: (success) => {
          this.totalItems = success.total;
          this.tagsList = success.tags;

          this.tagsList.length === 0 ? this.handleNoResultsMsg() : null;

          this.loadItemsSelectedFromStorageList();
        },
        error: (error) => {
          console.error(error);
          return;
        }
      });
  }

  private handleNoResultsMsg() {
    const noSearch = this.tagsQuery.search == '';

    if (noSearch && this.inTabTypeActive === TabType.TagBlock) {
      this.noResultMsgTitle = NoResultsMessages.TAG_BLOCK_TITLE;
      this.noResultMsgSubtitle = NoResultsMessages.TAG_BLOCK_SUBTITLE;
    }
    else if (noSearch && this.inTabTypeActive === TabType.TagUnblock) {
      this.noResultMsgTitle = NoResultsMessages.TAG_UNBLOCK_TITLE;
      this.noResultMsgSubtitle = NoResultsMessages.TAG_UNBLOCK_SUBTITLE;
    }
    else {
      this.noResultMsgTitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_SEARCH_NORESULT_TITLE;
      this.noResultMsgSubtitle = NoResultsMessages.TAG_BLOCK_UNBLOCK_SEARCH_NORESULT_SUBTITLE;
    }
  }

  getFormattedString(value: string): string {
    return !value ? '-' : value;
  }

  private sendEventWhenTagsSelectedStorageChange() {
    // Emits an event to update the list in other components
    this.outTagsSelected.emit(this.tagsSelectedStorage);
  }

  onPageSizeChange(event: any): void {
    this.tagsQuery.pageSize = event.target.value;
    this.tagsQuery.page = 1;

    this.requestAllTagsToBeBlockedUnblocked();
  }

  onPaginationChange(page: number) {
    this.tagsQuery.page = page;
    this.requestAllTagsToBeBlockedUnblocked();
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.tagsQuery.sortField == field && !this.tagsQuery.desc,
      'order-by-desc':
        this.tagsQuery.sortField == field && this.tagsQuery.desc,
    };
  }

  onOrderByChange(field: string) {
    if (this.tagsQuery.sortField == field) {
      this.tagsQuery.desc = !this.tagsQuery.desc;
    }
    else {
      this.tagsQuery.sortField = field;
      this.tagsQuery.desc = true;
    }

    this.tagsQuery.page = 1;
    this.requestAllTagsToBeBlockedUnblocked();
  }

  onSearchKeyup(): void {
    this.tagsQuery.search = this.searchForm.controls.searchField.value;
    this.tagsQuery.page = 1;

    this.requestAllTagsToBeBlockedUnblocked();
  }

  /*
   ******************************************
    Methods that handle checkbox selections
   ******************************************
   */
  private loadItemsSelectedFromStorageList() {
    this.deselectAllItems();

    if (this.tagsSelectedStorage.length > 0) {

      /*
       * Checks if there are any response items that are already in the storage list.
       * If they are, store them in the variable.
       */
      const itemsToSelect = this.tagsList.filter(
        (item) => {
          const itemMatch = this.tagsSelectedStorage.find(
            (itemStored) => itemStored.contractVehicleTagId === item.contractVehicleTagId
          );

          return itemMatch;
        });

      if (itemsToSelect) {
        this.selectListOfItems(itemsToSelect);
      }
    }
  }

  private deselectAllItems() {
    this.checkboxSelectionControl.clear(); // Clear selections
  }

  private selectListOfItems(list: ITagBlockUnblockDto[]) {
    this.checkboxSelectionControl.select(...list); // Mark items as selected
  }

  handleRowCheckboxChanges(item: ITagBlockUnblockDto) {
    this.selectOrDeselectItem(item);

    /*
     * Checks if item changed to selected.
     * If it is, add item to the storage list.
     * If it is not, remove item to the storage list.
     */
    this.checkboxSelectionControl.isSelected(item) ?
      this.addItemToTagsStorage(item) :
      this.removeItemFromTagsStorage(item);

    this.sendEventWhenTagsSelectedStorageChange();
  }

  private selectOrDeselectItem(item: ITagBlockUnblockDto) {
    this.checkboxSelectionControl.toggle(item);
  }

  private addItemToTagsStorage(item: ITagBlockUnblockDto) {
    this.tagsSelectedStorage.push(item);
  }

  private removeItemFromTagsStorage(item: ITagBlockUnblockDto) {
    if (this.tagsSelectedStorage.length > 0) {

      // Returns stored items that are different from the item to be removed
      const itemsWithoutTheRemoved = this.tagsSelectedStorage.filter(
        (itemStored) => item.contractVehicleTagId !== itemStored.contractVehicleTagId
      );

      // Stores items that have not been removed
      this.tagsSelectedStorage = itemsWithoutTheRemoved;
    }
  }

  handleHeaderCheckboxChanges(checked: boolean) {
    if (checked) { // If header checkbox changed to checked
      /*
       * Checks if response items are already selected.
       * If they are, select and add the unselected items to the storage list.
       * If they are not,  select and add all response items to the storage list.
       */
      this.checkboxSelectionControl.hasValue() ?
        this.selectAndAddResponseItemsNotSelected() :
        this.selectAndAddAllResponseItems();

      this.sendEventWhenTagsSelectedStorageChange();
      return;
    }

    /*
     * If header checkbox changed to unchecked.
     * Deselects and removes all response items from the storage list.
     */
    this.deselectAndRemoveAllResponseItems();
    this.sendEventWhenTagsSelectedStorageChange();
  }

  private selectAndAddAllResponseItems() {
    this.selectListOfItems(this.tagsList);
    this.tagsSelectedStorage.push(...this.tagsList);
  }

  private selectAndAddResponseItemsNotSelected() {
    /*
     * Check if stored items are selected.
     * If they are not, store them in the variable.
     */
    const itemsToSelectAndStored = this.tagsList.filter(
      (item) => {
        const isAlreadySelected = this.checkboxSelectionControl.selected.find(
          (itemSelected) => item.contractVehicleTagId === itemSelected.contractVehicleTagId
        );

        return !isAlreadySelected;
      }
    );

    // If there are unselected items, select and store them in the list
    if (itemsToSelectAndStored) {
      this.selectListOfItems(itemsToSelectAndStored);
      this.tagsSelectedStorage.push(...itemsToSelectAndStored);
    }
  }

  private deselectAndRemoveAllResponseItems() {
    /*
     * Check if stored items are selected.
     * If they are not, store them in the variable.
     */
    const itemsToKeepStored = this.tagsSelectedStorage.filter(
      (itemStored) => {
        const itemToRemove = this.checkboxSelectionControl.selected.find(
          (itemSelected) => itemStored.contractVehicleTagId === itemSelected.contractVehicleTagId
        );

        return !itemToRemove;
      }
    );

    // Stores only items that were not selected
    if (itemsToKeepStored) {
      this.tagsSelectedStorage = itemsToKeepStored;
    }

    this.deselectAllItems();
  }

  isAllChecked(): boolean {
    // Checks if all response items are selected
    return this.checkboxSelectionControl.selected.length === this.tagsList.length;
  }
  /*
   *********
     End
   *********
   */

}
