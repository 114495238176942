import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
registerLocaleData(ptBr);
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { CoreModule } from 'src/app/core/core.module';
import { MyaccountRoutingModule } from './routes/myaccount-routing.module';
import { MyaccountComponent } from './myaccount.component';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';
import { EditContractEmailModalComponent } from './components/edit-contract-email-modal/edit-contract-email-modal.component';
import { EditContractPhoneModalComponent } from './components/edit-contract-phone-modal/edit-contract-phone-modal.component';
import { PasswordRequirementsComponent } from './components/change-password-modal/components/password-requirements/password-requirements.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MyaccountRoutingModule
  ],
  declarations: [
    MyaccountComponent,
    ChangePasswordModalComponent,
    PasswordRequirementsComponent,
    EditContractEmailModalComponent,
    EditContractPhoneModalComponent
  ],
  providers: [DatePipe, provideNgxMask()],
})

export class MyaccountModule {}