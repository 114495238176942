<section class="card-body">
  <div class="col-12" *ngIf="isLoading">
    <div class="container-loading">
      <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
    </div>
  </div>
  <ul class="box-right">
    <li
      class="user-bar"
      *ngFor="let user of legalPerson?.legalRepresentativeCollection;
              let i = index"
    >
      <div class="profile-bar"></div>
      <div class="profile-content">
        <img
          src="../../assets/img/ico-account.svg"
          class="ico-person"
          alt=""
        />

        <div class="">
          <span class="nameDesc">{{ user.fullName }}</span>
          <div class="profileDesc">
            <span>Representante legal</span>
          </div>
        </div>
      </div>
      <button
        class="btn btn-circle"
        (click)="onOpenProsecutorsModal(user.id, requestModalType.request)"
      >
        <img src="../../assets/img/ico-search.svg" alt=""/>
      </button>
    </li>

    <li
      class="user-bar"
      *ngFor="let user of legalPerson?.attorneyCollection; let i = index"
    >
      <div class="profile-bar"></div>
      <div class="profile-content">
        <img
          src="../../assets/img/ico-account.svg"
          class="ico-person"
          alt=""
        />

        <div class="">
          <span class="nameDesc">{{ user.fullName }}</span>
          <div class="profileDesc">
            <span>Procurador</span>
          </div>
        </div>
      </div>
      <button
        class="btn btn-circle"
        (click)="onOpenProsecutorsModal(user.id, requestModalType.request)"
      >
        <img src="assets/img/ico-search.svg" alt=""/>
      </button>
    </li>
  </ul>
</section>
