<div class="contractualAlterationComponent">
  <div class="container header-page">
    <h1 data-e2e="page-title" translate>Contractual alteration</h1>
    <div class="actions-header-page"></div>
  </div>

  <div class="container col-md-11 mt-5">
    <div class="card mb-5 card-loading">
      <div class="card-header">
        <h2 data-e2e="card-title" translate>Client query</h2>
      </div>

      <div class="card-body pt-0 contract">
        <div class="row d-flex col-lg-12 search-content">
          <form
            [formGroup]="contractSearchForm"
            class="d-flex flex-column input-search position-relative my-2"
            novalidate
          >
            <div class="form-group select-table">
              <label data-e2e="search-label" translate>CPF or CNPJ</label>
              <input
                type="text"
                data-e2e="search-input"
                (ngModelChange)="clearMessage()"
                [mask]="getMask()"
                placeholder="{{
                  'Enter CPF/CNPJ to search for the customer' | translate
                }}"
                formControlName="documentSearch"
                class="client-search"
                [class.is-invalid]="
                  contractSearchForm.controls.documentSearch.invalid &&
                  contractSearchForm.controls.documentSearch.touched
                "
              />

              <button
                class="btn btn-search"
                data-e2e="search-btn"
                [disabled]="isLoading"
                (click)="
                  loadContractsByDocument(
                    contractSearchForm.value.documentSearch
                  )
                "
              >
                <div *ngIf="!isLoading">
                  <img src="assets/img/icon-search.svg" class="button-icon" />
                  <span translate>Search</span>
                </div>

                <div *ngIf="isLoading">
                  <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </div>
              </button>
            </div>

            <div>
              <app-vmessage
                data-e2e="search-error"
                *ngIf="
                  contractSearchForm.controls.documentSearch.invalid &&
                  contractSearchForm.controls.documentSearch.touched
                "
                errorMessage="{{ 'Document is required' | translate }}"
              >
              </app-vmessage>
            </div>
          </form>
        </div>

        <div
          class="form-row position-relative w-100 mt-2 mb-1 hr"
          *ngIf="contractAvailables != null"
        >
          <hr />
        </div>

        <div
          class="form-row position-relative w-100 mb-1 no-contract"
          *ngIf="contractAvailables?.length == 0 && !clear"
        >
          <p
            data-e2e="empty-search-msg"
            class="text-center text-danger w-100"
            translate
          >
            Informed customer has no contract
          </p>
        </div>

        <div
          class="row d-flex col-lg-12 ContractsList"
          *ngIf="contractAvailables?.length >= 1 && !selectedContract"
        >
          <div class="form-row position-relative w-100 mb-1 found-contract">
            <p
              data-e2e="search-result-msg"
              class="text-center w-100"
              *ngIf="contractAvailables[0]?.documentNumber.length == 11"
            >
              Encontrado {{ contractAvailables?.length }} contrato(s) para o CPF
              {{
                contractAvailables[0]?.documentNumber | mask: '00.000.000-00'
              }}
            </p>
            <p
              data-e2e="search-result-msg"
              class="text-center w-100"
              *ngIf="contractAvailables[0]?.documentNumber.length == 14"
            >
              Encontrado {{ contractAvailables?.length }} contrato(s) para o
              CNPJ
              {{
                contractAvailables[0]?.documentNumber
                  | mask: '00.000.000/0000-00'
              }}
            </p>
          </div>

          <div class="row d-flex col-lg-12 mt-3" [hidden]="!contractAvailables">
            <div
              class="col-lg-6 mt-4"
              *ngFor="let contract of contractAvailables; let i = index"
            >
              <div
                class="contract-card card"
                (click)="selectContract(contract)"
              >
                <div class="head">
                  <img src="assets/img/user.svg" />
                  <span [attr.data-e2e]="'contract-description-' + i">{{
                    contract.description
                  }}</span>
                </div>
                <div class="info">
                  <p
                    [attr.data-e2e]="'contract-tax-id-' + i"
                    *ngIf="contract.documentNumber.length == 14"
                  >
                    {{ contract.documentNumber | mask: '00.000.000/0000-00' }}
                    <br />
                    Contrato: {{ contract.id }}
                  </p>

                  <p
                    [attr.data-e2e]="'contract-social-security-' + i"
                    *ngIf="contract.documentNumber.length == 11"
                  >
                    {{ contract.documentNumber | mask: '00.000.000-00' }}
                    <br />
                    Contrato: {{ contract.id }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row d-flex col-lg-12 selectedContract"
          *ngIf="contractAvailables?.length >= 1 && selectedContract"
        >
          <div data-e2e="selected-description" class="col-12 description">
            {{ selectedContract.description }}
          </div>
          <div class="col-12 documentNumberAndId">
            <span
              data-e2e="selected-tax-id"
              id="documentNumber"
              *ngIf="selectedContract.documentNumber.length == 11"
            >
              {{ selectedContract.documentNumber | mask: '00.000.000-00' }}
            </span>

            <span
              data-e2e="selected-social-security"
              id="documentNumber"
              *ngIf="selectedContract.documentNumber.length == 14"
            >
              {{
                selectedContract.documentNumber | mask: '00.000.000/0000-00'
              }}</span
            >

            <span id="contractId">
              | Contrato:
              <span data-e2e="contract-id" id="id">{{
                selectedContract.id
              }}</span>
            </span>
          </div>

          <div class="buttons col-12">
            <button
              data-e2e="edit-registration-btn"
              class="btn btn-primary"
              [disabled]="true"
            >
              <img src="assets/svg/edit.svg" />
              Alterar dados cadastrais
            </button>
            <button
              data-e2e="open-limit-modal-btn"
              class="btn btn-primary"
              (click)="showLimitChangeModal(selectedContract)"
            >
              <img src="../assets/svg/attach_money-24px.svg" />
              Alterar limite contratado
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
