import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TripExpiratedRoutingModule } from './routes/trip-expirated-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TripExpiratedComponent } from './trip-expirated.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    NgbModule,
    TripExpiratedRoutingModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [TripExpiratedComponent],
  providers: [provideNgxMask()],
})
export class TripExpiratedModule {}
