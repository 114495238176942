@if (isLoading) {
  <div class="container-loading">
    <app-loader
      [isLoading]="isLoading"
      message="{{ 'Carregando...' }}"
    ></app-loader>
  </div>
}

@if (!isLoading) {
  <div class="edenred">

    <div class="content row edenred-title">
      <h1>Selecione abaixo qual portal deseja acessar:</h1>
    </div>

    <main>
      <div class="bnt-div">
        <button mat-stroked-button class="edenred-btn-large" (click)="toEnterCustomerPortal()">
          <img src="assets/icons/people.png" alt=""/>
          <span>Portal do Cliente</span>
        </button>
      </div>

      <div class="bnt-div">
        <button mat-stroked-button class="edenred-btn-large" (click)="toEnterBackOfficePortal()">
          <img src="assets/icons/monitor.png" alt=""/>
          <span>Portal de Atendimento</span>
        </button>
      </div>
    </main>

    <app-footer disableButton="true"></app-footer>

  </div>
}
