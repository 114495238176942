<div class="financialSalesReportComponent">
  <div class="container header-page">
    <h1 data-e2e="page-title" translate>Credit card transactions</h1>

    <div class="actions-header-page">
      <form class="input-search">
        <input
          class="vehicles"
          data-e2e="search-input"
          type="text"
          placeholder="{{ 'NSU, Athorization ou Document Number' | translate }}"
          (keyup)="onFullNameKeyup($event)"
          [value]="queryArguments.search"
        />

        <button
          class="btn btn-search"
          translate
          data-e2e="search-btn"
          (click)="onSearchClick()"
        >
          <img src="assets/img/icon-search.svg" class="button-icon" />

          <span data-e2e="search-btn-label" translate>Search</span>
        </button>
      </form>
    </div>
  </div>

  <div class="card mt-6rem overflow-initial">
    <div class="card-body pt-0">
      <div class="filter-download-section">
        <div class="filter-select">
          <app-filters
            [hasTransctionDateFilter]="true"
            [hasExpectedClearingDateFilter]="true"
            [hasTransctionTypeFilter]="true"
            [hasStatusTypeFilter]="true"
            [hasRangeAmountFilter]="true"
            [transactionTypes]="transactionTypesFilters"
            [statusTypes]="statusTypesFilters"
            (filtersParams)="handleFiltersParams($event)"
            (resetParams)="resetFilters()"
          ></app-filters>

          <select
            name="perPages"
            data-e2e="perPages"
            (change)="onPageSizeChange($event)"
          >
            <option *ngFor="let item of perPages" [value]="item">
              {{ 'exibir ' + item + ' linhas' }}
            </option>
          </select>
        </div>

        <div class="download-button">
          <button
            [disabled]="noFiles"
            class="btn btn-primary"
            translate
            data-e2e="export-btn"
            (click)="Download()"
          >
            Download CSV

            <img class="button-icon" src="assets/img/ico-download.svg" />
          </button>
        </div>
      </div>

      <div class="px-4 SalesListContainer">
        <table class="table">
          <thead class="thead">
            <tr>
              <th
                *ngFor="let column of tableHeader"
                [ngClass]="bindOrderByClass(column[0])"
              >
                <div
                  *ngIf="column[0] !== ''"
                  class="btn-order-table"
                  [attr.data-e2e]="'sort-' + column[0] + '-date-btn'"
                  translate
                >
                  {{ column[0] }}

                  <img
                    *ngIf="column[1] !== ''"
                    src="assets/svg/info.svg"
                    placement="top"
                    [ngbTooltip]="column[1]"
                    alt="INFORMAÇÃO"
                  />
                </div>

                <div
                  *ngIf="column[0] !== ''"
                  class="round-order"
                  (click)="onOrderByChange(column[0])"
                >
                  <img src="assets/svg/order-table.svg" />
                </div>
              </th>
            </tr>
          </thead>

          <tbody *ngIf="!isLoading && DtoTransactionSales?.totalItems == 0">
            <tr *ngIf="DtoTransactionSales?.totalItems == 0">
              <td data-e2e="empty-search-msg" colspan="12">
                <p class="empty-search-msg" translate>No Transactions Found</p>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!isLoading && DtoTransactionSales.totalItems !== 0">
            <tr *ngFor="let sale of DtoTransactionSales.data; let i = index">
              <td>
                <ng-template #popContentActions>
                  <div class="container">
                    <div class="row my-1">
                      <div
                        [attr.data-e2e]="'popover-name-label-' + i"
                        class="head-info"
                        translate
                      >
                        {{ popoverHeader[0] }}
                      </div>

                      <div
                        [attr.data-e2e]="'popover-name-' + i"
                        class="content-info"
                      >
                        {{ sale?.companyName }}
                      </div>
                    </div>

                    <div class="row my-1">
                      <div
                        [attr.data-e2e]="'popover-tax-id-label-' + i"
                        class="head-info"
                        translate
                      >
                        {{ popoverHeader[1] }}
                      </div>

                      <div
                        [attr.data-e2e]="'popover-tax-id-' + i"
                        class="content-info"
                      >
                        {{ sale?.documentNumber | mask: '00.000.000/0000-00' }}
                      </div>
                    </div>

                    <div class="row my-1">
                      <div
                        [attr.data-e2e]="'popover-invoice-label-' + i"
                        class="head-info"
                        translate
                      >
                        {{ popoverHeader[2] }}
                      </div>

                      <div
                        [attr.data-e2e]="'popover-invoice-' + i"
                        class="content-info"
                      >
                        {{ sale?.contractId }} - {{ sale?.contractName }}
                      </div>
                    </div>

                    <div class="row my-1">
                      <div
                        [attr.data-e2e]="'popover-contract-label-' + i"
                        class="head-info"
                        translate
                      >
                        {{ popoverHeader[3] }}
                      </div>

                      <div
                        [attr.data-e2e]="'popover-contract-' + i"
                        class="content-info"
                      >
                        {{ sale?.accountBalance }}
                      </div>
                    </div>

                    <div class="row my-1">
                      <div
                        [attr.data-e2e]="'popover-acquierer-label-' + i"
                        class="head-info"
                        translate
                      >
                        {{ popoverHeader[4] }}
                      </div>

                      <div
                        [attr.data-e2e]="'popover-acquierer-' + i"
                        class="content-info"
                      >
                        {{ sale?.vindiId }}
                      </div>
                    </div>

                    <div class="row my-1">
                      <div
                        [attr.data-e2e]="'popover-flag-label-' + i"
                        class="head-info"
                        translate
                      >
                        {{ popoverHeader[5] }}
                      </div>

                      <div
                        [attr.data-e2e]="'popover-flag-' + i"
                        class="content-info"
                      >
                        {{ sale?.creditCardBrandType }}
                      </div>
                    </div>

                    <div class="row my-1">
                      <div
                        [attr.data-e2e]="'popover-flag-label-' + i"
                        class="head-info"
                        translate
                      >
                        {{ popoverHeader[6] }}
                      </div>

                      <div
                        [attr.data-e2e]="'popover-flag-' + i"
                        class="content-info"
                      >
                        {{
                          !sale?.creditCardFirstSix
                            ? 'XXXXXX' + '******' + sale?.creditCard
                            : sale?.creditCardFirstSix +
                              '******' +
                              sale?.creditCard
                        }}
                      </div>
                    </div>
                  </div>
                </ng-template>

                <button
                  type="button"
                  class="btn-popover"
                  [attr.data-e2e]="'popover-btn-' + i"
                  [ngbPopover]="popContentActions"
                  [autoClose]="'outside'"
                  placement="right"
                  popoverClass="custom-popover3"
                >
                  <img src="assets/svg/icon-popover.svg" />
                </button>
              </td>

              <td [attr.data-e2e]="'table-sales-' + i">
                {{ sale?.transactionDate }}
              </td>

              <td [attr.data-e2e]="'table-payment-' + i">
                {{ sale?.expectedClearingDate }}
              </td>

              <td class="text2 valueColumn">
                <div class="row">
                  <div class="col-sm-auto"></div>

                  <div
                    [attr.data-e2e]="'transactionType' + i"
                    class="col"
                    translate
                  >
                    {{ sale?.transactionTypeDescription }}
                  </div>
                </div>
              </td>

              <td>
                <div class="row">
                  <div [attr.data-e2e]="'table-fee-' + i" class="col">
                    {{ sale?.authorization }}
                  </div>
                </div>
              </td>

              <td [attr.data-e2e]="'table-totalAmount-' + i">
                {{ sale?.totalAmount }}
              </td>

              <td class="text2 valueColumn">
                <div class="row">
                  <!-- <div class="col-sm-auto">
                    <span class="negative-amount" *ngIf="sale?.netAmount < 0"
                      >-</span
                    >
                  </div> -->

                  <div [attr.data-e2e]="'table-net-' + i" class="col">
                    {{ sale?.rechargeFee || '-' }}
                  </div>
                </div>
              </td>

              <td class="text2 valueColumn">
                <div class="row">
                  <div class="col-sm-auto">
                    <span
                      class="negative-amount"
                      *ngIf="sale?.rechargeFeeAmount < 0"
                      >-</span
                    >
                  </div>

                  <div [attr.data-e2e]="'table-fee-' + i" class="col">
                    {{ sale?.administrationFee || '-' }}
                  </div>
                </div>
              </td>

              <td [attr.data-e2e]="'table-status-' + i" class="text2">
                {{ sale?.transactionStatus }}
              </td>

              <td [attr.data-e2e]="'table-status-' + i">
                {{ sale?.transactionStatusChangeDate }}
              </td>
              <td [attr.data-e2e]="'table-status-' + i">
                {{ sale?.statusNd || '-' }}
              </td>
              <td>
                <img
                  src="assets/svg/Estorno.svg"
                  alt="Estorno"
                  ngbTooltip="Estornar transação"
                  positionTarget="top"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div class="container-loading" *ngIf="isLoading">
          <app-loader
            [isLoading]="isLoading"
            message="{{ message | translate }}"
          ></app-loader>
        </div>
      </div>

      <div class="pagination-custom" *ngIf="
      DtoTransactionSales?.totalItems > queryArguments.pageSize &&
      !isLoading
      ">
        <ngb-pagination
          (pageChange)="onPaginationChange($event)"
          [pageSize]="queryArguments.pageSize"
          [maxSize]="5"
          [collectionSize]="DtoTransactionSales?.totalItems"
          [(page)]="queryArguments.page"
          [rotate]="true"
          [boundaryLinks]="true"
          [ellipses]="false"
          display="dynamic"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
