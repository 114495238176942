import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
registerLocaleData(ptBr);

import { FinancialSalesReportRoutingModule } from './routes/financial-sales-report-routing.module';
import { FinancialSalesReportComponent } from './financial-sales-report.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {RadioButtonModule} from "../../../shared/components/radiobutton/radiobutton.module";
import {SharedModule} from "../../../shared/shared.module";
import {CoreModule} from "../../../core/core.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    FinancialSalesReportRoutingModule,
    NgbModule,
    BsDropdownModule,
    BsDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    NgxCurrencyDirective,
    RadioButtonModule,
    ToastrModule,
    NgxMaskPipe,
    NgxMaskDirective,
  ],
  declarations: [FinancialSalesReportComponent],
  providers: [provideNgxMask()],
})
export class FinancialSalesReportModule {}
