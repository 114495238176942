<div class="RechargeComponent">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 Dashboard_Overview header-page">
        <div class="d-flex bd-highlight mb-3 container-header">
          <div class="mr-auto p-2 bd-highlight">
            <h1 data-e2e="page-title">/ RECARGAS</h1>
          </div>
          <div>
            <div class="tag-white" *ngIf="hasCreditCard == false">
            <div><strong><b>C</b>adastre um cartão de crédito</strong>
              <p>Para que suas recargas sejam realizadas automaticamente.</p>
            </div>
            <div class="btn-action">
              <button
              *accessControl="101177"
              type="button"
              class="btn btn-secondary position-relative"
              (click)="showRegisterCreditCardModal()"
              >
               <span>Clique aqui</span>
             </button>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="prePaidConditions$ | async as conditions; else loading"
        class="col-12 d-flex RechargeContainer"
      >
        <div class="mt-5 Dashboard_col_1">
          <div class="container-faturas shadow">
            <div class="box-title">
              <span data-e2e="contracted-plan-label">Plano contratado</span>
            </div>

            <div class="box-body" *ngIf="!isLoading">
              <div class="col-12 d-flex pl-0 pr-0">
                <div class="col-6 pl-0">
                  <div class="box-left">
                    <div data-e2e="auto-recharge-title-example" class="title">
                      Recarga automática
                    </div>

                    <div class="text-center">
                      <div
                        data-e2e="auto-recharge-value-example"
                        class="box-recarga"
                      >
                        <b class="mr-2">R$</b>
                        {{
                          conditions.automaticRechargeValue
                            | currency : " " : "symbol" : "0.2-2" : "pt-BR"
                        }}
                      </div>

                      <div class="box-porcentagem">
                        <div class="percent_connect">
                          <div
                            data-e2e="auto-recharge-min-percentage-example"
                            class="porcentagem"
                          >
                            {{
                              conditions.minimumPercentageForAutomaticRecharge | currency : " " : "symbol" : "0.2-2" : "pt-BR"
                            }}%
                          </div>
                        </div>
                      </div>

                      <div class="box-border-left shadow-lg">
                        <b data-e2e="auto-recharge-tax-value-example">{{
                          conditions.contractMinimunBalance
                            | currency : "R$" : "symbol" : "0.2-2" : "pt-BR"
                        }}</b>
                        Saldo mínimo
                      </div>

                      <div class="box-taxa">
                        <span data-e2e="recharge-fee-label"
                          >Taxa de recarga:
                          <b
                            data-e2e="recharge-fee"
                            id="rechargeFee"
                            *ngIf="conditions.rechargeFee !== 0"
                            >{{ conditions.rechargeFee }}%</b
                          >
                          <b
                            data-e2e="recharge-exempted-msg"
                            id="rechargeFee"
                            *ngIf="conditions.rechargeFee == 0"
                            >Isento</b
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6 pr-0 pl-0">
                  <div class="box-plano">
                    <div data-e2e="plan-description-title" class="title">
                      {{ conditions.marketingPlanTypeDescription }}
                    </div>

                    <div class="infos">
                      <div class="infos-left">
                        <div data-e2e="tag-aquisition-label">
                          Aquisição de tags:
                        </div>

                        <div data-e2e="monthly-payment-label">Mensalidade:</div>

                        <div data-e2e="monthly-payment-day-label">
                          Dia do débito da mensalidade:
                        </div>
                      </div>

                      <div class="text-left">
                        <div
                          data-e2e="tag-aquisition"
                          id="tagMonthlyFeeUnitValue"
                        >
                          {{
                            conditions.value
                              | currency : "R$" : "symbol" : "0.2-2" : "pt-BR"
                          }}
                        </div>
                        <div data-e2e="monthly-payment" id="monthlyValue">
                          {{
                            conditions.tagMonthlyFeeUnitValue
                              | currency : "R$" : "symbol" : "0.2-2" : "pt-BR"
                          }}
                        </div>
                        <div
                          data-e2e="monthly-payment-day"
                          id="tagMonthlyFeeEndDate"
                        >
                          {{ conditions.monthlyDebitDay }}
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12 d-flex p-0 buttonChangeAutoRecharge">
                      <button
                        data-e2e="open-change-auto-recharge-modal-btn"
                        *accessControl="101176"
                        buttonBlock
                        [ContractBlock]="[3]"
                        class="btn btn-repom btn-repom-user m-0 d-flex"
                        type="button"
                        (click)="changeAutoRecharge(conditions)"
                        id="idButtonChangeAutoRecharge"
                      >
                        <img src="../../../assets/svg/edit.svg" alt="" />

                        <div
                          data-e2e="change-auto-recharge-value-btn"
                          class="desc"
                        >
                          Alterar valor da recarga automática
                        </div>

                        <span class="btn-animation"></span>
                      </button>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-12">
                <div data-e2e="recharge-description-msg" class="text">
                  <span class="recharge-text-bold">Com a recarga automática, sua frota pode economizar tempo e dinheiro</span>, além de evitar atrasos e multas em pedágios. <span class="recharge-text-bold">Cadastre um cartão de crédito e defina o valor da sua recarga automática a qualquer momento
                  </span> e, sempre que o saldo disponível chegar a {{ conditions.minimumPercentageForAutomaticRecharge  | currency : " " : "symbol" : "0.2-2" : "pt-BR" }}%
                  do valor definido, realizaremos uma nova recarga automática.<br>
                  <span class="recharge-text-bold">Dessa forma, garantimos mais praticidade, segurança e economia para a operação da sua empresa.</span><br><br>
                  <span>Para garantir a segurança da sua operação e evitar o bloqueio de suas tags, seu saldo disponível na plataforma deve ser superior a {{ conditions.percentageRechargeValue | currency : " " : "symbol" : "0.2-2" : "pt-BR"  }}% do valor cadastrado para a recarga automática. Recomendamos que mantenha o cartão de crédito sempre atualizado na plataforma.</span>
                  <div class="recharge-alert-description">Caso não tenha cartão de crédito cadastrado, seu saldo disponível no portal deve ser superior a R$ 50,00. Realize recargas avulsas para evitar bloqueios.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 Dashboard_col_1" *accessControl="101231">
          <div class="container-faturas shadow">
            <div class="box-title">
              <span>Recarga avulsa</span>
            </div>

            <div class="box-body recharge-container">
              <p>
                <b>Escolha um valor a partir de {{ conditions?.minSingleRechargeValue | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }} para sua recarga avulsa</b>
              </p>
              <div class="py-4 px-2">
                <form [formGroup]="looseRechargeForm">
                  <div class="form-group select-table">
                    <label> <b>Recarregar</b> </label>
                    <p class="RS">R$</p>
                    <input
                      id="idInputAmount"
                      formControlName="amount"
                      currencyMask
                      [options]="{ prefix: '', thousands: '.', decimal: ',' }"
                      class="form-control"
                    />
                  </div>
                  <div
                    class="alertErrorValidator"
                    *ngIf="
                      (looseRechargeForm.controls['amount']?.invalid &&
                        looseRechargeForm.controls['amount']?.touched) ||
                      (submitted &&
                        looseRechargeForm.controls['amount']?.invalid)
                    "
                  >
                    {{
                      generateMessage(
                        looseRechargeForm.controls["amount"].errors
                      )
                    }}
                  </div>
                  <div class="linkFeeRecharge">
                    <a (click)="showRechargeFeeModal()" ngbTooltip="Clique para ver as taxas de recarga.">Taxas de recarga<i class="fas fa-exclamation-circle"></i></a>
                  </div>
                </form>
              </div>
              <div class="box-payment">
                <div>
                  <app-payment-method
                    [reducedList]="false"
                    [selectorMode]="true"
                  ></app-payment-method>
                </div>

              </div>
              <div class="box-confirm">
                <button
                  *accessControl="101175"
                  [disabled]="
                    looseRechargeForm.invalid || validPaymentMethod()"
                  type="button"
                  class="btn btn-secondary position-relative"
                  (click)="looseRecharge()"
                >
                  <span>Próximo </span>
                  <img
                    class="loading-btn"
                    src="../../../assets/img/arrow_forward.png"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-template class="box-body box-body-loading" #loading>
        <div class="container-loading col-12">
          <app-loader
            [isLoading]="isLoading"
            message="{{ 'Carregando...' }}"
          ></app-loader>
        </div>
      </ng-template>
    </div>
  </div>
</div>
