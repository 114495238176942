<mat-card class="bill-card" *ngIf="bill">
  <div class="d-flex row justify-content-between">
    <div class="col-sm " [id]="'mat-card-header-1-' + count">
      <mat-card-header>
        <mat-card-title>
                  <span [style.border]="bill?.styleBorder"
                        [style.color]="bill?.styleStatusColor"
                        [style.background-color]="bill?.styleBackgroundColor"
                        class="card-bill-status">
                    <b>{{ bill.status }}</b>
                  </span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="d-flex flex-row">
          <div class="info-bill-code">
            <p [id]="'info-bill-code-title-' + count">Cod. Fatura</p>
            <p>
                        <span [id]="'bill-info-id-' + count"
                              [attr.data-e2e]="'bill-info-id-' + count">
                          {{ bill.id }}
                        </span>
            </p>
          </div>
          <div class="info-bill-period">
            <p [id]="'info-bill-period-title-' + count">Período</p>
            <p style="font-weight: bold">
                        <span [id]="'bill-info-openingDate-' + count"
                              [attr.data-e2e]="'bill-info-openingDate-' + count">
                          {{ bill.openingDate | date: "dd/MM/yyyy" }}
                        </span>
              a
              <span [id]="'bill-info-closingDate-' + count"
                    [attr.data-e2e]="'bill-info-closingDate-' + count">
                          {{ bill.closingDate | date: "dd/MM/yyyy" }}
                        </span>
            </p>
          </div>
        </div>
        <div [style.color]="bill?.styleAmountColor">
          <p>
                      <span class="info-bill-value"
                            [id]="'bill-info-amount-' + count"
                            [attr.data-e2e]="'bill-info-amount-' + count">
                        {{ bill.amount | currency: "BRL":"symbol":"0.2-2":"pt-BR" }}
                      </span>
          </p>
        </div>
        <div class="info-bill-dates">
          <p>Fechamento:
            <span [id]="'bill-info-closingDate-' + count"
                  [attr.data-e2e]="'bill-info-closingDate-' + count">
                        {{ bill.closingDate | date: "dd/MM/yyyy" }}
                      </span>
          </p>
          <p>Vencimento:
            <span [id]="'bill-info-dueDate-' + count"
                  [attr.data-e2e]="'bill-info-dueDate-' + count">
                        {{ bill.dueDate | date: "dd/MM/yyyy" }}
                      </span>
          </p>
          <p *ngIf="bill.paymentDate && bill.idStatus === BILL_STATUS.PAGA">Pagamento:
            <span [id]="'bill-info-paymentDate-' + count"
                  [attr.data-e2e]="'bill-info-paymentDate-' + count">
                        {{ bill.paymentDate | date: "dd/MM/yyyy" }}
                      </span>
          </p>
        </div>
      </mat-card-content>
    </div>
    <div class="col-sm" [id]="'mat-card-header-2-' + count">
      <div class="bill-col-btn-options">
        <mat-card-header>
          <mat-card-title>
            <button
              mat-raised-button
              *ngIf="isVisibleMainButtonVisualizarDetalhes(bill)"
              [id]="'bill-main-btn-bill-datails-access-' + count"
              [attr.data-e2e]="'bill-main-btn-bill-datails-access-' + count"
              [routerLink]="['/faturas/detalhe-fatura', bill.id]">
              Ver detalhes da fatura
            </button>
            <button
              mat-raised-button
              *ngIf="isVisibleMainButtonBaixarBoleto(bill)"
              [id]="'bill-main-btn-download-boleto-' + count"
              [attr.data-e2e]="'bill-main-btn-download-boleto-' + count"
              (click)="redirectToLink(bill.billetLink)"
              [disabled]="!bill.billetLink">
              Baixar boleto
            </button>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="mat-card-custom">
          <div class="d-flex flex-column justify-content-center" style="align-items: center;">
            <button mat-button (click)="isClickOpenClose(bill)" class="btn-open-close-options"
                    [attr.data-e2e]="'bill-btn-open-close-options-' + count"
                    [id]="'bill-btn-open-close-options-' + count">
              <div *ngIf="bill.isOpen" class="setting-text d-flex flex-row">
                <span>Ver menos</span>
                <img src="../../assets/svg/open-menu.svg" alt="" class="img-open-close"/>
              </div>
              <div *ngIf="!bill.isOpen" class="setting-text d-flex flex-row">
                <span class="setting-text"> Ver mais</span>
                <img src="../../assets/svg/close-menu.svg" alt="" class="img-open-close"/>
              </div>
            </button>
            <div [@openClose]="(bill.isOpen ?? false).toString()" class="open-close-container container-options">
              <div *ngIf="BILL_STATUS.ABERTA === bill.idStatus" class="open-close-info-option row"
                   [style.color]="COLOR.BLUE"
                   [attr.data-e2e]="'bill-info-options-open-' + count"
                   [id]="'bill-info-options-open-' + count">
                <span class="col-md-auto">
                  <img src="../../assets/svg/information-circle.svg" alt="" class="col bill-option-icon-alert"/>
                  {{ getDescriptionByBillStatusAndContractModalityType(bill.idStatus) }}
                </span>
              </div>
              <div *ngIf="BILL_STATUS.EM_FECHAMENTO === bill.idStatus" class="open-close-info-option row"
                   [style.color]="COLOR.PURPLE"
                   [attr.data-e2e]="'bill-info-options-closing-' + count"
                   [id]="'bill-info-options-closing-' + count">
                <span
                  class="col-md-auto">
                  <img src="../../assets/svg/alert-circle.svg" alt="" class="bill-option-icon-alert"/>
                  {{ getDescriptionByBillStatusAndContractModalityType(bill.idStatus) }}
                </span>
              </div>
              <div *ngIf="isVisibleBtnVisualizarDetalhes(bill)">
                <button mat-raised-button class="btn-option"
                        [attr.data-e2e]="'bill-btn-bill-datails-access-' + count"
                        [id]="'bill-btn-bill-datails-access-' + count"
                        [routerLink]="['/faturas/detalhe-fatura', bill.id]">Ver detalhes da fatura
                </button>
              </div>
              <div *ngIf="isVisibleBtnBoleto(bill)">
                <button mat-raised-button class="btn-option"
                        [attr.data-e2e]="'bill-btn-download-boleto-' + count"
                        [id]="'bill-btn-download-boleto-' + count"
                        [disabled]="this.userProfileService.isBackOffice() && !bill.billetLink"
                        (click)="redirectToLink(bill.billetLink)">Baixar boleto
                </button>
              </div>
              <div *ngIf="isVisibleBtnNotaFiscal(bill)">
                <button mat-raised-button class="btn-option"
                        [attr.data-e2e]="'bill-btn-download-nf-' + count"
                        [id]="'bill-btn-download-nf-' + count"
                        (click)="redirectToLink(bill.invoiceLink)">Baixar nota fiscal
                </button>
              </div>
              <div *ngIf="isVisibleBtnCSV(bill)">
                <button mat-raised-button class="btn-option"
                        [attr.data-e2e]="'bill-btn-download-csv-' + count"
                        [id]="'bill-btn-download-csv-' + count"
                        (click)="generateCSV(bill.id, bill.csvFile, bill.idStatus, bill.csvDate)">Baixar demonstrativo CSV
                </button>
              </div>
              <div *ngIf="isVisibleBtnPDF(bill)">
                <button mat-raised-button class="btn-option"
                        [attr.data-e2e]="'bill-btn-download-pdf-' + count"
                        [id]="'bill-btn-download-pdf-' + count"
                        (click)="generatePDF(bill.id, bill.flgDownloadPDF, bill.pdfFile)">Baixar fatura em
                  PDF
                </button>
              </div>
              <div *controlAccessBackOffice>
                <div *ngIf="isVisibleBtnBaixaManual(bill)">
                  <button mat-raised-button class="btn-option"
                          [attr.data-e2e]="'bill-btn-baixa-manual-' + count"
                          [id]="'bill-btn-baixa-manual-' + count"
                          (click)="getManualRecompositionLimit(bill.id)">Baixa manual
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </div>
    </div>
  </div>
</mat-card>
