<div class="deliveryAddressesModalComponent">
    <div class="modal-content">
        <div class="modal-header">
          <div class="box-title">
            <span data-e2e="modal-title">Endereços para entrega</span>
          </div>
          <button data-e2e="close-modal-top-btn" type="button" class="close" (click)="close()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body show">
          <div class="row">
              <div class="col searchBlock">
                  <form class="input-search">
                    <input
                      data-e2e="search-input"
                      id="search"
                      type="text"
                      placeholder="cep, endereço, número, ..."
                      (keyup)="onFullNameKeyup($event)"
                      [value]="search" />
                    <button
                      data-e2e="search-btn"
                      id="searchButton"
                      type="button"
                      class="btn btn-search"
                      (click)="onSearchClick()">
                      <img src="assets/img/icon-search.svg" class="button-icon" />
                      <span>Buscar</span>
                    </button>
                  </form>
                </div>
                <div class="col addBlock">
                  <button
                    *accessControl="101141"
                    data-e2e="add-address-btn"
                    id="addAddressBtn"
                    class="btn btn-repom btn-repom-user"
                    type="button"
                    (click)="showAddAddressModal()"
                  >
                    Cadastrar novo endereço
                    <span class="btn-animation"></span>
                  </button>
                </div>
          </div>
            <ul class="address-list"  *ngIf="!isLoading && deliveryAddresses?.legalPersonAddresses.length > 0">
                <li class="row" [ngClass]="fromOrder && (item.addressTypeId == 1 && item.landmark) ? 'lg-mg' : '' " *ngFor="let item of deliveryAddresses?.legalPersonAddresses; let i = index">
                  <div class="col-sm-auto ico-map-block" *ngIf="item.principalFlg == 1">
                    <i class="ico-map" data-e2e="address-flag">Principal</i>
                    <span *ngIf="item.addressTypeId == 1" class="address-type" data-e2e="address-type">Endereço Comum</span>
                    <span *ngIf="item.addressTypeId == 2" class="address-type" data-e2e="address-type">Caixa Postal</span>
                  </div>

                  <div class="col-sm-auto ico-map-block" *ngIf="item.principalFlg == 0">
                    <div class="ico-map"></div>
                    <span *ngIf="item.addressTypeId == 1" class="address-type" data-e2e="address-type">Endereço Comum</span>
                    <span *ngIf="item.addressTypeId == 2" class="address-type" data-e2e="address-type">Caixa Postal</span>
                  </div>

                  <div class="col-lg-auto description">
                    <p *ngIf="item.addressTypeId == 1">
                      <span [attr.data-e2e]="'address-street-' + i" id="streetAddress{{i}}"  class="capitalize">{{ item.streetAddress }}</span>, <span [attr.data-e2e]="'address-number-' + i" id="number{{i}}">{{ item.number }}</span><span [attr.data-e2e]="'address-complementary-' + i" id="streetAddressLine2_{{i}}" *ngIf="item.streetAddressLine2"> - {{item.streetAddressLine2}}</span> - <span [attr.data-e2e]="'address-district-' + i" id="district{{i}}"  class="capitalize">{{ item.district }}</span> - <span [attr.data-e2e]="'address-city-' + i" id="city{{i}}" class="capitalize">{{item.city}}</span> - <span [attr.data-e2e]="'address-federatedUnit-' + i" id="federatedUnit{{i}}"  class="uppercase">{{item.federatedUnit}}</span><br />
                      CEP: <span [attr.data-e2e]="'address-zipcode-' + i" id="zipCode{{i}}">{{item.zipCode | mask: 'AAAAA-AAA'}}</span> <br *ngIf="item.landmark"  />
                      <span *ngIf="item.landmark" [attr.data-e2e]="'address-landmark-' + i">{{item.landmark}}</span> <br />
                      <b id="typeAdress{{i}}">{{item.typeAdress}}</b>
                    </p>
                    <p *ngIf="item.addressTypeId == 2">
                      Código da caixa postal: <span [attr.data-e2e]="'address-mailbox-' + i" id="mailbox{{i}}">{{ item.mailbox }}</span> <br />
                      <span [attr.data-e2e]="'address-city-' + i" id="city{{i}}" class="capitalize">{{item.city}}</span> - <span [attr.data-e2e]="'address-federatedUnit-' + i" id="federatedUnit{{i}}"  class="uppercase">{{item.federatedUnit}}</span><br />
                      CEP: <span [attr.data-e2e]="'address-zipcode-' + i" id="zipCode{{i}}">{{item.zipCode | mask: 'AAAAA-AAA'}}</span> <br />
                      <b id="typeAdress{{i}}">{{item.typeAdress}}</b>
                    </p>
                    <button *ngIf="fromOrder" [attr.data-e2e]="'select-address-btn-' + i"  id="btn-select-address" class="btn btn-secondary" (click)="selectAddressToOrder(item.id,item.zipCode)">
                      Escolher endereço
                    </button>
                  </div>

                  <div class="col-sm-auto ico action-btns-block" *accessControl="101141">
                    <button
                      class="btn edit btn-functionality"
                      (click)="showEditAddressModal(item)"
                      type="button"
                      data-e2e="edit-btn"
                    >
                      <i class="ico ico-white ico-edit"></i>
                      <p>Editar</p>
                    </button>
                    <button
                      *accessControl="101142"
                      [disabled]="deliveryAddresses?.numberOfItems == 1"
                      class="btn delete btn-functionality"
                      (click)="showRemoveAddressModal(item)"
                      type="button"
                      data-e2e="delete-btn"
                    >
                      <i class="ico ico-white ico-delete"></i>
                      <p>Excluir</p>
                    </button>
                  </div>
                  <hr/>
                </li>
              </ul>
              <div colspan="12" class="text-center w-100 py-4" data-e2e="empty-Addresses-result-msg" *ngIf="!isLoading && deliveryAddresses?.legalPersonAddresses.length == 0">
                Nenhum resultado encontrado.
              </div>
              <div class="container-loading" *ngIf="isLoading">
                <app-loader [isLoading]="isLoading" message="{{ 'Carregando...' }}"></app-loader>
              </div>
        </div>
        <div class="modal-footer modal-footer-gray">
          <button type="button" data-e2e="close-modal-btn" class="btn btn-secondary p mr-4" (click)="close()">Fechar</button>
        </div>
      </div>
</div>

