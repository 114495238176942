<div class="container-invoice-responsive">
  <div
    class="container-repom container-dashboard"
    [ngClass]="contractBlock == 3 ? 'contractBlock' : ''"
    *ngIf="invoiceDashboard == null">
      <header class="dashboard-card-header">
          <div class="dashboard-card-title-box">
              <i class="ico ico-invoice"></i>
              <h2 data-e2e="invoice-title" class="card-title">FATURAS</h2>
          </div>

          <button
              data-e2e="check-invoice-link"
              id="ConsultInvoicesButton"
              class="btn btn-repom"
              [routerLink]="['/faturas']">
              <div>
                  Consultar Faturas
                  <i class="ico ico-double-arrow"></i>
              </div>

              <span class="btn-animation"></span>
          </button>
      </header>

      <app-loader [isLoading]="isLoading" message="Carregando..."></app-loader>

      <main class="row mt-4">
          <div class="col-lg-12 mt-5 text-center">
            <strong data-e2e="empty-search-msg">
              Não foi encontrada nenhuma fatura para este contrato.
            </strong>
          </div>
      </main>
  </div>

  <div
      class="container-repom container-dashboard"
      *ngIf="invoiceDashboard != null"
      [ngClass]="getContractClass(invoiceDashboard?.billStatusTypeID)">

      <app-loader [isLoading]="isLoading" message="Loading..."></app-loader>

      <header class="dashboard-card-header">
          <div class="dashboard-card-title-box">
              <i class="ico ico-invoice"></i>
              <h2 data-e2e="invoice-title" class="card-title">FATURAS</h2>
          </div>

          <button
              data-e2e="check-inovice-link"
              id="ConsultInvoicesButton"
              class="btn btn-repom"
              *accessControl="101126"
              [routerLink]="['/faturas']">
              <div>
                  Consultar Faturas
                  <i class="ico ico-double-arrow"></i>
              </div>

              <span class="btn-animation"></span>
          </button>
      </header>

      <main class="card-content mt-5" *ngIf="isError">
          <span data-e2e="error-load-information-msg" class="w-100 text-center d-inline-block error">
              <i class="fas fa-exclamation-circle"></i>
              Unable to load the information
          </span>
      </main>

      <main class="card-content" [hidden]="isError" *ngIf="!isLoading">
          <span data-e2e="open-invoice-msg" class="w-100 text-center d-inline-block msg-noresponsive" *ngIf="invoiceDashboard?.billStatusTypeID == 1 && contractBlock !== 3">
            Sua fatura está aberta.
          </span>

          <span data-e2e="closed-invoice-msg" class="w-100 text-center d-inline-block msg-noresponsive" *ngIf="invoiceDashboard?.billStatusTypeID == 2 && contractBlock !== 3">
            Sua fatura está em fechamento.
          </span>

          <span data-e2e="waiting-payment-msg" class="w-100 text-center d-inline-block msg-noresponsive" *ngIf="invoiceDashboard?.billStatusTypeID == 3 && contractBlock !== 3">
            Sua fatura está fechada e aguardando pagamento.
          </span>

          <span data-e2e="paid-invoice-msg" class="w-100 text-center d-inline-block msg-noresponsive" *ngIf="invoiceDashboard?.billStatusTypeID == 4 && contractBlock !== 3">
            Sua fatura está paga.
          </span>

          <span data-e2e="to-be-paid-invoice-msg" class="w-100 text-center d-inline-block msg-noresponsive" *ngIf="invoiceDashboard?.billStatusTypeID == 5 && lastBillDueDate !== today && contractBlock !== 3">
            Sua fatura está a pagar.
          </span>

          <div class="detail-payment-container">
              <div data-e2e="financial-block-msg" class="financialBlockMessage" *ngIf="contractBlock == 3">
                  regularize a situação para continuar usando nossos serviços
              </div>

              <div data-e2e="due-bill-msg" class="billToDueMessage" *ngIf="invoiceDashboard?.billStatusTypeID == 5 && lastBillDueDate == today && contractBlock !== 3">
                  Realize o pagamento e evite multas por atraso.<br><span class="gray">Se já foi pago, desconsidere.</span>
              </div>

              <div data-e2e="overdue-bill-msg" class="overdueBillMessage" *ngIf="invoiceDashboard?.billStatusTypeID == 6 && contractBlock !== 3">
                  Realize o pagamento hoje para evitar o bloqueio de seus serviços. <br><span class="gray">Se já foi pago, desconsidere.</span>
              </div>

              <div class="detail-payment-content">
                  <div class="status status-open" *ngIf="invoiceDashboard?.billStatusTypeID == 1">
                      <img src="assets/img/payment-open.svg" />
                      <span data-e2e="open-msg">Aberto</span>
                  </div>

                  <div class="status status-open" *ngIf="invoiceDashboard?.billStatusTypeID == 2">
                      <img src="assets/img/payment-open.svg" />
                      <span data-e2e="closing-msg">Fechamento</span>
                  </div>

                  <div class="status status-closed" *ngIf="invoiceDashboard?.billStatusTypeID == 3">
                      <img src="assets/img/payment-closed.svg" />
                      <span data-e2e="closed-msg">Fechado</span>
                  </div>

                  <div class="status status-paid-out" *ngIf="invoiceDashboard?.billStatusTypeID == 4">
                      <img src="assets/img/payment-paid-out.svg" />
                      <span data-e2e="paid-msg">Pago</span>
                  </div>

                  <div class="status status-to-pay" *ngIf="invoiceDashboard?.billStatusTypeID == 5">
                      <img src="assets/img/payment-to-pay.svg" />
                      <span data-e2e="due-msg">A Pagar</span>
                  </div>

                  <div class="status status-late" *ngIf="invoiceDashboard?.billStatusTypeID == 6">
                      <img src="assets/img/payment-late.svg" />
                      <span data-e2e="late-msg">Atrasado</span>
                  </div>

                  <div class="compliant-info">
                      <img class="img-calendar" src="assets/img/calendar.svg" />

                      <div data-e2e="opening-month-data" class="month">{{ openingMonth }}</div>

                      <div class="date" *ngIf="invoiceDashboard?.billStatusTypeID == 1 || invoiceDashboard?.billStatusTypeID == 2">
                          Fechamento: <span data-e2e="closing-date-data" id="closingDate">{{ invoiceDashboard?.closingDate | date: 'dd/MM/yyyy' }}</span>
                      </div>

                      <div class="date" *ngIf="invoiceDashboard?.billStatusTypeID == 3 || invoiceDashboard?.billStatusTypeID == 4 || invoiceDashboard?.billStatusTypeID == 5 || invoiceDashboard?.billStatusTypeID == 6 ">
                          Vencimento: <span data-e2e="due-date-data" id="dueDate">{{ invoiceDashboard.dueDate | date: 'dd/MM/yyyy' }}</span>
                      </div>

                      <div data-e2e="invoice-total-data" class="value">{{ totalInvoicesDashboard | currency: 'BRL':'symbol':'0.2-2':'pt-BR' }}</div>
                  </div>
              </div>
          </div>
        <div *ngIf="configurationCardContent" class="card-content-information-responsive">
          <span [attr.data-e2e]="configurationCardContent.dataE2e" class="{{configurationCardContent._class + '-responsive'}}">
            {{ configurationCardContent?.msgMain }}
            <span class="gray" *ngIf="configurationCardContent?.msgSecondary">
              <br>{{ configurationCardContent?.msgSecondary }}
            </span>
          </span>
        </div>
      </main>
  </div>
</div>
