<div class="box-title">
  <div class="row ps-rel">
    <div
      class="col-12 d-flex justify-content-center"
      style="top: 65px; position: absolute"
    >
      <ul
        ngbNav
        #nav="ngbNav"
        class="nav nav-tabs d-flex justify-content-between nav-justified"
      >
        <li ngbNavItem>
          <a ngbNavLink>Consolidado</a>
          <ng-template ngbNavContent>
            <div class="container px-0 mx-0">
              <div class="row align-items-center consolid-info-responsive" style="min-height: 350px">
                <div class="col-4 d-flex flex-column align-items-end justify-content-end info-value-responsive">
                  <span data-e2e="invoice-total-msg" class="text-bill-amount mb-2"
                    >Valor total da fatura</span
                  >
                  <div
                    class="box-amount d-flex align-items-center justify-content-end"
                  >
                    <span data-e2e="invoice-total-data" id="totalBillValue">{{
                      billSummary?.totalBillValue
                        | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                    }}</span>
                  </div>
                </div>

                <div class="col-4 info-value-responsive">
                  <span data-e2e="amount-to-pay-msg" class="text-title" *modalityType="2"
                    >Valores a pagar</span
                  >
                  <span data-e2e="amount-paid-msg" class="text-title" *modalityType="1"
                    >Valores pagos</span
                  >
                  <div class="row mt-3">
                    <div class="col-6">
                      <span data-e2e="toll-title" class="services-label d-block">PEDÁGIO</span>
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="toll-amount-data" class="services-values-label-text">
                          <span data-e2e="toll-amount-sign" class="negative-amount">{{
                            billSummary?.passesValue > 0 ? "-" : ""
                          }}</span>
                          <span data-e2e="toll-amount-number" id="passesValue">{{
                            billSummary?.passesValue
                              | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-6">
                      <span data-e2e="toll-reversal-label" class="services-label d-block"
                        >ESTORNOS DE PEDÁGIO</span
                      >
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="toll-reversal-data" class="services-values-label-text">
                          <span data-e2e="toll-reversal-sign">{{
                            billSummary?.reversalValue > 0 ? "+" : ""
                          }}</span>
                          <span data-e2e="toll-reversal-number" id="reversalValue">{{
                            billSummary?.reversalValue
                              | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-6">
                      <span data-e2e="parking-label" class="services-label d-block">ESTACIONAMENTO</span>
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="parking-data" class="services-values-label-text">
                          <span data-e2e="parking-sign" class="negative-amount">{{
                            billSummary?.parkingValue > 0 ? "-" : ""
                          }}</span>
                          <span data-e2e="parking-number" id="parkingValue">{{
                            billSummary?.parkingValue
                              | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-6">
                      <span data-e2e="parking-reversal-label" class="services-label d-block"
                        >ESTORNOS DE ESTACIONAMENTO</span
                      >
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="parking-reversal-data" class="services-values-label-text">
                          <span data-e2e="parking-reversal-sign">{{
                            billSummary?.parkingReversalValue > 0 ? "+" : ""
                          }}</span>
                          <span data-e2e="parking-reversal-number" id="parkingReversalValue">{{
                            billSummary?.parkingReversalValue
                              | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-3">
                    <div class="col-6">
                      <span data-e2e="services-label" class="services-label d-block">Serviços</span>
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="services-data" class="services-values-label-text">
                          <span data-e2e="services-sign" class="negative-amount">{{
                            billSummary?.servicesValue > 0 ? "-" : ""
                          }}</span>
                          <span data-e2e="services-number" id="servicesValue">
                            {{
                              billSummary?.servicesValue
                                | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--Pós-pago-->
                <div class="col-4 align-self-baseline pt-3 info-value-responsive" *modalityType="2">
                  <span data-e2e="paid-amounts-title" class="text-title">Valores pagos / Utilizados</span>

                  <div class="row mt-3">
                    <div class="col-6">
                      <span
                        data-e2e="used-vpr-label"
                        class="services-label d-block"
                        >Vale Pedagio <br />
                        Utilizado</span
                      >
                    </div>

                    <div class="col-6">
                      <div class="services-values-label">
                        <div data-e2e="used-vpr-data" class="services-values-label-text">
                          <span data-e2e="used-vpr-sign">{{
                            billSummary?.vprValue > 0 ? "+" : ""
                          }}</span>
                          <span data-e2e="used-vpr-number" id="vprValue">{{
                            billSummary?.vprValue
                              | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="billSummary?.hasVprAntecipation" class="mt-3 info-discount-bill-responsive">
                    <span data-e2e="invoice-discount-title" class="text-title">Desconto em fatura</span>

                    <div class="row mt-3">
                      <div class="col-6">
                        <span class="services-label d-block">
                          <img
                            ngbTooltip="Antecipação de vale-pedágio realizada no período"
                            src="../../../../../../assets/svg/info-24px.svg"
                            class="mr-1"
                            alt=""
                            data-e2e="vpr-antecipation-label"
                          />Antecipação <br />
                          de Vale-Pedágio</span
                        >
                      </div>

                      <div class="col-6">
                        <div class="services-values-label">
                          <div data-e2e="vpr-antecipation-data" class="services-values-label-text">
                            <span data-e2e="vpr-antecipation-sign">{{
                              billSummary?.vprAntecipationValue > 0 ? "+" : ""
                            }}</span>
                            <span data-e2e="vpr-antecipation-number" id="vprValue">{{
                              billSummary?.vprAntecipationValue
                                | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                            }}</span>

                            <button
                              data-e2e="vpr-more-details-btn"
                              ngbTooltip="Veja o relatório da antecipação de vale-pedágio"
                              (click)="showAdvanceDetailsModal()"
                              class="btn btn-circle-add"
                            >
                              <span>+</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--Pré-pago-->
                <div class="col-4 info-value-responsive" *modalityType="1">
                  <div class="row">
                    <div class="col-12 align-self-baseline mg15">
                      <span data-e2e="paid-amounts-title" class="text-title">Valores pagos / Utilizados</span>

                      <div class="row mt-3">
                        <div class="col-6">
                          <span data-e2e="used-vpr-label" class="services2-label d-block"
                            >Vale Pedagio <br />
                            Utilizado</span
                          >
                        </div>

                        <div class="col-6">
                          <div class="services-values-label">
                            <div data-e2e="used-vpr-data" class="services-values-label-text">
                              <span data-e2e="used-vpr-sign">{{
                                billSummary?.vprValue > 0 ? "+" : ""
                              }}</span>
                              <span data-e2e="used-vpr-number" id="vprValue">{{
                                billSummary?.vprValue
                                  | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 align-self-baseline mg15">
                      <span data-e2e="recharge-title" class="text-title">Valores de Recarga</span>

                      <div class="row mt-3">
                        <div class="col-6">
                          <span data-e2e="single-recharge-label" class="services2-label d-block"
                            >Recarga <br />
                            Avulsa</span
                          >
                        </div>

                        <div class="col-6">
                          <div class="services-values-label">
                            <div data-e2e="single-recharge-data" class="services-values-label-text">
                              <span data-e2e="single-recharge-sign">{{
                                billSummary?.singleRefillValue > 0 ? "+" : ""
                              }}</span>
                              <span data-e2e="single-recharge-number" id="singleRefillValue">{{
                                billSummary?.singleRefillValue
                                  | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row mt-3">
                        <div class="col-6">
                          <span data-e2e="auto-recharge-label" class="services2-label d-block"
                            >Recarga <br />
                            Automática</span
                          >
                        </div>

                        <div class="col-6">
                          <div class="services-values-label">
                            <div data-e2e="auto-recharge-data" class="services-values-label-text">
                              <span data-e2e="auto-recharge-sign">{{
                                billSummary?.automaticRefillValue > 0 ? "+" : ""
                              }}</span>
                              <span data-e2e="auto-recharge-number" id="automaticRefillValue">{{
                                billSummary?.automaticRefillValue
                                  | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li ngbNavItem class="nav-item-responsive">
          <a ngbNavLink data-e2e="services-link">Serviços</a>
          <ng-template ngbNavContent>
            <app-bill-services></app-bill-services>
          </ng-template>
        </li>
        <li ngbNavItem class="nav-item-responsive">
          <a ngbNavLink data-e2e="pass-reversal-link">Passagens e Estornos</a>
          <ng-template ngbNavContent>
            <app-bill-passes-chargeback></app-bill-passes-chargeback>
          </ng-template>
        </li>
      </ul>
    </div>
  </div>
</div>

<div class="box-body">
  <div [ngbNavOutlet]="nav"></div>
</div>
