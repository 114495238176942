    
    <div class="AddressFormComponent">
        <!-- Nav tabs -->
        <ul class="nav nav-tabs">
            <li class="nav-item">
            <a class="nav-link" [ngClass]="newAddress?.addressTypeId == 1 ? 'active' : ''"  id="commonAdress" (click)="changeAddressTypeId(1);">
                Endereço comum
            </a>
            </li>
            <li class="nav-item">
            <a class="nav-link" [ngClass]="newAddress?.addressTypeId == 2 ? 'active' : ''" id="mailbox"  (click)="changeAddressTypeId(2);">
                Caixa Postal
            </a>
            </li>
        </ul>

        <div class="tab-content">
            <div class="tab-pane container active">
                <form [formGroup]="AddressForm" (ngSubmit)="onSubmit()">
                    <div class="formGroup row">
                        <div class="formfield col-lg-12">
                            <label for="zipCode" data-e2e="zipCode-label">CEP</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['zipCode']?.invalid && AddressForm.controls['zipCode']?.touched)
                                  || (submitted && AddressForm.controls['zipCode']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput zipCodeInput"
                                  data-e2e="zipCode-input"
                                  id="zipCode"
                                  type="text"
                                  formControlName="zipCode"
                                  [mask]="'AAAAA-AAA'">
                
                                <div class="alertErrorValidator" data-e2e="zipCode-error-msg"
                                  *ngIf="(AddressForm.controls['zipCode']?.invalid && AddressForm.controls['zipCode']?.touched)
                                  || (submitted && AddressForm.controls['zipCode']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['zipCode'])}}
                                </div>
                             </div>
                          </div>
                          <div class="col-lg-7"></div>
                          <div class="formfield col-lg-12" *ngIf="newAddress?.addressTypeId == 1">
                            <label for="streetAddress" data-e2e="streetAddress-label">Endereço</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['streetAddress']?.invalid && AddressForm.controls['streetAddress']?.touched)
                                  || (submitted && AddressForm.controls['streetAddress']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput streetAddressInput"
                                  data-e2e="streetAddress-input"
                                  id="streetAddress"
                                  type="text"
                                  formControlName="streetAddress">          
                                <div class="alertErrorValidator" data-e2e="streetAddress-error-msg"
                                  *ngIf="(AddressForm.controls['streetAddress']?.invalid && AddressForm.controls['streetAddress']?.touched)
                                  || (submitted && AddressForm.controls['streetAddress']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['streetAddress'])}}
                                </div>
                             </div>
                          </div>
                          <div class="formfield col-lg-6" *ngIf="newAddress?.addressTypeId == 1">
                            <label for="number" data-e2e="number-label">Número</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['number']?.invalid && AddressForm.controls['number']?.touched)
                                  || (submitted && AddressForm.controls['number']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput numberInput"
                                  data-e2e="number-input"
                                  id="number"
                                  type="text"
                                  formControlName="number">             
                                <div class="alertErrorValidator" data-e2e="number-error-msg"
                                  *ngIf="(AddressForm.controls['number']?.invalid && AddressForm.controls['number']?.touched)
                                  || (submitted && AddressForm.controls['number']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['number'])}}
                                </div>
                             </div>
                          </div>
                          <div class="formfield col-lg-6" *ngIf="newAddress?.addressTypeId == 1">
                            <label for="district" data-e2e="district-label">Bairro</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['district']?.invalid && AddressForm.controls['district']?.touched)
                                  || (submitted && AddressForm.controls['district']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput districtInput"
                                  data-e2e="district-input"
                                  id="district"
                                  type="text"
                                  formControlName="district">             
                                <div class="alertErrorValidator" data-e2e="district-error-msg"
                                  *ngIf="(AddressForm.controls['district']?.invalid && AddressForm.controls['district']?.touched)
                                  || (submitted && AddressForm.controls['district']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['district'])}}
                                </div>
                             </div>
                          </div>
                          <div class="formfield col-lg-6">
                            <label for="city" data-e2e="city-label">Cidade</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['city']?.invalid && AddressForm.controls['city']?.touched)
                                  || (submitted && AddressForm.controls['city']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput cityInput"
                                  data-e2e="city-input"
                                  id="city"
                                  type="text"
                                  formControlName="city">             
                                <div class="alertErrorValidator" data-e2e="city-error-msg"
                                  *ngIf="(AddressForm.controls['city']?.invalid && AddressForm.controls['city']?.touched)
                                  || (submitted && AddressForm.controls['city']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['city'])}}
                                </div>
                             </div>
                          </div>
                          <div class="formfield col-lg-6">
                            <label for="federatedUnit" data-e2e="federatedUnit-label">Estado</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['federatedUnit']?.invalid && AddressForm.controls['federatedUnit']?.touched)
                                  || (submitted && AddressForm.controls['federatedUnit']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput federatedUnitInput"
                                  data-e2e="federatedUnit-input"
                                  id="federatedUnit"
                                  type="text"
                                  formControlName="federatedUnit">             
                                <div class="alertErrorValidator" data-e2e="federatedUnit-error-msg"
                                  *ngIf="(AddressForm.controls['federatedUnit']?.invalid && AddressForm.controls['federatedUnit']?.touched)
                                  || (submitted && AddressForm.controls['federatedUnit']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['federatedUnit'])}}
                                </div>
                             </div>
                          </div>
                          <div class="formfield col-lg-6"  *ngIf="newAddress?.addressTypeId == 1">
                            <label for="streetAddressLine2" data-e2e="streetAddressLine2-label">Complemento</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['streetAddressLine2']?.invalid && AddressForm.controls['streetAddressLine2']?.touched)
                                  || (submitted && AddressForm.controls['streetAddressLine2']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput streetAddressLine2Input"
                                  data-e2e="streetAddressLine2-input"
                                  id="streetAddressLine2"
                                  type="text"
                                  formControlName="streetAddressLine2">             
                                <div class="alertErrorValidator" data-e2e="federatedUnit-error-msg"
                                  *ngIf="(AddressForm.controls['streetAddressLine2']?.invalid && AddressForm.controls['streetAddressLine2']?.touched)
                                  || (submitted && AddressForm.controls['streetAddressLine2']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['streetAddressLine2'])}}
                                </div>
                             </div>
                          </div>
                          <div class="formfield col-lg-6"  *ngIf="newAddress?.addressTypeId == 1">
                            <label for="landmark" data-e2e="landmark-label">Referência</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['landmark']?.invalid && AddressForm.controls['landmark']?.touched)
                                  || (submitted && AddressForm.controls['landmark']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput landmarkInput"
                                  data-e2e="landmark-input"
                                  id="landmark"
                                  type="text"
                                  formControlName="landmark">             
                                <div class="alertErrorValidator" data-e2e="landmark-error-msg"
                                  *ngIf="(AddressForm.controls['landmark']?.invalid && AddressForm.controls['landmark']?.touched)
                                  || (submitted && AddressForm.controls['landmark']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['landmark'])}}
                                </div>
                             </div>
                          </div>
                          <div class="formfield col-lg-12"  *ngIf="newAddress?.addressTypeId == 2">
                            <label for="mailbox" data-e2e="mailbox-label">CÓDIGO DE CAIXA POSTAL</label>
                            <div class="formInput">
                                 <input
                                  [ngClass]="(AddressForm.controls['mailbox']?.invalid && AddressForm.controls['mailbox']?.touched)
                                  || (submitted && AddressForm.controls['mailbox']?.invalid) ? 'invalidField' : ''"
                                  class="AddressInput mailboxInput"
                                  data-e2e="mailbox-input"
                                  id="mailbox"
                                  type="text"
                                  formControlName="mailbox">             
                                <div class="alertErrorValidator" data-e2e="mailbox-error-msg"
                                  *ngIf="(AddressForm.controls['mailbox']?.invalid && AddressForm.controls['mailbox']?.touched)
                                  || (submitted && AddressForm.controls['mailbox']?.invalid)">
                                  {{ generateErrorMessage(AddressForm.controls['mailbox'])}}
                                </div>
                             </div>
                          </div>
                          <div class="formfield col-lg-12 formfieldCheckbox">
                             <label for="principalFlg" class="checkbox-label" data-e2e="principalFlg-label">Tornar endereço principal?</label>
                             <div class="formInput">
                                <input data-e2e="principalFlg-checkbox"  class="checkbox-input" type="checkbox" formControlName="principalFlg" (click)="onCheckboxChange($event)" />
                                <span class="checkmark"></span>
                             </div>
                          </div>
                    </div>
            </form>
            </div>
        </div>
        <!-- end Tab panes -->
    </div>
    