<div class="CredentialsModalComponent">
  <div class="modal-content">
      <header class="modal-header">
          <div class="box-title" ><span data-e2e="modal-title">CREDENCIAIS DE INTEGRAÇÃO</span></div>
          <button class="close" type="button" (click)="this.backContract()">
          <span aria-hidden="true">×</span>
          </button>
      </header>

      <div class="modal-body show" *ngIf="isLoading">
          <div class="container-loading" >
          <app-loader [isLoading]="isLoading" message="{{ 'Carregando...'}}"></app-loader>
          </div>
      </div>

      <div class="modal-body show" *ngIf="!isLoading">

          <div class="form-password d-flex align-items-center justify-content-center" *ngIf="step==1">
            <div class="row justify-content-center">
              <div class="col-6">
                <p>Para sua segurança, confirme sua senha para continuar.</p>

                <form [formGroup]="validPasswordForm">
                  <div class="form-group">
                    <label>Senha</label>
                    <div class="input-password">
                      <input [type]="hidepassword ? 'password' : 'text'" maxlength="30" placeholder="Digite sua senha" name="password" id="password" formControlName="password"/>
                      <i class="fa fa-eye" id="eye-password" *ngIf="hidepassword" (click)="viewpass()"></i>
                      <i class="fa fa-eye-slash" id="eye-password" *ngIf="!hidepassword" (click)="viewpass()"></i>
                    </div>
                  </div>
                </form>

                <p class="alert-validator" *ngIf="(validPasswordForm.controls['password']?.invalid && validPasswordForm.controls['password']?.touched) || this.passwordInvalid">
                  Senha inválida.
                </p>

              </div>
            </div>
          </div>

          <div class="form-password d-flex align-items-center justify-content-center mb-3" *ngIf="step==2">
            <div class="row justify-content-center">
              <div class="col-6">

                <div class="form-group">
                  <label>Client ID</label>
                  <div class="input-password">
                    <input type="text" value="{{this.clientId}}" #inputClientId />
                    <i class="fa fa-copy" id="eye-password" (click)="copyToClipboard(inputClientId, false)"></i>
                  </div>
                </div>
                <div class="copied" *ngIf="copied">Código copiado!</div>
                @if (this.userProfileService.isCustomer()) {
                  <div class="form-group">
                    <label>Client Secret</label>
                    <div class="input-password">
                      <input type="password" value="{{this.clientSecret}}" #inputSecret #passwordInput/>
                      <i class="fa fa-copy" id="eye-password" (click)="copyToClipboard(passwordInput.value, true)"></i>
                    </div>
                  </div>
                  <div class="copiedSecret" *ngIf="copiedSecret">Código copiado!</div>
                }
              </div>
            </div>
          </div>

      </div>

      <div class="modal-footer modal-footer-gray" [style.justify-content]="justifyStyle()">
        <button *ngIf="step == 1" [disabled]="isLoading" type="button" class="btn btn-tertiary mr-4" data-dismiss="modal" aria-label="Close" (click)="backContract()">Cancelar</button>
        <button *ngIf="step == 1" [disabled]="validPasswordForm.invalid" class="btn btn-secondary" type="button" (click)="viewCredentials()">Confirmar Senha</button>
        <button *ngIf="step == 2" (click)="close()" class="btn btn-secondary closeModal" type="button">Fechar</button>
      </div>

  </div>
</div>
