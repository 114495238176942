import { ConciliationFeedbackModalComponent } from './components/conciliation-feedback-modal/conciliation-feedback-modal.component';
import { Component, OnInit } from '@angular/core';
import { TransactionPartnersConciliationQueryArguments } from './dtos/transaction-partners-conciliation-query-arguments';
import { ISourcePartner, ITollVoucherConciliationFiles, ITransactionPartnersConciliaton, vprConciliationFileStatusId } from './dtos/transaction-partners-conciliation.dto';
import { TransactionPartnersConciliationService } from './services/transaction-partners-conciliation.service';
import { finalize } from 'rxjs';
import { NotifyService } from '@services/notify.service';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportConciliationDocumentModalComponent } from './components/import-conciliation-document-modal/import-conciliation-document-modal.component';


@Component({
  selector: 'app-transaction-partners-conciliation',
  templateUrl: './transaction-partners-conciliation.component.html',
  styleUrls: ['./transaction-partners-conciliation.component.scss']
})
export class TransactionPartnersConciliationComponent implements OnInit{
  message: string = 'Loading...';
  isLoading: boolean = false;
  isOpen: boolean;
  noFiles: boolean;
  Enum = vprConciliationFileStatusId;
  perPages = [10, 20, 50];
  tableHeader: [string,string][] = [
    ['referenceDate', 'Mês Referência'],
    ['partnerName', 'Razão Social Parceiro'],
    ['registrationDate', 'Data de importação'],
    ['reconciledValue', 'Valor conciliado'],
    ['vprConciliationFileStatusId', 'Status']
  ];
  queryArguments: TransactionPartnersConciliationQueryArguments;
  TollVoucherConciliationFiles: ITollVoucherConciliationFiles;
  SourcePartners: ISourcePartner[]
  selectedPartners: number[] = [];

  constructor( private _transactionPartnersConciliationService: TransactionPartnersConciliationService,
    private _notifyService : NotifyService,
    private _modalService: NgbModal,
  ) {
    this.queryArguments = new TransactionPartnersConciliationQueryArguments();
  }
  ngOnInit(): void {
    this.getData()
  }

  private getData(){
    this._transactionPartnersConciliationService.getSourcePartners().subscribe((sourcePartners) => {
      this.SourcePartners = sourcePartners;
    });
   this.getTransactionPartnersConciliationFiles(this.queryArguments)
  }
  getTransactionPartnersConciliationFiles(args: TransactionPartnersConciliationQueryArguments) {
    this.isLoading = true;
    this.message = 'Loading...';
    this._transactionPartnersConciliationService
      .getTransactionPartnersConciliation(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(
        {next: (success) => {
            this.noFiles = success.total == 0;
            this.TollVoucherConciliationFiles = success;
          },
          error:(error) => {
            console.error(error);
            this._notifyService.showError("Erro","Erro ao buscar dados do relatório")
          }
        }
      );
  }

  onPaginationChange(event: any) {
    this.queryArguments.page = event;
    this.getTransactionPartnersConciliationFiles(this.queryArguments);
  }
  onPageSizeChange(event: any) {
    this.queryArguments.page = 1;
    this.queryArguments.pageSize = event.target.value;
    this.getTransactionPartnersConciliationFiles(this.queryArguments);
  }
  onSelectPartners(partnerId: number,event: any) {
    let index = this.selectedPartners.indexOf(partnerId);

    if (index > -1) {
      this.selectedPartners.splice(index, 1);
    } else {
      this.selectedPartners.push(partnerId);
    }

    this.queryArguments.sourcePartnerTypeId = this.selectedPartners;

    this.getTransactionPartnersConciliationFiles(this.queryArguments);
  }
  Download() {
    this.isLoading = true;

    this.message = 'Download in progress...';
    this._transactionPartnersConciliationService

      .getTransactionPartnersConciliationCsv(this.queryArguments)

      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((x) => {
        this.handleCsv(x)
      });
  }
  private handleCsv(x: any){
    let now = new Date();
    let date = moment(now).format('DDMMYYYY');
    const element = document.createElement('a');
        var newBlob = new Blob([x.file], { type: 'text/csv' });
        element.href = URL.createObjectURL(newBlob);
        element.download = 'Conciliação repasses de parceiros_' + date + '.csv';
        element.click();
  }

  onOrderByChange(field: string) {
    if (this.queryArguments.sortField === field) {
      this.queryArguments.desc = !this.queryArguments.desc;
    } else {
      this.queryArguments.sortField = field;
      this.queryArguments.desc = true;
    }
    this.getTransactionPartnersConciliationFiles(this.queryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc':
        this.queryArguments.sortField == field && !this.queryArguments.desc,
      'order-by-desc':
        this.queryArguments.sortField == field && this.queryArguments.desc,
    };
  }

  openImportDocumentsModal() {
    const modal = this._modalService.open(ImportConciliationDocumentModalComponent);
    modal.componentInstance.SourcePartners = this.SourcePartners;
    modal.componentInstance.eventReload.subscribe(() => {
      this.getTransactionPartnersConciliationFiles(this.queryArguments);
    });

  }

  openErrorsModal(errors : string[], total : number,  itemInfo : ITransactionPartnersConciliaton) {
    const modal = this._modalService.open(ConciliationFeedbackModalComponent);
    modal.componentInstance.Errors = errors.length > 0 ? errors : ['Erro desconhecido na conciliação, revise a planilha e tente novamente']
    modal.componentInstance.totalErrors = total > 0 ? total : 1
    modal.componentInstance.hasErrors = true
    modal.componentInstance.itemId = itemInfo.id

    let itemData = this.convertDate(itemInfo.referenceDate.toString())

    let partner;
    this.SourcePartners.forEach((x) => {
      if (x.id === itemInfo.sourcePartnerTypeId) {
        partner = x.ipefName;
      }
    });

    modal.componentInstance.itemInfo = `${partner}_${itemData}`

  }

   convertDate(referenceDate: string) {
    const partesData: string[] = referenceDate.split('/');
    const mesNome: string = partesData[0];
    const ano: string = partesData[1];
    const meses: { [key: string]: string } = {
        "janeiro": "01",
        "fevereiro": "02",
        "março": "03",
        "abril": "04",
        "maio": "05",
        "junho": "06",
        "julho": "07",
        "agosto": "08",
        "setembro": "09",
        "outubro": "10",
        "novembro": "11",
        "dezembro": "12"
    };
    // Verificando se o nome do mês é válido
    const mesNumero: string = meses[mesNome.toLowerCase()];
    if (!mesNumero) {
      throw new Error("Nome do mês inválido: " + mesNome);
      }
    return `${mesNumero}${ano}`;
  }
}
