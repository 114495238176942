<div class="notification-area dropdown">
  <div class="ico-notification" [ngClass]="numberNotification > 0 ? 'ico-notification-active' : 'ico-notification-inactive'" (mouseover)="openDropdown('button')" (mouseout)="closeDropdown('button')">
    <div class="numberNotification" *ngIf="numberNotification > 0">
      {{handleNumberNotification()}}
    </div>
  </div>
  <div
    (mouseover)="openDropdown('dropdownItem')"
    (mouseout)="closeDropdown('dropdownItem')"
    class="notifications custom-tooltip dropdown-menu Header_UserMenu_Dropdown"
    [ngbCollapse]="!isOpenNotifications">
    <div class="notificationsTitle">
      <div class="status row">
          <div class="col-sm-auto pending"><div>•</div><span>não lido</span></div>
          <div class="col-sm-auto solved"><div>•</div><span>lido</span></div>
          </div>
    </div>
    <div class="notificationsBody" *ngIf="length > 0">
      <div
        class="NotificationsList row"
        (click)="markNotificationAsRead(notification);"
        [ngClass]="getClassNotificationRow(notification)"
        *ngFor="let notification of notificationQueryResult | slice: 0 : 5; let i = index">
        <div class="Lighthouse col-sm-auto">
          <div [ngClass]="notification.notificationStatusTypeId == 1  ? 'red' : 'turquoise'">•</div>
        </div>
        <div
          id="notificationDescription{{i}}"
          class="linetext col"
          [ngClass]="notification.notificationStatusTypeId == 1 ? 'red' : 'black'">
          <p class="gray">{{ notification.registrationDate | date: "HH:mm - dd/MM/yyyy"}}</p>
          <p class="mt-0">
            <ng-container *ngIf="notification.description.startsWith('Protocolo:'); else noProtocol">
              <strong>{{ notification.description.substr(0, 9) }}</strong>{{ notification.description.substr(9) }}
            </ng-container>
            <ng-template #noProtocol>
              {{ notification.description }}
            </ng-template>
          </p>
        </div>
      </div>
    </div>
    <div class="notificationsBody empty" *ngIf="length == 0">
      Não há notificações
    </div>
    <button class="notificationsFooter" [disabled]="true">
      Ver todas as notificações
    </button>
</div>
</div>
