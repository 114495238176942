<div class="tag-block-unblock-modal">
  <section class="modal-header">

    <div class="box-title"><span>{{ title }}</span></div>

    <button class="close" type="button" (click)="dismissModal()" id="btn-dismiss">
      <span aria-hidden="true">×</span>
    </button>
  </section>

  <section class="modal-body tag-block-unblock-modal__body">
    @if (isLoading) {

      <div class="tag-block-unblock-modal__loader">

        <app-loader [isLoading]="isLoading" message="Carregando..."></app-loader>

      </div>
    }
    @else {

      <div class="box-information box-information--yellow">
        <img class="box-information__icon" alt="Ícone exclamação com triângulo">

        <section class="box-information__text-container">
          <p class="box-information__text">{{ infoBoxText }}</p>
        </section>
      </div>

      @if (step == stepTypes.CONFIRMATION) {
        <h5 class="tag-block-unblock-modal__subtitle" >{{ subtitle }}</h5>

        <div class="tag-block-unblock-modal__selected-items" id="number-selected-items" data-e2e="number-selected-items">
          <b>{{ inTagsSelected.length }}</b> veículos selecionados
        </div>

        <div class="tag-block-unblock-modal__table-container">
          <table class="table tag-block-unblock-modal__table">
            <thead class="thead tag-block-unblock-modal__thead">
              <tr>
                <th class="tag-block-unblock-modal__th">
                  <div class="btn-order-table">
                    Veículo
                  </div>
                </th>

                <th class="tag-block-unblock-modal__th">
                  <div class="btn-order-table">
                    TAG
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              @for (item of inTagsSelected; track $index) {
                <tr>
                  <td id="table-data-modal-license-plate-{{$index}}" [attr.data-e2e]="'table-data-modal-license-plate-' + $index"
                    class="tag-block-unblock-modal__td tag-block-unblock-modal__td--emphasis"
                  >
                    {{ item.licensePlate }}
                  </td>

                  <td id="table-data-modal-serial-tag-{{$index}}" [attr.data-e2e]="'table-data-modal-serial-tag-' + $index"
                    class="tag-block-unblock-modal__td tag-block-unblock-modal__td--emphasis"
                  >
                    {{ item.serialTag }}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>

        @if (isUserProfileBackOffice()) {
          <form [formGroup]="tagBlockUnblockForm">

            <div class="input-box tag-block-unblock-modal__input-box">
              <label class="tag-block-unblock-modal__label" for="input-protocol">Informe o protocolo de solicitação <span>*</span></label>
              <input
                class="input-box__input tag-block-unblock-modal__input"
                [class.is-invalid]="
                  tagBlockUnblockForm.controls.protocol.invalid &&
                  tagBlockUnblockForm.controls.protocol.touched
                "
                formControlName="protocol"
                id="input-protocol"
                data-e2e="input-protocol"
                type="text"
                placeholder="Digite aqui o protocolo"
                maxlength="50"
                required
              />
            </div>

            @if (tagBlockUnblockForm.controls.protocol.invalid
                  && tagBlockUnblockForm.controls.protocol.touched) {
              <span class="tag-block-unblock-modal__input-error-msg">Digite um protocolo válido.</span>
            }
          </form>
        }
      }
      @else {

        <div class="tag-block-unblock-modal__feedback-body">
          <img class="tag-block-unblock-modal__feedback-icon" alt="Ícone de sucesso">
          <h5 class="tag-block-unblock-modal__feedback-subtitle" id="feedback-data-info" data-e2e="feedback-data-info">
            {{ feedbackSubtitle }} de {{ inTagsSelected.length }} tags em andamento!
          </h5>
          <p class="tag-block-unblock-modal__feedback-text">
            Acompanhe o status pela área de notificações ou pelo painel de Gestão em Lote.
          </p>
          <span class="tag-block-unblock-modal__feedback-data" id="feedback-date-username" data-e2e="feedback-date-username">{{ feedbackDate | date: 'dd/MM/yyyy - HH:mm:ss' }} por {{ feedbackUsername }}</span>

          @if (isUserProfileBackOffice()) {
            <p class="tag-block-unblock-modal__feedback-protocol" id="feedback-protocol" data-e2e="feedback-protocol"><b>Protocolo </b>{{ feedbackProtocol }}</p>
          }
        </div>
      }
    }

  </section>

  <section class="modal-footer modal-footer-gray tag-block-unblock-modal__footer">
    @if (step == stepTypes.CONFIRMATION) {
      <button
        class="btn btn-tertiary"
        id="modal-btn-cancel"
        data-e2e="modal-btn-cancel"
        type="button"
        (click)="dismissModal()"
      >
        Cancelar
      </button>
      <button
        class="btn btn-secondary tag-block-unblock-modal__btn-confirm"
        id="modal-btn-confirm"
        data-e2e="modal-btn-confirm"
        type="button"
        (click)="confirmBlockUnblock()"
        [disabled]="isInvalidToConfirm()"
      >
        <img [ngClass]="getBtnIconClass()" alt="Ícone cadeado">
        {{ btnConfirm }}
      </button>
    }
    @else {
      <button
        class="btn btn-secondary"
        id="modal-btn-close-below"
        data-e2e="modal-btn-close-below"
        type="button"
        (click)="dismissModal()"
      >
        Fechar
      </button>
    }
  </section>
</div>
