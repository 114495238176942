<div class="mt-6 px-0 tot">
  <div class="row totRow">
    <div class="col-sm-auto pr-0 columnVehicleList">
      <div class="row mb-3">
        <div class="col-12 px-0">
          <button
            data-e2e="filter-toggle-btn"
            type="button"
            id="button-animated"
            aria-controls="dropdown-animated"
            class="btn btn-filter dropdown-toggle"
            [class.without-border-bottom-radius]="isOpenDropdown"
            [class.active]="isOpenDropdown"
            [class.large-box]="isOpenDropdown"
            (click)="toogle()"
          >
            <img src="assets/img/filter.svg" class="button-icon" />
            Filtros
          </button>

          <div class="filter" *ngIf="open">
            <div class="form-header-search">
              <label data-e2e="search-label" for="formNumberOrderInput">Pesquisar</label>
              <input
                data-e2e="search-input"
                [(ngModel)]="search"
                id="formNumberOrderInput"
                placeholder="placa e apelido"
                type="text"
                (keyup)="onFullNameKeyup($event)"
                class="form-control ico-insert-text"
              />

              <button
                data-e2e="search-btn"
                id="searchButton"
                type="button"
                (click)="onSearchClick($event)"
                class="btn btn-primary btn btn-search"
              >
                <i class="ico ico-search-white"></i><span>buscar</span>
              </button>
            </div>
          </div>

          <!--                <div  class="col-md-auto search form-inline filter">
                    <label>Pesquisar</label>
                    <input type="text" placeholder="placa e apelido" class="form-control ico-insert-text" (keyup)="onFullNameKeyup($event)" [value]="getBillVehiclesQueryArguments.search" />
                    <button type="button" class="btn btn-primary" (click)="onSearchClick($event)"><i class="ico ico-search-white"></i> buscar</button>
                </div>
              -->
        </div>
      </div>

      <div class="container-loading" *ngIf="isLoading">
        <app-loader
          [isLoading]="isLoading"
          message="{{ 'Carregando...' }}"
        ></app-loader>
      </div>

      <div *ngIf="Empty == false">
        <ul class="box-vehicles">
          <li
            *ngFor="let vehicles of BillVehiclesResultDto?.data; let i = index"
            [ngClass]="ChangeClassSelect(i)"
            (click)="
              gridVehicles.OpenVehicleInfos(
                vehicles.licensePlate,
                vehicles.hierarchyId,
                vehicles.hierarchyDescription,
                vehicles.registerNickName
              );
              SelectVehicle(i)
            "
          >
            <div class="row align-items-center pl-3">
              <div class="col-8 d-flex flex-column">
                <span [attr.data-e2e]="'vehicle-plate-' + i" id="DetailLicensePlate{{ i }}">{{
                  vehicles.licensePlate
                }}</span>
                <small [attr.data-e2e]="'vehicle-nickname' + i" id="DetailNickname{{ i }}">{{
                  vehicles.registerNickName
                }}</small>
                <span [attr.data-e2e]="'vehicle-amount' + i" id="DetailAmountTotal{{ i }}" class="fs-1">{{
                  vehicles.amountTotal
                    | currency: "BRL":"symbol":"0.2-2":"pt-BR"
                }}</span>
              </div>

              <div class="col-4">
                <i
                  id="selectDetailVehicle{{ i }}"
                  class="ico-bill ico-white ico-search"
                  (click)="
                    gridVehicles.OpenVehicleInfos(
                      vehicles.licensePlate,
                      vehicles.hierarchyId,
                      vehicles.hierarchyDescription,
                      vehicles.registerNickName
                    );
                    SelectVehicle(i)
                  "
                ></i>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div data-e2e="empty-msg" class="empty" *ngIf="Empty == true">
        Não há veículos disponíveis.
      </div>

      <div
        class="bill-vehicles pagination-custom pagination-custom--side"
        *ngIf="
          !isLoading &&
          BillVehiclesResultDto?.total >
            BillVehiclesQueryArguments.pageVehicleSize
        "
      >
        <app-pagination
          [totalItems]="BillVehiclesResultDto?.total"
          [itemsPerPage]="BillVehiclesQueryArguments.pageVehicleSize"
          [maxPages]="3"
          [currentPage]="BillVehiclesQueryArguments.pageVehicle"
          (pageChangeEvent)="onPaginationChange($event)"
        ></app-pagination>
      </div>
    </div>
    <div class="col-sm-auto px-0 columnVehicleDesc">
      <div class="container-grid-vehicles">
        <app-grid-vehicles #gridVehicles></app-grid-vehicles>
      </div>
    </div>
  </div>
</div>
