import { Component, OnInit, EventEmitter } from '@angular/core';
import { BillListService } from './bill-list.service';
import { Bill } from '@models/old/financial/bill/bill-list/bill.dto';
import { ContractModality } from '@models/old/contractModality.enum';
import { EventEmitterService } from '@services/event-emitter.service';
import { Router } from '@angular/router';
import { AbstractProcessLauncher } from "../../../../../shared/abstracts/abstract-process-launcher";

@Component({
  selector: 'app-bill-list',
  templateUrl: './bill-list.component.html',
  styleUrls: ['./bill-list.component.scss']
})
export class BillListComponent extends AbstractProcessLauncher implements OnInit {
  bills: Bill[];
  contractModality = ContractModality;

  status: any = ['Pago', 'Aberto', 'Fechado', 'A Pagar', 'Atrasado', 'Fechamento']
  posPago: boolean;
  public isLoading;
  public message = 'Carregando...';
  pendentsNumber: number;
  updateAfterContractAlertFeedback = new EventEmitter<any>();
  contractModalityTypeId: ContractModality;

  constructor(
    protected billListService: BillListService,
    protected router: Router
  ) {
    super(router);
  }

  ngOnInit(): void {
    this.emmitEventWhenStartComponent();
    this.GetListBillsByContractId();
    this.contractModalityTypeId = this.contractService.getContractModalityTypeId();
  }

  private emmitEventWhenStartComponent(): void {
    this.updateAfterContractAlertFeedback = EventEmitterService.get('updateAfterContractAlertFeedback');
    if (this.updateAfterContractAlertFeedback.observers) {
      this.updateAfterContractAlertFeedback.subscribe(() => this.GetListBillsByContractId());
    }
  }

  GetListBillsByContractId() {
    this.isLoading = true
    this.billListService.GetListBillsByContractId().then((result) => {
      this.bills = result;
      this.pendentsNumber = result.filter((data) => data.idStatus == 6 || data.idStatus == 5).length;
      this.isLoading = false
      if (this.bills[0].contractType === 2) {
        this.posPago = true;
      }
    }, (errors) => {
      console.log("Promise rejeitada: " + JSON.stringify(errors));
    });
  }
}
