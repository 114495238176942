<div class="modal-content">
  <div class="modal-header">
    <div data-e2e="modal-title" class="box-title"><span>EXCLUIR NÍVEL DE HIERARQUIA</span></div>
    <button data-e2e="close-modal-top-btn" class="close" type="button" (click)="close()" id="btn-close">
      <span aria-hidden="true">×</span>
    </button>
  </div>

  <div class="modal-body show">
    <div class="steps-body">
      <div class="steps-remove row">
        <div
          id="tab-assosiar"
          [ngClass]="
            Step1Active === true
              ? 'step-active1 step col-sm-auto'
              : 'step-inactive1 step col-sm-auto'
          "
        >
          <div
            class="row"
            [ngClass]="Step1Active === true ? 'ico-associate' : ''"
          ></div>
          <p data-e2e="step-one-title" class="row">ASSOCIAR?</p>
        </div>

        <div
          id="tab-excluir"
          [ngClass]="
            Step2Active === true
              ? 'step-active2 step col-sm-auto'
              : 'step-inactive2 step col-sm-auto'
          "
        >
          <div
            class="row"
            [ngClass]="Step2Active === true ? 'ico-trash' : ''"
          ></div>
          <p data-e2e="step-two-title" class="row">EXCLUIR</p>
        </div>

        <div
          id="tab-concluido"
          [ngClass]="
            Step3Active === true
              ? 'step-active3 steplast col-sm-auto'
              : 'step-inactive3 steplast col-sm-auto'
          "
        >
          <div
            class="row"
            [ngClass]="Step3Active === true ? 'ico-ok' : ''"
          ></div>
          <p data-e2e="step-three-title" class="row">CONCLUÍDO</p>
        </div>
      </div>
    </div>

    <div class="container-loading" *ngIf="isLoading">
      <app-loader
        [isLoading]="isLoading"
        message="{{ 'Carregando...' }}"
      ></app-loader>
    </div>

    <div class="step1-body" *ngIf="!isLoading && Step1Active == true">
      <div
        class="header-step"
        [ngClass]="withoutVehicle === false ? 'default' : 'notdefault'"
      >
        <p *ngIf="withoutVehicle == false && FirstLevel == false">
          Deseja antes associar o(s) {{ totalVehicles }} veículo(s) a outro
          grupo?
        </p>
        <p *ngIf="withoutVehicle == true && FirstLevel == false">
          Grupo não possui veículos
        </p>
        <p *ngIf="FirstLevel == true">
          Exclusão de primeiro nível. Não há grupos para associar
        </p>
      </div>

      <div
        class="row-box-yellow"
        *ngIf="withoutVehicle == false && FirstLevel == false"
      >
        <span class="box-yellow">
          <b>É</b> possível adicionar os veículos sempre no último nível de
          algum grupo.
        </span>
      </div>

      <div class="body-step" *ngIf="FirstLevel == false">
        <div
          class="row"
          *ngFor="let childs of ChildrenDisassociate; let count = index"
        >
          <div class="col-sm-auto column">
            <div class="active_tags">
              <div [attr.data-e2e]="'hierarchy-path-' + count" id="childs">{{ childs.description_PT }}</div>
              <div>
                <p [attr.data-e2e]="'hierarchy-name-'" id="vehicleCount">{{ childs.vehicleCount }}</p>
                veículo(s) associado(s)
              </div>
            </div>
          </div>

          <div class="col column">
            <div class="vehicles">
              <a><img src="/assets/img/veiculos_juntos.svg" /></a>
            </div>
          </div>

          <div class="col-sm-auto column">
            <div
              class="other_groups"
              *ngIf="this.availableChildrens.length == 0"
            >
              <div data-e2e="empty-hierarchy-msg" class="withoutGroups" id="withoutGroups">
                Não há grupos disponíveis para associar
              </div>
            </div>

            <div
              class="other_groups"
              *ngIf="this.availableChildrens.length !== 0"
            >
              <label
                class="container"
                *ngFor="let destinyChild of availableChildrens"
                ><div class="row">
                  <p [attr.data-e2e]="'hierarchy-switch-path-' + count" class="col-sm-auto">
                    {{
                      generatebreadCrumb(
                        destinyChild.breadCrumb,
                        destinyChild.description_PT
                      )
                    }}
                  </p>
                  <p [attr.data-e2e]="'hierarchy-switch-name-' + count" class="col-sm-auto">{{ destinyChild.description_PT }}</p>
                </div>

                <input
                  type="radio"
                  [attr.data-e2e]="'hierarchy-switch-checkbox-' + count"
                  id="inputDestinyChild"
                  value="{{ destinyChild.id }}"
                  name="{{ childs.description_PT }}"
                  (change)="
                    onItemChange(
                      childs.id,
                      $event.target.value,
                      count,
                      destinyChild.breadCrumb,
                      destinyChild.description_PT
                    )
                  "
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="step2-body" *ngIf="!isLoading && Step2Active == true">
      <div class="header-step">
        <p data-e2e="confirmation-title">Tem certeza que deseja excluir a hierarquia de grupos abaixo?</p>
      </div>

      <div class="body-step">
      <!--   <tree-ngx [nodeItems]="nodeItems">
          <ng-template #nodeNameTemplate let-node="node" let-context="context">
            <span
              class="row"
              data-e2e="hierarchy-confirmation-name"
              [ngClass]="getDescClass(node.description_PT, node.vehicleCount)"
              [class.active]="context.active"
              >{{ node.description_PT }}</span
            >
            <div *ngIf="node.vehicleCount !== 0" class="new_hierarchy row">
              <a class="col-sm-auto"><img src="/assets/img/caminhao.svg" /></a>
              <p data-e2e="hierarchy-confirmation-path" class="col-sm-auto">{{ ShowBreadCrumb(node.id) }}</p>
              <p data-e2e="hierarchy-confirmation-description" class="col-sm-auto">{{ ShowDescription(node.id) }}</p>
            </div>
            <span data-e2e="hierarchy-confirmation-vehicles" class="row vehiclesCount" *ngIf="node.vehicleCount !== 0"
              >{{ node.vehicleCount }} veículo(s) desassociado(s)</span
            >
          </ng-template>
        </tree-ngx> -->
        <app-cdk-tree [tree]="nodeItems" [viewMode]="true" [deleteModal]="generateDeleteModalTreeObject()"></app-cdk-tree>
      </div>
    </div>

    <div class="!isLoading && step3-body" *ngIf="Step3Active == true">
      <div class="header-step">
        <p data-e2e="step-title" *ngIf="successDeleted == true">Exclusão Concluída</p>
        <p data-e2e="step-title" *ngIf="successDeleted == false">Ocorreu um erro na exclusão!</p>
      </div>

      <div class="body-step">
        <div class="row hierarchy-deleted">
          <div class="col-3">
            <div class="trash">
              <a><img src="/assets/img/lixeira.svg" /></a
              ><a
                ><img src="/assets/img/subdirectory_arrow_right-24px.svg"
              /></a>
            </div>
          </div>

          <div class="col-6">
            <div data-e2e="hierarchy-deleted-path" class="breadCrumb" id="breadCrumb">
              {{ breadCrumb }}
            </div>
          </div>

          <div class="col-3"></div>
        </div>
        <div
          class="new_hierarchy row"
          *ngFor="let hierarchy of ChangeHierarchy; let i=index"
        >
          <a class="col-sm-auto"><img src="/assets/img/caminhao.svg" /></a>
          <p [attr.data-e2e]="'hierarchy-deleted-path-' + i" class="col-sm-auto" id="generatebreadCrumb">
            {{ generatebreadCrumb(hierarchy.breadCrumb, hierarchy.description_PT) }}
          </p>
          <p [attr.data-e2e]="'hierarchy-deleted-name-' + i" class="col-sm-auto">{{ hierarchy.description_PT }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      *ngIf="Step1Active == true"
      class="btn btn-default mr-4"
      id="nextStep"
      data-e2e="next-step-btn"
      (click)="nextStep()"
    >
      Prosseguir >
    </button>

    <button
      *ngIf="Step2Active == true"
      class="btn btn-repom btn-repom-user"
      id="SaveChanges"
      (click)="nextStep(); SaveChanges()"
      data-toggle="modal"
      data-e2e="confirm-delete-btn"
      type="button"
    >
      <i class="ico ico-remove"></i>Confirmar exclusão<span
        class="btn-animation"
      ></span>
    </button>

    <button
      type="button"
      class="btn btn-default2 btn-primary mr-4"
      data-dismiss="modal"
      aria-label="Close"
      data-e2e="modal-close-btn"
      (click)="close()"
      id="btn-dismiss"
    >
      Fechar
    </button>
  </div>
</div>
