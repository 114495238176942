<div class="transferVprComponent">
  <div class="modal-header">
    <h4 class="modal-title text-uppercase">Transferir Saldo</h4>
    <a class="btn-close" (click)="activeModal.close()">
      <img src="assets/svg/close.svg" />
    </a>
  </div>
  <div class="modal-body">
    <div class="baloon">
      <span>O</span> saldo de vale-pedágio poderá ser transferido para outro
      veículo com tag ativa que faça parte do mesmo contrato
    </div>

    <div class="main-grid">
      <div class="vehicle-details">
        <h4>Dados do vínculo atual</h4>
        <div class="details">
          <div class="values">
            <span>Veículo</span>
            <span>{{ vehicle.licensePlate | mask: 'SSS-0A00' }}</span>
          </div>
          <hr />
          <div class="values">
            <span>Tag</span>
            <span>{{ vehicle.serialTag }}</span>
          </div>
          <hr />
          <div class="values">
            <span>Saldo vale-pedágio</span>
            <span>{{
              balance | currency: 'BRL' : 'symbol' : '0.2-2' : 'pt-BR'
            }}</span>
          </div>
        </div>
      </div>
      <div class="select-vehicle">
        <h4>Escolha um veículo cadastrado para transferir o saldo</h4>
        <app-vehicles-by-filter
          (changeVehicle)="getSelectedVehicle($event)"
          [filterType]="EFilterTypes.vehiclesWithActiveTag"
        ></app-vehicles-by-filter>
      </div>
    </div>
  </div>
  <div class="modal-footer mt-4">
    <button class="btn btn-outlined" (click)="previous()" translate>
      Voltar
    </button>
    <button
      class="btn btn-primary btn-custom"
      [disabled]="!selectedVehicle"
      (click)="next()"
    >
      Transferir saldo e cancelar tag
    </button>
  </div>
</div>
