import { HttpParams } from '@angular/common/http';


export class BillHierarchyQueryArguments {
  id: number;
  page: number;
  pageSize: number;
  sortField: string;
  desc: boolean;
  HierarchyId: string;
  SearchHierarchy: string;

  get httpParams() {
    let httpParams = new HttpParams()
      .set('BillId', this.id?.toString())
      .set('Page', this.page?.toString())
      .set('PageSize', this.pageSize?.toString())
      .set('SortField', this.sortField)
      .append('SortOrder', this.desc ? '1' : '0');

     if (this.HierarchyId)
      httpParams = httpParams.set('HierarchyId', this.HierarchyId);
      
      if (this.SearchHierarchy) {
        httpParams = httpParams.set('SearchHierarchy', this.SearchHierarchy);
      }
   

    return httpParams;
  }
}


