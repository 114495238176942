import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReportsRoutingModule } from './routes/reports-routing.module';
import { ReportsComponent } from './reports.component';
import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import {SharedModule} from "../../../shared/shared.module";
import {CoreModule} from "../../../core/core.module";

registerLocaleData(ptBr);

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    ReportsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [ReportsComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    DatePipe,
    provideNgxMask(),
  ],
})
export class ReportsModule {}
