<div class="container header-page tags-osa">
  <h1 data-e2e="page-title" translate>Tags received from OSA</h1>
  <div class="actions-header-page tags-osa">
    <form class="input-search">
      <input
        type="text"
        data-e2e="search-input"
        class="tags-osa"
        placeholder="{{ 'Tag number or lot' | translate }}"
        (keyup)="onFullNameKeyup($event)"
        [value]="tagsQueryArguments.search"
      />
      <button
        data-e2e="search-btn"
        class="btn btn-search"
        translate
        (click)="onBuscarClick($event)"
      >
        <img src="assets/img/icon-search.svg" class="button-icon" />
        <span translate>Search</span>
      </button>
    </form>
  </div>
</div>

<div class="container col-md-11 mt-5">
  <div class="card mb-5 card-loading">
    <div class="card-body pt-0">
      <div
        class="title d-flex align-items-center justify-content-between mt-4 mb-5"
      >
        <div class="d-flex justify-content-around align-items-center">
          <app-filters
            [hasTransctionDateFilter]="true"
            (filtersParams)="handleFiltersParams($event)"
            (resetParams)="resetFilters()"
          >
          </app-filters>
          <form novalidate>
            <div class="form-group select-table">
              <label data-e2e="page-size-label" translate>Lines amount</label>
              <select
                data-e2e="page-size-select"
                class="form-control form-select"
                (change)="onPageSizeChange($event)"
              >
                <option
                  *ngFor="let lines of perPages; let i = index"
                  [attr.data-e2e]="'page-size-option-' + i"
                  value="{{ lines }}"
                >
                  {{ lines }} linhas
                </option>
              </select>
            </div>
          </form>
        </div>
      </div>

      <div class="px-4">
        <table class="table tags-osa">
          <thead class="thead">
            <tr>
              <th>
                <button
                  data-e2e="sort-manufacturing-btn"
                  class="btn-order-table"
                  translate
                >
                  Manufacturing date and time
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-tag-btn"
                  class="btn-order-table"
                  translate
                >
                  Tag number
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-obuid-btn"
                  class="btn-order-table"
                  translate
                >
                  OBUID
                </button>
              </th>
              <th>
                <button
                  data-e2e="sort-batch-btn"
                  class="btn-order-table"
                  translate
                >
                  Lot number
                </button>
              </th>
            </tr>
          </thead>

          <div class="container-loading" *ngIf="isLoading">
            <app-loader
              [isLoading]="isLoading"
              message="{{ 'Loading...' | translate }}"
            ></app-loader>
          </div>

          <tbody>
            <tr *ngIf="getTagsQuery.total == 0">
              <td
                data-e2e="empty-search-msg"
                colspan="12"
                class="text-center w-100 py-4"
                translate
              >
                No result for this search
              </td>
            </tr>
            <tr *ngFor="let tag of getTagsQuery.tags; let i = index">
              <td [attr.data-e2e]="'table-manufacturing-' + i">
                {{ tag?.manufacturingDate | date: 'dd/MM/yyyy - HH:mm' }}
              </td>
              <td [attr.data-e2e]="'table-tag-' + i">{{ tag?.tagNumber }}</td>
              <td [attr.data-e2e]="'table-obuid-' + i">{{ tag?.obuid }}</td>
              <td [attr.data-e2e]="'table-batch-' + i">
                {{ tag?.productionBatch }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-custom"  *ngIf="getTagsQuery.total != 0">
        <ngb-pagination

          (pageChange)="onPaginationChange($event)"
          [pageSize]="tagsQueryArguments.pageSize"
          [maxSize]="5"
          [collectionSize]="getTagsQuery.total"
          [(page)]="tagsQueryArguments.page"
          [rotate]="true"
          [boundaryLinks]="true"
          [ellipses]="false"
          display="dynamic"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
