<card-model class="card-model">
  <ng-container *ngIf="stats$ | async as stats">
    <header class="card-model-header">
      <h1>Minha Frota</h1>
      <p>Acompanhe e controle os status de seus veículos.</p>
    </header>

    <section class="card-model-container">
      <div class="action-container">
        <button
          type="button"
          btn-export
          [service]="'myFleet'"
          [filters]="filters"
        ></button>
      </div>

      <div class="stats-cards-container">
        <card-model *ngFor="let item of stats">
          <div class="stats-card" [class]="item.color">
            <span>{{ item.stats }}</span>
            <p>{{ item.title }}</p>
          </div>
        </card-model>
      </div>

      <div class="footer-action-container">
        <button
          data-e2e="activate-tag-link"
          type="button"
          routerLink="/ativar-tags"
          class="btn btn-primary"
        >
          Ativar Tag
        </button>
      </div>
    </section>
  </ng-container>
</card-model>
