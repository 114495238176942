import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { UserConsultantCreateUpdateRoutingModule } from './routes/user-consultant-create-update-routing.module';
import { UserConsultantCreateUpdateComponent } from './user-consultant-create-update.component';
import { UserConsultantModalComponent } from './components/user-consultant-modal/user-consultant-modal.component';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";
import {NgxMaskDirective, NgxMaskPipe} from "ngx-mask";


@NgModule({
  declarations: [
    UserConsultantCreateUpdateComponent,
    UserConsultantModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    UserConsultantCreateUpdateRoutingModule,
    NgxMaskDirective,
    NgxMaskPipe
  ]
})
export class UserConsultantCreateUpdateModule { }
