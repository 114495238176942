<form class="FormInsertComponent" [formGroup]="holidaysForm">
  <div formArrayName="Rows">
    @for (holiday of formArr.controls; track holiday; let index = $index) {
      <div class="line">
        <div class="FormLineHolidayComponent" [formGroupName]="index">
          <div class="toll-input-box">
            <input
              class="toll-input-box__input"
              [class.is-invalid]="checkNameInvalid(index)"
              formControlName="name"
              [value]="holiday.get('name').value"
              [id]="'input-name-'+ index"
              data-e2e="input-name"
              type="text"
              placeholder="Nome do feriado"
              maxlength="50"
              required
            />
            @if (holiday.get('name').invalid && holiday.get('name').touched) {
              <app-vmessage
                id="msg-error-input-protocol"
                errorMessage="{{ generateErrorMessageByFormControlName('name', index) }}"
              >
              </app-vmessage>
            }
          </div>

          <div>
            <app-input-date-picker
              [id]="index"
              [date]="holiday.get('date').value"
              (formControlChange)="onDateChange($event, index)" (blur)="isTouched(index)"
              [invalid]="checkValidateDate(index)"
              [message]="generateErrorMessageByFormControlName('date', index)">
            </app-input-date-picker>
          </div>

          <mat-checkbox [id]="'checkbox-' + index" formControlName="repeatAnnually"></mat-checkbox>

          <img src="/assets/svg/icon-trash-blue.svg" (click)="remove(index)" alt="remover linha"/>
        </div>
      </div>
    }
  </div>
</form>
<div class="buttons">
  <a class="link-page-not-bg" (click)="adicionarLinha()">Adicionar outro feriado</a>
  <hr/>
  <button
    class="btn-bg-color-blue"
    [disabled]="areAllFormsValid()"
    data-e2e="export-btn"
    (click)="addHolidaysList()"
  >
    <img src="assets/svg/icon-success-white.svg" alt="ícone check"/>
    Confirmar inclusão
  </button>
</div>
