import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from 'rxjs/operators';
import { GetVehicleListQueryArguments } from '../../dtos/getVehicleListQueryResult.dto';
import { HierarchyService } from '../../services/hierarchy.service';
import { GetVehiclesQueryResultDto } from '../../../myvehicles/dtos/get-vehicles-query-result.dto';
import { VehiclesDto } from '@models/old/vehicles.model';
import { MyvehiclesService } from '../../../myvehicles/services/myvehicles.service';

@Component({
  selector: 'app-view-vehicle',
  templateUrl: './view-vehicle.component.html',
  styleUrls: ['./view-vehicle.component.scss']
})
export class ViewVehicleComponent implements OnInit {


  @Input() hierarchicaId;

  isLoading: boolean;
  description_PT: any;
  hierarchyId: any;
  breadCrumb: any;
  getVehiclesQueryArguments: GetVehicleListQueryArguments;
  getVehiclesQueryResult: GetVehiclesQueryResultDto;
  vehicles: Array<VehiclesDto>;
  perPages = [];




  constructor(
    protected myVehiclesService: MyvehiclesService,
    private bsModalRef: BsModalRef,
    private hierarchyService: HierarchyService
  ) {
    this.vehicles = [];

    this.perPages = [10, 20, 50];

    this.getVehiclesQueryResult = {
      total: 0,
      vehicles: []
    }

    this.getVehiclesQueryArguments = new GetVehicleListQueryArguments();
    this.getVehiclesQueryArguments.page = 1;
    this.getVehiclesQueryArguments.pageSize = 10; // TODO: Pegar do componnete verificar
    this.getVehiclesQueryArguments.orderBy = "licensePlate";
    this.getVehiclesQueryArguments.desc = false;
    this.getVehiclesQueryArguments.filter = 1;
    this.getVehiclesQueryArguments.search = "";
  }

  ngOnInit(): void {
    this.GetHierarchyList();
    this.getFromServer(this.getVehiclesQueryArguments);
  }


  onPageSizeChange(event: any) {
    // When changing the quantity per page, it returns to the first one in case the pagination focuses less than the current page
    this.getVehiclesQueryArguments.page = 1;
    this.getVehiclesQueryArguments.pageSize = event.target.value;
    this.getFromServer(this.getVehiclesQueryArguments);
  }

  bindOrderByClass(field: string) {
    return {
      'order-by-asc': this.getVehiclesQueryArguments.orderBy == field && !this.getVehiclesQueryArguments.desc,
      'order-by-desc': this.getVehiclesQueryArguments.orderBy == field && this.getVehiclesQueryArguments.desc
    }
  }


  onOrderByChange(field: string) {
    if (this.getVehiclesQueryArguments.orderBy === field) {
      this.getVehiclesQueryArguments.desc = !this.getVehiclesQueryArguments.desc;
    } else {
      this.getVehiclesQueryArguments.orderBy = field;
      this.getVehiclesQueryArguments.desc = true;
    }

    this.getFromServer(this.getVehiclesQueryArguments);

  }

  onPaginationChange(page: number) {
    this.getVehiclesQueryArguments.page = page;
    this.getFromServer(this.getVehiclesQueryArguments);
  }

  close() {
    this.bsModalRef.hide();
  }

  GetHierarchyList() {
    this.isLoading = true;
    this.hierarchyService
      .GetHierarchyBreadCrumbList()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success: any) => {
          this.description_PT = success.data.filter(item => item.id == this.hierarchicaId).map(item => item.description_PT).toString()
          let breadCrumb = success.data.filter(item => item.id == this.hierarchicaId).map(item => item.breadCrumb).toString()
          this.breadCrumb = breadCrumb.replace(this.description_PT, '')
        },
        (error) => {
          console.error("getFromServerError", error);
          return;
        }
      );
  }


  getFromServer(args: any) {
    this.isLoading = true;
    this.getVehiclesQueryArguments.HierarchyId = this.hierarchicaId;
    this.myVehiclesService
      .getVehicles(args)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (success) => {
          this.getVehiclesQueryResult = success;
        },
        (error) => {
          // TODO: Depois que for implementado alertService descomentar a linha de baixo para tratar os retornos das apis
          //this.alertService.danger(error.message);
          console.error("getFromServerError", error);
          return;
        }
      );
  }

}
