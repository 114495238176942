import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { UserConsultantViewRoutingModule } from './routes/user-consultant-view-routing.module';
import { UserConsultantViewComponent } from './user-consultant-view.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserConsultantDeleteModalComponent } from './components/user-consultant-delete-modal/user-consultant-delete-modal.component';
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";
import {NgxMaskPipe} from "ngx-mask";

@NgModule({
  declarations: [
    UserConsultantViewComponent,
    UserConsultantDeleteModalComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    UserConsultantViewRoutingModule,
    NgxMaskPipe
  ]
})
export class UserConsultantViewModule { }
