<div class="myorderComponent">
  <div class="Dashboard container-fluid page-myaccount">
    <div class="row">
        <div class="col-12 Dashboard_Overview header-page">
            <div class="row">
                <div class="col-lg-7">
                    <h1 data-e2e="page-title">/ MEUS PEDIDOS</h1>
                </div>
              <div class="col-lg-3 deliveryAddresses">
                <a>
                  <button *accessControl="101140" data-e2e="open-delivery-addresses-modal-btn"  id="AddressButton" type="button" class="btn btn-repom btn-repom-user" (click)="showDeliveryAddressesModal()">
                    <i class="ico ico-address"></i>Endereços para entrega<span class="btn-animation"></span></button>
                </a>
              </div>
                <div class="col-lg-2 newOrder" *controlAccessCustomer>
                    <a>
                        <button
                         *accessControl="101170"
                          data-e2e="new-order-link"
                          [routerLink]="['/novo-pedido']"
                          buttonBlock
                          [ContractBlock]="[2,3,6]"
                          id="newOrderButton"
                          class="btn btn-repom btn-repom-user"
                          data-target="#userIncludeModal"
                          data-toggle="modal"
                          type="button">
                          <i class="ico ico-shopping-car "></i>
                          novo pedido
                          <span class="btn-animation"></span>
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-12">
          <app-first-access-ballon [page]="3"></app-first-access-ballon>
        </div>
        <div class="container">
          <div class="row d-flex justify-content-end">
            <div class="col-lg-12 col-header-filte">
              <div class="row d-flex justify-content-end">
                <div class="col-lg-2">
                  <p><i data-e2e="filter-label" class="ico title ico-filter-transparent">Filtros</i></p>
                </div>

                <div class="col-lg-3 col-position simulate-select">
                    <div class="form-group form-icon">
                      <label data-e2e="period-filter-label" for="datePickerInput">Período</label>
                      <input
                        id="datePickerInput"
                        data-e2e="period-filter-input"
                        type="text"
                        placeholder="Selecione"
                        class="simulate-form-control form-control ico-date-picker"
                        #dp="bsDaterangepicker"
                        bsDaterangepicker
                        [bsConfig]="bsConfig"
                        placement="right"
                        [maxDate]="maxDate"
                        (bsValueChange)="onFilterDate($event)"
                        [bsValue]="dayCustom"
                        [minDate]="minDate"
                        [outsideClick]="false"
                        (click)="toggleDateCard()">

                      <div data-e2e="card-toggle-btn" class="simulate-button" [class.active]="dateOpen" (click)="toggleDateCard();dp.toggle();">
                        <img src="assets/img/arrow-select.svg" />
                      </div>

                      <div class="card date-picker-card" [ngClass]="{'show-date-picker-card' : dateOpen}">
                        <div class="clean-filter align-items-end d-flex justify-content-center">
                          <button
                            data-e2e="clear-filter-btn"
                            id="resetFiltersButton"
                            class="d-flex justify-content-center btn btn-primary"
                            (click)="resetFilters();dp.hide()">
                            <img class="button-icon" src="assets/img/ico-delete.svg" />
                            Limpar filtro
                          </button>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="col-lg-2 col-position ">
                    <div class="form-group form-icon ">
                        <label data-e2e="order-filter-label" for="formNumberOrderInput">Número de pedido</label>
                        <input
                          (keyup)="changeFilter($event)"
                          data-e2e="order-filter-input"
                          [value]="argsActiveOrders.tagRequestId"
                          #order
                          class="form-control ico-insert-text"
                          id="formNumberOrderInput"
                          placeholder="preencha"
                          type="text">
                    </div>
                </div>

                <div class="col-lg-4 col-position ">
                  <div class="dropdown form-group form-icon ">
                    <label data-e2e="status-filter-label" for="formStatusSelect" class="statusLabel">Status</label>
                    <i class="ico-arrow"></i>
                    <select
                      data-e2e="status-filter-select"
                      *modalityType="2"
                      (change)="changeStatus($event)"
                      [value]="argsActiveOrders.tagRequestStatusTypeId"
                      #status
                      class="form-control ico-status-text form-select"
                      id="formStatusSelect">
                        <option data-e2e="status--filter-option-0" value="0">Todos</option>
                        <option data-e2e="status--filter-option-1" value="1">&nbsp; > Pedido realizado</option>
                        <option data-e2e="status--filter-option-3" value="3">&nbsp; > Em separação</option>
                        <option data-e2e="status--filter-option-4" value="4">&nbsp; > Enviado para transportadora</option>
                        <option data-e2e="status--filter-option-5" value="5">&nbsp; > Em transporte</option>
                    </select>

                    <select
                      *modalityType="1"
                      data-e2e="status-filter-select"
                      (change)="changeStatus($event)"
                      [value]="argsActiveOrders.tagRequestStatusTypeId"
                      #status
                      class="form-control ico-status-text form-select"
                      id="formStatusSelect">
                      <option data-e2e="status-filter-option-0" value="0">Todos</option>
                      <option data-e2e="status-filter-option-9" value="9">&nbsp; > Pedido solicitado</option>
                      <option data-e2e="status-filter-option-8" value="8">&nbsp; > Aguardando pagamento</option>
                      <option data-e2e="status-filter-option-12" value="12">&nbsp; > Pagamento recusado</option>
                      <option data-e2e="status-filter-option-11" value="11">&nbsp; > Pagamento aprovado</option>
                      <option data-e2e="status-filter-option-1" value="1">&nbsp; > Pedido realizado</option>
                      <option data-e2e="status-filter-option-3" value="3">&nbsp; > Em separação</option>
                      <option data-e2e="status-filter-option-4" value="4">&nbsp; > Enviado para transportadora</option>
                      <option data-e2e="status-filter-option-5" value="5">&nbsp; > Em transporte</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12 col-box-repom ">
              <div class="row " id="rodoviario ">
                <div class="container-loading " *ngIf="isActiveOrdersLoading ">
                  <app-loader [isLoading]="isActiveOrdersLoading " message="{{ 'Carregando...'}} "></app-loader>
                </div>

                <div class="col-lg-4 " *ngFor="let item of allActiveOrdersFiltered; let i = index">
                  <div class="box-repom ">
                    <div class="row row-top ">
                      <div class="col-lg-6 ">
                        <div class="blue ">
                          <p [attr.data-e2e]="'order-filter-title-' + i" class="fw-600">PEDIDO</p>
                          <b [attr.data-e2e]="'order-filter-id-' + i" id="orderId{{i}}">{{ item.id }}</b>
                        </div>
                        <div *controlAccessCustomer>
                          <div *accessControl="101173" >
                            <button [attr.data-e2e]="'order-filter-confirm-receipt-btn' + i" class="orange " (click)="confirmReceiptTagRequest(item) " *ngIf="item.tagRequestStatusTypeId==5 ">
                              confirmar recebimento
                            </button>
                          </div>
                        </div>
                        <div *controlAccessBackOffice>
                          <button [attr.data-e2e]="'order-filter-confirm-receipt-btn' + i" class="orange" *ngIf="item.tagRequestStatusTypeId==5">
                            cliente deve confirmar recebimento das tags
                          </button>
                        </div>
                      </div>

                      <div class="col-lg-6 ">
                        <p [attr.data-e2e]="'order-filter-registration-' + i" class="date" id="registrationDate{{i}}">
                          {{ item.registrationDate | date: 'dd/MM/yyyy' }}
                        </p>

                        <p class="tags">
                          <b [attr.data-e2e]="'order-filter-amount-' + i" id="amount{{i}}">{{ item.amount }}</b> tags
                        </p>

                        <p class="value">
                          <b [attr.data-e2e]="'order-filter-total-' + i" id="totalAmountPayable{{i}}">{{ item.totalAmountPayable | currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}</b>
                        </p>
                      </div>
                    </div>

                    <div class="row row-bottom ">
                      <div class="col-lg-5 ">
                        <p>
                          <!-- <img src="../../../assets/svg/done.svg " />{{item.tagRequestStatusType}} -->
                          <!-- <img *ngIf="order?.tagRequestStatusTypeId==3 " src="assets/img/separate-order.svg " />
                          <img *ngIf="order?.tagRequestStatusTypeId==4 " src="assets/img/shipping-company.svg " />
                          <img *ngIf="order?.tagRequestStatusTypeId==5 " src="assets/img/track.svg " />-->
                          <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position ico-transport " *ngIf="item.tagRequestStatusTypeId==5" id="tagRequestStatusType{{i}}">{{ item.tagRequestStatusType }}</i>
                          <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position ico-carrying " *ngIf="item.tagRequestStatusTypeId==4" id="tagRequestStatusType{{i}}">{{ item.tagRequestStatusType }}</i>
                          <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position ico-separate-order " *ngIf="item.tagRequestStatusTypeId==3" id="tagRequestStatusType{{i}}">{{ item.tagRequestStatusType }}</i>
                          <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position ico-done " *ngIf="item.tagRequestStatusTypeId==1" id="tagRequestStatusType{{i}}">{{ item.tagRequestStatusType }}</i>
                          <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position2 ico-order-8 " *ngIf="item.tagRequestStatusTypeId==8" id="tagRequestStatusType{{i}}">{{ item.tagRequestStatusType }}</i>
                          <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position2 ico-order-9 " *ngIf="item.tagRequestStatusTypeId==9" id="tagRequestStatusType{{i}}">{{ item.tagRequestStatusType }}</i>
                          <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position2 ico-order-11 " *ngIf="item.tagRequestStatusTypeId==11" id="tagRequestStatusType{{i}}">{{ item.tagRequestStatusType }}</i>
                          <i [attr.data-e2e]="'order-filter-status-' + i" class="ico ico-position2 ico-order-12 " *ngIf="item.tagRequestStatusTypeId==12" id="tagRequestStatusType{{i}}">{{ item.tagRequestStatusType }}</i>
                        </p>
                      </div>

                      <div class="col-lg-7 ">
                        <a [attr.data-e2e]="'order-filter-view-link-' + i" [routerLink]="['/detalhe-pedido', item.id]">
                          <!-- <a [routerLink]="[ '/detalhe-pedido', item.id] "> -->
                          <button *accessControl="101172" id="viewOrderButton{{i}}" class="btn btn-repom btn-repom-user " type="button ">
                            <i class="ico ico-search "></i>
                            ver pedido <span class="btn-animation "></span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-e2e="empty-order-result-msg"
          class="col-lg-12 p-0 row d-flex justify-content-center"
          *ngIf="!isActiveOrdersLoading && allActiveOrdersFiltered?.length== 0 ">
          Nenhum pedido em andamento encontrado.
        </div>

        <div class="container mt-5 ">
          <div class="row card ">
            <div class="container-loading " *ngIf="isCanceledReceivedOrdersLoading ">
              <app-loader [isLoading]="isCanceledReceivedOrdersLoading " message="{{ 'Carregando...'}} "></app-loader>
            </div>

            <div class="col-lg-12 py-15px">
              <table class="table">
                <thead class="thead ">
                  <tr>
                    <th scope="col ">
                      <p data-e2e="canceled-order-date-label">data</p>
                    </th>
                    <th scope="col ">
                      <p data-e2e="canceled-order-id-label">número de pedido</p>
                    </th>
                    <th scope="col ">
                      <p data-e2e="canceled-order-tag-amount-label">QUANTIDADE DE TAGS</p>
                    </th>
                    <th scope="col ">
                      <p data-e2e="canceled-order-status-label">STATUS</p>
                    </th>
                    <th scope="col ">
                      <p data-e2e="canceled-order-amount-label">VALOR</p>
                    </th>
                    <th scope="col " class="no-border ">&nbsp;</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let item of allCanceledReceivedOrders; let count = index ">
                    <td [attr.data-e2e]="'canceled-order-date-' + count" id="registrationDate{{count}}">{{ item.registrationDate | date: 'dd/MM/yyyy' }}</td>
                    <td [attr.data-e2e]="'canceled-order-id-' + count" id="id{{count}}">{{ item.id }}</td>
                    <td><span [attr.data-e2e]="'canceled-order-tag-amount-' + count" id="amount{{count}}">{{ item.amount }}</span> TAGS</td>
                    <td class="py-2 ">
                      <i *ngIf="item.tagRequestStatusTypeId==7" class="ico ico-order-checked "></i>
                      <i *ngIf="item.tagRequestStatusTypeId==2" class="ico ico-order-canceled "></i>
                      <span [attr.data-e2e]="'canceled-order-status-' + count" id="tagRequestStatusType{{count}}">{{ item.tagRequestStatusType }}</span>
                    </td>
                    <td [attr.data-e2e]="'canceled-order-amount-' + count" id="totalAmountPayable{{count}}">{{ item.totalAmountPayable| currency:'BRL' : 'symbol':'0.2-2':'pt-BR' }}</td>
                    <td>
                      <a [attr.data-e2e]="'canceled-order-details-link-' + count" href="/detalhe-pedido/{{item.id}} ">
                        <button *accessControl="101172" id="allCanceledReceivedOrdersViewOrderButton" class="btn btn-repom btn-repom-user " type="button ">
                          <i class="ico ico-search "></i> ver
                          pedido
                          <span class="btn-animation "></span>
                        </button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div *ngIf="!isCanceledReceivedOrdersLoading && allCanceledReceivedOrders?.length== 0 ">
                <div data-e2e="empty-order-result-msg" colspan="12 " class="text-center w-100 pt-2" translate>Nenhum pedido cancelado ou recebido encontrado.</div>
              </div>
            </div>

          </div>
        </div>
        <!-- /container  -->
    </div>
</div>
</div>

