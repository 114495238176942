<div class="editContractPhoneModalComponent">
  <form class="form" [formGroup]="phoneForm" (ngSubmit)="onSubmit()">
      <div class="modal-content">
            <div class="modal-header">
              <div class="box-title">
                <span *ngIf="step==1" data-e2e="modal-title">Alterar telefone</span>
                <span *ngIf="step==2" data-e2e="modal-title">código de verificação</span> 
                <span *ngIf="step==3" data-e2e="modal-title">Número de telefone alterado com sucesso</span>                                   
              </div>
              <button data-e2e="close-modal-top-btn" type="button" class="close" (click)="close()">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body show" *ngIf="step == 1">
              <div class="form-description">Para alterar seu telefone, digite o novo número que deseja.</div>
              <div class="form-content">
                <div class="form-field">
                    <label for="newPhone" data-e2e="new-phone-label">Digite o número de telefone</label>
                    <div class="form-input">
                        <input
                        [mask]="getPhoneMask()"
                        [ngClass]="(phoneForm.controls['newPhone']?.invalid && phoneForm.controls['newPhone']?.touched)
                        || (submitted && phoneForm.controls['newPhone']?.invalid) ? 'invalidField' : ''"
                        class="newPhoneInput"
                        data-e2e="new-phone-input"
                        id="newPhone"
                        type="text"
                        formControlName="newPhone">
                        <div class="alert-error-validator" data-e2e="new-phone-error-msg"
                        *ngIf="(phoneForm.controls['newPhone']?.invalid && phoneForm.controls['newPhone']?.touched)
                        || (submitted && phoneForm.controls['newPhone']?.invalid)">
                        {{ generateErrorMessage(phoneForm.controls['newPhone'])}}
                        </div>
                    </div>
                </div>
            </div> 
            <div class="form-info">Você receberá um código de confirmação no seu e-mail <strong>{{ currentUser?.emailAddress }}</strong>.
            </div>
            </div>
            <div class="modal-body show" *ngIf="step == 2">
                <div class="message-update-phone">
                  <strong>Para confirmar a alteração do número de telefone, informe o código de confirmação que você recebeu no seu e-mail.</strong>
              </div>
              <app-verification-code [type]="updateType.phone"></app-verification-code>
            </div>
            <div class="modal-body show" *ngIf="step == 3">
              <div class="success-img">
                  <img src="../../../assets/img/success-update.svg" />
              </div>
              <div class="success-message">O número de telefone da sua conta foi alterado com sucesso!</div>
          </div>
            <div class="modal-footer modal-footer-gray" [ngClass]="step==3 ? 'modal-footer-confirmed' : ''">
                <button *ngIf="step==1 || step==2" data-e2e="cancel-btn" type="button" class="btn btn-tertiary mr-4" (click)="close()" id="btn-close">Cancelar</button>
                <button *ngIf="step==1" data-e2e="send-code-btn" type="submit" class="btn btn-secondary position-relative" [disabled]="phoneForm.invalid || isLoading || samePhoneNumber()">
                  <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
                  Enviar Código</button>      
                  <button *ngIf="step==2" data-e2e="confirm-btn" type="button" class="btn btn-secondary position-relative" [disabled]="isLoading || code?.length < 6 || !code" (click)="confirm()">
                    <img class="button-icon" *ngIf="isLoading" src="../../../assets/img/loader.gif" alt="Loading" />
                     Confirmar
                </button> 
                <button *ngIf="step==3" data-e2e="close-btn" type="button" class="btn btn-secondary position-relative" (click)="close()">
                     Fechar
                </button>        
            </div>
        </div>
    </form>
</div>

