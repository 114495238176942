<card-model class="container-dashboard container-repom container-tag-responsive">
  <header class="dashboard-card-header">
    <div class="dashboard-card-title-box">
      <i class="ico ico-tag"></i>
      <h2 class="card-title" data-e2e="tags-card-title">Tags</h2>
    </div>

    <div>
      <button
        data-e2e="activate-tag-link"
        buttonBlock
        [ContractBlock]="[3]"
        id="activeTagsButton"
        type="button"
        class="btn btn-repom"
        routerLink="/ativar-tags">
        <div>
          Ativar TAGs
          <i class="ico ico-double-arrow"></i>
        </div>

        <span class="btn-animation"></span>
      </button>
    </div>
  </header>

  <section class="col-lg-4 tag-total-responsive">
    <div data-e2e="tag-total" class="tag-total">
      <p>Total de Tags</p>
      <strong id="totalQuantity-responsive" class="tags-count">{{ tag?.totalQuantity }}</strong>
    </div>
  </section>

  <main class="row tag-card-content">
    <section class="col-lg-4">
      <h3 data-e2e="available-tag" class="box-featured box-featured-1">
        <span class="bullet color-1"></span>

        <div>
          <i class="ico ico-info no-responsive" ngbTooltip="Quantidade de tags disponíveis para serem ativadas"></i>
          <i class="ico ico-info responsive" ngbTooltip="Quantidade de tags disponíveis para serem ativadas"
             triggers="click" placement="left"></i>
          Em estoque <br />
          <strong id="availableQuantity">{{ tag?.availableQuantity }}</strong>
        </div>
      </h3>

      <h3 data-e2e="bonus-tag" class="box-featured box-featured-1">
        <span class="bullet color-1"></span>

        <div>
          <i class="ico ico-info no-responsive" ngbTooltip="Quantidade de tags isentas da cobrança de mensalidade de tag em estoque"></i>
          <i class="ico ico-info responsive" ngbTooltip="Quantidade de tags isentas da cobrança de mensalidade de tag em estoque"
             triggers="click" placement="left"></i>
          Bonificadas <br />
          <strong id="bonusQuantity">{{ tag?.bonificationQuantity }}</strong>
        </div>
      </h3>
    </section>

    <section class="col-lg-4">
      <h3 data-e2e="tag-total" class="tag-total text-center">
        <p>Total de Tags</p>
        <strong id="totalQuantity" class="tags-count">{{ tag?.totalQuantity }}</strong>
      </h3>
    </section>

    <section class="col-lg-4">
      <h3 data-e2e="active-tag" class="box-featured box-featured-2">
        <span class="bullet color-2"></span>

        <div>
          Ativas <br />
          <strong id="activeQuantity">{{ tag?.activeQuantity }}</strong>
        </div>
      </h3>

      <h3 data-e2e="blocked-tag" class="box-featured box-featured-3">
        <span class="bullet color-3"></span>

        <div>
          Bloqueadas <br />
          <strong id="blockedQuantity">{{tag?.blockedQuantity}}</strong>
        </div>
      </h3>
    </section>

  </main>
</card-model>
