<main class="CardInsertHolidayComponent">
  <div class="div-link">
    <a class="link-page-not-bg"  id="link-page-holidays-calendar" data-e2e="link-page-holidays-calendar" (click)="backToCalendar()"> Voltar para Calendário de feriados</a>
  </div>
   <section class="table-card">

    <app-header-form-insert-holiday>
    </app-header-form-insert-holiday>

    <app-form-insert-holiday>
    </app-form-insert-holiday>
  </section>
</main>

