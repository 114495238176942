<div class="modal-content confirmation-unlock">

  <div class="modal-header">
    <div class="box-title">
      <span>Bloquear TAGS</span>
    </div>
    <button type="button" class="close" (click)="close()" id="btn-dismiss">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="row-box-yellow">
      <span class="box-yellow">
        <b>O</b> bloqueio da TAG tem um prazo de até 6 horas<br> para ser efetivado em todos os estabelecimentos do Brasil.  
      </span>
    </div>
  </div>
  <div *ngIf="step == 1">
    <div class="modal-body">
      <div class="row row-animation">
        <h3 class="mt-5 font-weight-bold">Tem certeza de deseja bloquear a tag abaixo:</h3>
      </div>
      <div class="row row-modal-collapse">
        <div class="col-lg-12 col-vehicle-tag">
          <div class="row d-flex justify-content-center align-items-center">
            <div class="col-lg-4">
              <p>Veículo</p>
              <span></span>
              <!-- <b><i class="ico ico-white ico-menu-open"></i> v23423 </b> -->
              <b id="licensePlate">{{ vehicle.licensePlate | mask: 'AAA-AAAA' }}</b>
            </div>
            <div class="col-lg-4">
              <p>TAG</p>
              <span></span>
              <!-- <b><i class="ico ico-white ico-menu-open"></i> v23423 </b> -->
              <b id="serialTag">{{ vehicle.serialTag }} </b>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray">
      <button [disabled]="onSaving" type="button" class="btn btn-tertiary mr-4" (click)="close()" id="btn-close">Cancelar</button>
      <button [disabled]="onSaving" class="btn btn-primary ml-4" (click)="blockTag()" id="btn-confirm">
        <img class="button-icon mr-2" *ngIf="!onSaving" src="../../../assets/svg/block.svg" alt="" />
        <img class="button-icon" *ngIf="onSaving" src="../../../assets/img/loader.gif" alt="Loading" />
        CONFIRMAR BLOQUEIO
      </button>
    </div>
  </div>
  <div *ngIf="step == 2">
    <div class="modal-body row">
      <div class="col-9 container-confirmation">
        <div class="col-5 image-content">
          <img src="/assets/img/bg-place.svg" />
        </div>
        <div class="col-7 confirmation-text">
          <h3>Tag bloqueada</h3>
          <div class="row mt-4">
            <div class="col-6 vehicle">
              <span class="simulate-button">
                <img src="/assets/svg/icon-popover.svg" />
              </span>
              <strong id="blockLicensePlate">{{ successBlockTag?.licensePlate | mask: 'AAA-AAAA' }}</strong>
            </div>
            <div class="col-6 tag">
              <strong id="blockTagSerial">{{ successBlockTag?.tagSerial }}</strong>
            </div>
            <div class="author mt-4">
              <p id="changeDate">{{ successBlockTag?.changeDate | date:'dd/MM/yyyy HH:mm' }} por
                  {{ successBlockTag?.userFullName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer modal-footer-gray">
      <button type="button" class="btn btn-secondary" (click)="close()" id="btn-close">Fechar</button>
    </div>
  </div>
</div>