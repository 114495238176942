import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { finalize } from "rxjs/operators";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LegalPerson } from '@models/old/legalperson.model';
import { ContractUsers } from '@models/old/contractusers.model';
import { MyContractService } from '@services/mycontract.service';
import { EventEmitterService } from '@services/event-emitter.service';
import { AdduserModalComponent } from './components/add-user-modal/adduser-modal.component';
import { DeliveryAddressesModalComponent } from 'src/app/shared/components/address/delivery-addresses-modal/delivery-addresses-modal.component';
import { Router } from '@angular/router';
import { AbstractProcessLauncher } from "../../../shared/abstracts/abstract-process-launcher";
import { USER_STATUS } from 'src/app/shared/constants/user-status';

@Component({
  selector: 'app-mycontract',
  templateUrl: './mycontract.component.html',
  styleUrls: ['./mycontract.component.css']
})
export class MycontractComponent extends AbstractProcessLauncher implements OnInit {

  public legalPerson: LegalPerson;
  public id: number;
  isLoading: boolean;
  public loader = {
    reqsFinished: 0,
    reqsNumber: 4,
  };
  public principalPaymentMethodType: number;
  bsModalRef: BsModalRef;
  public commercialCondition: any;
  public groups = [];
  public contractUsers: Array<ContractUsers> = [];
  public codigoContrato: number;

  constructor(
    private modalService: BsModalService,
    private modal: NgbModal,
    private mycontractService: MyContractService,
    protected router: Router
  ) {
    super(router);
  }

  private emmitEventWhenStartComponent(): void {
    EventEmitterService.get('updateList').subscribe(data => this.updateListUser());
  }

  ngOnInit() {
    this.emmitEventWhenStartComponent();
    this.isLoading = true;
    this.showGroups();
    this.getLegalPerson();
    this.showContractUsers();
    this.initializeContract();
  }

  initializeContract() {
    const contractModalityTypeId = this.contractService.getContractModalityTypeId();
    this.getContract(contractModalityTypeId);
  }

  updateListUser() {
    this.isLoading = true;
    this.loader = {
      reqsFinished: 0,
      reqsNumber: 1,
    };
    this.showContractUsers();
  }


  VerifyElementReady() {
    this.loader.reqsFinished++;
    if (this.loader.reqsFinished == this.loader.reqsNumber) {
      return false;
    } else {
      return true;
    }
  }


  getContract(ContractModalityTypeId) {
    if (ContractModalityTypeId == 1) {
      this.getContractPrePaid();
    } else {
      this.getContractPostPaid();
    }
  }


  getContractPrePaid() {
    this.mycontractService
      .getContractPrePaidSummaryById()
      .pipe(
        finalize(() => {
          this.isLoading = this.VerifyElementReady();
        })
      )
      .subscribe(
        (success) => {
          this.commercialCondition = success[0];
        },
        (error) => {
          console.log("error: ", error);
          // this.alertService.danger(error.message);
          return;
        }
      );
  }

  getContractPostPaid() {
    this.mycontractService
      .getContractsById()
      .pipe(
        finalize(() => {
          this.isLoading = this.VerifyElementReady();
        })
      )
      .subscribe(
        (success) => {
          this.codigoContrato = success.contract.id;
          this.commercialCondition = success.contract;
          this.principalPaymentMethodType = success.principalPaymentMethodType;
          this.mycontractService.contractBillingCuttingCycle = success.contractBillingCuttingCycle;
        },
        (error) => {
          console.log("error: ", error);
          // this.alertService.danger(error.message);
          return;
        }
      );
  }

  getLegalPerson() {
    this.isLoading = true;
    this.mycontractService.getLegalPerson()
      .pipe(
        finalize(() => {
          this.isLoading = this.VerifyElementReady();
        })
      )
      .subscribe(
        (success) => {
          this.legalPerson = success;
        },
        (error) => {
          console.log("Promise rejeitada: " + JSON.stringify(error));
          // this.alertService.danger(error.message);
          return;
        }
      );
  }


  showContractUsers() {
    this.mycontractService.getContractUsers()
      .pipe(
        finalize(() => {
          this.isLoading = this.VerifyElementReady();
        })
      )
      .subscribe(
        (suces) => {
          this.contractUsers = this.handleUserStatusByUnblockDate(<ContractUsers[]>suces);
        },
        (error) => {
          console.log('Erro: ' + error);
        }
      );
  }

  showDeliveryAddressesModal() {
    const modalRef = this.modal.open(DeliveryAddressesModalComponent)
    modalRef.componentInstance.fromOrder = false;
  }

  showAddUserModal() {
    const initialState = { parameter: 2020, profileList: this.groups };
    this.bsModalRef = this.modalService.show(AdduserModalComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  showGroups() {
    this.mycontractService.getGroups()
      .pipe(
        finalize(() => {
          this.isLoading = this.VerifyElementReady();
        })
      )
      .subscribe(
        (sucess: any) => {
          this.groups = sucess
        },
        (error) => {
          console.log('Erro: ' + error);
        }
      );
  }

  private handleUserStatusByUnblockDate(users: ContractUsers[]): ContractUsers[]  {
    let usersHandled: ContractUsers[] = [];

    users.forEach((user) => {
      if(user.statusUsuarioId === USER_STATUS.TEMPORARY_BLOCK
          && this.isUnblockDateInPast(user.dataDesbloqueio)
      ) {
        user.statusUsuarioId = USER_STATUS.ACTIVE;
        user.statusUsuarioDescription = 'Ativo'
      }

      usersHandled.push(user);
    });

    return usersHandled;
  }

  private isUnblockDateInPast(unblockDate?: Date | string): boolean {
    if (unblockDate) {
      const dateNow = new Date();
      const dateUnblock = new Date(unblockDate);

      return dateUnblock < dateNow ? true : false;
    }

    return false;
  }

}
